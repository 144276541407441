import { MouseEvent } from "react"
import { CopyIcon, MixerHorizontalIcon, TrashIcon, ReloadIcon } from "@radix-ui/react-icons"
import { styled } from "@stitches/react"
import { blackA, gray, tomato } from "@radix-ui/colors"
import * as cssAnimations from "../../../../styles/cssAnimations"

interface IProps {
  open: boolean
  onEdit?: () => void
  onDuplicate?: () => void
  onRotate?: (e: MouseEvent) => void
  onDelete?: () => void
}

export default function NodeSettings(props: IProps) {
  return (
    <Container visible={props.open}>
      {props.onEdit && (
        <IconButton onClick={props.onEdit}>
          <MixerHorizontalIcon />
        </IconButton>
      )}
      {props.onDuplicate && (
        <IconButton onClick={props.onDuplicate}>
          <CopyIcon />
        </IconButton>
      )}
      {props.onRotate && (
        <IconButton onClick={props.onRotate}>
          <ReloadIcon />
        </IconButton>
      )}
      {props.onDelete && (
        <DangerIconButton onClick={props.onDelete}>
          <TrashIcon />
        </DangerIconButton>
      )}
    </Container>
  )
}

const Container = styled("div", {
  position: "absolute",
  right: -36,
  top: 0,
  bottom: 0,
  display: "none",
  flexWrap: "wrap",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "default",
  animationDuration: "600ms",
  animationTimingFunction: "cubic-bezier(0.250, 0.460, 0.450, 0.940)",
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  animationName: cssAnimations.slideRightAndFade,
  "&:hover": { display: "flex" },
  variants: {
    visible: {
      true: { display: "flex" },
      false: { display: "none" }
    }
  }
})

const IconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  height: 24,
  width: 24,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100%",
  boxShadow: `0 2px 10px ${blackA.blackA8}`,
  margin: 4,
  backgroundColor: gray.gray4,
  "& svg": { width: 12, height: 12 },
  "&:hover": { backgroundColor: gray.gray1 },
  "&:active": { scale: 0.9, boxShadow: `0 2px 5px ${blackA.blackA8}` }
})

const DangerIconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  height: 24,
  width: 24,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100%",
  boxShadow: `0 2px 10px ${blackA.blackA8}`,
  margin: 4,
  color: tomato.tomato10,
  backgroundColor: tomato.tomato6,
  "& svg": { width: 12, height: 12 },
  "&:hover": { backgroundColor: tomato.tomato9, color: tomato.tomato1 },
  "&:active": { scale: 0.9, boxShadow: `0 2px 5px ${blackA.blackA8}` }
})
