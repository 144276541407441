// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HwLTpup_4j9hdJagzENj{display:flex;flex-flow:column;width:100%;min-height:100vh;margin:0}.TZ0XBesy0myGjLepMimB{display:flex;flex-flow:column;flex:1;width:100%;align-items:center;overflow:scroll;position:relative;padding-top:128px}", "",{"version":3,"sources":["webpack://./src/app/components/Layout/Layout.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,UAAA,CACA,gBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CACA,MAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":[".layout {\n  display: flex;\n  flex-flow: column;\n  width: 100%;\n  min-height: 100vh;\n  margin: 0;\n}\n\n.appContainer {\n  display: flex;\n  flex-flow: column;\n  flex: 1;\n  width: 100%;\n  align-items: center;\n  overflow: scroll;\n  position: relative;\n  padding-top: 128px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "HwLTpup_4j9hdJagzENj",
	"appContainer": "TZ0XBesy0myGjLepMimB"
};
export default ___CSS_LOADER_EXPORT___;
