import React from "react"
import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  TextField
} from "@mui/material/"
import styles from "./InlinePicker.module.scss"
import { useNodeEditorStore } from "../../../../../../../context/rootStoreContext"
import { EditorType } from "../../../../../models/NodeEditors"
import ChipInput from "../../../../../../ChipInput"

interface Props {
  promptType?: string
  isMulti?: boolean
  options: string[]
  optionsToSelectIndividually: string[]
  isUndoable?: boolean
  trackResponse?: boolean
  forceValue?: boolean
  crisisDetection?: boolean
}

function InlinePicker(props: Props): JSX.Element {
  const { isMulti = false } = props
  const nodeEditorStore = useNodeEditorStore()
  const { questionEditorState } = nodeEditorStore
  const { promptSettings } = questionEditorState
  const { options, isUndoable, trackResponse, optionsToSelectIndividually } = promptSettings

  const handleAnswerAdd = value => {
    const matches = value.match(/"(.*?)"/g)
    const result = matches ? matches.map(match => match.slice(1, -1)) : [value]
    const updatedPromptSettings = {
      ...promptSettings,
      options: [...options, ...result]
    }
    if (questionEditorState.promptType === "checkbox") {
      updatedPromptSettings.checkboxOptions = [
        ...updatedPromptSettings.checkboxOptions,
        { body: value, initialValue: false }
      ]
    }
    updateState({ promptSettings: updatedPromptSettings })
  }

  const handleAnswerDelete = (value: string): void => {
    const updatedPromptSettings = {
      ...promptSettings,
      options: options.filter(option => option !== value),
      optionsToSelectIndividually: optionsToSelectIndividually.filter(option => option !== value)
    }
    if (questionEditorState.promptType === "checkbox") {
      updatedPromptSettings.checkboxOptions = updatedPromptSettings.checkboxOptions.filter(
        option => option.body !== value
      )
    }
    updateState({
      promptSettings: updatedPromptSettings
    })
  }

  const handleCheckboxChange = (value: boolean, key: string): void => {
    updateState({
      ...questionEditorState,
      promptSettings: {
        ...questionEditorState.promptSettings,
        [key]: value
      }
    })
  }

  const handleTextPromptPlaceholderChange = event => {
    updateState({
      ...questionEditorState,
      promptSettings: {
        ...questionEditorState.promptSettings,
        textPromptPlaceholder: event.target.value
      }
    })
  }

  const updateState = data => {
    nodeEditorStore.updateState(EditorType.QUESTION, data)
  }

  return (
    <div className={styles.inlinePickerContainer}>
      <FormGroup className={styles.checkboxFormGroup}>
        <FormControlLabel
          className={styles.formControlLabel}
          value="undoable"
          control={
            <Checkbox
              checked={isUndoable}
              onChange={(_event, value) => handleCheckboxChange(value, "isUndoable")}
            />
          }
          label="Undoable"
          labelPlacement="start"
        />
        <FormControlLabel
          className={styles.formControlLabel}
          value="trackResponse"
          control={
            <Checkbox
              checked={trackResponse}
              onChange={(_event, value) => handleCheckboxChange(value, "trackResponse")}
            />
          }
          label="Track response"
          labelPlacement="start"
        />
        <FormControlLabel
          className={styles.formControlLabel}
          value="setPeople"
          control={
            <Checkbox
              checked={promptSettings.setPeople ?? false}
              onChange={(_event, value) => handleCheckboxChange(value, "setPeople")}
            />
          }
          label="Set user data in Mixpanel"
          labelPlacement="start"
        />
        <div className={styles.optionsConfiguration}>
          <FormGroup className={styles.autocompleteFormGroup}>
            <ChipInput
              options={options}
              onAdd={handleAnswerAdd}
              onDelete={handleAnswerDelete}
              label="Answers"
              placeholder="Type the possible answers here..."
            />
            {isMulti ? (
              <Autocomplete
                className={styles.autocompleteOptions}
                multiple
                id="answers-individually"
                onChange={(_event, value) =>
                  updateState({
                    promptSettings: { ...promptSettings, optionsToSelectIndividually: value }
                  })
                }
                options={props.options.map(option => option)}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      className={styles.optionChip}
                    />
                  ))
                }
                value={props.optionsToSelectIndividually}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Can be selected individually"
                    placeholder="Add the answers that can be selected individually i.e. None"
                  />
                )}
              />
            ) : null}
          </FormGroup>
          {!isMulti && (
            <FormGroup className={styles.textPromptFormGroup}>
              <FormControlLabel
                className={styles.formControlLabel}
                value="textPromptWithInlineOption"
                control={
                  <Checkbox
                    checked={promptSettings.textPromptWithInlineOption || false}
                    onChange={(_event, value) =>
                      handleCheckboxChange(value, "textPromptWithInlineOption")
                    }
                  />
                }
                label="Include text prompt"
                labelPlacement="start"
              />
              <TextField
                disabled={!promptSettings.textPromptWithInlineOption}
                size="small"
                label="Text prompt placeholder"
                name="textPromptPlaceholder"
                className={styles.textField}
                placeholder="Text prompt placeholder"
                value={promptSettings.textPromptPlaceholder || ""}
                onChange={handleTextPromptPlaceholderChange}
              />
              <FormControlLabel
                disabled={!promptSettings.textPromptWithInlineOption}
                className={styles.formControlLabel}
                value="crisisDetection"
                control={
                  <Checkbox
                    checked={promptSettings.crisisDetection ?? true}
                    onChange={(_event, value) => handleCheckboxChange(value, "crisisDetection")}
                  />
                }
                label="Crisis detection"
                labelPlacement="start"
              />
              <FormControlLabel
                disabled={!promptSettings.textPromptWithInlineOption}
                className={styles.formControlLabel}
                value="textPromptForceValue"
                control={
                  <Checkbox
                    checked={promptSettings.textPromptForceValue || false}
                    onChange={(_event, value) =>
                      handleCheckboxChange(value, "textPromptForceValue")
                    }
                  />
                }
                label="Force value"
                labelPlacement="start"
              />
            </FormGroup>
          )}
        </div>
      </FormGroup>
    </div>
  )
}

export default InlinePicker
