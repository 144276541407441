import React, { useMemo } from "react"
import { observer } from "mobx-react"
import { useServiceConfigurationStore, useServiceStore } from "../../../../context/rootStoreContext"
import Button from "../../../../components/Button"
import BotPlaygroundSection from "../BotPlaygroundSection"
import deepCompare from "../../../../../utils/deepCompare"
import { ServiceConfigurationStore } from "../../../../stores/ServiceConfigurationStore"
import formatTermsAndConditions from "../../../../../utils/formatTermsAndConditions"
import BasicConfigurationInputs from "../BasicConfigurationInputs"
import styles from "./BasicConfiguration.module.scss"

interface Props {
  setError: (value: boolean) => void
}

function BasicConfiguration(props: Props): JSX.Element {
  const uploadedImage = React.useRef<any>(null)
  const uploadedImageMobile = React.useRef<any>(null)
  const imageUploader = React.useRef<HTMLInputElement>(null)

  const serviceStore = useServiceStore()
  const serviceConfigurationStore = useServiceConfigurationStore()
  const serviceDataConfiguration = serviceStore.serviceData?.[serviceStore.mode]?.configuration

  const isChanged = useMemo(() => {
    return !deepCompare(serviceDataConfiguration ?? {}, serviceConfigurationStore.config)
  }, [serviceDataConfiguration, serviceConfigurationStore.config])

  const onInputChange = (input: string, value?: string) => {
    const currentInput = (
      input.includes("organisationTerms") ? "organisationTerms" : input
    ) as keyof ServiceConfigurationStore["config"]

    // Handle multi inputs
    if (currentInput === "organisationTerms" || currentInput.includes("organisationPhoneNumbers")) {
      const [key, index] = input.split("-")
      const newInputValues = [...serviceConfigurationStore.config[key]]
      newInputValues[index] = value
      serviceConfigurationStore.setConfig({ [key]: newInputValues })
    } else {
      serviceConfigurationStore.setConfig({ [input]: value })
    }

    serviceConfigurationStore.validateConfig()
  }

  const onPhoneNumberDelete = organisationPhoneNumbers => {
    serviceConfigurationStore.setConfig({ organisationPhoneNumbers })
    serviceConfigurationStore.validateConfig()
    serviceConfigurationStore.setCurrentInput("organisationPhoneNumbers")
  }

  const onLogoChange = e => {
    const logo = e.target.files[0]
    if (logo) {
      serviceConfigurationStore.setLogo(logo)

      const reader = new FileReader()
      if (uploadedImage.current && uploadedImageMobile.current) {
        uploadedImage.current.file = logo
        uploadedImageMobile.current.file = logo
        reader.onload = e => {
          const source = e.target?.result
          if (source) {
            uploadedImage.current.src = source
            uploadedImageMobile.current.src = source
            serviceConfigurationStore.setConfig({ logo: String(source) })
            serviceConfigurationStore.validateConfig()
          }
        }
        reader.readAsDataURL(logo)
      }
    }
  }

  const onSubmitChanges = async () => {
    try {
      const { organisationTermsNames, organisationTermsLinksText, organisationTermsLinks } =
        serviceConfigurationStore.config
      const organisationTermsNamesParsed = organisationTermsNames.filter(item => item.length)
      const organisationTermsLinksTextParsed = organisationTermsLinksText.filter(
        item => item.length
      )
      const organisationTermsLinksParsed = organisationTermsLinks.filter(item => item.length)
      const organisationTerms = formatTermsAndConditions(
        organisationTermsNamesParsed,
        organisationTermsLinksTextParsed,
        organisationTermsLinksParsed
      )
      const config = {
        ...serviceConfigurationStore.config,
        organisationTerms,
        organisationTermsNames: organisationTermsNamesParsed,
        organisationTermsLinksText: organisationTermsLinksTextParsed,
        organisationTermsLinks: organisationTermsLinksParsed
      }
      const logo = serviceConfigurationStore.logo
      await serviceStore.updateServiceConfiguration(config, logo)
    } catch (e) {
      props.setError(true)
      // TODO: report exception to sentry
      console.log({ e })
    }
  }

  const onDiscardChanges = () => {
    if (isChanged) {
      serviceConfigurationStore.setConfig(
        serviceStore.serviceData?.[serviceStore.mode]?.configuration ?? {}
      )
      serviceConfigurationStore.setLogo()
    } else {
      serviceConfigurationStore.resetConfig()
    }
    serviceConfigurationStore.validateConfig()
  }

  return (
    <>
      <div className={styles.basicConfigurationContainer}>
        <BasicConfigurationInputs
          imageUploader={imageUploader}
          uploadedImage={uploadedImage}
          onColorChange={color =>
            serviceConfigurationStore.setConfig({ userMessageBackground: color.hex })
          }
          onLogoChange={onLogoChange}
          onInputChange={onInputChange}
          onPhoneNumberDelete={onPhoneNumberDelete}
        />
        <div style={{ width: 16 }} />
        <BotPlaygroundSection
          uploadedImage={uploadedImage}
          uploadedImageMobile={uploadedImageMobile}
        />
      </div>
      <div className={styles.basicConfigurationButtons}>
        <Button onClick={onSubmitChanges} disabled={!isChanged}>
          {serviceStore.isLoading ? "Updating..." : "Save Changes"}
        </Button>
        <Button onClick={onDiscardChanges} disabled={!isChanged}>
          Discard Changes
        </Button>
      </div>
    </>
  )
}

export default observer(BasicConfiguration)
