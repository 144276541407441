export default function deepMerge(obj1, obj2) {
  const result = { ...obj1 }
  Object.entries(obj2).forEach(([key, value]) => {
    if (isObject(value) && isObject(obj1[key])) {
      result[key] = deepMerge(obj1[key], value)
    } else {
      result[key] = value
    }
  })
  return result
}

const isObject = (item: any) => !!item && typeof item === "object" && !Array.isArray(item)
