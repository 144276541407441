import React from "react"
import { IconButton } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import BotBubbleEditable from "../../../../../BotPlayground/components/BotBubbleEditable"
import {
  IDefaultChatFlowMessages,
  IDefaultChatFlowSettings,
  StepResultBodyType
} from "@limbic/types"

import styles from "./MessageList.module.scss"

interface Props {
  messages: StepResultBodyType[]
  messageType: keyof IDefaultChatFlowMessages
  disabled?: boolean
  chatFlowSettings: IDefaultChatFlowSettings
  updateState: (data: Record<string, Record<any, any>>) => void
}

const MessageList = ({
  chatFlowSettings,
  messages,
  messageType,
  updateState,
  disabled = false
}: Props) => {
  const deleteMessage = (message: string, key: keyof IDefaultChatFlowMessages): void => {
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        messages: {
          ...chatFlowSettings.messages,
          [key]: [...messages!].filter(m => m !== message)
        }
      }
    })
  }

  const updateMessage = (value: string, index: number, key: string) => {
    const updatedMessages = [...messages!]
    updatedMessages[index] = value
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        messages: {
          ...chatFlowSettings.messages,
          [key]: updatedMessages
        }
      }
    })
  }

  return (
    <>
      {messages?.map((message, index) => (
        <div className={styles.messageRow} key={index}>
          <BotBubbleEditable
            disabled={disabled}
            isFirstMessage={index === 0}
            onMessageChange={(e, v) => updateMessage(e, v, messageType)}
            value={message as string} // TODO: This may not be string sometimes 🤔
            index={index}
          />
          {index !== 0 && (
            <IconButton
              disabled={disabled}
              size="small"
              className={styles.deleteButton}
              onClick={() => deleteMessage(message as string, messageType)}>
              <CancelIcon />
            </IconButton>
          )}
        </div>
      ))}
    </>
  )
}

export default MessageList
