// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wssy7x4HoV4EeOdULV1g{padding-right:1em;padding-bottom:1em;color:rgba(0,0,0,.7)}.I8Vimap0haqtWUAseV8g{margin:0;padding:0;font-weight:bold;font-size:16px;color:red}.q0dAhHBsXog4I2Lss9QW{margin:0;padding-top:8px;padding-left:16px;color:rgba(0,0,0,.7)}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/BookAppointment/BookAppointment.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,kBAAA,CACA,oBAAA,CAGF,sBACE,QAAA,CACA,SAAA,CACA,gBAAA,CACA,cAAA,CACA,SAAA,CAGF,sBACE,QAAA,CACA,eAAA,CACA,iBAAA,CACA,oBAAA","sourcesContent":[".title {\n  padding-right: 1em;\n  padding-bottom: 1em;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.warningInfo {\n  margin: 0;\n  padding: 0;\n  font-weight: bold;\n  font-size: 16px;\n  color: red;\n}\n\n.warningExplanation {\n  margin: 0;\n  padding-top: 8px;\n  padding-left: 16px;\n  color: rgba(0, 0, 0, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Wssy7x4HoV4EeOdULV1g",
	"warningInfo": "I8Vimap0haqtWUAseV8g",
	"warningExplanation": "q0dAhHBsXog4I2Lss9QW"
};
export default ___CSS_LOADER_EXPORT___;
