import React from "react"
import QuestionEditor from "../../../components/FlowEditor/components/QuestionEditor"
import ConditionEditor from "../../../components/FlowEditor/components/ConditionEditor"
import ActionEditor from "../../../components/FlowEditor/components/ActionEditor"
import ChatFlowEditor from "../../../components/FlowEditor/components/ChatFlowEditor"
import AdvancedConditionEditor from "../../../components/FlowEditor/components/AdvancedConditionEditor"
import { useNodeEditorStore } from "../../../context/rootStoreContext"
import { observer } from "mobx-react"
import IneligibleUserEditor from "../../../components/FlowEditor/components/IneligibleUserEditor"

function Editors(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  return (
    <>
      {nodeEditorStore.questionEditorOpen && <QuestionEditor />}
      {nodeEditorStore.conditionEditorOpen && <ConditionEditor />}
      {nodeEditorStore.advancedConditionEditorOpen && <AdvancedConditionEditor maxWidth="lg" />}
      {nodeEditorStore.actionEditorOpen && <ActionEditor />}
      {nodeEditorStore.ineligibleUserEditorOpen && <IneligibleUserEditor />}
      {nodeEditorStore.chatFlowEditorOpen && <ChatFlowEditor />}
    </>
  )
}

export default observer(Editors)
