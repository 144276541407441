import React from "react"
import { observer } from "mobx-react"
import FormControlWithCheckBox from "../../components/FormControlWithCheckBox"
import MessageList from "../../components/MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../../components/MessageForm/MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import Choice from "../../../Choice"
import OptionsPlaceholder from "../../components/OptionsPlaceholder"
import { Typography } from "@mui/material"

import styles from "./CollectDisability.module.scss"

function CollectDisability(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <FormControlWithCheckBox
        name="shouldAskDisabilityStatus"
        label="Ask disability status first"
        labelPlacement="start"
        updateState={updateState}
      />
      <Typography className={styles.questionLabel}>Disability status message:</Typography>
      <MessageList
        disabled={!chatFlowSettings.collectDisability?.shouldAskDisabilityStatus}
        messages={messages?.askDisabilityStatus ?? []}
        messageType="askDisabilityStatus"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <div className={styles.optionContainer}>
        <Choice
          disabled={!chatFlowSettings.collectDisability?.shouldAskDisabilityStatus}
          value="Yes"
          nodeId=""
          index={0}
          withoutHandle={true}
        />
        <Choice
          disabled={!chatFlowSettings.collectDisability?.shouldAskDisabilityStatus}
          value="No"
          nodeId=""
          index={0}
          withoutHandle={true}
        />
      </div>
      <MessageForm
        disabledInput={!chatFlowSettings.collectDisability?.shouldAskDisabilityStatus}
        value={chatFlowSettings.currentMessage?.askDisabilityStatus}
        placeholder="Type a message here..."
        name="askDisabilityStatus"
        updateState={updateState}
        messages={messages?.askDisabilityStatus ?? []}
      />
      <SolidDivider />
      <Typography className={styles.questionLabel}>Disability question message:</Typography>
      <MessageList
        messages={messages?.askDisability ?? []}
        messageType="askDisability"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <OptionsPlaceholder name="Disability" />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askDisability}
        placeholder="Type a message here..."
        name="askDisability"
        updateState={updateState}
        messages={messages?.askDisability ?? []}
      />
      <FormControlWithCheckBox
        name="multiSelectDisabilities"
        label="Allow selecting multiple disabilities (if unchecked, only one disability can be selected)"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldAskOtherDetails"
        label="Should ask details if Other is selected"
        labelPlacement="start"
        updateState={updateState}
      />
      <Typography className={styles.questionLabel}>Other question message:</Typography>
      <MessageList
        disabled={!chatFlowSettings.collectDisability?.shouldAskOtherDetails}
        messages={messages?.askDisabilityOther ?? []}
        messageType="askDisabilityOther"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={!chatFlowSettings.collectDisability?.shouldAskOtherDetails}
        value={chatFlowSettings.currentMessage?.askDisabilityOther}
        placeholder="Type a message here..."
        name="askDisabilityOther"
        updateState={updateState}
        messages={messages?.askDisabilityOther ?? []}
      />
    </>
  )
}

export default observer(CollectDisability)
