// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XMRt_CdvA1mj3QUycAzL{padding-bottom:1em;color:rgba(0,0,0,.7)}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/CollectFeedback/CollectFeedback.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,oBAAA","sourcesContent":[".collectFeedbackTitle {\n  padding-bottom: 1em;\n  color: rgba(0, 0, 0, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collectFeedbackTitle": "XMRt_CdvA1mj3QUycAzL"
};
export default ___CSS_LOADER_EXPORT___;
