import React from "react"
import { TwitterPicker } from "react-color"
import Logo from "../../../../assets/logo.png"
import LogoInput from "../../../../components/LogoInput"
import TermsAndConditions from "../TermsAndConditions"
import PhoneNumbersInput from "../PhoneNumbersInput"
import InputLabel from "../InputLabel"
import { DEFAULT_PICKER_COLORS } from "../../constants"
import styles from "./BasicConfigurationInputs.module.scss"
import { observer } from "mobx-react"
import { useServiceConfigurationStore } from "../../../../context/rootStoreContext"

interface Props {
  imageUploader: React.RefObject<HTMLInputElement>
  // TODO: Faced some issues with ref typing for uploadedImage
  uploadedImage: any
  onColorChange: (_color: {
    hex: string
    rgb: { r: number; g: number; b: number; a: number }
    hsl: { h: number; s: number; l: number; a: number }
  }) => void
  onLogoChange: (_event: Event) => void
  onInputChange: (input: string, text?: string) => void
  onPhoneNumberDelete: (_values: string[]) => void
}

function BasicConfigurationInputs(props: Props): JSX.Element {
  const {
    imageUploader,
    onColorChange,
    onInputChange,
    onLogoChange,
    onPhoneNumberDelete,
    uploadedImage
  } = props
  const serviceConfigurationStore = useServiceConfigurationStore()

  return (
    <div className={styles.configurationInputsContainer}>
      <div className={styles.inputSection}>
        <InputLabel
          label="Logo"
          tooltip="This will be the main logo of the bot. A 1:1 ratio image is recommended"
        />
        <div className={styles.logoInputWrapper}>
          <LogoInput
            onImageError={() => console.warn("error loading bot logo")}
            text="Click here to change the logo"
            onChange={onLogoChange}
            src={Logo}
            onClick={() => imageUploader.current?.click()}
            imageUploader={imageUploader}
            uploadedImage={uploadedImage}
          />
        </div>
      </div>
      <div className={styles.inputSection}>
        <InputLabel
          label="Title *"
          tooltip="This will be the title of the bot, placed on it's header"
          errorMessage={serviceConfigurationStore.errors?.title}
        />
        <input
          onFocus={() => serviceConfigurationStore.setCurrentInput("title")}
          name="title"
          value={serviceConfigurationStore.config.title}
          onChange={e => onInputChange(e.target.name, e.target.value)}
          placeholder="Enter the bot's title"
        />
      </div>
      <div className={styles.inputSection}>
        <InputLabel
          label="Service Name"
          tooltip="The main service name"
          errorMessage={serviceConfigurationStore.errors?.serviceName}
        />
        <input
          onFocus={() => serviceConfigurationStore.setCurrentInput("serviceName")}
          name="serviceName"
          value={serviceConfigurationStore.config.serviceName}
          onChange={e => onInputChange(e.target.name, e.target.value)}
          placeholder="Enter the name of the service"
        />
      </div>
      <div className={styles.inputSection}>
        <InputLabel
          label="Organisation Name *"
          tooltip="The main organisation name"
          errorMessage={serviceConfigurationStore.errors?.organisationName}
        />
        <input
          onFocus={() => serviceConfigurationStore.setCurrentInput("organisationName")}
          name="organisationName"
          value={serviceConfigurationStore.config.organisationName}
          onChange={e => onInputChange(e.target.name, e.target.value)}
          placeholder="Enter the name of the organisation"
        />
      </div>
      <div className={styles.inputSection}>
        <InputLabel
          label="Terms and Conditions"
          tooltip="Add any terms & conditions or privacy policies related to your organisations. At least one is required"
        />
        <TermsAndConditions
          values={{
            names: serviceConfigurationStore.config.organisationTermsNames ?? [],
            linkTexts: serviceConfigurationStore.config.organisationTermsLinksText ?? [],
            links: serviceConfigurationStore.config.organisationTermsLinks ?? []
          }}
          onFocus={() => serviceConfigurationStore.setCurrentInput("organisationTerms")}
          onChange={e => onInputChange(e.target.name, e.target.value)}
        />
      </div>
      <div className={styles.inputSection}>
        <InputLabel
          label="Service Phone Numbers"
          tooltip="Add at least one phone number as '0123456789' or 'Clinic: 0123456789'"
          errorMessage={serviceConfigurationStore.errors?.organisationPhoneNumbers}
        />
        <PhoneNumbersInput
          onFocus={() => serviceConfigurationStore.setCurrentInput("organisationPhoneNumbers")}
          onChange={e => onInputChange(e.target.name, e.target.value)}
          onDelete={onPhoneNumberDelete}
          initialRows={serviceConfigurationStore.config.organisationPhoneNumbers?.length ?? 0}
          values={serviceConfigurationStore.config.organisationPhoneNumbers ?? []}
        />
      </div>
      <div className={styles.inputSection}>
        <InputLabel
          label="Custom Crisis Phone Numbers"
          tooltip="Add custom phone numbers in case a user is in crisis"
        />
        <textarea
          onFocus={() => serviceConfigurationStore.setCurrentInput("crisisPhoneNumbers")}
          className={styles.textAreaInput}
          name="crisisPhoneNumbers"
          value={serviceConfigurationStore.config.crisisPhoneNumbers}
          onChange={e => onInputChange(e.target.name, e.target.value)}
          placeholder="Enter crisis phone numbers"
        />
      </div>
      <div className={styles.inputSection}>
        <InputLabel
          label="FAQ Link"
          tooltip="This is optional, it will appear as an FAQ link on the settings menu (top right corner)"
        />
        <input
          onFocus={() => serviceConfigurationStore.setCurrentInput("faqLink")}
          name="faqLink"
          value={serviceConfigurationStore.config.faqLink}
          onChange={e => onInputChange(e.target.name, e.target.value)}
          placeholder="Enter an FAQ url"
        />
      </div>
      <div className={styles.inputSection}>
        <InputLabel
          label="Color Theme"
          tooltip="This will be the main color of all the user message bubbles including the Undo button and Loading animation"
          errorMessage={serviceConfigurationStore.errors?.userMessageBackground}
        />
        <TwitterPicker
          colors={DEFAULT_PICKER_COLORS}
          className={styles.colorPicker}
          triangle="hide"
          color={serviceConfigurationStore.config.userMessageBackground}
          onChange={onColorChange}
        />
      </div>
    </div>
  )
}

export default observer(BasicConfigurationInputs)
