import React from "react"
import classes from "clsx"
import Tooltip from "@mui/material/Tooltip"
import InfoIcon from "@mui/icons-material/Info"
import styles from "./InputLabel.module.scss"

interface Props {
  label: string
  tooltip?: string
  errorMessage?: string
}

export default function InputLabel(props: Props): JSX.Element {
  const { label, tooltip, errorMessage } = props

  return (
    <span className={classes(styles.inputLabel, { [styles.inputLabelError]: !!errorMessage })}>
      {errorMessage ?? label}
      {tooltip && (
        <Tooltip title={tooltip}>
          <InfoIcon className={styles.infoIcon} />
        </Tooltip>
      )}
    </span>
  )
}
