import React, { useRef, useEffect } from "react"
import { ResetIcon, DoubleArrowDownIcon } from "@radix-ui/react-icons"
import Tooltip from "@mui/material/Tooltip"
import { styled } from "@stitches/react"
import autosize from "autosize"
import { CSSProperties } from "@stitches/react/types/css-util"
import { Checkbox, FormControlLabel } from "@mui/material"
import styles from "./CrisisIndicator.module.scss"

interface IProps {
  value: string
  editable?: boolean
  onCrisisCheckboxToggle: (value: boolean) => void
  enableHandles?: boolean
  nodeId: string
  index: number
  crisisShouldRepeatQuestion: boolean
}

const iconCheckBoxStyle: CSSProperties = {
  height: "14px",
  color: "white"
}

const label = { inputProps: { "aria-label": "Crisis Checkbox" } }

export default function CrisisIndicator({ editable = true, ...props }: IProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    autosize(textAreaRef.current)
  }, [props.value])

  const handleCheckBoxChange = e => {
    const value = e.target.checked
    props.onCrisisCheckboxToggle(value)
  }

  return (
    <Tooltip
      placement="right"
      style={{ zIndex: 3000, pointerEvents: "all" }}
      title={
        <>
          <span>
            When crisis is detected but it's wrong, by default the next question will be will be
            asked.
          </span>
          <br />
          <br />
          <span>
            If it's preferred to ask the same question again, please click this button to display
            the <ResetIcon style={{ height: 10 }} /> icon
          </span>
          <br />
          <br />
          <span>
            Alternatively, you can revert to asking the next question again, by clicking this button
            to display the <DoubleArrowDownIcon style={{ height: 10 }} /> icon
          </span>
        </>
      }>
      <CrisisIndicatorContainer>
        <CrisisButton>
          <FormControlLabel
            labelPlacement="start"
            label={props.value}
            classes={{
              label: styles.formControlLabel,
              labelPlacementStart: styles.formControlLabelPlacementStart
            }}
            control={
              <Checkbox
                checked={props.crisisShouldRepeatQuestion}
                onChange={handleCheckBoxChange}
                className={styles.crisisCheckbox}
                style={{ padding: 2 }}
                {...label}
                icon={<DoubleArrowDownIcon style={iconCheckBoxStyle} />}
                checkedIcon={<ResetIcon style={iconCheckBoxStyle} />}
              />
            }
          />
        </CrisisButton>
      </CrisisIndicatorContainer>
    </Tooltip>
  )
}

const CrisisIndicatorContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center"
})

const CrisisButton = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  fontSize: "10px",
  fontFamily: "unset",
  backgroundColor: "red",
  outline: "unset",
  border: "unset",
  borderRadius: "0 10px 10px 0",
  padding: "4px 16px 4px 8px",
  margin: "4px 4px 4px 0",
  textAlign: "unset",
  resize: "unset",
  color: "white",
  height: "22px",
  width: "60px",
  variants: {
    editable: {
      true: { pointerEvents: "auto" },
      false: { pointerEvents: "none" }
    }
  },
  "&:hover": {
    backgroundColor: "rgba(255,0,0,0.5)"
  }
})
