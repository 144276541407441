import { FlowStore } from "./FlowStore"
import { NodeEditorStore } from "./NodeEditorStore"
import { ServiceStore } from "./ServiceStore"
import { SettingsStore } from "./SettingsStore"
import { ServiceConfigurationStore } from "./ServiceConfigurationStore"
import { ClinicalPathsConfigurationStore } from "./ClinicalPathsConfigurationStore"
import { KeepingSafeEmailConfigurationStore } from "./KeepingSafeEmailConfigurationStore"
import { BackendMappingStore } from "./BackendMappingStore"
import { ServiceIntegrationStore } from "./ServiceIntegrationStore"
import { ChatbotPropertiesStore } from "./ChatbotPropertiesStore"
import { action } from "mobx"

export class RootStore {
  static __instance: RootStore
  static getInstance(): RootStore {
    if (!this.__instance) this.__instance = new RootStore()
    return this.__instance
  }

  flowStore: FlowStore
  nodeEditorStore: NodeEditorStore
  serviceStore: ServiceStore
  settingsStore: SettingsStore
  serviceConfigurationStore: ServiceConfigurationStore
  serviceIntegrationStore: ServiceIntegrationStore
  clinicalPathsConfigurationStore: ClinicalPathsConfigurationStore
  keepingSafeEmailConfigurationStore: KeepingSafeEmailConfigurationStore
  backendMappingStore: BackendMappingStore
  chatbotPropertiesStore: ChatbotPropertiesStore

  constructor() {
    this.flowStore = new FlowStore()
    this.nodeEditorStore = new NodeEditorStore()
    this.serviceStore = new ServiceStore()
    this.settingsStore = new SettingsStore()
    this.serviceConfigurationStore = new ServiceConfigurationStore()
    this.serviceIntegrationStore = new ServiceIntegrationStore()
    this.clinicalPathsConfigurationStore = new ClinicalPathsConfigurationStore()
    this.keepingSafeEmailConfigurationStore = new KeepingSafeEmailConfigurationStore()
    this.backendMappingStore = new BackendMappingStore()
    this.chatbotPropertiesStore = new ChatbotPropertiesStore()
    this.setup()
  }

  setup(): void {
    this.serviceStore.onGetServiceData = this._onGetServiceData.bind(this)
    this.chatbotPropertiesStore.onChatbotPropertiesUpdate =
      this._onChatbotPropertiesUpdate.bind(this)
  }

  @action
  private async _onGetServiceData(data: ServiceStore["serviceData"]): Promise<void> {
    this.serviceConfigurationStore.sync(data?.draft)
    this.serviceIntegrationStore.sync(data?.draft)
    this.clinicalPathsConfigurationStore.sync(data?.draft)
    this.keepingSafeEmailConfigurationStore.sync(data?.draft)
    this.chatbotPropertiesStore.sync(data?.draft)
    this.backendMappingStore.sync(data?.draft)

    // TODO: need to sync flow store here and probably node editor too
  }

  @action
  private _onChatbotPropertiesUpdate(): void {
    // TODO: eventually the backend mapping should rely on the chatbot properties so every
    //       backendMappingStore.sync should happen because chatbotPropertiesStore.sync
    //       was called first
    // this.backendMappingStore.sync(this.chatbotPropertiesStore.chatbotProperties)
  }
}

export default RootStore.getInstance()

interface IWindowWithRootStore extends Window {
  RootStore: RootStore
}

declare let window: IWindowWithRootStore
if (!window.RootStore) window.RootStore = RootStore.getInstance()
