// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Mqp2yXt8kaWxSQ_iYyAD{display:flex;flex-flow:column;padding:16px 8px 8px 8px;margin-bottom:8px;width:100%;border-radius:8px}.Mqp2yXt8kaWxSQ_iYyAD input{width:calc(100% - 16px);height:36px;outline:none;border:1px solid #d3d3d3;border-radius:5px;padding-left:8px;padding-right:8px;margin:auto}.Mqp2yXt8kaWxSQ_iYyAD input:hover{background:rgba(204,204,204,.4)}.Mqp2yXt8kaWxSQ_iYyAD label{padding-left:8px;margin-bottom:8px}.FO1KKfkmhBWinR6VzDqI{color:red}", "",{"version":3,"sources":["webpack://./src/app/views/ClinicalPaths/components/DefaultPaths/components/Input/Input.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,wBAAA,CACA,iBAAA,CACA,UAAA,CACA,iBAAA,CAEA,4BACE,uBAAA,CACA,WAAA,CACA,YAAA,CACA,wBAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,WAAA,CACA,kCACE,+BAAA,CAIJ,4BACE,gBAAA,CACA,iBAAA,CAIJ,sBACE,SAAA","sourcesContent":[".clinicalPathInputContainer {\n  display: flex;\n  flex-flow: column;\n  padding: 16px 8px 8px 8px;\n  margin-bottom: 8px;\n  width: 100%;\n  border-radius: 8px;\n\n  & input {\n    width: calc(100% - 16px);\n    height: 36px;\n    outline: none;\n    border: 1px solid lightgray;\n    border-radius: 5px;\n    padding-left: 8px;\n    padding-right: 8px;\n    margin: auto;\n    &:hover {\n      background: rgba(204, 204, 204, 0.4);\n    }\n  }\n\n  & label {\n    padding-left: 8px;\n    margin-bottom: 8px;\n  }\n}\n\n.inputError {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clinicalPathInputContainer": "Mqp2yXt8kaWxSQ_iYyAD",
	"inputError": "FO1KKfkmhBWinR6VzDqI"
};
export default ___CSS_LOADER_EXPORT___;
