import React from "react"
import { observer } from "mobx-react"
import { ChatFlowsEnum } from "@limbic/types"
import styles from "./CollectSubstances.module.scss"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import { Typography } from "@mui/material"
import MessageList from "../MessageList"
import MessageForm from "../MessageForm"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import clsx from "classnames"
import SolidDivider from "../../../../../SolidDivider"
import FormControlWithCheckboxControlledInput from "../FormControlWithCheckBox/FormControlWithCheckboxControlledInput"

function CollectSubstances(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages
  const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES]
  const useCustomKeys =
    !!currentSettings?.substancesKey ||
    !!currentSettings?.substancesForMoodKey ||
    !!currentSettings?.substancesInfoKey ||
    !!currentSettings?.substancesImpactLifeKey ||
    !!currentSettings?.nonPrescribedSubstancesKey ||
    !!currentSettings?.substancesAreMedicationKey ||
    !!currentSettings?.prescribedMedicationKey ||
    !!currentSettings?.medicationInfoKey ||
    !!currentSettings?.medicationWithinDosageKey ||
    !!currentSettings?.medicationNotTakingKey ||
    !!currentSettings?.medicationNotTakingInfoKey

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  const onCustomiseStateKeysClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: { ...currentSettings }
    }

    // if value is true it means we're enabling custom keys
    // so, we need to reset the entries to the default values
    // otherwise remove the entries entirely
    if (value) {
      newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] = {
        ...currentSettings,
        substancesKey: "substances",
        substancesForMoodKey: "substancesForMood",
        substancesInfoKey: "substancesInfo",
        substancesImpactLifeKey: "substancesImpactLife",
        nonPrescribedSubstancesKey: "nonPrescribedSubstances",
        substancesAreMedicationKey: "substancesAreMedication",
        prescribedMedicationKey: "takingPrescribedMedication",
        medicationInfoKey: "medicationInfo",
        medicationWithinDosageKey: "medicationWithinDosage",
        medicationNotTakingKey: "medicationNotTaking",
        medicationNotTakingInfoKey: "medicationNotTakingInfo"
      }
    } else {
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].substancesKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].substancesForMoodKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].substancesInfoKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].substancesImpactLifeKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].nonPrescribedSubstancesKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].substancesAreMedicationKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].prescribedMedicationKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].medicationInfoKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].medicationWithinDosageKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].medicationNotTakingKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES].medicationNotTakingInfoKey
    }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditSubstancesKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        substancesKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditSubstancesForMoodKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        substancesForMoodKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditSubstancesInfoKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        substancesInfoKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditSubstancesImpactLifeKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        substancesImpactLifeKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditNonPrescribedSubstancesKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        nonPrescribedSubstancesKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditsubstancesAreMedicationKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        substancesAreMedicationKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditPrescribedMedicationKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        prescribedMedicationKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditMedicationInfoKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        medicationInfoKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditMedicationWithinDosageKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        medicationWithinDosageKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditMedicationNotTakingKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        medicationNotTakingKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditMedicationNotTakingInfoKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_SUBSTANCES] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
        ...currentSettings,
        medicationNotTakingInfoKey: event.target.value || undefined // do not store empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  return (
    <>
      <Typography className={styles.settingsTitle}>Substances question settings:</Typography>
      <FormControlWithCheckBox
        name="shouldAskSubstances"
        label="Ask for recreational substances"
        labelPlacement="end"
        updateState={updateState}
      />
      <MessageList
        messages={messages?.askSubstances ?? []}
        messageType="askSubstances"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askSubstances}
        placeholder="Type a message here..."
        name="askSubstances"
        updateState={updateState}
        messages={messages?.askSubstances ?? []}
      />
      {useCustomKeys && (
        <div className={styles.keyInputContainer}>
          <Typography>State Key</Typography>
          <input
            className={styles.keyInput}
            value={currentSettings?.substancesKey ?? "substances"}
            placeholder="substances"
            onChange={onEditSubstancesKey}
          />
        </div>
      )}

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldAskSubstancesForMood"
        label="Ask if they take substances to improve their mood"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskSubstancesForMood
        })}>
        <MessageList
          messages={messages?.askSubstancesForMood ?? []}
          messageType="askSubstancesForMood"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askSubstancesForMood}
          placeholder="Type a message here..."
          name="askSubstancesForMood"
          updateState={updateState}
          messages={messages?.askSubstancesForMood ?? []}
        />

        {useCustomKeys && (
          <div className={styles.keyInputContainer}>
            <Typography>State Key</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.substancesForMoodKey ?? "substancesForMood"}
              placeholder="substancesForMood"
              onChange={onEditSubstancesForMoodKey}
            />
          </div>
        )}
      </div>

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldAskSubstancesInfo"
        label="Ask for details on substances"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskSubstancesInfo
        })}>
        <MessageList
          messages={messages?.askSubstancesInfo ?? []}
          messageType="askSubstancesInfo"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askSubstancesInfo}
          placeholder="Type a message here..."
          name="askSubstancesInfo"
          updateState={updateState}
          messages={messages?.askSubstancesInfo ?? []}
        />
        {useCustomKeys && (
          <div className={styles.keyInputContainer}>
            <Typography>State Key</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.substancesInfoKey ?? "substancesInfo"}
              placeholder="substancesInfo"
              onChange={onEditSubstancesInfoKey}
            />
          </div>
        )}
      </div>

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldAskSubstancesImpactLife"
        label="Ask if substances affect their life"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskSubstancesImpactLife
        })}>
        <MessageList
          messages={messages?.askSubstancesImpactLife ?? []}
          messageType="askSubstancesImpactLife"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askSubstancesImpactLife}
          placeholder="Type a message here..."
          name="askSubstancesImpactLife"
          updateState={updateState}
          messages={messages?.askSubstancesImpactLife ?? []}
        />
        {useCustomKeys && (
          <div className={styles.keyInputContainer}>
            <Typography>State Key</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.substancesImpactLifeKey ?? "substancesImpactLife"}
              placeholder="substancesImpactLife"
              onChange={onEditSubstancesImpactLifeKey}
            />
          </div>
        )}
      </div>

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldAskNonPrescribedSubstances"
        label="Ask if they take non-prescribed substances"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskNonPrescribedSubstances
        })}>
        <MessageList
          messages={messages?.askNonPrescribedSubstances ?? []}
          messageType="askNonPrescribedSubstances"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askNonPrescribedSubstances}
          placeholder="Type a message here..."
          name="askNonPrescribedSubstances"
          updateState={updateState}
          messages={messages?.askNonPrescribedSubstances ?? []}
        />
        {useCustomKeys && (
          <div className={styles.keyInputContainer}>
            <Typography>State Key</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.nonPrescribedSubstancesKey ?? "nonPrescribedSubstances"}
              placeholder="nonPrescribedSubstances"
              onChange={onEditNonPrescribedSubstancesKey}
            />
          </div>
        )}
      </div>

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldAskSubstancesAreMedication"
        label="Ask if the substances they take are medication"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskSubstancesAreMedication
        })}>
        <MessageList
          messages={messages?.askSubstancesAreMedication ?? []}
          messageType="askSubstancesAreMedication"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askSubstancesAreMedication}
          placeholder="Type a message here..."
          name="askSubstancesAreMedication"
          updateState={updateState}
          messages={messages?.askSubstancesAreMedication ?? []}
        />
        {useCustomKeys && (
          <div className={styles.keyInputContainer}>
            <Typography>State Key</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.substancesAreMedicationKey ?? "substancesAreMedication"}
              placeholder="substancesAreMedication"
              onChange={onEditsubstancesAreMedicationKey}
            />
          </div>
        )}
      </div>

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldAskPrescribedMedication"
        label="Ask if they are taking prescribed medication"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskPrescribedMedication
        })}>
        <MessageList
          messages={messages?.askPrescribedMedication ?? []}
          messageType="askPrescribedMedication"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askPrescribedMedication}
          placeholder="Type a message here..."
          name="askPrescribedMedication"
          updateState={updateState}
          messages={messages?.askPrescribedMedication ?? []}
        />
        {useCustomKeys && (
          <div className={styles.keyInputContainer}>
            <Typography>State Key</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.prescribedMedicationKey ?? "takingPrescribedMedication"}
              placeholder="takingPrescribedMedication"
              onChange={onEditPrescribedMedicationKey}
            />
          </div>
        )}
      </div>

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldAskMedicationInfo"
        label="Ask for details on medication"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskMedicationInfo
        })}>
        <MessageList
          messages={messages?.askMedicationInfo ?? []}
          messageType="askMedicationInfo"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askMedicationInfo}
          placeholder="Type a message here..."
          name="askMedicationInfo"
          updateState={updateState}
          messages={messages?.askMedicationInfo ?? []}
        />
        {useCustomKeys && (
          <div className={styles.keyInputContainer}>
            <Typography>State Key</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.medicationInfoKey ?? "medicationInfo"}
              placeholder="medicationInfo"
              onChange={onEditMedicationInfoKey}
            />
          </div>
        )}
      </div>

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldAskMedicationWithinDosage"
        label="Ask if the medication is within recommended dosage"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskMedicationWithinDosage
        })}>
        <MessageList
          messages={messages?.askMedicationWithinDosage ?? []}
          messageType="askMedicationWithinDosage"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askMedicationWithinDosage}
          placeholder="Type a message here..."
          name="askMedicationWithinDosage"
          updateState={updateState}
          messages={messages?.askMedicationWithinDosage ?? []}
        />
        {useCustomKeys && (
          <div className={styles.keyInputContainer}>
            <Typography>State Key</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.medicationWithinDosageKey ?? "medicationWithinDosage"}
              placeholder="medicationWithinDosage"
              onChange={onEditMedicationWithinDosageKey}
            />
          </div>
        )}
      </div>

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldAskMedicationNotTaking"
        label="Ask if they are prescribed medication but NOT taking it"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskMedicationNotTaking
        })}>
        <MessageList
          messages={messages?.askMedicationNotTaking ?? []}
          messageType="askMedicationNotTaking"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askMedicationNotTaking}
          placeholder="Type a message here..."
          name="askMedicationNotTaking"
          updateState={updateState}
          messages={messages?.askMedicationNotTaking ?? []}
        />
        {useCustomKeys && (
          <div className={styles.keyInputContainer}>
            <Typography>State Key</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.medicationNotTakingKey ?? "medicationNotTaking"}
              placeholder="medicationNotTaking"
              onChange={onEditMedicationNotTakingKey}
            />
          </div>
        )}
      </div>

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldAskMedicationNotTakingInfo"
        label="Ask details on prescribed medication they're not taking"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskMedicationNotTakingInfo
        })}>
        <MessageList
          messages={messages?.askMedicationNotTakingInfo ?? []}
          messageType="askMedicationNotTakingInfo"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askMedicationNotTakingInfo}
          placeholder="Type a message here..."
          name="askMedicationNotTakingInfo"
          updateState={updateState}
          messages={messages?.askMedicationNotTakingInfo ?? []}
        />
        {useCustomKeys && (
          <div className={styles.keyInputContainer}>
            <Typography>State Key</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.medicationNotTakingInfoKey ?? "medicationNotTakingInfo"}
              placeholder="medicationNotTakingInfo"
              onChange={onEditMedicationNotTakingInfoKey}
            />
          </div>
        )}
      </div>
      <SolidDivider />
      <Typography className={styles.settingsTitle}>Advanced Settings:</Typography>
      <FormControlWithCheckboxControlledInput
        name="customiseStateKeys"
        value={useCustomKeys}
        label="Edit state keys"
        labelPlacement="start"
        onToggle={onCustomiseStateKeysClick}
      />
    </>
  )
}

export default observer(CollectSubstances)
