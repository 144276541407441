import React, { useMemo, useState } from "react"
import { observer } from "mobx-react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import DefaultPaths from "./components/DefaultPaths"
import Dialog from "../../components/Dialog"
import CustomPaths from "./components/CustomPaths"
import styles from "./ClinicalPaths.module.scss"
import { useClinicalPathsConfigurationStore, useServiceStore } from "../../context/rootStoreContext"
import deepCompare from "../../../utils/deepCompare"
import Button from "../../components/Button"
import { removeAtIndex } from "../../../utils/array"
import { ClinicalFlags, ProblemCategories } from "@limbic/types"
import { ICustomClinicalPath } from "../../stores/ClinicalPathsConfigurationStore"

function ClinicalPaths(): JSX.Element {
  const serviceStore = useServiceStore()
  const clinicalPathsConfigurationStore = useClinicalPathsConfigurationStore()
  const [currentTab, setCurrentTab] = useState<"default" | "custom">("default")
  const [error, setError] = useState<string | undefined>()

  const serviceDataClinicalPaths = serviceStore.serviceData?.[serviceStore.mode]?.clinicalPaths

  const isDefaultPathsChanged = useMemo(() => {
    return !deepCompare(
      serviceDataClinicalPaths?.defaultClinicalPaths ?? [],
      clinicalPathsConfigurationStore.defaultPaths
    )
  }, [serviceDataClinicalPaths?.defaultClinicalPaths, clinicalPathsConfigurationStore.defaultPaths])

  const isCustomPathsChanged = useMemo(() => {
    return !deepCompare(
      serviceDataClinicalPaths?.customClinicalPaths?.dashboard ?? [],
      clinicalPathsConfigurationStore.customPaths
    )
  }, [
    serviceDataClinicalPaths?.customClinicalPaths?.dashboard,
    clinicalPathsConfigurationStore.customPaths
  ])

  const isChanged = currentTab === "default" ? isDefaultPathsChanged : isCustomPathsChanged

  const onClinicalPathChange = (event, index: number) => {
    const newPaths = [...clinicalPathsConfigurationStore.defaultPaths]
    newPaths[index] = { ...newPaths[index], defaultReferralType: event.target.value }
    clinicalPathsConfigurationStore.setDefaultPaths(newPaths)
  }

  const onChangeCustomClinicalPathReferralType = (index: number, referralType?: string) => {
    const newPaths: ICustomClinicalPath[] = [...clinicalPathsConfigurationStore.customPaths]
    newPaths[index] = {
      ...newPaths[index],
      referralType: referralType ?? ""
    }
    clinicalPathsConfigurationStore.setCustomPaths(newPaths)
    clinicalPathsConfigurationStore.validateCustomPaths()
  }

  const onChangeCustomClinicalPathPrimaries = (index: number, primaries?: ProblemCategories[]) => {
    const newPaths: ICustomClinicalPath[] = [...clinicalPathsConfigurationStore.customPaths]
    newPaths[index] = { ...newPaths[index], primaryProblems: primaries ?? [] }
    clinicalPathsConfigurationStore.setCustomPaths(newPaths)
    clinicalPathsConfigurationStore.validateCustomPaths()
  }

  const onChangeCustomClinicalPathSecondaries = (
    index: number,
    secondaries?: ProblemCategories[]
  ) => {
    const newPaths: ICustomClinicalPath[] = [...clinicalPathsConfigurationStore.customPaths]
    newPaths[index] = { ...newPaths[index], secondaryProblems: secondaries ?? [] }
    clinicalPathsConfigurationStore.setCustomPaths(newPaths)
    clinicalPathsConfigurationStore.validateCustomPaths()
  }

  const onChangeCustomClinicalPathEnableSecondaries = (index: number, checked: boolean) => {
    const newPaths = [...clinicalPathsConfigurationStore.customPaths]
    newPaths[index] = {
      ...newPaths[index],
      enableSecondary: checked,
      // 👇 no matter what, when you toggle, this should always be an
      // empty array, because you either come from a disabled state
      // or you go to a disabled state, and in both cases you don't
      // want to keep the previous values
      secondaryProblems: []
    }
    clinicalPathsConfigurationStore.setCustomPaths(newPaths)
    clinicalPathsConfigurationStore.validateCustomPaths()
  }

  const onChangeCustomClinicalPathFlags = (
    index: number,
    flags?: ClinicalFlags | "ALL" | "NONE"
  ) => {
    const newPaths: ICustomClinicalPath[] = [...clinicalPathsConfigurationStore.customPaths]
    newPaths[index] = { ...newPaths[index], flags: flags ?? "ALL" }
    clinicalPathsConfigurationStore.setCustomPaths(newPaths)
    clinicalPathsConfigurationStore.validateCustomPaths()
  }

  const onAddCustomClinicalPath = () => {
    clinicalPathsConfigurationStore.addNewCustomPath()
    clinicalPathsConfigurationStore.validateCustomPaths()
  }

  const onRemoveCustomClinicalPath = (index: number) => {
    const newPaths = removeAtIndex([...clinicalPathsConfigurationStore.customPaths], index)
    clinicalPathsConfigurationStore.setCustomPaths(newPaths)
    clinicalPathsConfigurationStore.validateCustomPaths()
  }

  const onSaveDefaultChanges = async () => {
    try {
      const defaultPaths = clinicalPathsConfigurationStore.defaultPaths
      await serviceStore.updateDefaultClinicalPathsConfiguration(defaultPaths)
      await serviceStore.getServiceData()
    } catch (e) {
      setError(e.message)
      // TODO: report exception to sentry
      console.log({ e })
    }
  }

  const onSaveCustomChanges = async () => {
    try {
      const customPaths = clinicalPathsConfigurationStore.customPaths
      await serviceStore.updateCustomClinicalPathsConfiguration(customPaths)
      await serviceStore.getServiceData()
    } catch (e) {
      setError(e.message)
      // TODO: report exception to sentry
      console.log({ e })
    }
  }

  const onDiscardDefaultChanges = () => {
    clinicalPathsConfigurationStore.setDefaultPaths(serviceDataClinicalPaths?.defaultClinicalPaths)
  }

  const onDiscardCustomChanges = () => {
    clinicalPathsConfigurationStore.setCustomPaths(
      serviceDataClinicalPaths?.customClinicalPaths?.dashboard?.map(i => ({ ...i, valid: true }))
    )
    clinicalPathsConfigurationStore.validateCustomPaths()
  }

  return (
    <div className={styles.clinicalPathsContainer}>
      <Dialog onClose={() => setError(undefined)} open={!!error} title="Error" maxWidth="sm">
        <>
          <span>Something went wrong while making updates to the configuration</span>
          <br />
          <span style={{ color: "red" }}>{error}</span>
        </>
      </Dialog>
      <div className={styles.clinicalPathsTabsContainer}>
        <Tabs
          value={currentTab}
          onChange={(_, tab) => setCurrentTab(tab)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Clinical Paths Navigation">
          <Tab
            label="Default Clinical Paths"
            value="default"
            disabled={currentTab === "custom" && isCustomPathsChanged}
          />
          <Tab
            label="Custom Clinical Paths"
            value="custom"
            disabled={currentTab === "default" && isDefaultPathsChanged}
          />
        </Tabs>
      </div>
      <div className={styles.clinicalPathRowsContainer}>
        {currentTab === "default" ? (
          <DefaultPaths onClinicalPathChange={onClinicalPathChange} />
        ) : (
          <CustomPaths
            onAddCustomClinicalPath={onAddCustomClinicalPath}
            onRemoveCustomClinicalPath={onRemoveCustomClinicalPath}
            onChangeCustomClinicalPathReferralType={onChangeCustomClinicalPathReferralType}
            onChangeCustomClinicalPathPrimaries={onChangeCustomClinicalPathPrimaries}
            onChangeCustomClinicalPathSecondaries={onChangeCustomClinicalPathSecondaries}
            onChangeCustomClinicalPathEnableSecondaries={
              onChangeCustomClinicalPathEnableSecondaries
            }
            onChangeCustomClinicalPathFlags={onChangeCustomClinicalPathFlags}
          />
        )}
      </div>
      <div className={styles.buttonsSpacer} />
      <div className={styles.buttonsContainer}>
        <Button
          onClick={currentTab === "default" ? onSaveDefaultChanges : onSaveCustomChanges}
          disabled={!isChanged || !clinicalPathsConfigurationStore.isValid}>
          {serviceStore.isLoading ? "Updating..." : "Save Changes"}
        </Button>
        <Button
          onClick={currentTab === "default" ? onDiscardDefaultChanges : onDiscardCustomChanges}
          disabled={!isChanged}>
          Discard Changes
        </Button>
      </div>
    </div>
  )
}

export default observer(ClinicalPaths)
