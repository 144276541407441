function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  let truncatedString = str.slice(0, num).trim()
  if (truncatedString[truncatedString.length - 1].match(/[^a-zA-Z ]/g)) {
    truncatedString = truncatedString.slice(0, truncatedString.length - 1)
  }
  return truncatedString + "..."
}

export default truncateString
