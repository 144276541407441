import * as self from "./mapping"
import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import { IDashboardDoc, IKeyTransformMap } from "@limbic/types"
import invariant from "../../utils/invariant"
import Logger from "../../utils/Logger"
import formatUnicorn from "../../utils/formatUnicorn"

const TOTAL_RETRIES = 3
const MAPPING_URL = `${BACKEND_URL}/v1/dashboard/{serviceApiKey}/mapping`

const method = "POST"
const headers = new Headers()
headers.set("x-api-key", API_KEY)
headers.set("Content-Type", "application/json")

export async function updateBackendMapping(
  serviceApiKey: string,
  transformMap: IKeyTransformMap,
  retry = 0
): Promise<IDashboardDoc | undefined> {
  invariant(serviceApiKey, "Cannot update mapping without a serviceApiKey")
  invariant(transformMap, "Cannot update mapping without a transformMap")

  const URL = formatUnicorn(`${MAPPING_URL}/backend`, { serviceApiKey })
  const body = JSON.stringify({ transformMap })
  try {
    const result = await fetch(URL, { body, method, headers })
    const json = await result.json()

    const { success, data, error, validations } = json

    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "updateBackendMapping failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`updateBackendMapping body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("updateBackendMapping retry")
      await delay(1)
      return await self.updateBackendMapping(serviceApiKey, transformMap, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
