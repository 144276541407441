// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--buttonSecondary: #fd81a0;--textSecondary: #ffffff;--secondaryButtonFontSize: 18px}.hRqjBkOUshzSTkwQ7uYg{white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/app/styles/variables.scss","webpack://./src/app/components/Button/Button.module.scss"],"names":[],"mappings":"AAKA,MACE,0BAAA,CACA,wBAAA,CACA,+BAAA,CCNF,sBACE,kBAAA","sourcesContent":["$buttonSecondary: var(--buttonSecondary);\n$textSecondary: var(--textSecondary);\n$secondaryButtonFontSize: var(--secondaryButtonFontSize);\n$font--sans--medium: \"Aeroport-Medium\";\n\n:root {\n  --buttonSecondary: #fd81a0;\n  --textSecondary: #ffffff;\n  --secondaryButtonFontSize: 18px;\n}\n","@import \"../../styles/variables.scss\";\n\n.button {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "hRqjBkOUshzSTkwQ7uYg"
};
export default ___CSS_LOADER_EXPORT___;
