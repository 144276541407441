import React from "react"
import { FormControlLabel, Checkbox } from "@mui/material"
import styles from "./ActiveCheckBox.module.scss"

interface Props {
  disabled?: boolean
  isChecked: boolean
  name: string
  label: string
  labelPlacement: "end" | "start" | "top" | "bottom"
  handleChange: () => void
  title?: string
}

const ActiveCheckBox = ({
  disabled = false,
  isChecked,
  name,
  label,
  labelPlacement,
  handleChange,
  title = ""
}: Props) => {
  return (
    <FormControlLabel
      title={title}
      disabled={disabled}
      className={label.length ? styles.formControlLabel : styles.formControlNoLabel}
      value={name}
      control={<Checkbox checked={isChecked} onChange={() => handleChange()} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

export default ActiveCheckBox
