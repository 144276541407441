import React from "react"
import { FormControlLabel, Checkbox } from "@mui/material"
import styles from "./FormControlWithCheckBox.module.scss"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import { observer } from "mobx-react"

interface Props {
  disabled?: boolean
  name: string
  label: string
  labelPlacement: "end" | "start" | "top" | "bottom"
  classNameLabel?: string
  updateState: (data: Record<string, Record<any, any>>) => void
}

const FormControlWithCheckbox = ({
  disabled = false,
  name,
  label,
  labelPlacement,
  updateState,
  classNameLabel
}: Props) => {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlow, chatFlowSettings } = chatFlowEditorState

  const updateCheckbox = (value: boolean): void => {
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [chatFlow]: { ...chatFlowSettings[chatFlow], [name]: value }
      }
    })
  }

  const checked =
    chatFlowSettings[chatFlow] && chatFlowSettings[chatFlow][name]
      ? chatFlowSettings[chatFlow][name]
      : false

  return (
    <FormControlLabel
      disabled={disabled}
      classes={{ label: classNameLabel }}
      className={styles.formControlLabel}
      value={name}
      control={<Checkbox checked={checked} onChange={(_e, value) => updateCheckbox(value)} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

export default observer(FormControlWithCheckbox)
