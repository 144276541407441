import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import { IServiceExtendedConfig } from "@limbic/types"
import { ServiceStatus } from "../../models/Service"
import Logger from "../../utils/Logger"
import * as services from "./services"

const TOTAL_RETRIES = 3
const DASHBOARD_URL = `${BACKEND_URL}/v1/dashboard/services`

const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export async function getAvailableServices(
  retry = 0
): Promise<[IServiceExtendedConfig[], ServiceStatus]> {
  try {
    const result = await fetch(DASHBOARD_URL, { headers })
    const json = await result.json()

    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    if (success && !data) {
      return [data, ServiceStatus.NoServiceFound]
    }
    return [data, ServiceStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "getAvailableServices failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("getAvailableServices retry")
      await delay(1)
      return await services.getAvailableServices(retry + 1)
    }
    throw e
  }
}
