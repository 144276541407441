import { useCallback, useState } from "react"
import { NodeProps, Position, useStore } from "react-flow-renderer"
import classNames from "classnames"
import { tomato } from "@radix-ui/colors"
import { styled } from "@stitches/react"
import { CustomNode } from "../CustomNode"
import EndShape from "../../../components/shapes/EndShape"
import NodeSettings from "../NodeSettings"
import useDeleteNode from "../../../../../hooks/useDeleteNode"
import { positionsMap } from "../../../config"

export const FLOW_END_NODE_SIZE = 32

export default function FlowEndNode(props: NodeProps) {
  const { id, selected, dragging } = props
  const onDelete = useDeleteNode(id)
  const [labelPosition, setLabelPosition] = useState(Position.Bottom)
  const store = useStore()
  const handleStyle = store.connectionHandleId ? handleVisible : handleHidden
  const onRotate = useCallback(() => setLabelPosition(p => positionsMap[p]), [])

  const style = {
    [Position.Left]: { top: 0, bottom: 0, left: -28 },
    [Position.Right]: { top: 0, bottom: 0, right: -28 },
    [Position.Top]: { left: 0, right: 0, top: -16 },
    [Position.Bottom]: { left: 0, right: 0, bottom: -16 }
  }[labelPosition]

  return (
    <CustomNode.Container
      dynamicHandles
      id={id}
      dragging={dragging}
      sourceHandleStyle={handleHidden}
      targetHandleStyle={handleStyle}>
      <Container className={classNames("react-flow__node-shape nopan", { selected })}>
        <EndShape
          size={FLOW_END_NODE_SIZE}
          fillColor={"black"}
          strokeColor={tomato.tomato9}
          strokeWidth={selected ? 1.5 : 1}
        />
        <Content style={style}>
          <CustomNode.Label>End</CustomNode.Label>
        </Content>
      </Container>
      <NodeSettings
        open={props.selected && !props.dragging}
        onRotate={onRotate}
        onDelete={onDelete}
      />
    </CustomNode.Container>
  )
}

const Container = styled("div", {
  alignItems: "center",
  justifyContent: "center"
})

const Content = styled("div", {
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})

const handleVisible = { borderColor: tomato.tomato9, backgroundColor: tomato.tomato9 }
const handleHidden = { opacity: 0 }
