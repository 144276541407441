// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oD0L5GWIvTwSHPf9xUQw{display:flex;width:100%;justify-content:flex-end}.ggYUdLWMd2Sex1xM1IEn{border:1px solid #d3d3d3;margin-bottom:.5em}.uLJhPtnlFWFPd1iNv7kL{color:red;padding:.5em;text-align:right}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/GoodbyeRecap/GoodbyeRecap.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,wBAAA,CAGF,sBACE,wBAAA,CACA,kBAAA,CAGF,sBACE,SAAA,CACA,YAAA,CACA,gBAAA","sourcesContent":[".addConditionButtonContainer {\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n}\n\n.recapConditionMessagesWrapper {\n  border: 1px solid lightgray;\n  margin-bottom: 0.5em;\n}\n\n.conditionCombinationNotAllowed {\n  color: red;\n  padding: 0.5em;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addConditionButtonContainer": "oD0L5GWIvTwSHPf9xUQw",
	"recapConditionMessagesWrapper": "ggYUdLWMd2Sex1xM1IEn",
	"conditionCombinationNotAllowed": "uLJhPtnlFWFPd1iNv7kL"
};
export default ___CSS_LOADER_EXPORT___;
