// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hJGZgplCUaKJ3jBNp90y{display:none}.ahpsE_PEQB9PAa6VIbkW{cursor:default !important;filter:grayscale(1);-webkit-filter:grayscale(1)}.Q9GHjFbIF7Ur_l0fPdlt{object-fit:contain;height:calc(100% - 4px);width:auto;border:none}.t3pN0rZkKPA_x36zhNho{cursor:pointer;display:flex;align-items:center;justify-content:center;height:100px;width:100%}.QXlmPal4HTX6cRnH77XD{cursor:pointer;display:flex;align-items:center;justify-content:flex-start;height:24px;width:100%}.LpPt_9b7gxA8wGtyhg90{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/app/components/LogoInput/LogoInput.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,yBAAA,CACA,mBAAA,CACA,2BAAA,CAGF,sBACE,kBAAA,CACA,uBAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,UAAA,CAGF,sBACE,cAAA,CACA,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,WAAA,CACA,UAAA,CAGF,sBACE,cAAA","sourcesContent":[".imageInput {\n  display: none;\n}\n\n.imageInputDisabled {\n  cursor: default !important;\n  filter: grayscale(1);\n  -webkit-filter: grayscale(1);\n}\n\n.imageLogo {\n  object-fit: contain;\n  height: calc(100% - 4px);\n  width: auto;\n  border: none;\n}\n\n.imageUploader {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100px;\n  width: 100%;\n}\n\n.imageUploaderText {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  height: 24px;\n  width: 100%;\n}\n\n.uploadText {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageInput": "hJGZgplCUaKJ3jBNp90y",
	"imageInputDisabled": "ahpsE_PEQB9PAa6VIbkW",
	"imageLogo": "Q9GHjFbIF7Ur_l0fPdlt",
	"imageUploader": "t3pN0rZkKPA_x36zhNho",
	"imageUploaderText": "QXlmPal4HTX6cRnH77XD",
	"uploadText": "LpPt_9b7gxA8wGtyhg90"
};
export default ___CSS_LOADER_EXPORT___;
