import React from "react"
import { observer } from "mobx-react"
import DummyInput from "../../../QuestionEditor/components/DummyInput"
import { PromptType } from "../../../../models/NodeEditors"
import FormControlWithCheckBox from "../../components/FormControlWithCheckBox"
import MessageList from "../../components/MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../../components/MessageForm/MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import Choice from "../../../Choice"
import OptionsPlaceholder from "../../components/OptionsPlaceholder"

import styles from "./CollectLanguage.module.scss"

function CollectLanguage(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <MessageList
        messages={messages?.askPrimaryLanguage ?? []}
        messageType="askPrimaryLanguage"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <OptionsPlaceholder
        name={
          chatFlowSettings.collectLanguage?.usePrimaryLanguageTextPrompt ? "English" : "Language"
        }
      />
      {chatFlowSettings.collectLanguage?.usePrimaryLanguageTextPrompt && (
        <DummyInput promptType={PromptType.Text} />
      )}
      <MessageForm
        value={chatFlowSettings.currentMessage?.askPrimaryLanguage}
        placeholder="Type a message here..."
        name="askPrimaryLanguage"
        updateState={updateState}
        messages={messages?.askPrimaryLanguage ?? []}
      />
      <FormControlWithCheckBox
        classNameLabel={styles.customLabel}
        name="usePrimaryLanguageTextPrompt"
        label="Show English option only and a free text input for all other languages"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="disableInterpreterLanguageQuestion"
        label="Do not ask any questions related to an interpreter"
        labelPlacement="start"
        updateState={updateState}
      />
      <MessageList
        disabled={chatFlowSettings.collectLanguage?.disableInterpreterLanguageQuestion}
        messages={messages?.askRequiresAnInterpreter ?? []}
        messageType="askRequiresAnInterpreter"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <div className={styles.optionContainer}>
        <Choice
          disabled={chatFlowSettings.collectLanguage?.disableInterpreterLanguageQuestion}
          value="Yes"
          nodeId=""
          index={0}
          withoutHandle={true}
        />
        <Choice
          disabled={chatFlowSettings.collectLanguage?.disableInterpreterLanguageQuestion}
          value="No"
          nodeId=""
          index={0}
          withoutHandle={true}
        />
      </div>
      <MessageForm
        disabledInput={chatFlowSettings.collectLanguage?.disableInterpreterLanguageQuestion}
        value={chatFlowSettings.currentMessage?.askRequiresAnInterpreter}
        placeholder="Type a message here..."
        name="askRequiresAnInterpreter"
        updateState={updateState}
        messages={messages?.askRequiresAnInterpreter ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        disabled={chatFlowSettings.collectLanguage?.disableInterpreterLanguageQuestion}
        name="skipInterpreterLanguageQuestion"
        label="Do not ask the language of the interpreter"
        labelPlacement="start"
        updateState={updateState}
      />
      <MessageList
        disabled={
          chatFlowSettings.collectLanguage?.disableInterpreterLanguageQuestion ||
          chatFlowSettings.collectLanguage?.skipInterpreterLanguageQuestion
        }
        messages={messages?.askInterpreterLanguage ?? []}
        messageType="askInterpreterLanguage"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <OptionsPlaceholder
        name="Language"
        disabled={
          chatFlowSettings.collectLanguage?.disableInterpreterLanguageQuestion ||
          chatFlowSettings.collectLanguage?.skipInterpreterLanguageQuestion
        }
      />
      {chatFlowSettings.collectLanguage?.includeInterpreterLanguageTextPrompt && (
        <DummyInput promptType={PromptType.Text} />
      )}
      <MessageForm
        disabledInput={
          chatFlowSettings.collectLanguage?.disableInterpreterLanguageQuestion ||
          chatFlowSettings.collectLanguage?.skipInterpreterLanguageQuestion
        }
        value={chatFlowSettings.currentMessage?.askInterpreterLanguage}
        placeholder="Type a message here..."
        name="askInterpreterLanguage"
        updateState={updateState}
        messages={messages?.askInterpreterLanguage ?? []}
      />
      <FormControlWithCheckBox
        disabled={
          chatFlowSettings.collectLanguage?.disableInterpreterLanguageQuestion ||
          chatFlowSettings.collectLanguage?.skipInterpreterLanguageQuestion
        }
        name="includeInterpreterLanguageTextPrompt"
        label="Include a free text input"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldAskAbleToCommunicateInEnglish"
        label="Ask user if they are able to communicate in English"
        labelPlacement="start"
        updateState={updateState}
      />
      <MessageList
        disabled={!chatFlowSettings.collectLanguage?.shouldAskAbleToCommunicateInEnglish}
        messages={messages?.askAbleToCommunicateInEnglish ?? []}
        messageType="askAbleToCommunicateInEnglish"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <div className={styles.optionContainer}>
        <Choice
          disabled={!chatFlowSettings.collectLanguage?.shouldAskAbleToCommunicateInEnglish}
          value="Yes"
          nodeId=""
          index={0}
          withoutHandle={true}
        />
        <Choice
          disabled={!chatFlowSettings.collectLanguage?.shouldAskAbleToCommunicateInEnglish}
          value="No"
          nodeId=""
          index={0}
          withoutHandle={true}
        />
      </div>
      <MessageForm
        disabledInput={!chatFlowSettings.collectLanguage?.shouldAskAbleToCommunicateInEnglish}
        value={chatFlowSettings.currentMessage?.askAbleToCommunicateInEnglish}
        placeholder="Type a message here..."
        name="askAbleToCommunicateInEnglish"
        updateState={updateState}
        messages={messages?.askAbleToCommunicateInEnglish ?? []}
      />
    </>
  )
}

export default observer(CollectLanguage)
