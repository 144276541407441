import React, { useState } from "react"
import InputLabel from "../../views/BasicConfiguration/components/InputLabel"
import styles from "./SnippetInjectionBox.module.scss"
import CodeMirror from "@uiw/react-codemirror"
import { html } from "@codemirror/lang-html"
import { MenuItem, Select, Button, FormControl } from "@mui/material"
import { SelectChangeEvent } from "@mui/material/Select/SelectInput"
import { Snippet } from "@limbic/types"
import { observer } from "mobx-react"
import SnippetItem from "../SnippetItem/SnippetItem"

interface SnippetInjectionBoxProps {
  currentSnippets: Snippet[]
  onSnippetChange: (snippets: Snippet[]) => void
}

const SnippetInjectionBox: React.FC<SnippetInjectionBoxProps> = ({
  currentSnippets,
  onSnippetChange
}) => {
  const [snippets, setSnippets] = useState<Snippet[]>(currentSnippets || [])
  const [content, setContent] = useState<string>("<style>\n\n</style>")
  const [position, setPosition] = useState<string>("head")
  const [editId, setEditId] = useState<string | null>(null)
  const [type, setType] = useState<"css" | "js">("css")

  const handleSnippetContentChange = (value: string) => {
    setContent(value)
  }

  const handlePositionChange = (event: SelectChangeEvent) => {
    setPosition(event.target.value)
  }

  const handleSnippetTypeChange = (event: SelectChangeEvent<string>) => {
    const selectedType = event.target.value as "css" | "js"
    setType(selectedType)
    setContent(selectedType === "js" ? "<script>\n\n</script>" : "<style>\n\n</style>")
  }

  const handleAddSnippet = () => {
    let updatedSnippets
    if (editId !== null) {
      updatedSnippets = snippets.map(snippet =>
        snippet.id === editId ? { ...snippet, content, position } : snippet
      )
      setEditId(null)
    } else {
      const newSnippet: Snippet = {
        id: Date.now().toString(),
        content,
        position
      }
      updatedSnippets = [...snippets, newSnippet]
    }
    setSnippets(updatedSnippets)
    setType("css")
    setContent("<style>\n\n</style>")
    onSnippetChange(updatedSnippets)
  }

  const handleEditSnippet = (id: string) => {
    const snippetToEdit = snippets.find(snippet => snippet.id === id)
    if (snippetToEdit) {
      setContent(snippetToEdit.content)
      setPosition(snippetToEdit.position)
      setType(snippetToEdit.content.startsWith("<script>") ? "js" : "css")
      setEditId(id)
    }
  }

  const handleDeleteSnippet = (id: string) => {
    const updatedSnippets = snippets.filter(snippet => snippet.id !== id)
    setSnippets(updatedSnippets)
    onSnippetChange(updatedSnippets)
  }

  return (
    <div className={styles.snippetConfigurationContainer}>
      <div className={styles.snippetEditor}>
        <InputLabel
          label="Snippet injection"
          tooltip="Add custom snippets to change CSS, JS, etc"
        />
        <div className={styles.snippetPositionSelector}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel label="Position" tooltip="Select where the snippet is injected" />
            <Select value={position} label="Position" onChange={handlePositionChange}>
              <MenuItem value="head">End of head</MenuItem>
              <MenuItem value="body">End of body</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel label="Type" tooltip="Select the type of snippet" />
            <Select value={type} label="Type" onChange={handleSnippetTypeChange}>
              <MenuItem value="css">CSS</MenuItem>
              <MenuItem value="js">JS</MenuItem>
            </Select>
          </FormControl>
        </div>
        <CodeMirror
          value={content}
          theme="dark"
          height="200px"
          extensions={[html()]}
          onChange={value => handleSnippetContentChange(value)}
        />
        <Button variant="contained" color="primary" onClick={handleAddSnippet}>
          {editId !== null ? "Save Snippet" : "Add Snippet"}
        </Button>
      </div>
      <div className={styles.snippetList}>
        {snippets.map(snippet => (
          <SnippetItem
            key={snippet.id}
            snippet={snippet}
            onEdit={() => handleEditSnippet(snippet.id)}
            onDelete={() => handleDeleteSnippet(snippet.id)}
          />
        ))}
      </div>
    </div>
  )
}

export default observer(SnippetInjectionBox)
