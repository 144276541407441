import React from "react"
import { observer } from "mobx-react"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import { Typography } from "@mui/material"

import styles from "./SubmitReferral.module.scss"

function SubmitReferral(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <Typography className={styles.submitReferralLabel}>
        Bot message when the referral is submitted successfully:
      </Typography>
      <MessageList
        messages={messages?.sayReferralSucceeded ?? []}
        messageType="sayReferralSucceeded"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayReferralSucceeded}
        placeholder="Type a message here..."
        name="sayReferralSucceeded"
        updateState={updateState}
        messages={messages?.sayReferralSucceeded ?? []}
      />
      <SolidDivider />
      <Typography className={styles.submitReferralLabel}>
        Bot message in the case that the referral is NOT submitted successfully (i.e due to network
        issues):
      </Typography>
      <MessageList
        messages={messages?.sayReferralFailed ?? []}
        messageType="sayReferralFailed"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayReferralFailed}
        placeholder="Type a message here..."
        name="sayReferralFailed"
        updateState={updateState}
        messages={messages?.sayReferralFailed ?? []}
      />
    </>
  )
}

export default observer(SubmitReferral)
