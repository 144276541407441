import { action, computed, makeObservable, observable } from "mobx"
import {
  clinicalPaths,
  IBotServiceData,
  IClinicalPath,
  ICustomClinicalPathsDescriptor
} from "@limbic/types"

export interface ICustomClinicalPath extends ICustomClinicalPathsDescriptor {
  valid: boolean
}

export class ClinicalPathsConfigurationStore {
  @observable defaultPaths!: IClinicalPath[]
  @observable customPaths!: ICustomClinicalPath[]

  constructor() {
    this.reset()
    makeObservable(this)
  }

  /** Actions */

  @action
  reset(): void {
    this.defaultPaths = clinicalPaths
    this.customPaths = []
  }

  @action
  sync(data?: IBotServiceData): void {
    this.setDefaultPaths(data?.clinicalPaths?.defaultClinicalPaths)
    this.setCustomPaths(
      data?.clinicalPaths?.customClinicalPaths?.dashboard.map(i => ({ ...i, valid: true }))
    )
  }

  @action
  setDefaultPaths(paths?: IClinicalPath[]): void {
    this.defaultPaths = paths?.length ? paths : clinicalPaths
  }

  @action
  setCustomPaths(paths?: ICustomClinicalPath[]): void {
    this.customPaths = paths ?? []
  }

  @action
  addNewCustomPath(): void {
    this.setCustomPaths([
      ...this.customPaths,
      {
        primaryProblems: [],
        secondaryProblems: [],
        flags: "ALL",
        referralType: "",
        enableSecondary: false,
        valid: true
      }
    ])
  }

  @action
  validateCustomPaths(): void {
    const newPaths = this.customPaths.map(path => {
      if (
        !path.primaryProblems.length ||
        (path.enableSecondary && !path.secondaryProblems.length) ||
        !path.flags ||
        !path.referralType.length
      ) {
        console.log("invalid path", path)
        return { ...path, valid: false }
      }

      return { ...path, valid: true }
    })
    this.setCustomPaths(newPaths)
  }

  /** Getters / Setters */

  @computed
  get isValid(): boolean {
    return !this.customPaths.some(path => !path.valid)
  }
}
