// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GqVmwk90jzft6YUG8RDT{display:flex;flex-flow:column;flex:1;justify-content:center;align-items:center;align-self:stretch}.mQlC3bItntAkLKgqG9JW{width:100%;margin-bottom:16px}.PpBW03fzWreRI1wyorfM{width:55%;margin:8px !important}.J0VRqKr2hkuiH0AWfptg{display:flex;flex-flow:row;justify-content:center;align-items:center;width:100%;margin:8px 0;border:1px solid #d3d3d3;padding:.5em}.KHmSA5EQJCIfOAzd0QOs{width:25%}.fYwnwctnzzuD62YrKIll{margin:0 4px !important}", "",{"version":3,"sources":["webpack://./src/app/views/BasicConfiguration/components/TranslationsConfiguration/TranslationsConfiguration.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,MAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CACA,kBAAA,CAGF,sBACE,SAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,aAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,YAAA,CACA,wBAAA,CACA,YAAA,CAGF,sBACE,SAAA,CAGF,sBACE,uBAAA","sourcesContent":[".translationsConfigurationContainer {\n  display: flex;\n  flex-flow: column;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  align-self: stretch;\n}\n\n.autocompleteOptions {\n  width: 100%;\n  margin-bottom: 16px;\n}\n\n.formControl {\n  width: 55%;\n  margin: 8px !important;\n}\n\n.languages {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  margin: 8px 0;\n  border: 1px solid lightgray;\n  padding: 0.5em;\n}\n\n.defaultLanguage {\n  width: 25%;\n}\n\n.optionChip {\n  margin: 0 4px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"translationsConfigurationContainer": "GqVmwk90jzft6YUG8RDT",
	"autocompleteOptions": "mQlC3bItntAkLKgqG9JW",
	"formControl": "PpBW03fzWreRI1wyorfM",
	"languages": "J0VRqKr2hkuiH0AWfptg",
	"defaultLanguage": "KHmSA5EQJCIfOAzd0QOs",
	"optionChip": "fYwnwctnzzuD62YrKIll"
};
export default ___CSS_LOADER_EXPORT___;
