import { observer } from "mobx-react"
import React, { useState } from "react"
import { Tab, Tabs } from "@mui/material"
import styles from "./Integration.module.scss"
import BackendMapping from "./BackendMapping"
import Dialog from "../../components/Dialog"
import ServiceIntegration from "./ServiceIntegration"

function Integration() {
  const [currentTab, setCurrentTab] = useState<"backend" | "service" | "integration">("integration")
  const [error, setError] = useState(false)

  const handleError = () => setError(true)

  return (
    <div className={styles.container}>
      <Dialog
        onClose={() => setError(false)}
        open={error}
        title="Something went wrong"
        maxWidth="sm">
        {error && (
          <>
            <span>Something went wrong while making updates to the mapping</span>
            <br />
            <span>Please try again</span>
          </>
        )}
      </Dialog>
      <div className={styles.headerContainer}>
        <Tabs
          value={currentTab}
          onChange={(_, tab) => setCurrentTab(tab)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Mapping Navigation">
          <Tab label="Integration" value="integration" />
          <Tab label="Backend Mapping" value="backend" />
          <Tab disabled label="Service Mapping" value="service" />
        </Tabs>
      </div>

      {currentTab === "backend" && <BackendMapping onError={handleError} />}
      {currentTab === "integration" && <ServiceIntegration onError={handleError} />}
    </div>
  )
}

export default observer(Integration)
