import React, { useMemo } from "react"
import { observer } from "mobx-react"
import styles from "../Integration.module.scss"
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material"
import { useBackendMappingStore } from "../../../context/rootStoreContext"
import { computed } from "mobx"
import { IKeyTransform, KeyType } from "@limbic/types"
import CancelIcon from "@mui/icons-material/Cancel"
import IconButton from "@mui/material/IconButton"

interface IProps {
  transform: IKeyTransform
  onChangeValueMapping: (
    keyMap: IKeyTransform,
    sourceValue: string | number | boolean,
    targetValue: string | number | boolean
  ) => void
  onRemoveTargetValue: (keyMap: IKeyTransform, sourceValue: string | number | boolean) => void
}

function ValueMappingInput(props: IProps) {
  const { transform } = props
  const mappingStore = useBackendMappingStore()

  const valuesMapEntries = useMemo(
    () => computed(() => Object.entries(transform.valuesMap ?? {})),
    [transform.valuesMap]
  ).get()

  // if there's no target yet, then there ain't nothing to map to
  if (!transform.targetKey) return null

  // if for whatever reason the target key metadata is not found, then there ain't nothing to map to
  const keyMetaData = mappingStore.targetKeys[transform.targetKey]
  if (!keyMetaData) return null

  // if the source or the target, have no values, then there ain't nothing to map from/to
  const allowedValues = keyMetaData.allowedValues ?? []
  if (!(valuesMapEntries.length && allowedValues.length)) return null

  // if both are boolean, then there ain't no reason to map
  const isBothBoolean = [keyMetaData.type, transform.type].every(t => t === KeyType.Boolean)
  if (isBothBoolean) return null

  return (
    <div className={styles.valuesMapContainer}>
      <div className={styles.valuesMapEntriesContainer}>
        {valuesMapEntries.map(([sourceValue, targetValue], index) => (
          <div key={index} className={styles.valuesMapEntry}>
            <FormControl
              className={styles.valuesMapInput}
              data-shadow-value={
                String(sourceValue).length > (String(targetValue ?? "").length ?? "")
                  ? sourceValue
                  : targetValue
              }>
              <Tooltip title={sourceValue}>
                <InputLabel>
                  {sourceValue === "__inlineFreeTextValue__" ? "Free Text Input" : sourceValue}
                </InputLabel>
              </Tooltip>
              <Select
                value={targetValue ?? ""}
                label={sourceValue}
                onChange={e => props.onChangeValueMapping(transform, sourceValue, e.target.value)}>
                {allowedValues.map(value => (
                  <MenuItem
                    key={String(value)}
                    value={String(value)}
                    className={styles.questionItem}>
                    {String(value)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {targetValue != null && (
              <IconButton
                data-testid={`backend-mapping-unmap-value-${transform.context}.${transform.sourceKey}.${sourceValue}`}
                className={styles.unMapValueButton}
                onClick={() => props.onRemoveTargetValue(transform, sourceValue)}>
                <CancelIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(ValueMappingInput)
