import React from "react"
import { observer } from "mobx-react"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"
import styles from "./CollectSMI.module.scss"
import { Typography } from "@mui/material"
import SolidDivider from "../../../../../SolidDivider"
import FormControlWithCheckBox from "../FormControlWithCheckBox"

function CollectSMI(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <Typography className={styles.settingsTitle}>Intro message:</Typography>
      <MessageList
        messages={messages?.smiIntroMessage ?? []}
        messageType="smiIntroMessage"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.smiIntroMessage}
        placeholder="Type a message here..."
        name="smiIntroMessage"
        updateState={updateState}
        messages={messages?.smiIntroMessage ?? []}
      />

      <SolidDivider />

      <FormControlWithCheckBox
        name="shouldUpdateReferral"
        label="Should update referral"
        labelPlacement="end"
        updateState={updateState}
      />
    </>
  )
}

export default observer(CollectSMI)
