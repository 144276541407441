import React, { ChangeEvent } from "react"
import { observer } from "mobx-react"
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material"
import {
  IChatbotPropertyKey,
  KeyType,
  LeftHandContextType,
  RightHandContextType,
  RightHandContextTypeForList
} from "@limbic/types"
import sortObjectAlphabetically from "../../../../../../../utils/sortObjectAlphabetically"
import {
  useChatBotPropertiesStore,
  useNodeEditorStore,
  useSettingsStore
} from "../../../../../../context/rootStoreContext"
import { storageNames } from "../../constants"
import { transformersMap, TransformerKeys } from "@limbic/transformers"
import { OPERAND_FALLBACK_VALUE, VALUE_NOT_SET } from "../../../../../../stores/NodeEditorStore"
import styles from "./Operand.module.scss"

// Not using the react HTMLInputTypeAttribute because types need an update and
// everything becomes red when the types are updated
type InputType = "number" | "text"

export type OperandType = "left" | "right"

interface Props {
  type: OperandType
  operandContext: LeftHandContextType | RightHandContextType
  handleContextChange: (
    value: LeftHandContextType | RightHandContextType,
    type: OperandType
  ) => void
  handleTextInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: OperandType
  ) => void
  handleOperandChange: (value: IChatbotPropertyKey | string, type: OperandType) => void
  handleCustomOperandChange?: (value: string, type: OperandType) => void
  handleCustomValueChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: OperandType
  ) => void
  handleOperandTransformerChange: (value: TransformerKeys | "-", type: OperandType) => void
}

function Operand(props: Props): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { advancedConditionEditorState } = nodeEditorStore
  const chatbotPropertiesStore = useChatBotPropertiesStore()
  const settingsStore = useSettingsStore()
  const { chatbotProperties } = chatbotPropertiesStore
  const { type } = props
  const isLeft = props.type === "left"
  const operand = advancedConditionEditorState[`${props.type}Operand`]?.value
  const ContextType = isLeft ? LeftHandContextType : RightHandContextType
  const availableTransformers = operand?.type ? transformersMap[operand?.type] : undefined

  const isNumberStringBooleanInput =
    props.operandContext === RightHandContextType.BOOLEAN ||
    props.operandContext === RightHandContextType.STRING ||
    props.operandContext === RightHandContextType.NUMBER

  const shouldShowTextInput =
    isNumberStringBooleanInput || props.operandContext === ContextType.CUSTOM_FIELDS

  const shouldDisableAllInputs =
    !isLeft && advancedConditionEditorState.leftOperand?.value?.type === KeyType.Boolean

  const shouldDisableTransformer =
    shouldDisableAllInputs ||
    !Object.keys(transformersMap[advancedConditionEditorState.leftOperand?.value?.type] ?? {})
      .length

  const isLeftAList =
    advancedConditionEditorState.leftOperand?.value?.type === KeyType.BooleanList ||
    advancedConditionEditorState.leftOperand?.value?.type === KeyType.TextList ||
    advancedConditionEditorState.leftOperand?.value?.type === KeyType.NumberList ||
    advancedConditionEditorState.leftOperand?.value?.type === KeyType.ObjectList
  const transformers = advancedConditionEditorState[`${type}Operand`]?.transformers

  const contextOptions = isLeft
    ? Object.keys(LeftHandContextType)
    : Object.keys(isLeftAList ? RightHandContextTypeForList : RightHandContextType)

  const getProperties = (): IChatbotPropertyKey[] => {
    const valueToCheck = props.operandContext
    const leftOperandType = advancedConditionEditorState.leftOperand?.value.type
    switch (valueToCheck) {
      case ContextType.STATE:
        return [OPERAND_FALLBACK_VALUE, ...chatbotProperties].filter(
          p => props.type === "left" || p.type === leftOperandType
        )
      case ContextType.CLINICAL_STORE: {
        const clinicalStoreProperties =
          settingsStore.settings?.chatbotSettings?.clinicalStoreProperties ?? []
        return clinicalStoreProperties?.filter(
          p => props.type === "left" || p.type === leftOperandType
        )
      }
      case ContextType.CONFIG_STORE: {
        const configStoreProperties =
          settingsStore.settings?.chatbotSettings?.configStoreProperties ?? []
        return configStoreProperties.filter(
          p => props.type === "left" || p.type === leftOperandType
        )
      }
      case ContextType.REFERRAL_STORE: {
        const referralStoreProperties =
          settingsStore.settings?.chatbotSettings?.referralStoreProperties ?? []
        return referralStoreProperties.filter(
          p => props.type === "left" || p.type === leftOperandType
        )
      }
      default:
        return []
    }
  }

  const getInputType = (operandContext: LeftHandContextType | RightHandContextType): InputType => {
    switch (operandContext) {
      case RightHandContextType.BOOLEAN:
      case RightHandContextType.STRING:
        return "text"
      case RightHandContextType.NUMBER:
        return "number"
      default:
        return "text"
    }
  }

  return (
    <>
      <Typography className={styles.descriptionSubtitle} variant="subtitle1">
        {isLeft ? "Left" : "Right"} hand operand
      </Typography>
      <FormControl className={styles.formControlAdvancedCondition}>
        <InputLabel id={`${type}-hand-operand-label`}>Source Type</InputLabel>
        <Select
          labelId={`${type}-hand-operand-label`}
          id={`${type}-hand-operand-select`}
          value={props.operandContext}
          label={`${isLeft ? "Left" : "Right"} hand operand`}
          onChange={e =>
            props.handleContextChange(
              e.target.value as LeftHandContextType | RightHandContextType,
              props.type
            )
          }
          disabled={shouldDisableAllInputs}>
          {contextOptions.map(key => (
            <MenuItem key={`${type}-operand-${key}`} value={RightHandContextType[key]}>
              {storageNames[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={styles.formControlAdvancedCondition}>
        {shouldShowTextInput ? (
          <TextField
            type={getInputType(props.operandContext)}
            disabled={shouldDisableAllInputs}
            placeholder="Property Name"
            className={styles.customFieldInput}
            value={isNumberStringBooleanInput ? operand?.customValue : operand?.sourceKey}
            onChange={e =>
              isNumberStringBooleanInput
                ? props.handleCustomValueChange(e, props.type)
                : props.handleTextInputChange(e, props.type)
            }
          />
        ) : (
          <Autocomplete
            classes={{ option: styles.autcompleteOption }}
            disabled={shouldDisableAllInputs}
            multiple={false}
            freeSolo
            id={`${type}HandOperand`}
            onChange={(_event, value) => props.handleOperandChange(value!, props.type)}
            onInputChange={(_event, value) => {
              if (
                isLeft &&
                props.operandContext === ContextType.STATE &&
                props.handleCustomOperandChange
              ) {
                props.handleCustomOperandChange(value!, props.type)
              }
            }}
            options={getProperties().sort(sortObjectAlphabetically("sourceKey"))}
            getOptionLabel={option => {
              if (typeof option === "string") {
                return option
              }
              return option.sourceKey === VALUE_NOT_SET
                ? option.sourceKey
                : `${option.sourceKey} [${option.type}]`
            }}
            value={operand}
            isOptionEqualToValue={(o, v) => o.sourceKey === v.sourceKey}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Property"
                placeholder={`Select the property of the ${type} hand operand`}
              />
            )}
          />
        )}
      </FormControl>
      <FormControl className={styles.formControlAdvancedCondition}>
        <InputLabel id={`${type}-hand-operand-transforrmer-label`}>Transformer</InputLabel>
        <Select
          disabled={shouldDisableTransformer}
          labelId={`${type}-hand-operand-transformer-label`}
          id={`${type}-hand-operand-transformer-select`}
          value={transformers?.length ? transformers[0] : VALUE_NOT_SET}
          label="Transformer"
          onChange={e =>
            props.handleOperandTransformerChange(e.target.value as TransformerKeys, props.type)
          }>
          {availableTransformers ? (
            [VALUE_NOT_SET, ...Object.keys(availableTransformers)].map(transformer => (
              <MenuItem key={`${type}-hand-operand-transformer-${transformer}`} value={transformer}>
                {transformer}
              </MenuItem>
            ))
          ) : (
            <MenuItem
              key={`${type}-hand-operand-transformer-${VALUE_NOT_SET}`}
              value={VALUE_NOT_SET}>
              {VALUE_NOT_SET}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  )
}

export default observer(Operand)
