import { action, makeObservable, observable } from "mobx"
import { IBotServiceData, KeepingSafeEmailContent } from "@limbic/types"
import Logo from "../assets/logo.png"
import { KeepingSafeEmail } from "@limbic/types/dist/access/config/dashboard"
import { previewKeepingSafeEmail } from "../../backend/api/keepingSafeEmail"

export class KeepingSafeEmailConfigurationStore {
  @observable keepingSafeEmail!: KeepingSafeEmail
  @observable attachmentFile?: File
  @observable logo?: File
  @observable errors!: Record<string, string[]>

  constructor() {
    this.reset()
    makeObservable(this)
  }

  /** Actions */

  @action
  reset(organisationName?: string): void {
    const orgName = organisationName ?? "[SERVICE NAME]"
    const orgNameHTML =
      organisationName ?? "<span style='color:hsl(0, 75%, 60%);'>[SERVICE NAME]</span>"
    this.keepingSafeEmail = {
      subject: `${orgName} Keeping Safe Message`,
      content: {
        text: `<p>You have made a self-referral today to ${orgNameHTML}. In one of your answers you said you were experiencing some thoughts of self-harm or taking your own life.</p><p>${orgNameHTML} are not an emergency service, so cannot help you in times of immediate crisis</p><p>It's common for people experiencing depression and anxiety to have thoughts related to self-harm and suicide. Whilst these thoughts can be really distressing, there is nothing wrong with this and there is help and support available for you. In the first instance you can talk to a friend or family member, have a discussion with your GP or call one of the numbers below. Alternatively you can create a safety plan, using this website <span style="color:hsl(0, 75%, 60%);">[INSERT WEBSITE URL]</span>.</p><p><b>Samaritans:</b> Call the Samaritans for free on 116 123 / Call line open 24/7 / <a href="https://www.samaritans.org">www.samaritans.org</a><br/><b>Shout:</b> Text SHOUT to 85258 for free support via text / Open 24/7 / <a href="https://giveusashout.org/">giveusashout.org</a><br/><b>NHS 999:</b> Always call 999 if you need urgent, life-threatening medical help or attend your local A&E department.</p>`,
        signature: `The ${orgName} Team`,
        title: `${orgName} Keeping Safe Message`
      },
      logoURL: Logo,
      attachmentURL: "",
      shouldSendKeepingSafeEmail: false
    }
    this.attachmentFile = undefined
    this.errors = {}
  }

  @action
  sync(data?: IBotServiceData): void {
    this.setKeepingSafeEmail(data?.keepingSafeEmail)
  }

  @action
  setShouldSendKeepingSafe(value: boolean): void {
    this.keepingSafeEmail.shouldSendKeepingSafeEmail = value
  }

  @action
  setSubject(subject: string): void {
    this.keepingSafeEmail.subject = subject
  }

  @action
  setContent(content: KeepingSafeEmailContent): void {
    this.keepingSafeEmail.content = content
  }

  @action
  setLogoURL(logoURL: string): void {
    this.keepingSafeEmail.logoURL = logoURL
  }

  @action
  setAttachmentURL(attachmentURL?: string): void {
    this.keepingSafeEmail.attachmentURL = attachmentURL ?? ""
  }

  @action
  setAttachmentFile(attachmentFile?: File): void {
    this.attachmentFile = attachmentFile
  }

  @action
  setLogo(logo?: File): void {
    this.logo = logo
  }

  @action
  setError(key: string, error: string): void {
    this.errors[key] = [error]
  }

  @action.bound
  clearErrors(): void {
    this.errors = {}
  }

  @action
  setKeepingSafeEmail(keepingSafeEmail?: KeepingSafeEmail | null): void {
    const { subject, content, logoURL, attachmentURL, shouldSendKeepingSafeEmail } =
      keepingSafeEmail ?? {}

    if (!(subject && content?.title && content?.text && content?.signature && logoURL)) {
      return this.reset()
    }

    this.setShouldSendKeepingSafe(shouldSendKeepingSafeEmail ?? false)
    this.setSubject(subject)
    this.setContent(content)
    this.setLogoURL(logoURL)
    this.setAttachmentURL(attachmentURL)
  }

  @action
  validateKeepingSafeEmail(): void {
    this.errors = {}
    this.errors.subject = this.keepingSafeEmail.subject ? [] : ["Subject is Required"]
    this.errors.title = this.keepingSafeEmail.content.title ? [] : ["Title is Required"]
    this.errors.text = this.keepingSafeEmail.content.text ? [] : ["Content is Required"]
    this.errors.signature = this.keepingSafeEmail.content.signature ? [] : ["Signature is Required"]
    this.errors.logoURL = this.keepingSafeEmail.logoURL ? [] : ["Logo is Required"]
  }

  /** Generic Handlers */

  async getEmailPreviewHTML(logoURL?: string): Promise<string> {
    const emailContent = this.keepingSafeEmail.content
    let emailHTML = await previewKeepingSafeEmail(emailContent, logoURL)
    emailHTML = emailHTML.replace(
      new RegExp("<a href=", "g"),
      "<a target='_blank' rel='noreferrer' href="
    )
    return emailHTML
  }
}
