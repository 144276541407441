import React from "react"
import IconButton from "@mui/material/IconButton"
import CancelIcon from "@mui/icons-material/Cancel"
import AddIcon from "@mui/icons-material/AddCircle"
import { Button as MuiButton } from "@mui/material"
import styles from "./PhoneNumbersInput.module.scss"

interface Props {
  initialRows: number
  values: string[]
  onFocus: () => void
  onChange: (_event) => void
  onDelete: (_values) => void
}

function PhoneNumbersInput(props: Props): JSX.Element {
  const { onFocus, onChange, onDelete, initialRows, values } = props
  const [rows, setRows] = React.useState<number[]>([0])
  const [updatedValues, setUpdatedValues] = React.useState<string[]>([])

  React.useEffect(() => {
    setUpdatedValues(values)
  }, [values])

  React.useEffect(() => {
    setRows(Array.from({ length: initialRows }).map((_currentElement, i) => i))
  }, [initialRows])

  const addPhoneNumber = () => {
    const newRows = [...rows]
    newRows.push(rows[rows.length - 1] + 1)
    setRows(newRows)
  }

  const deletePhoneNumber = row => {
    const newRows = [...rows]
    newRows.splice(row, 1)
    setRows(newRows)
    const newValues = [...updatedValues]
    newValues.splice(row, 1)
    setUpdatedValues(newValues)
    onDelete(newValues)
  }

  return (
    <div className={styles.phoneNumbersContainer}>
      {rows.map(row => (
        <div className={styles.phoneNumberRow} key={`phoneNumberRow-${row}`}>
          <input
            onFocus={onFocus}
            name={`organisationPhoneNumbers-${row}`}
            value={values[row]}
            onChange={onChange}
            placeholder="Add a phone number"
          />
          <IconButton
            size="small"
            className={styles.deleteButton}
            disabled={row === 0}
            onClick={() => deletePhoneNumber(row)}>
            <CancelIcon />
          </IconButton>
        </div>
      ))}
      <MuiButton className={styles.addButton} onClick={addPhoneNumber}>
        <AddIcon />
        <span>Add a number</span>
      </MuiButton>
    </div>
  )
}

export default PhoneNumbersInput
