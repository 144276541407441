import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import { IDashboardDoc } from "@limbic/types"
import { ServiceStatus } from "../../models/Service"
import invariant from "../../utils/invariant"
import Logger from "../../utils/Logger"
import * as service from "./service"

const TOTAL_RETRIES = 3
const DASHBOARD_URL = `${BACKEND_URL}/v1/dashboard`

const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export async function getServiceWithVersion(
  serviceApiKey: string,
  version: "draft" | "published" = "draft",
  retry = 0
): Promise<[IDashboardDoc | undefined, ServiceStatus]> {
  invariant(serviceApiKey, "Cannot get data without a serviceApiKey")
  try {
    const result = await fetch(`${DASHBOARD_URL}/${serviceApiKey}/${version}`, { headers })
    const json = await result.json()

    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    if (success && !data) {
      return [data, ServiceStatus.NoServiceFound]
    }
    return [data, ServiceStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "getServiceWithVersion failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`getServiceWithVersion key for retry ${retry}`, serviceApiKey)
      Logger.getInstance().message("getServiceWithVersion retry")
      await delay(1)
      return await service.getServiceWithVersion(serviceApiKey, version, retry + 1)
    }
    throw e
  }
}

export async function createService(
  serviceName: string,
  serviceKey: string,
  botApiKeys: string[],
  retry = 0
): Promise<[IDashboardDoc | undefined, ServiceStatus]> {
  invariant(serviceName, "Cannot create a service without a serviceName")
  invariant(serviceKey, "Cannot create a service without a serviceKey")
  invariant(botApiKeys, "Cannot create a service without botApiKeys")

  try {
    const method = "POST"
    const body = JSON.stringify({
      key: serviceKey,
      name: serviceName,
      botApiKeys
    })
    const result = await fetch(`${DASHBOARD_URL}/new`, { body, method, headers })
    const json = await result.json()

    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return [data, ServiceStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "createService failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`createService key for retry ${retry}`, serviceKey)
      Logger.getInstance().message("createService retry")
      await delay(1)
      return await service.createService(serviceName, serviceKey, botApiKeys, retry + 1)
    }
    throw e
  }
}

export async function getPresignedURL(
  serviceApiKey: string,
  // TODO: Move in @limbic/types?
  fileType:
    | "logo-leaflet.jpeg"
    | "logo-leaflet.jpg"
    | "logo-leaflet.png"
    | "attachment.pdf"
    | "logo-bot.jpeg"
    | "logo-bot.jpg"
    | "logo-bot.png"
    | "iapt-icon.jpeg"
    | "iapt-icon.jpg"
    | "iapt-icon.png",
  customFolder?: string,
  retry = 0
): Promise<string> {
  try {
    const method = "POST"
    const body = JSON.stringify({
      fileType,
      customFolder
    })
    const result = await fetch(`${DASHBOARD_URL}/${serviceApiKey}/pre-signed-url`, {
      body,
      method,
      headers
    })
    const json = await result.json()

    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "getPresignedURL failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`getPresignedURL key for retry ${retry}`, serviceApiKey)
      Logger.getInstance().message("getPresignedURL retry")
      await delay(1)
      return await service.getPresignedURL(serviceApiKey, fileType, customFolder, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
