import React from "react"
import { ClinicalGroups, IClinicalPath } from "@limbic/types"
import styles from "./Input.module.scss"

interface Props {
  input: IClinicalPath
  index: number
  onChange: (_event, index: number) => void
}

function Input(props: Props): JSX.Element {
  const { input, index, onChange } = props

  const name = getClinicalPathName(input)
  const flags = input.matcher?.flags?.length ? ` w/ ${input.matcher.flags.join(", ")}` : ""

  return (
    <div className={styles.clinicalPathInputContainer}>
      <label htmlFor={input.clinicalGroup}>
        <b>{name}</b>
        {flags}
      </label>
      <input
        name={input.clinicalGroup}
        value={input.defaultReferralType}
        onChange={event => onChange(event, index)}
        placeholder="Add a referral type"
      />
    </div>
  )
}

export default React.memo(Input)

function getClinicalPathName(clinicalPath: IClinicalPath): string | undefined {
  if (
    [
      ClinicalGroups.BelowCaseness,
      ClinicalGroups.BelowCaseness_MS_FI,
      ClinicalGroups.BelowCaseness_S_FI
    ].includes(clinicalPath.clinicalGroup)
  ) {
    return ClinicalGroups.BelowCaseness
  }

  return clinicalPath.matcher?.primaryProblems[0] ?? clinicalPath.clinicalGroup
}
