import React from "react"
import { observer } from "mobx-react"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import styles from "./CollectAlcohol.module.scss"
import { Typography } from "@mui/material"
import MessageList from "../MessageList"
import MessageForm from "../MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import { ChatFlowsEnum } from "@limbic/types"
import clsx from "classnames"
import FormControlWithCheckboxControlledInput from "../FormControlWithCheckBox/FormControlWithCheckboxControlledInput"

function CollectAlcohol(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages
  const currentSettings = chatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION]
  const useCustomKeys =
    !!currentSettings?.alcoholKey ||
    !!currentSettings?.alcoholFrequencyKey ||
    !!currentSettings?.alcoholQuantityKey ||
    !!currentSettings?.alcoholImpactsLifeKey

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  const onCustomiseStateKeysClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION]: { ...currentSettings }
    }

    // if value is true it means we're enabling custom keys
    // so, we need to reset the entries to the default values
    // otherwise remove the entries entirely
    if (value) {
      newChatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION] = {
        ...currentSettings,
        alcoholKey: "alcohol",
        alcoholFrequencyKey: "alcoholFrequency",
        alcoholQuantityKey: "alcoholQuantity",
        alcoholImpactsLifeKey: "alcoholImpactsLife"
      }
    } else {
      delete newChatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION].alcoholKey
      delete newChatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION].alcoholFrequencyKey
      delete newChatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION].alcoholQuantityKey
      delete newChatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION].alcoholImpactsLifeKey
    }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditAlcoholKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION]: {
        ...currentSettings,
        alcoholKey: event.target.value || undefined // do not store an empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditAlcoholFrequencyKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION]: {
        ...currentSettings,
        alcoholFrequencyKey: event.target.value || undefined // do not store an empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditAlcoholQuanityKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION]: {
        ...currentSettings,
        alcoholQuantityKey: event.target.value || undefined // do not store an empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditAlcoholImpactsLifeKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION]: {
        ...currentSettings,
        alcoholImpactsLifeKey: event.target.value || undefined // do not store an empty string
      }
    }
    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  return (
    <>
      <Typography className={styles.settingsTitle}>Alcohol question settings:</Typography>

      <MessageList
        messages={messages?.askAlcohol ?? []}
        messageType="askAlcohol"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askAlcohol}
        placeholder="Type a message here..."
        name="askAlcohol"
        updateState={updateState}
        messages={messages?.askAlcohol ?? []}
      />

      <FormControlWithCheckBox
        name="shouldAskAlcoholFrequency"
        label="Ask how often they drink"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskAlcoholFrequency
        })}>
        <MessageList
          messages={messages?.askAlcoholFrequency ?? []}
          messageType="askAlcoholFrequency"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askAlcoholFrequency}
          placeholder="Type a message here..."
          name="askAlcoholFrequency"
          updateState={updateState}
          messages={messages?.askAlcoholFrequency ?? []}
        />
      </div>

      <FormControlWithCheckBox
        name="shouldAskAlcoholQuantity"
        label="Ask how much they drink"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskAlcoholQuantity
        })}>
        <MessageList
          messages={messages?.askAlcoholQuantity ?? []}
          messageType="askAlcoholQuantity"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askAlcoholQuantity}
          placeholder="Type a message here..."
          name="askAlcoholQuantity"
          updateState={updateState}
          messages={messages?.askAlcoholFrequency ?? []}
        />
      </div>

      <FormControlWithCheckBox
        name="shouldAskAlcoholImpactsLife"
        label="Ask if drinking impacts their life"
        labelPlacement="end"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskAlcoholImpactsLife
        })}>
        <MessageList
          messages={messages?.askAlcoholImpactsLife ?? []}
          messageType="askAlcoholImpactsLife"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askAlcoholImpactsLife}
          placeholder="Type a message here..."
          name="askAlcoholImpactsLife"
          updateState={updateState}
          messages={messages?.askAlcoholImpactsLife ?? []}
        />
      </div>

      <SolidDivider />

      <Typography className={styles.settingsTitle}>Advanced Settings:</Typography>

      <FormControlWithCheckboxControlledInput
        name="customiseStateKeys"
        value={useCustomKeys}
        label="Edit state keys"
        labelPlacement="start"
        onToggle={onCustomiseStateKeysClick}
      />

      {useCustomKeys && (
        <>
          <div className={styles.keyInputContainer}>
            <Typography>Alcohol</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.alcoholKey ?? "alcohol"}
              placeholder="alcohol"
              onChange={onEditAlcoholKey}
            />
          </div>

          <div className={styles.keyInputContainer}>
            <Typography>Alcohol Frequency</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.alcoholFrequencyKey ?? "alcoholFrequency"}
              placeholder="alcoholFrequency"
              onChange={onEditAlcoholFrequencyKey}
            />
          </div>

          <div className={styles.keyInputContainer}>
            <Typography>Alcohol Quantity</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.alcoholQuantityKey ?? "alcoholQuantity"}
              placeholder="alcoholQuantity"
              onChange={onEditAlcoholQuanityKey}
            />
          </div>

          <div className={styles.keyInputContainer}>
            <Typography>Alcohol Impacts Life</Typography>
            <input
              className={styles.keyInput}
              value={currentSettings?.alcoholImpactsLifeKey ?? "alcoholImpactsLife"}
              placeholder="alcoholImpactsLife"
              onChange={onEditAlcoholImpactsLifeKey}
            />
          </div>
        </>
      )}
    </>
  )
}

export default observer(CollectAlcohol)
