// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._h2JXkF2vz_Koo5qOUwk{display:flex;flex-direction:column;width:100%;margin-top:20px}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ActionEditor/components/AddClinicalNote/AddClinicalNote.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,eAAA","sourcesContent":[".addClinicalNoteContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addClinicalNoteContainer": "_h2JXkF2vz_Koo5qOUwk"
};
export default ___CSS_LOADER_EXPORT___;
