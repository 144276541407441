// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pUWJXQLM9iZkBMO2QRCk{display:flex;flex-flow:row;justify-content:center;align-items:center;width:944px;padding:6px 0;position:relative}@media only screen and (max-width: 960px){.pUWJXQLM9iZkBMO2QRCk{width:calc(100vw - 16px)}}@media only screen and (max-width: 800px){.pUWJXQLM9iZkBMO2QRCk{width:80vw}}.DImjWBo7QWfxZDiQmCQT{display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;align-items:center;margin:16px 0 32px 0}", "",{"version":3,"sources":["webpack://./src/app/views/BasicConfiguration/components/BasicConfiguration/BasicConfiguration.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,aAAA,CACA,iBAAA,CACA,0CARF,sBASI,wBAAA,CAAA,CAEF,0CAXF,sBAYI,UAAA,CAAA,CAIJ,sBACE,YAAA,CACA,aAAA,CACA,cAAA,CACA,sBAAA,CACA,kBAAA,CACA,oBAAA","sourcesContent":[".basicConfigurationContainer {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  align-items: center;\n  width: calc(960px - 16px);\n  padding: 6px 0;\n  position: relative;\n  @media only screen and (max-width: 960px) {\n    width: calc(100vw - 16px);\n  }\n  @media only screen and (max-width: 800px) {\n    width: 80vw;\n  }\n}\n\n.basicConfigurationButtons {\n  display: flex;\n  flex-flow: row;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  margin: 16px 0 32px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"basicConfigurationContainer": "pUWJXQLM9iZkBMO2QRCk",
	"basicConfigurationButtons": "DImjWBo7QWfxZDiQmCQT"
};
export default ___CSS_LOADER_EXPORT___;
