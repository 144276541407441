import self from "./configuration"
import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import { BasicConfiguration, IDashboardDoc } from "@limbic/types"
import invariant from "../../utils/invariant"
import Logger from "../../utils/Logger"
import { getPresignedURL } from "./service"
import Logo from "../../app/assets/logo.png"
import formatUnicorn from "../../utils/formatUnicorn"

const TOTAL_RETRIES = 3
const CONFIGURATION_URL = `${BACKEND_URL}/v1/dashboard/{serviceApiKey}/configuration`

const method = "POST"
const headers = new Headers()
headers.set("x-api-key", API_KEY)
headers.set("Content-Type", "application/json")

export default async function updateConfiguration(
  serviceApiKey: string,
  config: BasicConfiguration,
  logo?: File,
  retry = 0
): Promise<IDashboardDoc | undefined> {
  invariant(serviceApiKey, "Cannot update configuration without a serviceApiKey")

  const payload = { ...config }

  let uploadUrl
  let uploadHeaders
  if (logo) {
    const fileType = `logo-bot.${logo?.type.split("/")[1]}` as
      | "logo-bot.jpeg"
      | "logo-bot.jpg"
      | "logo-bot.png"
    uploadUrl = await getPresignedURL(serviceApiKey, fileType)
    uploadHeaders = new Headers()
    uploadHeaders.set("Content-Type", logo?.type)
    const uploadLogo = await fetch(uploadUrl, {
      method: "PUT",
      headers: uploadHeaders,
      body: logo
    })
    payload.logo = uploadLogo.url.split("?")[0]
  }

  if (!logo && !payload.logo) {
    let blob
    const oReq = new XMLHttpRequest()
    oReq.open("get", Logo, true)
    oReq.responseType = "blob"
    oReq.onload = function () {
      blob = oReq.response
    }
    oReq.send()
    uploadUrl = await getPresignedURL(serviceApiKey, "logo-bot.png")
    uploadHeaders = new Headers()
    uploadHeaders.set("Content-Type", "image/png")
    const uploadLogo = await fetch(uploadUrl, {
      method: "PUT",
      headers: uploadHeaders,
      body: blob
    })
    payload.logo = uploadLogo.url.split("?")[0]
  }

  const URL = formatUnicorn(CONFIGURATION_URL, { serviceApiKey })
  const body = JSON.stringify(payload)

  try {
    const result = await fetch(URL, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "updateConfiguration failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`updateConfiguration body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("updateConfiguration retry")
      await delay(1)
      return await self(serviceApiKey, config, logo, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
