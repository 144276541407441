import { memo } from "react"
import { gray } from "@radix-ui/colors"

interface IProps {
  size?: number
  strokeColor?: string
  strokeWidth: number
  fillColor: string
  strokeDasharray?: string
}

function RhombusShape({
  size = 100,
  strokeWidth = 1,
  fillColor = gray.gray1,
  strokeColor = "black",
  ...props
}: IProps): JSX.Element {
  const d = getVectorPath(size)
  return (
    <svg width={size} height={size} style={{ display: "block", overflow: "visible" }}>
      <path
        d={d}
        fill={fillColor}
        strokeWidth={strokeWidth}
        stroke={strokeColor}
        strokeDasharray={props.strokeDasharray}></path>
    </svg>
  )
}

const getVectorPath = (n: number) => `M0,${n / 2} L${n / 2},0 L${n},${n / 2} L${n / 2},${n} z`

export default memo(RhombusShape)
