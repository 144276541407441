import * as React from "react"
import MuiDrawer, { DrawerProps } from "@mui/material/Drawer"
import { styled } from "@mui/material/styles"

interface CustomDrawerProps extends DrawerProps {
  width?: string | undefined
}

type Anchor = "top" | "left" | "bottom" | "right"

interface Props {
  width?: string
  children: React.ReactChild | React.ReactChild[]
  isDrawerOpen: boolean
  onClose: () => void
  anchor?: Anchor
}

const Drawer = styled(({ width, ...otherProps }: CustomDrawerProps) => (
  <MuiDrawer {...otherProps} />
))(({ theme, width }) => ({
  zIndex: 3,
  ".MuiDrawer-paper": {
    width: width ?? "600px",
    "@media only screen and (max-width: 600px)": {
      width: "calc(100% - 24px)"
    }
  }
}))

export default function CustomDrawer({
  anchor = "left",
  children,
  isDrawerOpen,
  onClose,
  width
}: Props) {
  return (
    <Drawer width={width} anchor={anchor} open={isDrawerOpen} onClose={onClose}>
      {children}
    </Drawer>
  )
}
