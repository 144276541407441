import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import { IDashboardDoc, KeepingSafeEmailContent, KeepingSafeEmailUpload } from "@limbic/types"
import invariant from "../../utils/invariant"
import Logger from "../../utils/Logger"
import { getPresignedURL } from "./service"
import * as keepingSafeEmail from "../api/keepingSafeEmail"
import formatUnicorn from "../../utils/formatUnicorn"

const TOTAL_RETRIES = 3
const KEEPING_SAFE_URL = `${BACKEND_URL}/v1/dashboard/{serviceApiKey}/keeping-safe`
const KEEPING_SAFE_EMAIL_PREVIEW_URL = `${BACKEND_URL}/v1/dashboard/keeping-safe/preview`

const method = "POST"
const headers = new Headers()
headers.set("x-api-key", API_KEY)
headers.set("Content-Type", "application/json")

export async function updateKeepingSafeEmail(
  serviceApiKey: string,
  email: KeepingSafeEmailUpload,
  logoURL: string,
  shouldSendKeepingSafeEmail: boolean,
  removeAttachment: boolean,
  retry = 0
): Promise<IDashboardDoc | undefined> {
  invariant(email, "Cannot update email without email data")
  invariant(serviceApiKey, "Cannot update email without a serviceApiKey")

  const payload: any = {
    shouldSendKeepingSafeEmail,
    subject: email.subject,
    text: email.content.text,
    title: email.content.title,
    signature: email.content.signature,
    logoURL
  }

  if (email.logo) {
    // TODO: Refactor to reuse logo/attachment
    // TODO: Move in @limbic/types?
    const fileType = `logo-leaflet.${email.logo?.type.split("/")[1]}` as
      | "logo-leaflet.jpeg"
      | "logo-leaflet.jpg"
      | "logo-leaflet.png"
      | "attachment.pdf"
    const uploadUrl = await getPresignedURL(serviceApiKey, fileType)
    const uploadHeaders = new Headers()
    uploadHeaders.set("Content-Type", email.logo?.type)
    const uploadLogo = await fetch(uploadUrl, {
      method: "PUT",
      headers: uploadHeaders,
      body: email.logo
    })
    payload.logoURL = uploadLogo.url?.split("?")[0]
  }

  if (email.attachment) {
    // TODO: Refactor to reuse logo/attachment
    // TODO: Move in @limbic/types?
    const fileType = `attachment.${email.attachment?.type.split("/")[1]}` as
      | "logo-leaflet.jpeg"
      | "logo-leaflet.jpg"
      | "logo-leaflet.png"
      | "attachment.pdf"
    const uploadUrl = await getPresignedURL(serviceApiKey, fileType)
    const uploadHeaders = new Headers()
    uploadHeaders.set("Content-Type", email.attachment?.type)
    const uploadAttachment = await fetch(uploadUrl, {
      method: "PUT",
      headers: uploadHeaders,
      body: email.attachment
    })
    payload.attachmentURL = uploadAttachment.url?.split("?")[0]
  }

  // Maybe there's a better solution here
  if (removeAttachment) {
    payload.attachmentURL = "delete"
  }

  const URL = formatUnicorn(KEEPING_SAFE_URL, { serviceApiKey })
  const body = JSON.stringify(payload)

  try {
    const result = await fetch(URL, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "updateKeepingSafeEmail failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`updateKeepingSafeEmail body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("updateKeepingSafeEmail retry")
      await delay(1)
      return await keepingSafeEmail.updateKeepingSafeEmail(
        serviceApiKey,
        email,
        logoURL,
        shouldSendKeepingSafeEmail,
        removeAttachment,
        retry + 1
      )
    }
    throw e
  }
}

export async function previewKeepingSafeEmail(
  emailContent: KeepingSafeEmailContent,
  logoURL?: string,
  retry = 0
): Promise<string> {
  invariant(emailContent, "Cannot get formatted email without email content")
  const body = JSON.stringify({
    logoURL,
    signature: emailContent.signature,
    text: emailContent.text,
    title: emailContent.title
  })
  try {
    const result = await fetch(KEEPING_SAFE_EMAIL_PREVIEW_URL, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "formatEmail failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`formatEmail body for retry ${retry}`, body)
      Logger.getInstance().message("formatEmail retry")
      await delay(1)
      return await keepingSafeEmail.previewKeepingSafeEmail(emailContent, logoURL, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
