import React from "react"
import { observer } from "mobx-react"
import MenuIcon from "@mui/icons-material/Menu"
import { Menu, MenuItem, Button as MuiButton, Typography } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useMatch, useNavigate } from "react-router-dom"
import styles from "./Header.module.scss"
import Logo from "../../assets/logo.png"
import Button from "../Button"
import DashboardNavigation from "../DashboardNavigation"
import { useServiceStore, useSettingsStore } from "../../context/rootStoreContext"
import PublishDialogue from "./components/PublishDialogue"
import SettingsDialogue from "./components/SettingsDialogue"
import { REGION, REGION_REDIRECT } from "../../../config/config"
import { getCountryFlagImgSrc } from "@limbic/types"

function Header(): JSX.Element {
  const navigate = useNavigate()
  const isSelectService = !!useMatch("/select-service")
  const serviceStore = useServiceStore()
  const settingsStore = useSettingsStore()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorElRegion, setAnchorElRegion] = React.useState<null | HTMLElement>(null)
  const [isPublishDialogueOpen, setPublishDialogueOpen] = React.useState<boolean>(false)
  const [isSettingsDialogueOpen, setSettingsDialogueOpen] = React.useState<boolean>(false)

  const goToSelectService = () => {
    navigate("/select-service", { replace: true })
  }

  const onOpenRegionMenu = (e?: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElRegion(e?.currentTarget ?? null)
  }
  const onCloseRegionMenu = () => setAnchorElRegion(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (type: "publish" | "settings") => {
    setAnchorEl(null)
    switch (type) {
      case "publish":
        setPublishDialogueOpen(!isPublishDialogueOpen)
        break
      case "settings":
        setSettingsDialogueOpen(!isSettingsDialogueOpen)
        break
      default:
        break
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.headerMain}>
        <div className={styles.headerLeft}>
          <img className={styles.logo} src={Logo} alt="Limbic" />
        </div>
        {!isSelectService && serviceStore.serviceName && (
          <Typography variant="h5" data-testid="header-title">
            {serviceStore.serviceName}
          </Typography>
        )}
        <div className={styles.headerRight}>
          {!isSelectService && (
            <MuiButton onMouseDown={goToSelectService} color="secondary" className={styles.link}>
              <ArrowBackIcon htmlColor="#303030" />
            </MuiButton>
          )}
          {isSelectService && <Button disabled>Login</Button>}
          <Button
            color="secondary"
            onClick={onOpenRegionMenu}
            css={{ boxShadow: "none", padding: 8 }}>
            <img
              src={getCountryFlagImgSrc(REGION === "UK" ? "GB" : REGION)}
              style={{ paddingRight: 8 }}
              alt=""
            />
            {REGION === "UK" ? "UK" : REGION}
          </Button>
          <Menu
            id="region-menu"
            anchorEl={anchorElRegion}
            open={!!anchorElRegion}
            onClose={onCloseRegionMenu}
            MenuListProps={{ "aria-labelledby": "region-button" }}>
            <MenuItem>
              <a href={REGION_REDIRECT} style={{ textDecoration: "none" }}>
                <img
                  src={getCountryFlagImgSrc(REGION === "UK" ? "US" : "GB")}
                  style={{ paddingRight: 8 }}
                  alt=""
                />
                {REGION === "UK" ? "USA" : "UK"}
              </a>
            </MenuItem>
          </Menu>

          <MuiButton color="secondary" onClick={handleClick}>
            <MenuIcon fontSize="large" htmlColor="#303030" />
          </MuiButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            MenuListProps={{ "aria-labelledby": "basic-button" }}>
            {!isSelectService && (
              <MenuItem onClick={() => handleClose("publish")} style={{ color: "red" }}>
                Publish
              </MenuItem>
            )}
            <MenuItem
              disabled={settingsStore.areSettingsLoading}
              onClick={() => handleClose("settings")}>
              Settings
            </MenuItem>
          </Menu>
        </div>
      </div>
      {!isSelectService && <DashboardNavigation />}
      <PublishDialogue
        isPublishDialogueOpen={isPublishDialogueOpen}
        setPublishDialogueOpen={setPublishDialogueOpen}
      />
      <SettingsDialogue
        isSettingsDialogueOpen={isSettingsDialogueOpen}
        setSettingsDialogueOpen={setSettingsDialogueOpen}
      />
    </div>
  )
}

export default observer(Header)
