import React from "react"
import styles from "./TermsAndConditions.module.scss"

interface Props {
  values: {
    names: string[]
    linkTexts: string[]
    links: string[]
  }
  onFocus: () => void
  onChange: (_event) => void
}

function TermsAndConditions(props: Props): JSX.Element {
  const { onFocus, values, onChange } = props

  return (
    <>
      <div className={styles.termsPairContainer}>
        <input
          onFocus={onFocus}
          name="organisationTermsNames-0"
          value={values.names[0] || ""}
          onChange={onChange}
          placeholder="Add text"
        />
        <input
          onFocus={onFocus}
          name="organisationTermsLinksText-0"
          value={values.linkTexts[0] || ""}
          onChange={onChange}
          placeholder="Add the link's text"
        />
        <input
          onFocus={onFocus}
          name="organisationTermsLinks-0"
          value={values.links[0] || ""}
          onChange={onChange}
          placeholder="Add a link"
        />
      </div>
      <div className={styles.termsPairContainer}>
        <input
          onFocus={onFocus}
          name="organisationTermsNames-1"
          value={values.names[1] || ""}
          onChange={onChange}
          placeholder="Add text"
        />
        <input
          onFocus={onFocus}
          name="organisationTermsLinksText-1"
          value={values.linkTexts[1] || ""}
          onChange={onChange}
          placeholder="Add the link's text"
        />
        <input
          onFocus={onFocus}
          name="organisationTermsLinks-1"
          value={values.links[1] || ""}
          onChange={onChange}
          placeholder="Add a link"
        />
      </div>
      <div className={styles.termsPairContainer}>
        <input
          onFocus={onFocus}
          name="organisationTermsNames-2"
          value={values.names[2] || ""}
          onChange={onChange}
          placeholder="Add text"
        />
        <input
          onFocus={onFocus}
          name="organisationTermsLinksText-2"
          value={values.linkTexts[2] || ""}
          onChange={onChange}
          placeholder="Add the link's text"
        />
        <input
          onFocus={onFocus}
          name="organisationTermsLinks-2"
          value={values.links[2] || ""}
          onChange={onChange}
          placeholder="Add a link"
        />
      </div>
    </>
  )
}

export default TermsAndConditions
