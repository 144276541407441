// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YRCEXOvr6ZF0VDe0qn1x{display:flex;flex-flow:row;align-items:flex-start;justify-content:center;width:100%;margin:16px 0}._AYPlucTAA2pkorv0uU7{height:3vh;color:red;overflow-y:auto;padding:1px}.u45OxOd6N3pqBlsS47_D{width:90%;overflow-y:scroll;height:47vh}", "",{"version":3,"sources":["webpack://./src/app/components/Header/components/SettingsDialogue/SettingsDialogue.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,sBAAA,CACA,sBAAA,CACA,UAAA,CACA,aAAA,CAGF,sBACE,UAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CAGF,sBACE,SAAA,CACA,iBAAA,CACA,WAAA","sourcesContent":[".settingsEditorContainer {\n  display: flex;\n  flex-flow: row;\n  align-items: flex-start;\n  justify-content: center;\n  width: 100%;\n  margin: 16px 0;\n}\n\n.settingsErrorContainer {\n  height: 3vh;\n  color: red;\n  overflow-y: auto;\n  padding: 1px;\n}\n\n.reactDiffViewerContainer {\n  width: 90%;\n  overflow-y: scroll;\n  height: 47vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsEditorContainer": "YRCEXOvr6ZF0VDe0qn1x",
	"settingsErrorContainer": "_AYPlucTAA2pkorv0uU7",
	"reactDiffViewerContainer": "u45OxOd6N3pqBlsS47_D"
};
export default ___CSS_LOADER_EXPORT___;
