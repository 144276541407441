// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EVkKW4grxgeAlZIvq3Yz{display:flex;flex-flow:column;align-items:center}.SXib84zuApyuhffR9tbg{padding:1em;display:flex;flex-flow:column;align-items:flex-start}.t4jJ6jnshzIL6wR2kEkg{display:flex;flex-flow:row;align-items:center;justify-content:center}.U_j73YzCmkG_RBTxssua{width:300px;font-style:italic}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/IneligibleUserEditor/IneligibleUserEditor.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,gBAAA,CACA,sBAAA,CAGF,sBACE,YAAA,CACA,aAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,WAAA,CACA,iBAAA","sourcesContent":[".nodeEditorContainer {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n}\n\n.optionsContainer {\n  padding: 1em;\n  display: flex;\n  flex-flow: column;\n  align-items: flex-start;\n}\n\n.checkboxContainer {\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n  justify-content: center;\n}\n\n.ineligibilityReasonCaption {\n  width: 300px;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodeEditorContainer": "EVkKW4grxgeAlZIvq3Yz",
	"optionsContainer": "SXib84zuApyuhffR9tbg",
	"checkboxContainer": "t4jJ6jnshzIL6wR2kEkg",
	"ineligibilityReasonCaption": "U_j73YzCmkG_RBTxssua"
};
export default ___CSS_LOADER_EXPORT___;
