// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PJ0_vCqGmtzet3kgiFQX{display:flex;flex-flow:column;align-items:flex-start}.uKPwj823g0aBw9Rv9QS_{display:flex;flex-flow:row;justify-content:space-between;align-items:center;width:100%}.uKPwj823g0aBw9Rv9QS_ input{width:90%}.ZVtheiTvhqi_rosCLEtU{padding:2px !important}.ZVtheiTvhqi_rosCLEtU svg{height:24px}.QWDDkM9eHAleZFjocx51{padding:0}.QWDDkM9eHAleZFjocx51 span:first-of-type{padding-left:4px;text-transform:none}", "",{"version":3,"sources":["webpack://./src/app/views/BasicConfiguration/components/PhoneNumbersInput/PhoneNumbersInput.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,sBAAA,CAGF,sBACE,YAAA,CACA,aAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CACA,4BACE,SAAA,CAIJ,sBACE,sBAAA,CACA,0BACE,WAAA,CAIJ,sBACE,SAAA,CACA,yCACE,gBAAA,CACA,mBAAA","sourcesContent":[".phoneNumbersContainer {\n  display: flex;\n  flex-flow: column;\n  align-items: flex-start;\n}\n\n.phoneNumberRow {\n  display: flex;\n  flex-flow: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  & input {\n    width: 90%;\n  }\n}\n\n.deleteButton {\n  padding: 2px !important;\n  & svg {\n    height: 24px;\n  }\n}\n\n.addButton {\n  padding: 0;\n  & span:first-of-type {\n    padding-left: 4px;\n    text-transform: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneNumbersContainer": "PJ0_vCqGmtzet3kgiFQX",
	"phoneNumberRow": "uKPwj823g0aBw9Rv9QS_",
	"deleteButton": "ZVtheiTvhqi_rosCLEtU",
	"addButton": "QWDDkM9eHAleZFjocx51"
};
export default ___CSS_LOADER_EXPORT___;
