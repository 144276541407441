// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OEMhojpoQvSfGwpMm40x{display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/views/nodes/generic/Choices/Choices.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,cAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".choicesContainer {\n  display: flex;\n  flex-flow: row;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"choicesContainer": "OEMhojpoQvSfGwpMm40x"
};
export default ___CSS_LOADER_EXPORT___;
