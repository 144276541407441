import { OptionTypes } from "./options"

export const optionLabels: Record<OptionTypes | "collectGenderSecondary", string> = {
  collectEthnicity: "Ethnicity",
  collectNationality: "Nationality",
  collectGender: "Gender",
  collectGenderSecondary: "Gender Same As Birth",
  collectSexuality: "Sexuality",
  collectLanguage: "Language",
  collectDisability: "Disability",
  collectReligion: "Religion",
  askCurrentlySeeingAMentalHealthProfessional: "Mental Health Professionals",
  askLongTermMedicalConditions: "Long Term Medical Conditions"
}
