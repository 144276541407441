import { observer } from "mobx-react"
import styles from "./UpdateReferral.module.scss"
import {
  useBackendMappingStore,
  useFlowStore,
  useNodeEditorStore
} from "../../../../../../context/rootStoreContext"
import React from "react"
import LabeledCheckbox from "../../../../../ui/Form/LabeledCheckbox"
import { IKeyTransform } from "@limbic/types"
import Button from "../../../../../Button"
import { cheapClone } from "../../../../../../../utils/object"
import { EditorType } from "../../../../models/NodeEditors"

type ITransformGroup = Record<string, Record<string, Array<IKeyTransform & { checked: boolean }>>>

function UpdateReferral() {
  const flowStore = useFlowStore()
  const nodeEditorStore = useNodeEditorStore()
  const { transformMap } = useBackendMappingStore()
  const selectedKeys = nodeEditorStore.actionEditorState.actionUpdateReferralWithKeys

  const groups: ITransformGroup = React.useMemo(() => {
    return Object.values(transformMap).reduce((dialogueGroup, t: IKeyTransform) => {
      const [dialogue, stepName] = t.context.split("-->")
      const item = cheapClone({ ...t, checked: selectedKeys.includes(t.sourceKey) })
      dialogueGroup[dialogue] ??= {}
      dialogueGroup[dialogue][stepName] ??= []
      dialogueGroup[dialogue][stepName].push(item)
      return dialogueGroup
    }, {})
  }, [transformMap, selectedKeys])

  const setSelectedKeys = (keys: string[]) => {
    nodeEditorStore.updateState(EditorType.ACTION, { actionUpdateReferralWithKeys: keys })
  }

  const onToggleKey = (key: string, value: boolean) => {
    setSelectedKeys(
      value //
        ? [...new Set([...selectedKeys, key])]
        : selectedKeys.filter(i => i !== key)
    )
  }

  const onToggleAllKeys = () => {
    if (selectedKeys.length) setSelectedKeys([])
    else
      setSelectedKeys(
        Object.values(groups)
          .map(group => Object.values(group))
          .flat(2)
          .map(t => t.sourceKey)
      )
  }

  const onToggleCurrentDialogueKeys = () => {
    const currentDialogue = flowStore.currentHighLevelDialogue.value
    const stepsMap = groups[currentDialogue] ?? {}
    setSelectedKeys(
      Object.values(stepsMap)
        .flat()
        .map(t => t.sourceKey)
    )
  }

  return (
    <div className={styles.updateReferralContainer}>
      <p>{selectedKeys.length} selected</p>
      <div className={styles.updateReferralKeysList}>
        {Object.entries(groups).map(([dialogue, steps]) => (
          <React.Fragment key={dialogue}>
            <h3>{dialogue}</h3>
            {Object.entries(steps).map(([key, transforms]) =>
              transforms.map(transform => {
                const label = (
                  <span>
                    {transform.sourceKey}{" "}
                    <span style={{ color: "grey", fontSize: "0.8em" }}>Step: {key}</span>
                  </span>
                )
                return (
                  <LabeledCheckbox
                    key={transform.sourceKey}
                    name={transform.sourceKey}
                    label={label}
                    labelPlacement="end"
                    checked={transform.checked}
                    onChange={v => onToggleKey(transform.sourceKey, v)}
                  />
                )
              })
            )}
          </React.Fragment>
        ))}
      </div>
      <div className={styles.controlsRow}>
        <Button onClick={onToggleCurrentDialogueKeys}>Current dialogue only</Button>
        <Button onClick={onToggleAllKeys}>
          {selectedKeys.length ? "Deselect All" : "Select All"}
        </Button>
      </div>
    </div>
  )
}

export default observer(UpdateReferral)
