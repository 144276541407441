import React from "react"
import { Tooltip } from "@mui/material"
import { styled } from "@stitches/react"
import { EdgeProps, getEdgeCenter, Position } from "react-flow-renderer"
import truncateString from "../../../../../utils/truncateString"
import toSentenceCase from "../../../../../utils/toSentenceCase"

const TOOLTIP_TEXT = "Click to select connecting line"

interface CustomEdgeProps extends EdgeProps {
  showTextPath?: boolean
}

interface IPoint {
  x: number
  y: number
}
const point = (x: number, y: number): IPoint => ({ x, y })
const _2d = (p: IPoint): string => `${p.x},${p.y}`

const MIN_EDGE_SEGMENT_LENGTH = 20

function getBufferPoint(s: IPoint, t: IPoint, position: Position): IPoint {
  const isCrossedX = { [Position.Left]: t.x > s.x, [Position.Right]: t.x < s.x }[position]
  const isCrossedY = { [Position.Top]: t.y > s.y, [Position.Bottom]: t.y < s.y }[position]

  const deltaX = Math.abs(t.x - s.x) * 0.3
  const deltaY = Math.abs(t.y - s.y) * 0.3

  const dX = isCrossedX ? MIN_EDGE_SEGMENT_LENGTH : Math.max(MIN_EDGE_SEGMENT_LENGTH, deltaX)
  const dY = isCrossedY ? MIN_EDGE_SEGMENT_LENGTH : Math.max(MIN_EDGE_SEGMENT_LENGTH, deltaY)

  return {
    [Position.Left]: { ...s, x: s.x - dX },
    [Position.Right]: { ...s, x: s.x + dX },
    [Position.Top]: { ...s, y: s.y - dY },
    [Position.Bottom]: { ...s, y: s.y + dY }
  }[position]
}

function getPath(source: IPoint, target: IPoint, sPosition: Position, tPosition: Position): string {
  const p0 = _2d(source)
  const p1 = _2d(getBufferPoint(source, target, sPosition))
  const p2 = _2d(getBufferPoint(target, source, tPosition))
  const p3 = _2d(target)

  return `M ${p0} L${p1} ${p2} ${p3}`
}

export default function CustomEdge(props: CustomEdgeProps) {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    sourceHandleId,
    targetHandleId,
    style = {},
    data,
    markerEnd,
    showTextPath = false
  } = props

  const [centerX, centerY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY
  })

  let customCenterX = centerX
  let customCenterY = centerY
  const isTargetLeftRight = targetHandleId && ["t-left", "t-right"].includes(targetHandleId)
  const isSourceLeftRight = sourceHandleId && ["s-left", "s-right"].includes(sourceHandleId)
  const isSourceBottomTop = sourceHandleId && ["s-bottom", "s-top"].includes(sourceHandleId)

  if (!data?.label) {
    customCenterX = sourceX - (isSourceLeftRight ? 0 : 7.5)
    customCenterY = sourceY - (isSourceBottomTop ? 0 : 7.5)
  }

  return (
    <>
      <path
        id={id}
        style={{
          ...style,
          cursor: "pointer",
          strokeWidth: 1.5
        }}
        className="react-flow__edge-path"
        d={getPath(
          point(sourceX, isSourceLeftRight ? sourceY : sourceY - 3),
          point(targetX, isTargetLeftRight ? targetY : targetY - 5),
          sourcePosition,
          targetPosition
        )}
        markerEnd={markerEnd}
      />
      {data?.label ? (
        <foreignObject
          x={customCenterX - 10}
          y={customCenterY}
          height={20}
          width={0}
          style={{ overflow: "visible" }}>
          <Tooltip title={TOOLTIP_TEXT}>
            <EdgeLabel selected={props.selected}>
              {data.label === "Crisis"
                ? "Crisis next step"
                : truncateString(toSentenceCase(data?.label), 12)}
            </EdgeLabel>
          </Tooltip>
        </foreignObject>
      ) : (
        <foreignObject
          x={customCenterX}
          y={customCenterY}
          height={15}
          width={15}
          style={{ overflow: "visible" }}>
          <Tooltip title={TOOLTIP_TEXT}>
            <EdgeHandle selected={props.selected} />
          </Tooltip>
        </foreignObject>
      )}
      {showTextPath && (
        <text x={centerX} y={centerY}>
          <textPath
            href={`#${id}`}
            style={{ fontSize: "12px" }}
            startOffset="50%"
            textAnchor="middle">
            {data?.label}
          </textPath>
        </text>
      )}
    </>
  )
}

const conditionalEdgeHandleColor = {
  variants: {
    selected: {
      true: {
        backgroundColor: "gray",
        "&:hover": {
          backgroundColor: "lightgray"
        }
      },
      false: {
        backgroundColor: "lightgray",
        "&:hover": {
          backgroundColor: "gray"
        }
      }
    }
  }
}

const basicHandleStyle = {
  cursor: "pointer",
  padding: "1px 2px",
  whiteSpace: "nowrap"
}

const EdgeHandle = styled("div", {
  width: "15px",
  border: "1px solid black",
  borderRadius: "25px",
  boxSizing: "border-box",
  height: "15px",
  ...basicHandleStyle,
  ...conditionalEdgeHandleColor
})

const EdgeLabel = styled("span", {
  width: "auto",
  border: "1px dashed gray",
  borderRadius: "5px",
  ...basicHandleStyle,
  ...conditionalEdgeHandleColor
})
