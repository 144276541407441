// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JWMrQo3Wqgir2rWGV3Ad{display:flex;flex-flow:column;align-items:center}._SUq86fqKwXnxwPWeKnm{display:flex;flex-flow:column;align-items:center;width:50%}.Ku5LyPp3Tc0FAfTyqRet div:first-child{min-height:20px;border-radius:5px;padding-top:2px !important;padding-bottom:2px !important}.Ku5LyPp3Tc0FAfTyqRet div:first-child input{padding:4px;height:10px;font-size:.85rem}.Ku5LyPp3Tc0FAfTyqRet div:first-child:hover{background:rgba(204,204,204,.4)}.Ku5LyPp3Tc0FAfTyqRet div:first-child:hover fieldset{border:1px solid #d3d3d3}.Ku5LyPp3Tc0FAfTyqRet div:first-child fieldset{border:1px solid #d3d3d3}.SnLat8gRYqf_kYvblNCX{width:100%;padding:6px}.kRVF4J4x4DPOGTNAU6kw{width:100%;margin:8px 0}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ConditionEditor/ConditionEditor.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CACA,kBAAA,CACA,SAAA,CAIA,sCACE,eAAA,CACA,iBAAA,CACA,0BAAA,CACA,6BAAA,CACA,4CACE,WAAA,CACA,WAAA,CACA,gBAAA,CAEF,4CACE,+BAAA,CACA,qDACE,wBAAA,CAGJ,+CACE,wBAAA,CAKN,sBACE,UAAA,CACA,WAAA,CAGF,sBACE,UAAA,CACA,YAAA","sourcesContent":[".nodeEditorContainer {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n}\n\n.optionsContainer {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  width: 50%;\n}\n\n.textField {\n  & div:first-child {\n    min-height: 20px;\n    border-radius: 5px;\n    padding-top: 2px !important;\n    padding-bottom: 2px !important;\n    & input {\n      padding: 4px;\n      height: 10px;\n      font-size: 0.85rem;\n    }\n    &:hover {\n      background: rgba(204, 204, 204, 0.4);\n      & fieldset {\n        border: 1px solid lightgray;\n      }\n    }\n    & fieldset {\n      border: 1px solid lightgray;\n    }\n  }\n}\n\n.optionsContent {\n  width: 100%;\n  padding: 6px;\n}\n\n.formControl {\n  width: 100%;\n  margin: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodeEditorContainer": "JWMrQo3Wqgir2rWGV3Ad",
	"optionsContainer": "_SUq86fqKwXnxwPWeKnm",
	"textField": "Ku5LyPp3Tc0FAfTyqRet",
	"optionsContent": "SnLat8gRYqf_kYvblNCX",
	"formControl": "kRVF4J4x4DPOGTNAU6kw"
};
export default ___CSS_LOADER_EXPORT___;
