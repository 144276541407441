import invariant from "../utils/invariant"

export const backendEnv = (process.env.REACT_APP_BACKEND_ENV || "production") as
  | "staging"
  | "development"
  | "production"

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || ""
export const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY || ""
export const REGION = process.env.REACT_APP_REGION || ""
export const REGION_REDIRECT = process.env.REACT_APP_REGION_REDIRECT || ""
invariant(BACKEND_URL, "Environment variable REACT_APP_BACKEND_URL was not found")
invariant(API_KEY, "Environment variable REACT_APP_ACCESS_BACKEND_API_KEY was not found")
invariant(REGION, "Environment variable REACT_APP_REGION was not found")
invariant(REGION_REDIRECT, "Environment variable REACT_APP_REGION_REDIRECT was not found")
