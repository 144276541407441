import * as React from "react"
import { styled } from "@mui/material/styles"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion"
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordionDetails, { AccordionDetailsProps } from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import styles from "./Accordion.module.scss"

interface CustomAccordionDetailsProps extends AccordionDetailsProps {
  maxHeight?: string | undefined
}

interface CustomAccordionProps extends AccordionProps {
  forceBorderBottom?: boolean
}

interface Props {
  summaryButton?: React.ReactChild | React.ReactChild[]
  className?: string
  forceBorderBottom?: boolean
  expanded: string | false
  panel: string
  summary: string
  onHandlePanelChange: (
    _panel: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void
  children: React.ReactChild | React.ReactChild[]
  maxHeight?: string | undefined
}

const Accordion = styled(({ forceBorderBottom, ...otherProps }: CustomAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...otherProps} />
))(({ theme, forceBorderBottom }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: forceBorderBottom ? `1px solid ${theme.palette.divider}` : 0
  },
  "&:before": {
    display: "none"
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(({ maxHeight, ...otherProps }: CustomAccordionDetailsProps) => (
  <MuiAccordionDetails {...otherProps} />
))(({ theme, maxHeight }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  maxHeight: maxHeight ?? "300px",
  overflowY: "auto"
}))

export default function CustomizedAccordion(props: Props) {
  const {
    className,
    children,
    summary,
    summaryButton,
    panel,
    onHandlePanelChange,
    expanded,
    maxHeight = "300px",
    forceBorderBottom = false
  } = props

  return (
    <Accordion
      className={className}
      expanded={expanded === panel}
      onChange={onHandlePanelChange(panel)}
      forceBorderBottom={forceBorderBottom}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className={styles.accordionSummaryContainer}>
          <Typography>{summary}</Typography>
          {summaryButton}
        </div>
      </AccordionSummary>
      <AccordionDetails maxHeight={maxHeight}>{children}</AccordionDetails>
    </Accordion>
  )
}
