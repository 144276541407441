import * as React from "react"
import { MuiChipsInput } from "mui-chips-input"
import { Chip } from "@mui/material"
import styles from "./ChipInput.module.scss"

const CustomChip = ({ chipValue, onDelete }) => (
  <Chip className={styles.optionChip} label={chipValue} onDelete={onDelete}>
    {chipValue}
  </Chip>
)

interface Props {
  options: string[]
  error?: boolean
  helperText?: string
  label?: string
  placeholder?: string
  onAdd: (value: string) => void
  onDelete: (value: string) => void
  onKeyDownCapture?: () => void
}

function ChipInput(props: Props): JSX.Element | null {
  const { options, label, placeholder, helperText, onAdd, onDelete, onKeyDownCapture } = props

  const renderCustomChip = (_chipComponent, key, chipProps) => {
    return (
      <CustomChip
        key={key}
        chipValue={chipProps.label}
        onDelete={() => onDelete(chipProps.label)}
      />
    )
  }

  return (
    <MuiChipsInput
      id="mui-chip-input"
      error={props.error}
      helperText={helperText}
      renderChip={renderCustomChip}
      className={styles.chipInput}
      inputProps={{ className: styles.chipInputInput }}
      value={options}
      onKeyDownCapture={onKeyDownCapture}
      onAddChip={value => onAdd(value)}
      onDeleteChip={value => onDelete(value)}
      fullWidth
      label={label ?? "Options"}
      variant="filled"
      placeholder={placeholder ?? "Select an option"}
    />
  )
}

export default ChipInput
