import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import { ILimbicSettingsDoc } from "@limbic/types"
import { SettingsStatus } from "../../models/Settings"
import invariant from "../../utils/invariant"
import Logger from "../../utils/Logger"
import * as settings from "./settings"

const TOTAL_RETRIES = 3
const DASHBOARD_URL = `${BACKEND_URL}/v1/settings`

const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export async function getSettings(
  retry = 0
): Promise<[ILimbicSettingsDoc | undefined, SettingsStatus]> {
  try {
    const result = await fetch(`${DASHBOARD_URL}`, { headers })
    const json = await result.json()

    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    if (success && !data) {
      return [data, SettingsStatus.NoSettingsFound]
    }
    return [data, SettingsStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "getSettings failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("getSettings retry")
      await delay(1)
      return await settings.getSettings(retry + 1)
    }
    throw e
  }
}

export async function updateSettings(
  settings: Record<any, any>,
  retry = 0
): Promise<[ILimbicSettingsDoc | undefined, SettingsStatus]> {
  invariant(settings, "Cannot update settings without a settings object")
  const method = "POST"
  const body = JSON.stringify({ settings })

  try {
    const result = await fetch(`${DASHBOARD_URL}/update/`, { body, method, headers })
    const json = await result.json()

    const { success, data, error, validations } = json

    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    if (success && !data) {
      return [data, SettingsStatus.NoSettingsFound]
    }
    return [data, SettingsStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "updateSettings failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`updateSettings version for retry ${retry}`, JSON.stringify(settings))
      Logger.getInstance().message("updateSettings retry")
      await delay(1)
      return await settings.updateSettings(settings, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
