import React from "react"
import { Typography } from "@mui/material/"
import { observer } from "mobx-react"
import DummyInput from "../../../QuestionEditor/components/DummyInput"
import { PromptType } from "../../../../models/NodeEditors"
import FormControlWithCheckBox from "../../components/FormControlWithCheckBox"
import SolidDivider from "../../../../../SolidDivider"
import MessageList from "../../components/MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../../components/MessageForm/MessageForm"
import OptionsPlaceholder from "../../components/OptionsPlaceholder"

import styles from "./CollectGender.module.scss"

function CollectGender(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <FormControlWithCheckBox
        name="withTextPrompt"
        label="Include a free text input to allow typing of gender"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <Typography className={styles.genderLabel}>Gender question message:</Typography>
      <MessageList
        messages={messages?.askGender ?? []}
        messageType="askGender"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <OptionsPlaceholder name="Gender" />
      {chatFlowSettings.collectGender?.withTextPrompt && (
        <DummyInput promptType={PromptType.Text} />
      )}
      <MessageForm
        value={chatFlowSettings.currentMessage?.askGender}
        placeholder="Type a message here..."
        name="askGender"
        updateState={updateState}
        messages={messages?.askGender ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="skipSameGenderAsBirth"
        label="Skip gender same as birth question"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <Typography className={styles.genderLabel}>Same gender as birth question message:</Typography>
      <MessageList
        disabled={chatFlowSettings.collectGender?.skipSameGenderAsBirth}
        messages={messages?.askSameGenderAsBirth ?? []}
        messageType="askSameGenderAsBirth"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <OptionsPlaceholder
        name="Gender same as birth"
        disabled={chatFlowSettings.collectGender?.skipSameGenderAsBirth}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askSameGenderAsBirth}
        disabledInput={chatFlowSettings.collectGender?.skipSameGenderAsBirth}
        placeholder="Type a message here..."
        name="askSameGenderAsBirth"
        updateState={updateState}
        messages={messages?.askSameGenderAsBirth ?? []}
      />
    </>
  )
}

export default observer(CollectGender)
