import React, { useEffect } from "react"
import { CustomNode } from "../../CustomNode"
import Path from "./components/Path"
import { ChatFlowsEnum, EligibilityPresetFlowResults } from "@limbic/types"
import { useFlowStore } from "../../../../../../context/rootStoreContext"
import _isEqual from "lodash.isequal"
import styles from "./ChatFlowPaths.module.scss"

type ChatFlowPath = {
  id: string
  value: EligibilityPresetFlowResults
  body: string
  isConnectable: boolean
}

const ELIGIBILITY_PATHS = {
  [ChatFlowsEnum.SERVICE_SEARCH]: [
    {
      id: "1",
      value: EligibilityPresetFlowResults.ELIGIBLE,
      body: "Eligible",
      isConnectable: true
    },
    {
      id: "2",
      value: EligibilityPresetFlowResults.INELIGIBLE,
      body: "Ineligible",
      isConnectable: true
    },
    {
      id: "3",
      value: EligibilityPresetFlowResults.UNDERAGE,
      body: "Underage",
      isConnectable: true
    }
  ],
  [ChatFlowsEnum.SPINE_SEARCH]: [
    {
      id: "1",
      value: EligibilityPresetFlowResults.ELIGIBLE,
      body: "Eligible",
      isConnectable: true
    },
    {
      id: "2",
      value: EligibilityPresetFlowResults.INELIGIBLE,
      body: "Ineligible",
      isConnectable: true
    },
    {
      id: "3",
      value: EligibilityPresetFlowResults.UNDERAGE,
      body: "Underage",
      isConnectable: true
    }
  ],
  [ChatFlowsEnum.COLLECT_DATE_OF_BIRTH]: [
    {
      id: "1",
      value: EligibilityPresetFlowResults.ELIGIBLE,
      body: "Eligible",
      isConnectable: true
    },
    {
      id: "3",
      value: EligibilityPresetFlowResults.UNDERAGE,
      body: "Underage",
      isConnectable: true
    }
  ]
}

interface Props {
  type: ChatFlowsEnum
  nodeID: string
}

function ChatFlowPaths(props: Props): JSX.Element {
  const { nodeID } = props
  const flowStore = useFlowStore()

  const showPaths =
    props.type === ChatFlowsEnum.SERVICE_SEARCH ||
    props.type === ChatFlowsEnum.SPINE_SEARCH ||
    props.type === ChatFlowsEnum.COLLECT_DATE_OF_BIRTH

  const [paths, setPaths] = React.useState<ChatFlowPath[]>(ELIGIBILITY_PATHS[props.type] ?? [])

  useEffect(() => {
    if (ELIGIBILITY_PATHS[props.type]?.length) setPaths(ELIGIBILITY_PATHS[props.type])
  }, [props.type])

  useEffect(() => {
    const updatedPaths = paths.map((path: ChatFlowPath) => {
      const isConnectable = !flowStore.edges.find(
        edge => edge.data?.label === path.value && edge.source === nodeID
      )
      return { ...path, isConnectable }
    })
    if (!_isEqual(paths, updatedPaths)) setPaths(updatedPaths)
  }, [flowStore.edges, paths, nodeID])

  return (
    <CustomNode.Container id="container">
      <div
        className={styles.chatFlowPathsContainer}
        style={{ display: showPaths ? "flex" : "none" }}>
        {paths &&
          paths.map((path, index) => {
            return (
              <React.Fragment key={`${path.id}-path-${index}`}>
                <Path
                  isConnectable={path.isConnectable}
                  body={path.body}
                  value={path.value}
                  nodeId={nodeID}
                  index={index}
                />
              </React.Fragment>
            )
          })}
      </div>
    </CustomNode.Container>
  )
}

export default ChatFlowPaths
