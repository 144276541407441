import moment from "moment"

/**
 * Check if a date format is valid, by formatting a current date
 * and parsing it back to a date, then comparing the two dates.
 * @param format The date format to check
 */
export default function isValidFormat(format?: string): boolean {
  try {
    if (!format) return false
    const testDate = moment(new Date())
    const formatted = testDate.format(format)
    const parsed = moment(formatted, format, true)

    // Check if formatted string is a valid date and matches the original date when re-parsed
    return parsed.isValid() && parsed.format(format) === formatted
  } catch (error) {
    // If there's an error in formatting or parsing, consider the format invalid
    return false
  }
}
