import React from "react"
import { observer } from "mobx-react"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"

function CollectASD(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <MessageList
        messages={messages?.askHasASD ?? []}
        messageType="askHasASD"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askHasASD}
        placeholder="Type a message here..."
        name="askHasASD"
        updateState={updateState}
        messages={messages?.askHasASD ?? []}
      />
    </>
  )
}

export default observer(CollectASD)
