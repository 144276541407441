// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A8cLvZ3D4msSHCQSlFJj{display:flex;flex-direction:column;width:100%;margin-top:20px}.iItqOMzuWL3Lo_WTGFZf{display:flex;justify-content:space-between;align-items:center;margin-bottom:10px}.iKe6xqK1rgeURwaZWzqc{flex:1;display:flex;flex-direction:column;max-height:360px;overflow-y:auto}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ActionEditor/components/UpdateReferral/UpdateReferral.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAGF,sBACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,eAAA","sourcesContent":[".updateReferralContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin-top: 20px;\n}\n\n.controlsRow {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.updateReferralKeysList {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  max-height: 360px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"updateReferralContainer": "A8cLvZ3D4msSHCQSlFJj",
	"controlsRow": "iItqOMzuWL3Lo_WTGFZf",
	"updateReferralKeysList": "iKe6xqK1rgeURwaZWzqc"
};
export default ___CSS_LOADER_EXPORT___;
