import React from "react"
import { observer } from "mobx-react"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"

function CollectGoalForTherapy(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <MessageList
        messages={messages?.askWhatIsYourGoal ?? []}
        messageType="askWhatIsYourGoal"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askWhatIsYourGoal}
        placeholder="Type a message here..."
        name="askWhatIsYourGoal"
        updateState={updateState}
        messages={messages?.askWhatIsYourGoal ?? []}
      />
      <FormControlWithCheckBox
        name="forceGoalForTherapyValue"
        label="Do not allow user to skip this question"
        labelPlacement="start"
        updateState={updateState}
      />
      <FormControlWithCheckBox
        name="addClinicalNote"
        label="Add user's response to the clinical notes"
        labelPlacement="start"
        updateState={updateState}
      />
    </>
  )
}

export default observer(CollectGoalForTherapy)
