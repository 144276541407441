// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RwIJ5uL4VaN9fkaaOQJg{margin-top:1em;border-top:1px solid #bbb;width:100%}", "",{"version":3,"sources":["webpack://./src/app/components/SolidDivider/SolidDivider.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,yBAAA,CACA,UAAA","sourcesContent":[".solidDivider {\n  margin-top: 1em;\n  border-top: 1px solid #bbb;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"solidDivider": "RwIJ5uL4VaN9fkaaOQJg"
};
export default ___CSS_LOADER_EXPORT___;
