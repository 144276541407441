// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RbxLFmxBgpIO2DWFsB3n{display:flex;flex-flow:row;justify-content:flex-end;align-items:center}.RbxLFmxBgpIO2DWFsB3n p{margin-right:8px}.xcmh4wq71v8zGAMjyF6w{width:4.5em}.Xx2kjI41IEAR2pfBrY0C{padding-right:1em;padding-bottom:1em;color:rgba(0,0,0,.7)}.q8mPlqVASxAWXtrS70_D{padding-right:1em;padding-bottom:1em;padding-top:1.5em;color:rgba(0,0,0,.7)}.OSOTpx6fQ9owbTMLZmT3{margin-top:1em;display:flex;flex-flow:column;align-items:flex-end}.cakwVLdJWYLzYiz2rIGq{padding:.25em 0 0 0;font-style:italic;text-align:right;color:gray}.BlfQihMSWUjqCFv9fhEP{color:#f08080}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/SpineSearch/SpineSearch.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,wBAAA,CACA,kBAAA,CACA,wBACE,gBAAA,CAIJ,sBACE,WAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CACA,oBAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CACA,iBAAA,CACA,oBAAA,CAGF,sBACE,cAAA,CACA,YAAA,CACA,gBAAA,CACA,oBAAA,CAGF,sBACE,mBAAA,CACA,iBAAA,CACA,gBAAA,CACA,UAAA,CAGF,sBACE,aAAA","sourcesContent":[".retriesNumberSelect {\n  display: flex;\n  flex-flow: row;\n  justify-content: flex-end;\n  align-items: center;\n  & p {\n    margin-right: 8px;\n  }\n}\n\n.retriesSelector {\n  width: 4.5em;\n}\n\n.spineSearchTitle {\n  padding-right: 1em;\n  padding-bottom: 1em;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.spineSearchCustomTitle {\n  padding-right: 1em;\n  padding-bottom: 1em;\n  padding-top: 1.5em;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.allowReferralCheckboxContainer {\n  margin-top: 1em;\n  display: flex;\n  flex-flow: column;\n  align-items: flex-end;\n}\n\n.allowReferralToContinueTip {\n  padding: 0.25em 0 0 0;\n  font-style: italic;\n  text-align: right;\n  color: gray;\n}\n\n.allowReferralToContinueTipWarning {\n  color: lightcoral;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"retriesNumberSelect": "RbxLFmxBgpIO2DWFsB3n",
	"retriesSelector": "xcmh4wq71v8zGAMjyF6w",
	"spineSearchTitle": "Xx2kjI41IEAR2pfBrY0C",
	"spineSearchCustomTitle": "q8mPlqVASxAWXtrS70_D",
	"allowReferralCheckboxContainer": "OSOTpx6fQ9owbTMLZmT3",
	"allowReferralToContinueTip": "cakwVLdJWYLzYiz2rIGq",
	"allowReferralToContinueTipWarning": "BlfQihMSWUjqCFv9fhEP"
};
export default ___CSS_LOADER_EXPORT___;
