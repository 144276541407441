import React, { useEffect, useRef, useState } from "react"
import Editor from "@limbic/ckeditor5-custom-build/build/ckeditor"
import styles from "./CustomCKEditor.module.scss"
import { CKEditor } from "@ckeditor/ckeditor5-react"

interface Props {
  disabled?: boolean
  data: string
  onChange: (data?: string) => void
}

export default function CustomCKEditor(props: Props): JSX.Element {
  const { data } = props

  const [isEditorReady, setEditorReady] = useState(false)
  const editorRef = useRef<{ editor?: Editor }>({})

  useEffect(() => {
    const { editor } = editorRef.current
    if (editor && editor.getData() !== data) {
      editor.setData(data)
    }
  }, [data, isEditorReady])

  return (
    <div className={styles.ckeditorWrapper}>
      {/*
        Workaround to avoid using the disable of CKEditor
        because it was not working well, and was causing
        some unwanted issues and errors
       */}
      {props.disabled ? (
        <div className={styles.disabledDiv} dangerouslySetInnerHTML={{ __html: data }} />
      ) : (
        <CKEditor
          editor={Editor}
          data={data}
          onReady={editor => {
            editorRef.current.editor = editor
            setEditorReady(true)
          }}
          onChange={(_, editor) => props.onChange(editor.getData())}
        />
      )}
    </div>
  )
}
