import React from "react"
import { observer } from "mobx-react"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageList from "../../components/MessageList"
import MessageForm from "../../components/MessageForm"
import FormControlWithCheckBox from "../FormControlWithCheckBox"

function CollectPreferredCorrespondence(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <MessageList
        messages={messages?.askPreferredContactMethod ?? []}
        messageType="askPreferredContactMethod"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askPreferredContactMethod}
        placeholder="Type a message here..."
        name="askPreferredContactMethod"
        updateState={updateState}
        messages={messages?.askPreferredContactMethod ?? []}
      />
      <FormControlWithCheckBox
        name="shouldDisplayTextMessageOption"
        label="Include option 'Text message' as preferred correspondence"
        labelPlacement="start"
        updateState={updateState}
      />
    </>
  )
}

export default observer(CollectPreferredCorrespondence)
