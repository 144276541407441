import { styled } from "@stitches/react"
import { mauve, violet } from "@radix-ui/colors"
import * as ContextMenuPrimitive from "@radix-ui/react-context-menu"
import {
  ContextMenuItem,
  ContextMenuCheckbox,
  ContextMenuRadio,
  ContextMenuSubTrigger
} from "./ContextMenuItem"
import * as cssAnimations from "../../../../styles/cssAnimations"

const contentStyles = {
  minWidth: 220,
  backgroundColor: "white",
  borderRadius: 8,
  overflow: "hidden",
  padding: 4,
  boxShadow:
    "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "600ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: cssAnimations.slideDownAndFade },
      '&[data-side="right"]': { animationName: cssAnimations.slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: cssAnimations.slideUpAndFade },
      '&[data-side="left"]': { animationName: cssAnimations.slideRightAndFade }
    }
  }
}

const StyledContent = styled(ContextMenuPrimitive.Content, { ...contentStyles })

function Content(props) {
  return (
    <ContextMenuPrimitive.Portal>
      <StyledContent {...props} />
    </ContextMenuPrimitive.Portal>
  )
}

const StyledSubContent = styled(ContextMenuPrimitive.SubContent, { ...contentStyles })

function SubContent(props) {
  return (
    <ContextMenuPrimitive.Portal>
      <StyledSubContent {...props} />
    </ContextMenuPrimitive.Portal>
  )
}

const StyledSeparator = styled(ContextMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: violet.violet6,
  margin: 5
})

const StyledLabel = styled(ContextMenuPrimitive.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: "25px",
  color: mauve.mauve11
})

export const ContextMenu = {
  Root: ContextMenuPrimitive.Root,
  Trigger: ContextMenuPrimitive.Trigger,
  Content: Content,
  Item: ContextMenuItem,
  Checkbox: ContextMenuCheckbox,
  RadioGroup: ContextMenuPrimitive.RadioGroup,
  Radio: ContextMenuRadio,
  Sub: ContextMenuPrimitive.Sub,
  SubTrigger: ContextMenuSubTrigger,
  SubContent: SubContent,
  Label: StyledLabel,
  Separator: StyledSeparator
}
