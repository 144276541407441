import React, { ChangeEvent, useCallback, useEffect } from "react"
import { Dialog as MuiDialog } from "@mui/material/"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Button from "../../../Button"
import {
  useFlowStore,
  useNodeEditorStore,
  useServiceStore
} from "../../../../context/rootStoreContext"
import { EditorType } from "../../models/NodeEditors"
import { observer } from "mobx-react"
import {
  IChatbotPropertyKey,
  KeyType,
  LeftHandContextType,
  LeftHandOperand,
  RightHandContextType,
  RightHandOperand,
  TextOperators,
  BooleanOperators,
  ListOperators,
  NumberOperators,
  AllOperators
} from "@limbic/types"
import { TransformerKeys, resultTypeMap } from "@limbic/transformers"
import { IAdvancedConditionEditorState, VALUE_NOT_SET } from "../../../../stores/NodeEditorStore"
import Operand, { OperandType } from "./components/Operand/Operand"
import Operator from "./components/Operator"
import styles from "./AdvancedConditionEditor.module.scss"
import { Typography } from "@mui/material"

export type Operators = TextOperators | NumberOperators | ListOperators | BooleanOperators

interface Props {
  ActionButton?: React.ReactChild | React.ReactChild[]
  children?: React.ReactChild | React.ReactChild[]
  onClose?: (value: boolean) => void
  open?: boolean | undefined
  title?: string
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
}

function AdvancedConditionEditor(props: Props): JSX.Element {
  const { ActionButton, title, maxWidth = "md" } = props
  const flowStore = useFlowStore()
  const nodeEditorStore = useNodeEditorStore()
  const { advancedConditionEditorState } = nodeEditorStore
  const leftOperand = advancedConditionEditorState.leftOperand?.value
  const serviceStore = useServiceStore()

  const [hasInitialized, setHasInitialized] = React.useState<boolean>(false)
  const [customInput, setCustomInput] = React.useState<string>("")
  const [leftOperandContext, setLeftOperandContext] = React.useState<LeftHandContextType>(
    LeftHandContextType.STATE
  )
  const [rightOperandContext, setRightOperandContext] = React.useState<RightHandContextType>(
    RightHandContextType.STATE
  )
  const [currentOperators, setCurrentOperators] = React.useState<
    TextOperators[] | NumberOperators[] | BooleanOperators[] | ListOperators[] | AllOperators[]
  >(Object.values(TextOperators))

  useEffect(() => {
    /** Reset the custom input each time the operand changes */
    setCustomInput("")
  }, [leftOperand])

  const updateOperatorsAndGetDefaultOperator = useCallback(
    (type: KeyType | "custom"): Operators => {
      const updatedType =
        type === "custom" && leftOperand.sourceKey !== VALUE_NOT_SET ? leftOperand.type : type
      let operator
      switch (updatedType) {
        case KeyType.Boolean:
          setCurrentOperators(Object.values(BooleanOperators))
          operator = BooleanOperators.IS_TRUE
          break
        case KeyType.Text:
          setCurrentOperators(Object.values(TextOperators))
          operator = TextOperators.EQUALS
          break
        case KeyType.Number:
          setCurrentOperators(Object.values(NumberOperators))
          operator = NumberOperators.EQUALS
          break
        case KeyType.BooleanList:
        case KeyType.NumberList:
        case KeyType.TextList:
          setCurrentOperators(Object.values(ListOperators))
          operator = ListOperators.INCLUDES
          break
        case KeyType.Unknown:
        case "custom":
          setCurrentOperators(Object.values(AllOperators))
          operator = AllOperators.EQUALS
          break
        default:
          setCurrentOperators(Object.values(BooleanOperators))
          operator = BooleanOperators.IS_TRUE
          break
      }
      return operator
    },
    [setCurrentOperators, leftOperand]
  )

  const checkAndUpdateOperatorList = useCallback(
    (operand: LeftHandOperand): Operators => {
      if (operand.storageType === LeftHandContextType.CUSTOM_FIELDS) {
        return updateOperatorsAndGetDefaultOperator(KeyType.Unknown)
      } else if (operand.transformers.length && operand.value?.transformerType) {
        return updateOperatorsAndGetDefaultOperator(operand.value?.transformerType)
      } else if (operand.value.customValue && String(operand.value.customValue).length) {
        return updateOperatorsAndGetDefaultOperator(KeyType.Unknown)
      } else {
        return updateOperatorsAndGetDefaultOperator(operand.value.type)
      }
    },
    [updateOperatorsAndGetDefaultOperator]
  )

  useEffect(() => {
    const nodeId = nodeEditorStore.nodeId
    const currentNode = flowStore.nodes.find(node => node.id === nodeId)

    if (
      !hasInitialized &&
      currentNode &&
      currentNode.settings &&
      Object.keys(currentNode.settings).length
    ) {
      const { settings } = currentNode
      const { advancedCondition } = settings!
      setLeftOperandContext(
        advancedCondition?.leftOperand?.storageType ?? LeftHandContextType.STATE
      )
      setRightOperandContext(
        advancedCondition?.rightOperand?.storageType ?? RightHandContextType.STATE
      )
      if (advancedCondition?.leftOperand) checkAndUpdateOperatorList(advancedCondition?.leftOperand)
      nodeEditorStore.updateState(EditorType.ADVANCED_CONDITION, advancedCondition)
      setHasInitialized(true)
    } else if (!hasInitialized) {
      nodeEditorStore.resetState(EditorType.ADVANCED_CONDITION)
      setHasInitialized(true)
    }
  }, [
    serviceStore.mode,
    serviceStore.serviceData,
    flowStore.nodes,
    flowStore.edges,
    nodeEditorStore,
    checkAndUpdateOperatorList,
    setHasInitialized,
    hasInitialized
  ])

  const updateState = useCallback(
    (data: Partial<IAdvancedConditionEditorState>, isLeftOperand: boolean) => {
      /**
       * Then we always go through the left operand to figure out what type
       * it currently supports in order to update the operator list
       */
      let operator = data.operator
      if (isLeftOperand && data.leftOperand) {
        operator = checkAndUpdateOperatorList(data.leftOperand)
      }

      nodeEditorStore.updateState(EditorType.ADVANCED_CONDITION, { ...data, operator })
    },
    [nodeEditorStore, checkAndUpdateOperatorList]
  )

  const handleClose = (): void => {
    nodeEditorStore.setEditorClosed(EditorType.ADVANCED_CONDITION)
  }

  const handleSave = (): void => {
    const nodeId = nodeEditorStore.nodeId
    const leftOperandSourceKey = advancedConditionEditorState.leftOperand?.value?.sourceKey
    const rightOperandSourceKey = advancedConditionEditorState.rightOperand?.value?.sourceKey
    const label = `${leftOperandSourceKey} ${advancedConditionEditorState.operator} ${rightOperandSourceKey}`

    flowStore.updateAdvancedConditionNode(nodeId, advancedConditionEditorState, label, customInput)
  }

  const handleContextChange = (
    value: LeftHandContextType | RightHandContextType,
    type: OperandType
  ): void => {
    const key = `${type}Operand`
    const isLeft = type === "left"
    const isKnownType =
      value === RightHandContextType.NUMBER ||
      value === RightHandContextType.STRING ||
      value === RightHandContextType.BOOLEAN

    if (isLeft) {
      setLeftOperandContext(value as LeftHandContextType)
    } else {
      setRightOperandContext(value as RightHandContextType)
    }

    const updatedOperand: LeftHandOperand | RightHandOperand = {
      ...advancedConditionEditorState[key],
      storageType: value,
      /**
       * Whenever context is changed reset value of transformer
       * */
      transformers: [],
      value: {
        ...advancedConditionEditorState[key]?.value,
        /**
         * If the context is not STATE, then override the
         * context type of the value in the editor state
         * */
        context:
          value !== RightHandContextType.STATE
            ? value
            : advancedConditionEditorState[key]?.value?.context,
        /**
         * Whenever context is changed reset value of property
         * and  set type to unknown unless its known
         * */
        sourceKey: "-",
        type: isKnownType ? value : KeyType.Unknown
      }
    }

    updateState(
      {
        ...advancedConditionEditorState,
        operator:
          leftOperandContext === LeftHandContextType.CUSTOM_FIELDS
            ? BooleanOperators.IS_TRUE
            : advancedConditionEditorState.operator,
        [key]: updatedOperand
      },
      type === "left"
    )
  }

  const handleOperandChange = (value: IChatbotPropertyKey | string, type: OperandType): void => {
    const isString = typeof value === "string"
    const key = `${type}Operand`

    if (isString && value !== VALUE_NOT_SET) {
      const customValue: IChatbotPropertyKey = {
        sourceKey: value,
        type: KeyType.Unknown,
        context: "customState"
      }
      updateState(
        {
          ...advancedConditionEditorState,
          [key]: {
            /**
             * Whenever operand is changed reset value of transformer
             * */
            transformers: [],
            ...advancedConditionEditorState[key],
            value: customValue
          }
        },
        type === "left"
      )
    } else if (value !== null && value !== VALUE_NOT_SET) {
      updateState(
        {
          ...advancedConditionEditorState,
          [key]: {
            /**
             * Whenever operand is changed reset value of transformer
             * */
            transformers: [],
            ...advancedConditionEditorState[key],
            value
          }
        },
        type === "left"
      )
    }
  }

  const handleCustomValueChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: OperandType
  ) => {
    const key = `${type}Operand`
    updateState(
      {
        ...advancedConditionEditorState,
        [key]: {
          ...advancedConditionEditorState[key],
          value: {
            ...advancedConditionEditorState[key].value,
            customValue: event.target.value
          }
        }
      },
      type === "left"
    )
  }

  const handleCustomOperandChange = useCallback(
    (value: string, type: OperandType): void => {
      setCustomInput(value)
    },
    [setCustomInput]
  )

  const handleOperandTransformerChange = (
    value: TransformerKeys | "-",
    type: OperandType
  ): void => {
    const isTransformerSet = value !== VALUE_NOT_SET
    const resultType = isTransformerSet ? resultTypeMap[value] : leftOperand?.type

    const key = `${type}Operand`
    const updatedValue: LeftHandOperand | RightHandOperand = {
      ...advancedConditionEditorState[key].value,
      /**
       * If notSet set transformer type to undefine this will then be
       * checked in the bot and if defined it will use the transformer type
       */
      transformerType: isTransformerSet ? resultType : undefined
    }

    updateState(
      {
        ...advancedConditionEditorState,
        [key]: {
          ...advancedConditionEditorState[key],
          transformers: !isTransformerSet ? [] : [value],
          value: updatedValue
        }
      },
      type === "left"
    )
  }

  const handleOperatorChange = (value: Operators): void => {
    updateState(
      {
        ...advancedConditionEditorState,
        operator: value
      },
      false
    )
  }

  const handleTextInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: OperandType
  ): void => {
    const key = `${type}Operand`
    updateState(
      {
        ...advancedConditionEditorState,
        [key]: {
          ...advancedConditionEditorState[key],
          value: {
            ...advancedConditionEditorState[key].value,
            sourceKey: event.target.value
          }
        }
      },
      type === "left"
    )
  }

  const validateSetOfOperands = (): boolean => {
    const { leftOperand, rightOperand, operator } = advancedConditionEditorState
    const leftValueSourceKey = leftOperand.value.sourceKey
    const isLeftValueSet = leftValueSourceKey !== VALUE_NOT_SET

    /**
     * For a specific case where leftOperand is of type Boolean and
     * its sourceKey is not VALUE_NOT_SET, return false
     * */
    if (
      leftOperand.value?.type === KeyType.Boolean &&
      leftOperand.value?.sourceKey !== VALUE_NOT_SET
    ) {
      return true
    }

    const hasCustomValueSet =
      typeof rightOperand?.value.customValue !== undefined &&
      String(rightOperand?.value.customValue).length

    const rightStorageType = rightOperand?.storageType
    const isRightStorageCustom =
      rightStorageType === RightHandContextType.BOOLEAN ||
      rightStorageType === RightHandContextType.NUMBER ||
      rightStorageType === RightHandContextType.STRING

    /**
     * If the right hand operand is set to a custom storage type
     * (Boolean/String/Number i.e. entering a custom comparison value)
     * then check the customValue
     */
    if (leftOperand && operator && isRightStorageCustom && isLeftValueSet && hasCustomValueSet) {
      return true
    }

    const rightValueSourceKey = rightOperand?.value.sourceKey
    const isRightValueSet = rightValueSourceKey !== VALUE_NOT_SET
    /**
     * Return true if leftOperand or operator is falsy, or if
     * any operand's sourceKey is VALUE_NOT_SET
     * */
    if (!leftOperand || !operator || !isLeftValueSet || !isRightValueSet) {
      return false
    }

    /** If none of the above conditions are met, return false */
    return true
  }

  return (
    <MuiDialog
      fullWidth
      maxWidth={maxWidth}
      open={nodeEditorStore.advancedConditionEditorOpen}
      onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={styles.advancedConditionEditorWrapper}>
          <Typography
            variant="caption"
            color="error"
            align="center"
            style={{ padding: "0 0 0.5em 0" }}>
            If the question you are looking for is not visible in the state list, you need to first
            save the flow with the new question without the advanced condition and then create the
            advanced condition
          </Typography>
          <div className={styles.advancedConditionEditorContainer}>
            <div className={styles.leftHandOperandContainer}>
              <Operand
                type="left"
                operandContext={leftOperandContext}
                handleContextChange={handleContextChange}
                handleTextInputChange={handleTextInputChange}
                handleOperandChange={handleOperandChange}
                handleCustomOperandChange={handleCustomOperandChange}
                handleOperandTransformerChange={handleOperandTransformerChange}
                handleCustomValueChange={handleCustomValueChange}
              />
            </div>
            <div className={styles.operatorContainer}>
              <Operator operators={currentOperators} handleOperatorChange={handleOperatorChange} />
            </div>
            <div className={styles.rightHandOperandContainer}>
              <Operand
                type="right"
                operandContext={rightOperandContext}
                handleContextChange={handleContextChange}
                handleTextInputChange={handleTextInputChange}
                handleOperandChange={handleOperandChange}
                handleOperandTransformerChange={handleOperandTransformerChange}
                handleCustomValueChange={handleCustomValueChange}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {ActionButton && ActionButton}
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSave} disabled={!validateSetOfOperands()}>
          Save
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default observer(AdvancedConditionEditor)
