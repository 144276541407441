const toCamelCase = (string: string): string => {
  const formattedString = string
    .toLowerCase()
    ?.match(/[A-Z0-9]+/gi)
    ?.map(function (word, i) {
      if (!i) return word
      return word[0].toUpperCase() + word.slice(1)
    })
    .join("")
  return formattedString || ""
}

export default toCamelCase
