import { PlusIcon, Cross1Icon, FrameIcon } from "@radix-ui/react-icons"
import ChangeCircle from "@mui/icons-material/ChangeCircle"
import { useFlowStore, useServiceStore } from "../../../../context/rootStoreContext"
import Button from "../../../Button"
import { Toolbar } from "../../components/Toolbar/Toolbar"
import CreateToolDropdownMenu from "./CreateToolDropdownMenu"
import React from "react"
import formatTimeStamp from "../../../../../utils/formatTimeStamp"
import { Menu, MenuItem } from "@mui/material"
import defaultFlows from "../../defaultFlows"

interface Props {
  onBackgroundToggle: () => void
  onLoadDefaultFlow: (type?: string) => void
  onSaveFlowChanges: () => void
  onHighLevelFlowDialogueClick: () => void
  disableSave: boolean
}

export default function MainToolBar(props: Props) {
  const { disableSave } = props
  const serviceStore = useServiceStore()
  const flowStore = useFlowStore()

  const hasMultiDefaultFlows =
    Object.keys(defaultFlows[flowStore.currentHighLevelDialogue?.value]).length > 1

  const dialogue = flowStore.currentHighLevelDialogue.value
  const mode = serviceStore.mode
  const updatedTimeStamp = serviceStore.serviceData?.[mode]?.flow?.[dialogue]?.updated
  const publishTimeStamp = serviceStore.serviceData?.lastPublishDate

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isMultiDropdownOpen, setIsMultiDropdownOpen] = React.useState(false)

  const [formattedUpdatedTimeStamp, formattedUpdatedTimeStampTitle] =
    formatTimeStamp(updatedTimeStamp)
  const [formattedPublishedTimeStamp, formattedPublishedTimeStampTitle] =
    formatTimeStamp(publishTimeStamp)

  const resetDiagram = () => {
    flowStore.setNodes([])
    flowStore.setEdges([])
  }

  const handleLoadDefaultClick = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    multi = false
  ) => {
    if (multi && e) {
      setIsMultiDropdownOpen(!isMultiDropdownOpen)
      setAnchorEl(e.currentTarget)
    } else {
      props.onLoadDefaultFlow()
    }
  }

  const handleDropdownClick = (type: string) => {
    setIsMultiDropdownOpen(false)
    setAnchorEl(null)
    props.onLoadDefaultFlow(type)
  }

  return (
    <Toolbar.Root
      aria-label="Main toolbar"
      css={{
        display: "flex",
        alignItems: "center",
        boxShadow: "rgba(0, 0, 0, 0.14) 0px 2px 10px"
      }}>
      {/*
        The Create button needs customised styles because the CreateToolDropdownMenu
        was causing issues when it was wrapping the button (not working) and thus
        was moved to wrap the Icon instead
      */}
      <Button
        css={{ width: "auto", minWidth: 0, padding: 8 }}
        aria-label="Create Node"
        title="Create Node">
        <CreateToolDropdownMenu>
          <PlusIcon />
        </CreateToolDropdownMenu>
      </Button>
      <Button
        css={{ width: "auto", minWidth: 0, padding: 8 }}
        aria-label="Reset"
        onClick={resetDiagram}
        title="Clear All">
        <Cross1Icon />
      </Button>
      <Button
        css={{ width: "auto", minWidth: 0, padding: 8 }}
        aria-label="Toggle Grid"
        onClick={props.onBackgroundToggle}
        title="Toggle Grid">
        <FrameIcon />
      </Button>
      <Toolbar.Separator css={{ height: "100%" }} />
      <Button
        title="Click to change dialogue"
        aria-label="Change dialogue"
        onClick={props.onHighLevelFlowDialogueClick}
        color="secondary"
        css={{ paddingRight: 20 }}>
        {flowStore.currentHighLevelDialogue.body}
        <ChangeCircle style={{ marginLeft: 4 }} />
      </Button>
      <div style={{ display: "flex", flexFlow: "column" }}>
        <div title={formattedUpdatedTimeStampTitle} style={{ marginLeft: 12, cursor: "default" }}>
          <b>Draft updated:</b> {formattedUpdatedTimeStamp ? formattedUpdatedTimeStamp : "N/A"}
        </div>
        <div title={formattedPublishedTimeStampTitle} style={{ marginLeft: 12, cursor: "default" }}>
          <b>Last publish:</b> {formattedPublishedTimeStamp ? formattedPublishedTimeStamp : "N/A"}
        </div>
      </div>
      <Button
        css={{ marginLeft: "auto" }}
        onClick={e => handleLoadDefaultClick(e, hasMultiDefaultFlows)}>
        Load Default
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMultiDropdownOpen}
        onClose={() => setIsMultiDropdownOpen(false)}>
        {Object.keys(defaultFlows[flowStore.currentHighLevelDialogue?.value]).map(key => {
          return (
            <MenuItem key={`${key}_option`} onClick={e => handleDropdownClick(key)}>
              {key}
            </MenuItem>
          )
        })}
      </Menu>
      <Button onClick={props.onSaveFlowChanges} disabled={disableSave}>
        Save Changes
      </Button>
    </Toolbar.Root>
  )
}
