import React from "react"
import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useNodeEditorStore, useServiceStore } from "../../../../../../context/rootStoreContext"
import { EditorType } from "../../../../models/NodeEditors"
import styles from "./SetLanguage.module.scss"
import { observer } from "mobx-react"
import { LanguageCodes, languagesMap } from "@limbic/types"

function SetLanguage(): JSX.Element | null {
  const nodeEditorStore = useNodeEditorStore()
  const serviceStore = useServiceStore()

  const { actionEditorState } = nodeEditorStore
  const { actionLanguageToSet } = actionEditorState
  const defaultLanguage = serviceStore.modeConfig?.translations?.defaultLanguage || LanguageCodes.EN
  const supportedLanguages = serviceStore.modeConfig?.translations?.supportedLanguages ?? []

  const handleLanguageChange = (
    e: SelectChangeEvent<LanguageCodes | "browserLanguage" | "defaultLanguage">
  ): void => {
    const actionLanguageToSet = e.target.value
    nodeEditorStore.updateState(EditorType.ACTION, { actionLanguageToSet })
  }

  return (
    <div className={styles.setLanguageContainer}>
      <Select
        id="select-language-to-set"
        color="primary"
        variant="outlined"
        className={styles.select}
        onChange={handleLanguageChange}
        value={actionLanguageToSet}>
        <MenuItem key="browser-language" value="browserLanguage">
          Match Browser Language (if language supported)
        </MenuItem>
        <MenuItem key="default-language" value="defaultLanguage">
          Default Language ({languagesMap[defaultLanguage]} [{defaultLanguage}])
        </MenuItem>
        {supportedLanguages.map(code => (
          <MenuItem key={code} value={code}>
            {`${languagesMap[code]} [${code}]`}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default observer(SetLanguage)
