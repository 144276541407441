import { action, makeObservable } from "mobx"
import {
  CHATFLOW_CUSTOM_KEYS,
  ChatFlowsEnum,
  IBotFlow,
  IBotServiceData,
  IDashboardNode,
  IKeyTransform,
  IKeyTransformMap,
  KeyType
} from "@limbic/types"
import toCamelCase from "../../utils/toCamelCase"
import { MappingStore } from "./MappingStore"
import invariant from "../../utils/invariant"
import {
  isChatFlowNode,
  isCheckboxNode,
  isDateNode,
  isFreeTextNode,
  isInlinePickerNode,
  isSetStateActionNode
} from "../../utils/node"

/**
 * This store is used to generate a transform map for the backend
 * based on the nodes in the flow. It is used to map the keys the
 * chatbot collects to the keys the backend expects to receive.
 */
export class BackendMappingStore extends MappingStore {
  constructor() {
    super()
    makeObservable(this)
  }

  @action
  sync(data?: IBotServiceData): void {
    const backendMapping = data?.backendMapping
    if (backendMapping?.transformMap) this.setTransformMap(backendMapping.transformMap)

    this.generateTransformMap(data?.flow ?? {})
    this.setTargetKeys(backendMapping?.targetKeys)
  }

  /**
   * Reads all the nodes in the flow and generates a transform map
   * based on the various steps and questions in all the available
   * dialogues
   * @param flow {IBotFlow} the bot flow to generate the transform map from
   */
  @action
  generateTransformMap(flow: IBotFlow): void {
    const dialogues = Object.entries(flow ?? {}).map(([dialogue, flow]) => ({
      dialogue,
      nodes: flow.dashboard.nodes
    }))
    if (!dialogues.length) return

    const newTransformMap: IKeyTransformMap = { ...this.transformMap }
    dialogues.forEach(({ dialogue, nodes }) => {
      nodes.forEach((node: IDashboardNode) => {
        const keysTransforms: IKeyTransform[] = []

        if (isChatFlowNode(node)) {
          const transforms = this.getChatFlowTransforms(dialogue, node)
          keysTransforms.push(...transforms)
        }

        if (isCheckboxNode(node)) {
          const transforms = this.getCheckboxTransforms(dialogue, node)
          keysTransforms.push(...transforms)
        }

        if (isInlinePickerNode(node)) {
          const transforms = this.getInlinePickerTransforms(dialogue, node)
          keysTransforms.push(...transforms)
        }

        if (isFreeTextNode(node)) {
          const questionID = node.settings?.questionID
          const questionName = node.settings?.questionName
          const context = `${dialogue}-->${questionName ?? node.id}`
          const sourceKey = questionID ?? questionName!
          keysTransforms.push({ type: KeyType.Text, context, sourceKey })
        }

        if (isDateNode(node)) {
          const questionID = node.settings?.questionID
          const questionName = node.settings?.questionName
          const context = `${dialogue}-->${questionName ?? node.id}`
          const sourceKey = questionID ?? questionName!
          keysTransforms.push({ type: KeyType.Number, context, sourceKey })
        }

        if (isSetStateActionNode(node)) {
          const transforms = this.getSetStateActionTransforms(dialogue, node)
          keysTransforms.push(...transforms)
        }

        // Save in the transform map only the transforms that are not
        // already in the transform map, or if they are, only if the
        // context is different, because if the context is the same,
        // it means it's the same key, so we don't want to override
        // any mappings that the user might have already done
        keysTransforms.forEach(k => {
          if (newTransformMap[k.sourceKey]?.context !== k.context) {
            newTransformMap[k.sourceKey] = k
            return
          }

          // get all the new keys that are not currently in the valuesMap
          // and add them (for example, when a value of an inline picker
          // is added in the diagram, here we're adding it to the valuesMap
          // of the transform as well)
          Object.entries(k.valuesMap ?? {}).forEach(([sourceValue, targetValue]) => {
            if (!(String(sourceValue) in (newTransformMap[k.sourceKey]?.valuesMap ?? {}))) {
              newTransformMap[k.sourceKey].valuesMap ??= k.valuesMap
              newTransformMap[k.sourceKey].valuesMap![sourceValue] = targetValue
              return
            }
          })

          // get all the values that were in the valuesMap previously
          // but are not any more (for example, when a value of an inline
          // picker is being removed from the diagram, here we're also
          // removing it from the valuesMap)
          Object.keys(newTransformMap[k.sourceKey]?.valuesMap ?? {}).forEach(key => {
            if (!(key in (k.valuesMap ?? {}))) {
              delete newTransformMap[k.sourceKey].valuesMap![key]
            }
          })
        })
      })
    })

    // TODO: figure out how to delete transforms that are no longer
    //       needed like for instance when the user removes an entire
    //       node from the diagram and now we're still seeing its
    //       transforms in the mapping UI

    this.setTransformMap(newTransformMap)
  }

  /** Generic Handlers */

  /**
   * For a given node that is a chatFlow, it will check the list of known
   * customisable keys for chatFlows, to see if this chatFlow has any keys
   * that can be customised (aka, be saved in a different key in the bot's
   * state than what the chatFlow would do by default) and if for any customisable
   * key it finds, it creates the corresponding empty transform object that
   * will then be shown to the user in the mapping interface so tha they can
   * determine what exactly will need to happen to those customisable keys
   * @param dialogue the dialogue the node belongs to
   * @param node the chatFlow node to check for customisable keys
   */
  getChatFlowTransforms(dialogue: string, node: IDashboardNode): IKeyTransform[] {
    const chatFlow = node.settings?.chatFlow
    invariant<ChatFlowsEnum>(chatFlow, `Chat flow ${chatFlow} not found for node ${node.id}`)

    const context = `${dialogue}-->${node.id}-${chatFlow}`
    const settings = node.settings?.chatFlowSettings?.[chatFlow!] ?? {}

    return Object.entries(CHATFLOW_CUSTOM_KEYS[chatFlow!] ?? {}) //
      .filter(([key]) => !!settings[key])
      .map(([key, metaData]) => {
        const valuesMap = metaData.allowedValues?.reduce((obj, key: string | number | boolean) => {
          obj[String(key)] = null
          return obj
        }, {})
        return { type: metaData.type, context, sourceKey: settings[key], valuesMap }
      })
  }

  /**
   * For a given node that is a checkbox, it will create a transform object
   * for each checkbox option, so that the user can map each checkbox option
   * to a different key in the backend
   * @param dialogue the dialogue the node belongs to
   * @param node the checkbox node to create transforms for
   */
  getCheckboxTransforms(dialogue: string, node: IDashboardNode): IKeyTransform[] {
    const questionName = node.settings?.questionName
    const context = `${dialogue}-->${questionName ?? node.id}`

    const checkboxOptions = node.settings?.promptSettings?.checkboxOptions ?? []
    return checkboxOptions.map(o => ({
      type: KeyType.Boolean,
      context,
      sourceKey: o.body.includes(" ") ? toCamelCase(o.body) : o.body,
      valuesMap: { true: null, false: null }
    }))
  }

  /**
   * For a given node that is an inlinePicker or an InlinePickerMultiSelect, it
   * will create a transform object with an empty valuesMap where the keys are
   * the different values in the picker so that the user can then use the interface
   * to map each individual option into a specific value if needed.
   * If the node also contains a free text input (aka the prompt is both a picker and
   * a text input), it will add a __freeTextValue__ key in the valuesMap in case the
   * user wants to map the custom value as a specific value in the given target key,
   * but it will also create a separate standalone transform just for this value as
   * if it was a free text node, so that the user can then map the custom value to
   * a completely separate key in the final payload. One example is if you're asking
   * for disability, and you give the user a bunch of options to click on, but you
   * also give them a free text input so that they can add their custom information.
   * You would be able to map it in such a way that you could save the free text answer
   * as "OTHER" in the "disability" key of the final payload, and then save the actual
   * answer into a completely different key in the payload, like "disabilityDetails"
   * @param dialogue the dialogue the node belongs to
   * @param node the checkbox node to create transforms for
   */
  getInlinePickerTransforms(dialogue: string, node: IDashboardNode): IKeyTransform[] {
    const transforms: IKeyTransform[] = []
    const promptType = node.settings?.promptType
    const questionID = node.settings?.questionID
    const questionName = node.settings?.questionName
    const context = `${dialogue}-->${questionName ?? node.id}`
    const sourceKey = questionID ?? questionName!
    const keyType = promptType === "inlinePickerMultiSelect" ? KeyType.TextList : KeyType.Text
    const valuesMap =
      node.settings?.promptSettings?.options?.reduce((obj, key) => {
        obj[key] = null
        return obj
      }, {}) ?? {}
    console.log({ context, valuesMap })
    if (node.settings?.promptSettings?.textPromptWithInlineOption) {
      valuesMap["__inlineFreeTextValue__"] = null
      transforms.push({
        type: keyType,
        context: `${context} (Free Text)`,
        sourceKey: `__inlineFreeTextValue__${sourceKey}`
      })
    }
    transforms.push({ type: keyType, context, sourceKey, valuesMap })
    console.log({ valuesMap, transforms })
    return transforms
  }

  getSetStateActionTransforms(dialogue: string, node: IDashboardNode): IKeyTransform[] {
    const sourceKey = node.settings?.actionStateKey
    invariant(sourceKey, `Action state key not found for node ${node.id}`)

    const type = node.settings?.actionStateValueType
    const valuesMap = type === "boolean" ? { true: null, false: null } : undefined
    const context = `${dialogue}-->${node.id}`
    return [{ type: KeyType.Text, context, sourceKey, valuesMap }]
  }
}
