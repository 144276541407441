import React, { useCallback } from "react"
import {
  Autocomplete as MUIAutocomplete,
  AutocompleteProps,
  autocompleteClasses,
  styled,
  TextField,
  Popper
} from "@mui/material"
import classes from "clsx"
import {
  ClinicalFlags,
  disordersMap,
  ICustomClinicalPathsDescriptor,
  ProblemCategories
} from "@limbic/types"
import styles from "./AutoComplete.module.scss"

interface Props
  extends Omit<AutocompleteProps<any, any, any, any>, "renderInput" | "onChange" | "options"> {
  type: "primaryProblems" | "secondaryProblems" | "flags"
  disabled?: boolean
  input?: ICustomClinicalPathsDescriptor
  className?: string
  testId?: string
  onChange: (value?: any) => void
}

const AUTOCOMPLETE_PROPS: Omit<AutocompleteProps<any, any, any, any>, "renderInput"> = {
  filterSelectedOptions: true,
  disableClearable: true,
  size: "small",
  options: []
}

const PROBLEM_CATEGORIES = enumToArray(ProblemCategories)

const FLAGS = [
  "ALL",
  "NONE",
  ClinicalFlags.MS_FunctionalImpairment,
  ClinicalFlags.S_FunctionalImpairment
]

const FLAGS_MAP = {
  ALL: "All",
  NONE: "None",
  [ClinicalFlags.MS_FunctionalImpairment]: "Moderately Severe Functional Impairment",
  [ClinicalFlags.S_FunctionalImpairment]: "Severe Functional Impairment"
}

export default function AutoComplete<T>(props: Props): JSX.Element {
  const { value, type, className, input, testId, ...restProps } = props
  const autoCompleteProps = { ...AUTOCOMPLETE_PROPS, ...restProps }
  const options = type === "flags" ? FLAGS : PROBLEM_CATEGORIES

  const filterOptions = useCallback(
    options => {
      if (type === "flags") return options
      return getUnusedProblemCategoriesOptions(
        [...(input?.primaryProblems ?? []), ...(input?.secondaryProblems ?? [])],
        options
      )
    },
    [input?.primaryProblems, input?.secondaryProblems, type]
  )

  if (type === "flags") {
    autoCompleteProps.getOptionLabel = option => FLAGS_MAP[option]
    autoCompleteProps.isOptionEqualToValue = (option, value) => option === value
  }

  return (
    <div className={classes(styles.inputSection, className)}>
      <MUIAutocomplete
        {...autoCompleteProps}
        openOnFocus
        id={`tags-${type}`}
        data-testid={testId}
        value={value}
        options={options}
        PopperComponent={StyledAutocompletePopper}
        style={{ width: "100%" }}
        filterOptions={filterOptions}
        onChange={(event, value: T | null) => props.onChange(value ?? undefined)}
        renderInput={params => <TextField className={styles.textField} {...params} />}
      />
    </div>
  )
}

const StyledAutocompletePopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "0px 24px 56px 0px rgba(0,0,0,0.2)"
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === "light" ? " #eaecef" : "#30363d"}`,
      '&[aria-selected="true"]': { backgroundColor: "transparent" },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        { backgroundColor: theme.palette.action.hover },
      [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: "relative"
      }
    }
  }
}))

function getUnusedProblemCategoriesOptions(
  used?: ProblemCategories[],
  list?: ProblemCategories[]
): ProblemCategories[] {
  const usedProblemCategories =
    used
      ?.map(item => {
        const { depression, anxiety, anxietyExtra } = disordersMap
        if (depression.includes(item)) return depression
        if (anxiety.gad.includes(item)) return anxiety.gad
        if (anxiety.agoraPhobia.includes(item)) return anxiety.agoraPhobia
        if (anxiety.socialPhobia.includes(item)) return anxiety.socialPhobia
        if (anxiety.phobia.includes(item)) return anxiety.phobia
        if (anxiety.panic.includes(item)) return anxiety.panic
        if (anxietyExtra.includes(item)) return anxietyExtra
        return []
      })
      .flat(2) ?? []
  return list?.filter(problemCategory => !usedProblemCategories?.includes(problemCategory)) ?? []
}

function enumToArray(someEnum: any): (typeof someEnum)[] {
  if (someEnum == null) return []
  const enumValues: Array<typeof someEnum> = []
  for (const key in someEnum) {
    enumValues.push(someEnum[key] as typeof someEnum)
  }
  return enumValues
}
