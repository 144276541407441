import React from "react"
import { observer } from "mobx-react"
import Input from "./components/Input"
import { useClinicalPathsConfigurationStore } from "../../../../context/rootStoreContext"

interface Props {
  onClinicalPathChange: (_event, index: number) => void
}

function DefaultPaths(props: Props): JSX.Element {
  const clinicalPathsConfigurationStore = useClinicalPathsConfigurationStore()

  return (
    <>
      {clinicalPathsConfigurationStore.defaultPaths.map((path, index) => (
        <Input //
          key={path.id}
          input={path}
          index={index}
          onChange={props.onClinicalPathChange}
        />
      ))}
    </>
  )
}

export default observer(DefaultPaths)
