import { useCallback, useState } from "react"
import {
  Connection,
  Handle,
  NodeProps,
  Position,
  useUpdateNodeInternals
} from "react-flow-renderer"
import classNames from "classnames"
import { tomato } from "@radix-ui/colors"
import { styled } from "@stitches/react"
import { CustomNode } from "../CustomNode"
import CircleShape from "../../../components/shapes/Circle"
import NodeSettings from "../NodeSettings"
import { opposingPositionsMap, positionsMap } from "../../../config"
import useDeleteNode from "../../../../../hooks/useDeleteNode"

export const FLOW_START_NODE_SIZE = 28

export default function FlowStartNode(props: NodeProps) {
  const { id, selected, dragging } = props
  const [handlePosition, setHandlePosition] = useState(Position.Bottom)
  const updateInternals = useUpdateNodeInternals()
  const onDelete = useDeleteNode(id)

  const onRotate = useCallback(() => {
    setHandlePosition(p => positionsMap[p])
    updateInternals(id)
  }, [id, updateInternals])

  const labelPosition = opposingPositionsMap[handlePosition]

  const style = {
    [Position.Left]: { top: 0, bottom: 0, left: -32 },
    [Position.Right]: { top: 0, bottom: 0, right: -32 },
    [Position.Top]: { left: 0, right: 0, top: -16 },
    [Position.Bottom]: { left: 0, right: 0, bottom: -16 }
  }[labelPosition]

  const checkConnection = ({ source, target }: Connection): boolean => {
    return source !== target
  }

  return (
    <CustomNode.Container id={id} dragging={dragging}>
      <Handle
        type="source"
        position={handlePosition}
        style={handleStyle}
        isValidConnection={connection => checkConnection(connection)}
      />
      <Container className={classNames("react-flow__node-shape nopan", { selected })}>
        <CircleShape
          size={FLOW_START_NODE_SIZE}
          fillColor={"black"}
          strokeColor={tomato.tomato9}
          strokeWidth={selected ? 2 : 1}
        />
        <Content style={style}>
          <CustomNode.Label>Start</CustomNode.Label>
        </Content>
      </Container>
      <NodeSettings
        open={props.selected && !props.dragging}
        onRotate={onRotate}
        onDelete={onDelete}
      />
    </CustomNode.Container>
  )
}

const Container = styled("div", {
  alignItems: "center",
  justifyContent: "center"
})

const Content = styled("div", {
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})

const handleStyle = {
  borderColor: tomato.tomato9,
  backgroundColor: tomato.tomato9
}
