import { action, makeObservable, observable } from "mobx"
import { IKeyMetaData, IKeyTransform, IKeyTransformMap } from "@limbic/types"

export abstract class MappingStore {
  @observable transformMap: IKeyTransformMap
  @observable targetKeys: Record<string, IKeyMetaData>

  constructor() {
    this.transformMap = {}
    this.targetKeys = {}
    makeObservable(this)
  }

  /** Actions */

  @action
  setTransformMap(transformMap?: IKeyTransformMap) {
    this.transformMap = transformMap ?? {}
  }

  @action
  setTargetKeys(targetKeys?: Record<string, IKeyMetaData>) {
    this.targetKeys = targetKeys ?? {}
  }

  @action
  setKeyTransform(newKeyTransform: IKeyTransform) {
    this.transformMap[newKeyTransform.sourceKey] = newKeyTransform
    this.setTransformMap({ ...this.transformMap })
  }
}
