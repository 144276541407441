import React from "react"
import styles from "./Button.module.scss"
import { Button as MuiButton } from "@mui/material"
import { CSSProperties } from "@stitches/react"

interface Props {
  children: string | React.ReactChild | React.ReactChild[]
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  css?: CSSProperties
  color?: "primary" | "secondary"
  title?: string
}

function Button(props: Props): JSX.Element | null {
  const { onClick, css, color = "primary", title, disabled } = props

  return (
    <MuiButton
      className={styles.button}
      style={css}
      title={title ?? ""}
      disabled={disabled}
      variant="contained"
      color={color}
      onMouseDown={e => onClick?.(e)}>
      {props.children}
    </MuiButton>
  )
}

export default Button
