import React, { PropsWithChildren } from "react"
import CancelIcon from "@mui/icons-material/Cancel"
import classes from "clsx"
import styles from "./PDFInput.module.scss"

interface Props {
  disabled?: boolean
  file?: File
  currentFileURL?: string
  onChangeLeaflet: (file?: File) => void
  onRemoveLeaflet: () => void
  index: number
}

function PDFInput(props: PropsWithChildren<Props>): JSX.Element {
  const { currentFileURL, file } = props
  const inputID = props.index ? `attachment-upload-${props.index}` : "attachment-upload"

  return (
    <>
      <div
        className={classes(styles.customUploaderWrapper, {
          [styles.customUploaderWrapperDisabled]: props.disabled
        })}>
        <label
          htmlFor={inputID}
          className={classes(styles.customUploader, {
            [styles.fileInputDisabled]: props.disabled
          })}>
          {file ? file.name : "Click to select a leaflet (PDF)"}
        </label>
        <input
          disabled={props.disabled}
          accept=".pdf"
          className={classes(styles.fileInput, { [styles.fileInputDisabled]: props.disabled })}
          id={inputID}
          name="attachment"
          type="file"
          onChange={event => props.onChangeLeaflet(event.target.files?.[0])}
          value=""
        />
      </div>
      {currentFileURL && currentFileURL !== "delete" ? (
        <div className={styles.attachmentContainer}>
          <a className={styles.leafletLink} href={currentFileURL} target="_blank" rel="noreferrer">
            {props.children}
          </a>
          {props.disabled ? (
            <span className={styles.removeLeaflet} title="Remove Leaflet">
              <CancelIcon fontSize="small" />
            </span>
          ) : (
            <span
              className={styles.removeLeaflet}
              onClick={props.onRemoveLeaflet}
              title="Remove Leaflet">
              <CancelIcon fontSize="small" />
            </span>
          )}
        </div>
      ) : null}
    </>
  )
}

export default PDFInput
