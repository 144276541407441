import React from "react"
import { observer } from "mobx-react"
import {
  ReactGrid,
  Column,
  Row,
  CellChange,
  DefaultCellTypes,
  HeaderCell,
  TextCell
} from "@silevis/reactgrid"
import { LanguageCodes, Translation, languagesMap } from "@limbic/types"
import classes from "clsx"
import {
  useServiceConfigurationStore,
  useServiceStore
} from "../../../../../../context/rootStoreContext"
import { cellTemplates } from "../CustomCells"
import { CellWithButton } from "../CustomCells/CellWithButton.cell"
import deepCompare from "../../../../../../../utils/deepCompare"
import { Button, MenuItem, Select, TextField } from "@mui/material"
import Dialog from "../../../../../../components/Dialog"
import styles from "./TranslationsEditor.module.scss"
import "@silevis/reactgrid/styles.css"
import "../../../../../../styles/reactGrid.css"
import { uniquelyConcat } from "../../../../../../../utils/array"

interface Props {
  setError: (value: boolean) => void
}

function TranslationsEditor(props: Props): React.ReactElement {
  const serviceStore = useServiceStore()
  const serviceConfigurationStore = useServiceConfigurationStore()
  const [searchType, setSearchType] = React.useState<"originalText" | "status" | LanguageCodes>(
    "originalText"
  )
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>()
  const [isConfirmDialogueOpen, setIsConfirmDialogueOpen] = React.useState(false)

  const defaultLanguage = serviceConfigurationStore.config.translations?.defaultLanguage
  const supportedLanguages = serviceConfigurationStore.config.translations?.supportedLanguages
  const translations = serviceConfigurationStore.config.translations?.customTranslations
  const serviceDataTranslations = serviceStore.modeConfig?.translations?.customTranslations

  const onCellChanged = (changes: CellChange[]) => {
    changes
      .filter(c => c.type === "text")
      .forEach(c => {
        const change = c as CellChange<TextCell>
        const row = gridRows[change.rowId]
        const originalText = row.cells[0].text
        const languageCode = change.columnId as LanguageCodes
        const translationObject = translations?.[originalText] ?? {}
        const translation = change.newCell.text

        serviceConfigurationStore.setConfig({
          ...serviceConfigurationStore.config,
          translations: {
            ...serviceConfigurationStore.config.translations,
            customTranslations: {
              ...(translations ?? {}),
              [originalText]: { ...translationObject, [languageCode]: translation }
            }
          }
        })
      })
  }

  const onSaveChanges = async () => {
    try {
      if (translations) await serviceStore.updateCustomTranslations(translations)
    } catch (e) {
      props.setError(true)
      console.error(e)
    }
  }

  const onConfirmDiscardChanges = () => {
    if (isChanged) setIsConfirmDialogueOpen(true)
  }

  const onDiscardChanges = () => {
    serviceConfigurationStore.setConfig({
      ...serviceConfigurationStore.config,
      translations: {
        ...serviceConfigurationStore.config.translations,
        customTranslations: serviceDataTranslations
      }
    })
    setIsConfirmDialogueOpen(false)
  }

  const onDeleteRow = React.useCallback(
    (originalText: string) => {
      const newTranslations = JSON.parse(JSON.stringify(translations))
      delete newTranslations[originalText]
      serviceConfigurationStore.setConfig({
        ...serviceConfigurationStore.config,
        translations: {
          ...serviceConfigurationStore.config.translations,
          customTranslations: newTranslations
        }
      })
    },
    [serviceConfigurationStore, translations]
  )

  const { gridColumns, gridRows, isChanged } = React.useMemo(() => {
    const gridColumns = getColumns(supportedLanguages ?? [], defaultLanguage)
    const gridRows = getRows(
      translations ?? {},
      supportedLanguages ?? [],
      defaultLanguage,
      onDeleteRow
    ).filter((row, idx) => {
      if (!searchQuery) return true // show all rows if no search query
      if (idx === 0) return true // show header row
      if (searchType === "status") {
        const statusCell = row.cells[1] as TextCell
        return statusCell.text.toLowerCase().includes(searchQuery?.toLowerCase() ?? "")
      }
      if (searchType === "originalText") {
        const originalCell = row.cells[0] as TextCell
        return originalCell.text.toLowerCase().includes(searchQuery?.toLowerCase() ?? "")
      }
      if (supportedLanguages?.includes(searchType)) {
        const columnIndex = gridColumns.findIndex(c => c.columnId === searchType)
        const languageCell = row.cells[columnIndex] as TextCell
        return languageCell.text.toLowerCase().includes(searchQuery?.toLowerCase() ?? "")
      }
      return false
    })

    // prettier-ignore
    const isChanged = translations && serviceDataTranslations && !deepCompare(translations, serviceDataTranslations)
    return { gridColumns, gridRows, isChanged }
  }, [
    supportedLanguages,
    defaultLanguage,
    translations,
    onDeleteRow,
    serviceDataTranslations,
    searchQuery,
    searchType
  ])

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={isConfirmDialogueOpen}
        title="Confirm discard of data"
        closeButtonLabel="No"
        onClose={() => setIsConfirmDialogueOpen(false)}
        ActionButton={
          <Button variant="contained" onClick={onDiscardChanges}>
            Yes
          </Button>
        }>
        Are you sure you want to discard the changes?
      </Dialog>
      <div className={styles.translationsEditorWrapper}>
        <div className={styles.translationEditorToolbar}>
          <div>
            <TextField
              variant="standard"
              value={searchQuery}
              placeholder="Search for text..."
              onChange={e => setSearchQuery(e.target.value)}
              className={styles.searchInput}
            />
            <Select
              variant="standard"
              value={searchType}
              onChange={e => setSearchType(e.target.value as any)}
              className={styles.searchTypeSelect}>
              <MenuItem value="originalText">Original Text</MenuItem>
              <MenuItem value="status">Status</MenuItem>
              {supportedLanguages?.map(lang => (
                <MenuItem key={lang} value={lang}>
                  {languagesMap[lang]} [{lang}]
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <Button variant="contained" disabled={!isChanged} onClick={onSaveChanges}>
              Save Changes
            </Button>
            <Button variant="contained" disabled={!isChanged} onClick={onConfirmDiscardChanges}>
              Discard Changes
            </Button>
          </div>
        </div>
        <div className={styles.translationsEditorContainer}>
          <ReactGrid
            disableVirtualScrolling
            customCellTemplates={cellTemplates}
            stickyLeftColumns={1}
            stickyTopRows={1}
            rows={gridRows}
            columns={gridColumns}
            onCellsChanged={onCellChanged}
          />
        </div>
      </div>
    </>
  )
}

export default observer(TranslationsEditor)

function getColumns(
  supportedLanguages: LanguageCodes[],
  defaultLanguage?: LanguageCodes
): Column[] {
  return [
    { columnId: "original", width: 325 },
    { columnId: "status", width: 125 },
    ...getLanguageColumns(supportedLanguages, defaultLanguage).map(lang => ({
      columnId: lang,
      width: 325
    }))
  ]
}

function getRows(
  translations: Record<string, Translation>,
  supportedLanguages: LanguageCodes[],
  defaultLanguage: LanguageCodes | undefined,
  onDeleteRow: (originalText: string) => void
): Row<DefaultCellTypes | CellWithButton>[] {
  const languageColumns = getLanguageColumns(supportedLanguages, defaultLanguage)
  return [
    {
      rowId: "0",
      cells: [
        { type: "header", text: "Original Text", className: styles.headerCell },
        { type: "header", text: "Status", className: styles.headerCell },
        ...languageColumns.map(
          code =>
            ({
              type: "header",
              text: `${languagesMap[code]} [${code}]`,
              className: styles.headerCell,
              columnId: code
            } as HeaderCell)
        )
      ]
    },
    ...Object.entries(translations)
      .sort(([, a], [, b]) => (a.isDefault ? 1 : 0) - (b.isDefault ? 1 : 0))
      .map((t, idx) => {
        const [originalText, translation] = t
        const rowId = `${idx + 1}`
        const cells: (DefaultCellTypes | CellWithButton)[] = [
          {
            type: "text",
            text: originalText,
            nonEditable: true,
            className: classes(styles.nonEditableBoldCell, styles.nonEditableCell)
          },
          {
            type: "cellWithButton",
            text: translation.status ?? (translation.isDefault ? "default" : ""),
            nonEditable: true,
            className: styles.nonEditableCell,
            onClick: () => onDeleteRow(originalText)
          },
          ...languageColumns.map(
            languageCode =>
              ({
                type: "text",
                text: translation[languageCode] ?? "",
                nonEditable: translation.status === "unused",
                columnId: languageCode,
                className:
                  translation.status === "unused"
                    ? styles.nonEditableCell
                    : translation[languageCode]
                    ? styles.editableCell
                    : styles.editableCellEmpty
              } as DefaultCellTypes)
          )
        ]

        return { rowId, height: 75, cells }
      })
  ]
}

function getLanguageColumns(
  supportedLanguages: LanguageCodes[],
  defaultLanguage?: LanguageCodes
): string[] {
  return uniquelyConcat(defaultLanguage, supportedLanguages).filter(Boolean) as LanguageCodes[]
}
