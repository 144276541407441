import React, { memo } from "react"
import Choice from "../../../../components/Choice"
import { CustomNode } from "../../CustomNode"
import styles from "./Choices.module.scss"

interface Props {
  // 👇 This needs to be more specific of a type
  data: Record<any, any>
}

function Choices(props: Props): JSX.Element {
  const { data } = props
  return (
    <CustomNode.Container id="container">
      <div className={styles.choicesContainer}>
        {data.choices.map((choice, index) => {
          return (
            <React.Fragment key={`${data.id}-choice-${index}`}>
              <Choice value={choice.body} nodeId={data.nodeId} index={index} />
            </React.Fragment>
          )
        })}
      </div>
    </CustomNode.Container>
  )
}

export default memo(Choices)
