// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lgklg_JPLqcvIpDQcQeA{opacity:0;transform:translateY(100%);transition:opacity .3s ease-in,transform .3s ease-in;pointer-events:none;position:fixed;bottom:0;left:auto;width:50%;border:1px solid red;background-color:#eac9c9;text-align:center;padding:1em}@media only screen and (max-width: 800px){.lgklg_JPLqcvIpDQcQeA{width:70%}}.zsALwE7bl9z3ybDwU5Js{pointer-events:auto;opacity:1;transform:translateY(0)}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ValidationDialogue/ValidationDialogue.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,0BAAA,CACA,oDAAA,CACA,mBAAA,CACA,cAAA,CACA,QAAA,CACA,SAAA,CACA,SAAA,CACA,oBAAA,CACA,wBAAA,CACA,iBAAA,CACA,WAAA,CACA,0CAbF,sBAcI,SAAA,CAAA,CAIJ,sBACE,mBAAA,CACA,SAAA,CACA,uBAAA","sourcesContent":[".validationErrorContainer {\n  opacity: 0;\n  transform: translateY(100%);\n  transition: opacity 0.3s ease-in, transform 0.3s ease-in;\n  pointer-events: none;\n  position: fixed;\n  bottom: 0;\n  left: auto;\n  width: 50%;\n  border: 1px solid red;\n  background-color: #eac9c9;\n  text-align: center;\n  padding: 1em;\n  @media only screen and (max-width: 800px) {\n    width: 70%;\n  }\n}\n\n.validationErrorVisible {\n  pointer-events: auto;\n  opacity: 1;\n  transform: translateY(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"validationErrorContainer": "lgklg_JPLqcvIpDQcQeA",
	"validationErrorVisible": "zsALwE7bl9z3ybDwU5Js"
};
export default ___CSS_LOADER_EXPORT___;
