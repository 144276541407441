import React from "react"
import { observer } from "mobx-react"
import { Route, Routes, Navigate } from "react-router-dom"
import Layout from "./components/Layout"
import SelectService from "./pages/SelectService"
import { useServiceStore } from "./context/rootStoreContext"
import BasicConfiguration from "./views/BasicConfiguration"
import KeepingSafe from "./views/KeepingSafe"
import ClinicalPaths from "./views/ClinicalPaths"
import BotQuestions from "./views/BotQuestions"
import Integration from "./views/Integration/Integration"

function App(): JSX.Element {
  const serviceStore = useServiceStore()
  return (
    <Layout>
      <Routes>
        <Route
          path="/*"
          element={
            serviceStore.serviceData ? (
              <Routes>
                <Route path="/" element={<BasicConfiguration />} />
                <Route path="keeping-safe" element={<KeepingSafe />} />
                <Route path="clinical-paths" element={<ClinicalPaths />} />
                <Route path="bot-questions" element={<BotQuestions />} />
                <Route path="integration" element={<Integration />} />
              </Routes>
            ) : (
              <Navigate replace to="/select-service" />
            )
          }
        />
        <Route path="select-service" element={<SelectService />} />
      </Routes>
    </Layout>
  )
}

export default observer(App)
