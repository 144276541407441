import {
  ETHNICITY,
  NATIONALITY,
  GENDER,
  SEXUALITY,
  LANGUAGE,
  DISABILITY,
  LTC,
  GenderBirthAssigned,
  ChatFlowsEnum,
  RELIGION
} from "@limbic/types"

export type OptionTypes =
  | ChatFlowsEnum.COLLECT_ETHNICITY
  | ChatFlowsEnum.COLLECT_NATIONALITY
  | ChatFlowsEnum.COLLECT_GENDER
  | ChatFlowsEnum.COLLECT_SEXUALITY
  | ChatFlowsEnum.COLLECT_LANGUAGE
  | ChatFlowsEnum.COLLECT_DISABILITY
  | ChatFlowsEnum.ASK_LONG_TERM_MEDICAL_CONDITIONS
  | ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL
  | ChatFlowsEnum.COLLECT_RELIGION

export type OptionsEthnicity = { body: string; value: keyof typeof ETHNICITY; include: boolean }[]
export type OptionsNationality = {
  body: string
  value: keyof typeof NATIONALITY
  include: boolean
}[]
export type OptionsGender = { body: string; value: keyof typeof GENDER; include: boolean }[]
export type OptionsGenderSameAsBirth = {
  body: string
  value: GenderBirthAssigned
  include: boolean
}[]
export type OptionsSexuality = { body: string; value: keyof typeof SEXUALITY; include: boolean }[]
export type OptionsReligion = { body: string; value: keyof typeof RELIGION; include: boolean }[]
export type OptionsLanguage = { body: string; value: keyof typeof LANGUAGE; include: boolean }[]
export type OptionsDisability = { body: string; value: keyof typeof DISABILITY; include: boolean }[]
export type OptionsLTC = { body: string; value: keyof typeof LTC; include: boolean }[]

export type DefaultChatFlowOptions =
  | OptionsEthnicity
  | OptionsNationality
  | OptionsGender
  | OptionsGenderSameAsBirth
  | OptionsSexuality
  | OptionsLanguage
  | OptionsDisability
  | OptionsLTC
  | OptionsReligion

export const ethnicity: OptionsEthnicity = [
  { body: "White - British", value: "WHITE_BRITISH", include: true },
  { body: "White - Irish", value: "WHITE_IRISH", include: true },
  { body: "White - American", value: "WHITE_AMERICAN", include: true },
  { body: "White - Any other White background", value: "WHITE_OTHER", include: true },
  { body: "Asian - Indian", value: "ASIAN_INDIAN", include: true },
  { body: "Asian - Pakistani", value: "ASIAN_PAKISTANI", include: true },
  { body: "Asian - Bangladeshi", value: "ASIAN_BANGLADESHI", include: true },
  { body: "Asian - Other", value: "ASIAN_OTHER", include: true },
  { body: "Asian British - Indian", value: "ASIAN_BRITISH_INDIAN", include: true },
  { body: "Asian British - Pakistani", value: "ASIAN_BRITISH_PAKISTANI", include: true },
  { body: "Asian British - Bangladeshi", value: "ASIAN_BRITISH_BANGLADESHI", include: true },
  { body: "Asian British - Other", value: "ASIAN_BRITISH_OTHER", include: true },
  { body: "Black - Caribbean", value: "BLACK_CARIBBEAN", include: true },
  { body: "Black British - Carribean", value: "BLACK_BRITISH_CARIBBEAN", include: true },
  { body: "Black - African", value: "BLACK_AFRICAN", include: true },
  { body: "Black British - African", value: "BLACK_BRITISH_AFRICAN", include: true },
  { body: "Black - American", value: "BLACK_AMERICAN", include: true },
  { body: "Black - Other", value: "BLACK_OTHER", include: true },
  { body: "Black British - Other", value: "BLACK_BRITISH_OTHER", include: true },
  { body: "Mixed - White and Asian", value: "MIXED_WHITE_ASIAN", include: true },
  { body: "Mixed - White and Black", value: "MIXED_WHITE_BLACK", include: true },
  {
    body: "Mixed - White and Black Caribbean",
    value: "MIXED_WHITE_BLACK_CARIBBEAN",
    include: true
  },
  { body: "Mixed - White and Black African", value: "MIXED_WHITE_BLACK_AFRICAN", include: true },
  { body: "Mixed - Other", value: "MIXED_OTHER", include: true },
  { body: "Chinese", value: "CHINESE", include: true },
  { body: "Gypsy/Traveller", value: "GYPSY", include: true },
  { body: "Native American", value: "NATIVE_AMERICAN", include: true },
  { body: "Other", value: "OTHER", include: true },
  { body: "Not Stated", value: "NOT_ANSWERED", include: true },
  { body: "Not known", value: "UNKNOWN", include: true }
]

export const nationality: OptionsNationality = [
  { body: "English", value: "ENGLAND", include: true },
  { body: "Scottish", value: "SCOTLAND", include: true },
  { body: "Irish", value: "IRELAND", include: true },
  { body: "Welsh", value: "WALES", include: true },
  { body: "British", value: "BRITAIN", include: true },
  { body: "Other", value: "NOT_LISTED", include: true },
  { body: "Not specified", value: "NOT_ANSWERED", include: true },
  { body: "Not known", value: "UNKNOWN", include: true }
]

export const gender: OptionsGender = [
  { body: "Male (including trans man)", value: "MALE", include: true },
  { body: "Female (including trans woman)", value: "FEMALE", include: true },
  { body: "Non-binary", value: "NON_BINARY", include: true },
  { body: "Transgender", value: "TRANSGENDER", include: true },
  { body: "Transsexual Male", value: "TRANS_MALE", include: true },
  { body: "Transsexual Female", value: "TRANS_FEMALE", include: true },
  { body: "Gender Fluid", value: "FLUID", include: true },
  { body: "Intersex", value: "INTERSEX", include: true },
  { body: "Other", value: "NOT_LISTED", include: true },
  { body: "Not known", value: "UNKNOWN", include: true },
  { body: "I don't want to say", value: "NOT_ANSWERED", include: true }
]

export const genderSameAsBirth: OptionsGenderSameAsBirth = [
  { body: "Yes", value: "YES", include: true },
  { body: "No", value: "NO", include: true },
  { body: "Not known", value: "UNKNOWN", include: true },
  { body: "I don't want to say", value: "NOT_ANSWERED", include: true }
]

export const sexuality: OptionsSexuality = [
  { body: "Heterosexual", value: "HETEROSEXUAL", include: true },
  { body: "Lesbian or Gay", value: "HOMOSEXUAL", include: true },
  { body: "Male homosexual", value: "HOMOSEXUAL_MALE", include: true },
  { body: "Female homosexual", value: "HOMOSEXUAL_FEMALE", include: true },
  { body: "Bisexual", value: "BISEXUAL", include: true },
  { body: "Asexual", value: "ASEXUAL", include: true },
  /**
   * TODO: Pansexual is not supported yet - so setting this to 'PANSEXUAL as any' for now
   * needs to be updated at a later stage when pansexual is also added to types
   */
  { body: "Pansexual", value: "PANSEXUAL" as any, include: true },
  { body: "Other", value: "NOT_LISTED", include: true },
  { body: "Not sure", value: "UNSURE", include: true },
  { body: "Not known", value: "UNKNOWN", include: true },
  { body: "I don't want to say", value: "NOT_ANSWERED", include: true }
]

export const religion: OptionsReligion = [
  { body: "None", value: "NONE", include: true },
  { body: "Agnostic", value: "AGNOSTIC", include: true },
  { body: "Baha'i", value: "BAHAI", include: true },
  { body: "Buddhist", value: "BUDDHIST", include: true },
  { body: "Christian", value: "CHRISTIAN", include: true },
  { body: "Christian Orthodox", value: "CHRISTIAN_ORTHODOX", include: true },
  { body: "Church of England", value: "CHURCH_OF_ENGLAND", include: true },
  { body: "Hindu", value: "HINDU", include: true },
  { body: "Jain", value: "JAIN", include: true },
  { body: "Jewish", value: "JEWISH", include: true },
  { body: "Jewish Orthodox", value: "JEWISH_ORTHODOX", include: true },
  { body: "Muslim", value: "MUSLIM", include: true },
  { body: "Pagan", value: "PAGAN", include: true },
  { body: "Protestan", value: "PROTESTANT", include: true },
  { body: "Rastafarian", value: "RASTAFARIAN", include: true },
  { body: "Roman Catholic", value: "ROMAN_CATHOLIC", include: true },
  { body: "Shiite Muslim", value: "SHIITE_MUSLIM", include: true },
  { body: "Sikh", value: "SIKH", include: true },
  { body: "Sunni Muslim", value: "SUNNI_MUSLIM", include: true },
  { body: "Zoroastrian", value: "ZOROASTRIAN", include: true },
  { body: "Other", value: "OTHER", include: true },
  { body: "I don't want to say", value: "DECLINE_TO_RESPOND", include: true },
  { body: "I don't want to say", value: "NOT_ANSWERED", include: true },
  { body: "Not known", value: "UNKNOWN", include: true }
]

export const language: OptionsLanguage = [
  { body: "Akan (Ashanti)", value: "AKAN", include: true },
  { body: "Albanian", value: "ALBANIAN", include: true },
  { body: "Amharic", value: "AMHARIC", include: true },
  { body: "Arabic", value: "ARABIC", include: true },
  { body: "Bengali & Sylheti", value: "BENGALI", include: true },
  { body: "Brawa & Somali", value: "BRAWA", include: true },
  { body: "British Sign Language", value: "BRITISH_SIGN_LANGUAGE", include: true },
  { body: "Bulgarian", value: "BULGARIAN", include: true },
  { body: "Cantonese", value: "CANTONESE", include: true },
  { body: "Creole", value: "CREOLE", include: true },
  { body: "Croatian", value: "CROATIAN", include: true },
  { body: "Czech", value: "CZECH", include: true },
  { body: "Dari - Afghan Persion", value: "DARI_AFGHAN_PERSIAN", include: true },
  { body: "Dutch", value: "DUTCH", include: true },
  { body: "English", value: "ENGLISH", include: true },
  { body: "Ethiopian", value: "ETHIOPIAN", include: true },
  { body: "Finnish", value: "FINNISH", include: true },
  { body: "Flemish", value: "FLEMISH", include: true },
  { body: "French", value: "FRENCH", include: true },
  { body: "French Creole", value: "FRENCH_CREOLE", include: true },
  { body: "Gaelic", value: "GAELIC", include: true },
  { body: "German", value: "GERMAN", include: true },
  { body: "Greek", value: "GREEK", include: true },
  { body: "Gujerati", value: "GUJARATI", include: true },
  { body: "Hakka", value: "HAKKA", include: true },
  { body: "Hausa", value: "HAUSA", include: true },
  { body: "Hebrew", value: "HEBREW", include: true },
  { body: "Hindi", value: "HINDI", include: true },
  { body: "Hungarian", value: "HUNGARIAN", include: true },
  { body: "Igbo (Ibo)", value: "IGBO", include: true },
  { body: "Italian", value: "ITALIAN", include: true },
  { body: "Japanese", value: "JAPANESE", include: true },
  { body: "Korean", value: "KOREAN", include: true },
  { body: "Kurdish", value: "KURDISH", include: true },
  { body: "Kurdish (Kurmanji)", value: "KURDISH_KURMANJI", include: true },
  { body: "Kurdish (Sorani)", value: "KURDISH_SORANI", include: true },
  { body: "Kutchi", value: "KUTCHI", include: true },
  { body: "Latvian", value: "LATVIAN", include: true },
  { body: "Lingala", value: "LINGALA", include: true },
  { body: "Lithuanian", value: "LITHUANIAN", include: true },
  { body: "Luganda", value: "LUGANDA", include: true },
  { body: "Makaton (Sign Language)", value: "MAKATON_SIGN_LANGUAGE", include: true },
  { body: "Malayalam", value: "MALAYALAM", include: true },
  { body: "Mandarin", value: "MANDARIN", include: true },
  { body: "Mirpuri", value: "MIRPURI", include: true },
  { body: "Nepalese", value: "NEPALESE", include: true },
  { body: "Norwegian", value: "NORWEGIAN", include: true },
  { body: "Oromo", value: "OROMO", include: true },
  { body: "Pashto", value: "PASHTO", include: true },
  { body: "Patois", value: "PATOIS", include: true },
  { body: "Persian/Farsi", value: "PERSIAN_FARSI", include: true },
  { body: "Polish", value: "POLISH", include: true },
  { body: "Portuguese", value: "PORTUGUESE", include: true },
  { body: "Punjabi", value: "PUNJABI", include: true },
  { body: "Romanian", value: "ROMANIAN", include: true },
  { body: "Russian", value: "RUSSIAN", include: true },
  { body: "Serbian", value: "SERBIAN", include: true },
  { body: "Somali", value: "SOMALI", include: true },
  { body: "Sinhala", value: "SINHALA", include: true },
  { body: "Slovak", value: "SLOVAK", include: true },
  { body: "Spanish", value: "SPANISH", include: true },
  { body: "Swahili", value: "SWAHILI", include: true },
  { body: "Swedish", value: "SWEDISH", include: true },
  { body: "Sylheti", value: "SYLHETI", include: true },
  { body: "Tagalog (Filipino)", value: "TAGALOG_FILIPINO", include: true },
  { body: "Tamil", value: "TAMIL", include: true },
  { body: "Thai", value: "THAI", include: true },
  { body: "Tigrinya", value: "TIGRINYA", include: true },
  { body: "Turkish", value: "TURKISH", include: true },
  { body: "Urdu", value: "URDU", include: true },
  { body: "Vietnamese", value: "VIETNAMESE", include: true },
  { body: "Welsh", value: "WELSH", include: true },
  { body: "Yoruba", value: "YORUBA", include: true },
  { body: "Other", value: "NOT_LISTED", include: true },
  { body: "Prefer not to say", value: "NOT_ANSWERED", include: true },
  { body: "Not known", value: "UNKNOWN", include: true }
]

export const disability: OptionsDisability = [
  { body: "None", value: "NONE", include: true },
  { body: "ADHD", value: "ADHD", include: true },
  { body: "Asperger's syndrome", value: "ASPERGER", include: true },
  { body: "Autistic spectrum disorder", value: "AUTISM", include: true },
  { body: "Behavioural and emotional", value: "BEHAVIOUR", include: true },
  { body: "Hearing", value: "HEARING", include: true },
  { body: "Manual dexterity", value: "MANUAL", include: true },
  { body: "Learning disability", value: "LEARNING", include: true },
  { body: "Physical disability", value: "MOTOR", include: true },
  { body: "Perception of physical danger", value: "PERCEPTION", include: true },
  { body: "Personal, self care & continence", value: "SELF_CARE", include: true },
  { body: "Progessive conditions & physical health", value: "PROGRESSIVE", include: true },
  { body: "Sight", value: "SIGHT", include: true },
  { body: "Speech", value: "SPEECH", include: true },
  { body: "Other", value: "NOT_LISTED", include: true },
  { body: "Not known", value: "UNKNOWN", include: true },
  { body: "Prefer not to say", value: "NOT_ANSWERED", include: true }
]

export const ltc: OptionsLTC = [
  { body: "Aids", value: "AIDS", include: true },
  { body: "Auto Immune Disease", value: "AUTO_IMMUNE", include: true },
  { body: "Arthritis", value: "ARTHRITIS", include: true },
  { body: "Asthma", value: "ASTHMA", include: true },
  { body: "Bladder/Bowel Condition", value: "BLADDER_BOWEL", include: true },
  { body: "Acquired brain injury", value: "BRAIN", include: true },
  { body: "Cancer", value: "CANCER", include: true },
  {
    body: "Cardiovascular disease (angina, heart attack, stroke, TIA)",
    value: "CARDIOVASCULAR",
    include: true
  },
  { body: "Cerebal Palsy", value: "CEREBRAL_PALSY", include: true },
  { body: "Coronary Heart Disease (CHD)", value: "CHD", include: true },
  { body: "Chronic Pain", value: "CHRONIC_PAIN", include: true },
  { body: "Chronic Muscular Skeletal", value: "CMS", include: true },
  { body: "COPD", value: "COPD", include: true },
  { body: "Crohns Disease", value: "CROHNS", include: true },
  { body: "Cystic Fibrosis", value: "CYSTIC_FIBROSIS", include: true },
  { body: "Dementia", value: "DEMENTIA", include: true },
  { body: "Diabetes", value: "DIABETES", include: true },
  { body: "Diabetes Type 1", value: "DIABETES_TYPE1", include: true },
  { body: "Diabetes Type 2", value: "DIABETES_TYPE2", include: true },
  { body: "Digestive Tract Conditions", value: "DIGESTIVE", include: true },
  { body: "Epilepsy", value: "EPILEPSY", include: true },
  { body: "Chronic Fatigue Syndrome (ME)", value: "FATIGUE", include: true },
  { body: "Fibromyalgia", value: "FIBROMYALGIA", include: true },
  { body: "FND (Functional Neurological Disorder)", value: "FND", include: true },
  { body: "Gastrointestinal Disease", value: "GASTROINTESTINAL", include: true },
  { body: "Gynaecological Disease", value: "GYNAECOLOGICAL", include: true },
  { body: "Heart Disease", value: "HEART_DISEASE", include: true },
  { body: "Heart Failure", value: "HEART_FAILURE", include: true },
  { body: "Hepatitis C", value: "HEPATITIS_C", include: true },
  { body: "HIV", value: "HIV", include: true },
  { body: "Hypertension", value: "HYPERTENSION", include: true },
  { body: "Irritable Bowel Syndrome", value: "IBS", include: true },
  { body: "Insulin-Dependent Diabetes", value: "IDDM", include: true },
  { body: "Kidney Disease", value: "KIDNEY", include: true },
  { body: "Liver Disease", value: "LIVER", include: true },
  { body: "Malignant Neoplastic Disease", value: "MALIGNANT_NEOPLASTIC_DISEASE", include: true },
  { body: "Medically unexplained symptoms", value: "MEDICALLY_UNEXPLAINED", include: true },
  { body: "Mental Health", value: "MENTAL_HEALTH", include: true },
  { body: "Migraine", value: "MIGRAINE", include: true },
  { body: "Multiple Sclerosis", value: "MS", include: true },
  // 👇 Not sure which one is the correct one (or better one)
  // { body: "Musculoskeletal Disorder (MSK)", value: "MSK", include: true },
  { body: "Musculoskeletal Disorder (MSK)", value: "MUSCULOSKELETAL", include: true },
  { body: "Neurological Disorder", value: "NEUROLOGICAL", include: true },
  { body: "Osteoporosis", value: "OSTEOPOROSIS", include: true },
  { body: "Parkinson's", value: "PARKINSON", include: true },
  { body: "Chronic Pancreatitis", value: "PANCREATITIS", include: true },
  { body: "PCOS stands for Polycystic Ovary Syndrome", value: "PCOS", include: true },
  { body: "Long covid", value: "POST_COVID", include: true },
  { body: "Other Respiratory Disease (not COPD)", value: "RESPIRATORY", include: true },
  { body: "Sickle Cell Disease", value: "SICKLE_CELL", include: true },
  { body: "Skin Condition", value: "SKIN", include: true },
  { body: "Stroke and Transient Ischaemic Attack", value: "STIA", include: true },
  { body: "Thyroid Disease", value: "THYROID", include: true },
  { body: "Tinnitus ", value: "TINNITUS", include: true },
  { body: "Urological Disorder", value: "UROLOGICAL", include: true },
  // { body: "Other", value: "NOT_LISTED", include: true }, // 👈 This should be handled in the bot
  { body: "Not known", value: "UNKNOWN", include: true },
  { body: "Prefer not to say", value: "NOT_ANSWERED", include: true }
]

const optionsMap: Record<OptionTypes | "collectGenderSecondary", DefaultChatFlowOptions> = {
  collectEthnicity: ethnicity,
  collectNationality: nationality,
  collectGender: gender,
  collectGenderSecondary: genderSameAsBirth,
  collectSexuality: sexuality,
  collectLanguage: language,
  collectDisability: disability,
  collectReligion: religion,
  askCurrentlySeeingAMentalHealthProfessional: [],
  askLongTermMedicalConditions: ltc
}

export default optionsMap
