import { createContext, PropsWithChildren, useContext } from "react"
import { RootStore } from "../stores/RootStore"
import { FlowStore } from "../stores/FlowStore"
import { NodeEditorStore } from "../stores/NodeEditorStore"
import { ServiceConfigurationStore } from "../stores/ServiceConfigurationStore"
import { ClinicalPathsConfigurationStore } from "../stores/ClinicalPathsConfigurationStore"
import { KeepingSafeEmailConfigurationStore } from "../stores/KeepingSafeEmailConfigurationStore"
import { ServiceStore } from "../stores/ServiceStore"
import { SettingsStore } from "../stores/SettingsStore"
import { BackendMappingStore } from "../stores/BackendMappingStore"
import { ServiceIntegrationStore } from "../stores/ServiceIntegrationStore"
import { ChatbotPropertiesStore } from "../stores/ChatbotPropertiesStore"

const ctx = createContext<RootStore | null>(null)

interface IRootStoreProviderProps {
  rootStore: RootStore
}

export function RootStoreProvider(props: PropsWithChildren<IRootStoreProviderProps>) {
  const { rootStore, ...restProps } = props
  return <ctx.Provider value={props.rootStore} {...restProps} />
}

export function useRootStore(): RootStore {
  const root = useContext(ctx)
  if (!root) throw new Error("Can't call useRootStore outside a provider")
  return root
}

export function useFlowStore(): FlowStore {
  const rootStore = useRootStore()
  return rootStore.flowStore
}

export function useNodeEditorStore(): NodeEditorStore {
  const rootStore = useRootStore()
  return rootStore.nodeEditorStore
}

export function useServiceStore(): ServiceStore {
  const rootStore = useRootStore()
  return rootStore.serviceStore
}

export function useSettingsStore(): SettingsStore {
  const rootStore = useRootStore()
  return rootStore.settingsStore
}

export function useServiceConfigurationStore(): ServiceConfigurationStore {
  const rootStore = useRootStore()
  return rootStore.serviceConfigurationStore
}

export function useServiceIntegrationStore(): ServiceIntegrationStore {
  const rootStore = useRootStore()
  return rootStore.serviceIntegrationStore
}

export function useClinicalPathsConfigurationStore(): ClinicalPathsConfigurationStore {
  const rootStore = useRootStore()
  return rootStore.clinicalPathsConfigurationStore
}

export function useKeepingSafeEmailConfigurationStore(): KeepingSafeEmailConfigurationStore {
  const rootStore = useRootStore()
  return rootStore.keepingSafeEmailConfigurationStore
}

export function useChatBotPropertiesStore(): ChatbotPropertiesStore {
  const rootStore = useRootStore()
  return rootStore.chatbotPropertiesStore
}

export function useBackendMappingStore(): BackendMappingStore {
  const rootStore = useRootStore()
  return rootStore.backendMappingStore
}
