import React, { useEffect } from "react"
import { observer } from "mobx-react"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"
import { AutocompleteChangeDetails, AutocompleteChangeReason, Button } from "@mui/material"
import {
  ChatFlowsEnum,
  ClinicalConditions,
  GoodbyeRecapMessageKeys,
  StateConditions,
  IGoodbyeRecapMessageConditionChain
} from "@limbic/types"
import ConditionsBuilder from "./components/ConditionsBuilder"
import deepCompare from "../../../../../../../utils/deepCompare"
import AddIcon from "@mui/icons-material/AddCircle"
import styles from "./GoodbyeRecap.module.scss"
import { DEFAULT_OPTIONS, DISALLOWED_PAIRS } from "./constants"

export type RecapMessageOptions = {
  [K in GoodbyeRecapMessageKeys]: IGoodbyeRecapMessageConditionChain
}

function GoodbyeRecap(): JSX.Element {
  const [isInitalized, setIsInitialized] = React.useState<boolean>(false)
  const [notAllowed, setNotAllowed] = React.useState<
    | {
        type: "true" | "false"
        message: GoodbyeRecapMessageKeys
        conditions: (ClinicalConditions | StateConditions)[]
      }
    | undefined
  >(undefined)
  const [conditionsExpanded, setConditionsExpanded] = React.useState<string | false>(false)
  const [recapConditions, setRecapConditions] = React.useState<Partial<RecapMessageOptions>>({
    [GoodbyeRecapMessageKeys.MESSAGE0]: JSON.parse(JSON.stringify(DEFAULT_OPTIONS))
  })

  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  useEffect(() => {
    if (
      !isInitalized &&
      chatFlowSettings[ChatFlowsEnum.GOODBYE_RECAP] &&
      chatFlowSettings[ChatFlowsEnum.GOODBYE_RECAP].conditions &&
      !deepCompare(chatFlowSettings[ChatFlowsEnum.GOODBYE_RECAP].conditions, recapConditions)
    ) {
      setRecapConditions(chatFlowSettings[ChatFlowsEnum.GOODBYE_RECAP].conditions)
      setIsInitialized(true)
    }
  }, [chatFlowSettings, recapConditions, isInitalized])

  useEffect(() => {
    const recapConditionsKeys = Object.keys(recapConditions)
    const missingConditionKeys: string[] = []
    recapConditionsKeys.forEach(key => {
      const hasTrueCondition = recapConditions[key].some(condition => condition.value === true)
      const hasFalseCondition = recapConditions[key].some(condition => condition.value === false)

      if (!hasTrueCondition && !hasFalseCondition) {
        missingConditionKeys.push(key)
      }
    })
    if (missingConditionKeys.length) {
      const reasonMap = {}
      Object.values(GoodbyeRecapMessageKeys).map(
        (value, index) => (reasonMap[value] = `SET ${index + 1}`)
      )
      const reason = `Conditions not set for: ${missingConditionKeys
        .map(key => `${reasonMap[key]}`)
        .join(", ")}. Either set true or false conditions or remove the Conditions Set`
      nodeEditorStore.setSaveButtonDisabilityStatus(!!missingConditionKeys.length, reason)
    } else {
      nodeEditorStore.setSaveButtonDisabilityStatus(false)
    }
    return () => {
      nodeEditorStore.setSaveButtonDisabilityStatus(false)
    }
  }, [recapConditions, nodeEditorStore])

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  const handleConditionsToggle =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setConditionsExpanded(newExpanded ? panel : false)
    }

  const findConditionIndex = (conditions, nameToFind, value) => {
    return (
      conditions?.findIndex(
        condition => condition.name === nameToFind && condition.value === value
      ) ?? -1
    )
  }

  const onHandleConditionChange = (
    type: true | false | "skip",
    message: GoodbyeRecapMessageKeys,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<ClinicalConditions | StateConditions> | undefined
  ) => {
    setNotAllowed(undefined)
    const conditionsWithExtraRules = [
      ClinicalConditions.IS_HIGH_RISK,
      ClinicalConditions.IS_MODERATE_RISK,
      StateConditions.NEEDS_ASSESSMENT_CALL,
      StateConditions.NEEDS_TO_CALL
    ]
    if (details?.option && conditionsWithExtraRules.includes(details.option)) {
      const messageConditions = recapConditions[message]
      const disallowedPairs = type === true ? DISALLOWED_PAIRS.allTrue : DISALLOWED_PAIRS.allFalse
      const notAllowedValue = disallowedPairs[details.option]
      const searchIndex = findConditionIndex(messageConditions, notAllowedValue, type)

      if (searchIndex >= 0 && messageConditions) {
        setNotAllowed({
          message,
          type: type ? "true" : "false",
          conditions: [messageConditions[searchIndex].name, details.option]
        })
        return
      }
    }

    const messageConditions = recapConditions[message]
    if (messageConditions) {
      const conditionIndex = messageConditions.findIndex(option => option.name === details?.option)
      const condition = JSON.parse(JSON.stringify(messageConditions[conditionIndex]))

      if (reason === "removeOption") {
        if (type === "skip") return
        condition.value = "skip"
      } else {
        condition.value = type === "skip" ? "skip" : type
      }

      const newOptions = JSON.parse(JSON.stringify(recapConditions[message]))
      newOptions[conditionIndex] = condition

      const updatedRecapConditions = {
        ...recapConditions,
        [message]: newOptions
      }

      setRecapConditions(updatedRecapConditions)

      const currentSettings = chatFlowSettings[ChatFlowsEnum.GOODBYE_RECAP] ?? {}
      const newChatFlowSettings = {
        ...chatFlowSettings,
        [ChatFlowsEnum.GOODBYE_RECAP]: {
          ...currentSettings,
          conditions: updatedRecapConditions
        }
      }
      updateState({ chatFlowSettings: newChatFlowSettings })
    }
  }

  const addNewConditionSet = () => {
    const keys = Object.keys(recapConditions)
    const lastKey = keys[keys.length - 1]
    const lastNumber = Number(lastKey.replace("conditionalRecapMessage", ""))
    const currentRecapConditions = { ...recapConditions }
    currentRecapConditions[`conditionalRecapMessage${lastNumber + 1}`] = DEFAULT_OPTIONS
    setRecapConditions(currentRecapConditions)
  }

  const removeAndRenumber = (keyToRemove: GoodbyeRecapMessageKeys) => {
    const indexToRemove = parseInt(keyToRemove.replace("conditionalRecapMessage", ""), 10)

    const newObject = {}
    let currentIndex = 0
    for (const key in recapConditions) {
      if (Object.prototype.hasOwnProperty.call(recapConditions, key)) {
        const index = parseInt(key.replace("conditionalRecapMessage", ""), 10)
        if (index === indexToRemove) continue
        newObject[`conditionalRecapMessage${currentIndex}`] = recapConditions[key]
        currentIndex++
      }
    }

    setRecapConditions(newObject)
  }

  return (
    <>
      {Object.keys(recapConditions).map((key, index) => {
        const id = key as GoodbyeRecapMessageKeys
        const msgs = messages ? messages[id] : []
        const conditions = recapConditions[id] ?? DEFAULT_OPTIONS
        const currentMessage =
          chatFlowSettings.currentMessage && chatFlowSettings.currentMessage[id]
            ? chatFlowSettings.currentMessage[id]
            : ""

        return (
          <div className={styles.recapConditionMessagesWrapper} key={`condiitonal-editor-${id}`}>
            {notAllowed && notAllowed.message === key && (
              <div className={styles.conditionCombinationNotAllowed}>
                {`The following conditions cannot be ${notAllowed.type} at the same time: `}
                <br />
                <b>
                  {notAllowed.conditions.map(
                    (condition, index) => ` ${condition}${index === 0 ? "," : ""}`
                  )}
                </b>
              </div>
            )}
            <ConditionsBuilder
              disableDelete={Object.keys(recapConditions).length < 2}
              deleteConditionsSet={removeAndRenumber}
              title={`Conditions Set - ${index + 1}`}
              conditionID={id}
              conditionsExpanded={conditionsExpanded}
              recapConditions={conditions}
              handleConditionsToggle={handleConditionsToggle}
              onHandleConditionChange={onHandleConditionChange}
            />
            <MessageList
              messages={msgs ?? []}
              messageType={id}
              chatFlowSettings={chatFlowSettings}
              updateState={updateState}
            />
            <MessageForm
              value={currentMessage}
              placeholder="Type a message here..."
              name={id}
              updateState={updateState}
              messages={msgs ?? []}
            />
          </div>
        )
      })}
      <div className={styles.addConditionButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<AddIcon />}
          className={styles.addButton}
          disabled={
            Object.keys(recapConditions).length === Object.keys(GoodbyeRecapMessageKeys).length
          }
          onClick={addNewConditionSet}>
          Add New Set of Conditions
        </Button>
      </div>
    </>
  )
}

export default observer(GoodbyeRecap)
