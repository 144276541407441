import React from "react"
import clsx from "classnames"
import { useFlowStore } from "../../../../context/rootStoreContext"
import { IFlowValidation } from "../../models/NodeEditors"
import { DiscussionSteps } from "@limbic/types"

import styles from "./ValidationDialogue.module.scss"

const parseErrors = (validity: IFlowValidation | undefined): string => {
  let text = ""
  if (!validity?.hasFlowStart) {
    text =
      text +
      "Flow start is missing. Please create a flow start node at the start of the diagram to indicate the beginning of the flow.\n"
  }
  if (!validity?.hasFlowEnd) {
    text =
      text +
      "Flow end is missing. Please create a flow end node at the end of the diagram to indicate the end of the flow.\n"
  }
  if (validity?.needsSubmitReferral) {
    text =
      text +
      "The Self Referral dialogue, requires a Submit Referral chat flow. Please create a chat flow and set it to Submit Referral.\n"
  }
  if (validity?.someNodesInvalid) {
    text =
      text +
      "There are one or more nodes with missing connecting lines. Please check for RED marked handles and create the desired connections.\n"
  }
  return text
}

interface Props {
  dialogueName: DiscussionSteps
}

function FlowArrow({ dialogueName }: Props) {
  const flowStore = useFlowStore()
  const { diagramValidity, getIsDiagramValid } = flowStore

  const readableErrors = parseErrors(diagramValidity[dialogueName]?.validation)
  const visible = !getIsDiagramValid()

  const renderedErrors = readableErrors.split("\n").map((error, index, array) => (
    <React.Fragment key={index}>
      <p>
        {error && <b>[ERROR]:</b>} {error}
      </p>
    </React.Fragment>
  ))

  return (
    <div
      className={clsx(styles.validationErrorContainer, {
        [styles.validationErrorVisible]: visible
      })}>
      {renderedErrors}
    </div>
  )
}

export default FlowArrow
