import React, { ChangeEvent, useState } from "react"
import { observer } from "mobx-react"
import { ChatFlowsEnum, IDefaultChatFlowSettings, ISelectableExtended } from "@limbic/types"
import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import ChipInput from "../../../../../ChipInput"
import { optionLabels } from "../../constants/optionLabels"
import styles from "./Options.module.scss"
import CustomizedAccordion from "../../../../../Accordion/Accordion"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"

const INLINE_PICKER_LABEL_MAP = {
  [ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL]:
    "Please add the respective health professionals (including the 'I'm not seeing a mental health professional' option)"
}

type ValueOf<T> = T[keyof T]
type ChatFlows = ValueOf<typeof ChatFlowsEnum>

interface Props {
  options: ISelectableExtended[]
  secondaryOptions?: ISelectableExtended[]
  chatFlow: ChatFlows
  chatFlowSettings: IDefaultChatFlowSettings
}

function Options(props: Props): JSX.Element {
  const { options, secondaryOptions, chatFlow, chatFlowSettings } = props
  const [expanded, setExpanded] = useState<string | false>("panel1")
  const [isSelectAllPrimary, setIsSelectAllPrimary] = useState<boolean>(true)
  const [isSelectAllSecondary, setIsSelectAllSecondary] = useState<boolean>(false)
  const nodeEditorStore = useNodeEditorStore()

  const onCheckboxChange = (
    value: boolean,
    optionValue: string,
    type: "primary" | "secondary"
  ): void => {
    let newOptions
    let key: "choicesMap" | "secondaryChoicesMap" = "choicesMap"

    if (type === "secondary") {
      key = "secondaryChoicesMap"
      newOptions = [...secondaryOptions!].map(option =>
        option.value === optionValue ? { ...option, include: value } : { ...option }
      )
      if (newOptions.every(option => option.include)) {
        setIsSelectAllSecondary(true)
      } else {
        setIsSelectAllSecondary(false)
      }
    } else {
      newOptions = [...options!].map(option =>
        option.value === optionValue ? { ...option, include: value } : { ...option }
      )
      if (newOptions.every(option => option.include)) {
        setIsSelectAllPrimary(true)
      } else {
        setIsSelectAllPrimary(false)
      }
    }

    nodeEditorStore.updateChatFlowState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [chatFlow]: {
          ...chatFlowSettings[chatFlow],
          [key]: newOptions
        }
      }
    })
  }

  const onInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    optionValue: string,
    type: "primary" | "secondary"
  ): void => {
    const value = event.target.value
    let newOptions
    let key: "choicesMap" | "secondaryChoicesMap" = "choicesMap"
    if (type === "secondary") {
      key = "secondaryChoicesMap"
      newOptions = [...secondaryOptions!].map(option =>
        option.value === optionValue ? { ...option, body: value } : { ...option }
      )
    } else {
      newOptions = [...options!].map(option =>
        option.value === optionValue ? { ...option, body: value } : { ...option }
      )
    }

    nodeEditorStore.updateChatFlowState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [chatFlow]: {
          ...chatFlowSettings[chatFlow],
          [key]: newOptions
        }
      }
    })
  }

  const handleAnswerAdd = value => {
    const choicesMap = chatFlowSettings[ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL]?.choicesMap
    const currentChoicesMap = choicesMap && choicesMap.length ? choicesMap : []
    const updatedOptions = [...currentChoicesMap, { body: value, value, include: true }]
    nodeEditorStore.updateChatFlowState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL]: {
          ...chatFlowSettings[chatFlow],
          choicesMap: updatedOptions
        }
      }
    })
  }

  const handleAnswerDelete = (value: string): void => {
    const updatedOptions = chatFlowSettings[
      ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL
    ]?.choicesMap!.filter(option => option.body !== value)
    nodeEditorStore.updateChatFlowState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL]: {
          ...chatFlowSettings[chatFlow],
          choicesMap: updatedOptions
        }
      }
    })
  }

  const handleSelectAllPrimaryCheckbox = (): void => {
    const newOptions = options.map(option => ({ ...option, include: !isSelectAllPrimary }))
    nodeEditorStore.updateChatFlowState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [chatFlow]: {
          ...chatFlowSettings[chatFlow],
          choicesMap: newOptions
        }
      }
    })
    setIsSelectAllPrimary(!isSelectAllPrimary)
  }

  const handleSelectAllSecondaryCheckbox = (): void => {
    const newOptions = secondaryOptions?.map(option => ({
      ...option,
      include: !isSelectAllSecondary
    }))
    nodeEditorStore.updateChatFlowState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [chatFlow]: {
          ...chatFlowSettings[chatFlow],
          secondaryChoicesMap: newOptions
        }
      }
    })
    setIsSelectAllSecondary(!isSelectAllSecondary)
  }

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <div className={styles.defaultChatFlowOptionsContainer}>
      {chatFlow === ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL ? (
        <div>
          <Typography>{optionLabels[chatFlow]} Options</Typography>
          <ChipInput
            options={options && options.length ? options.map(c => c.body!) : []}
            onAdd={handleAnswerAdd}
            onDelete={handleAnswerDelete}
            label="Answers"
            placeholder="Type the possible answers here..."
          />
          <Typography variant="subtitle2">{INLINE_PICKER_LABEL_MAP[chatFlow]}</Typography>
        </div>
      ) : (
        <>
          <div className={styles.optionsContainer}>
            <CustomizedAccordion
              summary={`${optionLabels[chatFlow]} Options`}
              expanded={expanded}
              onHandlePanelChange={handleAccordionChange}
              panel="panel1">
              <>
                <div className={styles.checkUncheckAllContainer}>
                  <FormControlLabel
                    className={styles.formControlLabel}
                    value={isSelectAllPrimary}
                    control={
                      <Checkbox
                        checked={isSelectAllPrimary}
                        onChange={(_event, value) => handleSelectAllPrimaryCheckbox()}
                      />
                    }
                    label={isSelectAllPrimary ? "Uncheck all" : "Check all"}
                    labelPlacement="start"
                  />
                </div>
                {options.map((option, index) => (
                  <div
                    className={styles.optionBlock}
                    key={`options-${chatFlow}-${option.value}-${index}`}>
                    <FormControlLabel
                      className={styles.formControlLabel}
                      value={option.value}
                      control={
                        <Checkbox
                          checked={option.include}
                          onChange={(_event, value) =>
                            onCheckboxChange(value, option.value, "primary")
                          }
                        />
                      }
                      label={
                        <input
                          className={styles.labelInput}
                          value={option.body}
                          onChange={event => onInputChange(event, option.value, "primary")}
                        />
                      }
                      labelPlacement="start"
                    />
                    <span style={{ width: "310px" }}>
                      <b>Value:</b> {option.value}
                    </span>
                  </div>
                ))}
              </>
            </CustomizedAccordion>
          </div>
          <div className={styles.optionsContainer}>
            {secondaryOptions?.length && (
              <CustomizedAccordion
                summary={`${optionLabels[`${chatFlow}Secondary`]} Options`}
                expanded={expanded}
                onHandlePanelChange={handleAccordionChange}
                panel="panel2">
                <>
                  <div className={styles.checkUncheckAllContainer}>
                    <FormControlLabel
                      className={styles.formControlLabel}
                      value={isSelectAllSecondary}
                      control={
                        <Checkbox
                          checked={isSelectAllSecondary}
                          onChange={(_event, value) => handleSelectAllSecondaryCheckbox()}
                        />
                      }
                      label={isSelectAllSecondary ? "Uncheck all" : "Check all"}
                      labelPlacement="start"
                    />
                  </div>
                  {secondaryOptions?.map(option => (
                    <div
                      className={styles.optionBlock}
                      key={`secondary-options-${chatFlow}-${option.value}`}>
                      <FormControlLabel
                        className={styles.formControlLabel}
                        value={option.value}
                        control={
                          <Checkbox
                            checked={option.include}
                            onChange={(_event, value) =>
                              onCheckboxChange(value, option.value, "secondary")
                            }
                          />
                        }
                        label={
                          <input
                            className={styles.labelInput}
                            value={option.body}
                            onChange={event => onInputChange(event, option.value, "secondary")}
                          />
                        }
                        labelPlacement="start"
                      />
                      <span style={{ width: "310px" }}>
                        <b>Value:</b> {option.value}
                      </span>
                    </div>
                  ))}
                </>
              </CustomizedAccordion>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default observer(Options)
