import React from "react"
import { observer } from "mobx-react"
import { Button as MuiButton, Checkbox, TextField } from "@mui/material"
import { ClinicalFlags, ProblemCategories } from "@limbic/types"
import IconButton from "@mui/material/IconButton"
import CancelIcon from "@mui/icons-material/Cancel"
import AddIcon from "@mui/icons-material/AddCircle"
import AutoComplete from "./components/AutoComplete"
import styles from "./CustomPaths.module.scss"
import { useClinicalPathsConfigurationStore } from "../../../../context/rootStoreContext"
import classNames from "classnames"

interface Props {
  onAddCustomClinicalPath: () => void
  onRemoveCustomClinicalPath: (idx: number) => void
  onChangeCustomClinicalPathReferralType: (idx: number, referralType?: string) => void
  onChangeCustomClinicalPathPrimaries: (idx: number, primaries?: ProblemCategories[]) => void
  onChangeCustomClinicalPathSecondaries: (idx: number, secondaries?: ProblemCategories[]) => void
  onChangeCustomClinicalPathEnableSecondaries: (idx: number, checked: boolean) => void
  onChangeCustomClinicalPathFlags: (idx: number, flags?: ClinicalFlags | "ALL" | "NONE") => void
}

function CustomPaths(props: Props): JSX.Element {
  const clinicalPathsConfigurationStore = useClinicalPathsConfigurationStore()

  return (
    <>
      {clinicalPathsConfigurationStore.customPaths.map((path, index) => (
        <React.Fragment key={index}>
          <div className={styles.fieldsContainer}>
            <div className={styles.fieldContainer}>
              <div className={styles.labelContainer}>
                <label htmlFor={`Primary-${index}`}>Primary Problem(s)</label>
              </div>
              <AutoComplete
                multiple
                disableCloseOnSelect
                type="primaryProblems"
                value={clinicalPathsConfigurationStore.customPaths[index].primaryProblems}
                input={clinicalPathsConfigurationStore.customPaths[index]}
                testId={`custom-clinical-path-input-primary-${index}`}
                onChange={(value?: ProblemCategories[]) => {
                  props.onChangeCustomClinicalPathPrimaries(index, value)
                }}
              />
            </div>

            <div className={styles.fieldContainer}>
              <div className={styles.labelContainer}>
                <Checkbox
                  checked={clinicalPathsConfigurationStore.customPaths[index].enableSecondary}
                  onChange={event =>
                    props.onChangeCustomClinicalPathEnableSecondaries(index, event.target.checked)
                  }
                />
                <label htmlFor={`Secondary-${index}`}>Secondary Problem(s)</label>
              </div>
              <div
                className={classNames({
                  [styles.disabled]:
                    !clinicalPathsConfigurationStore.customPaths[index].enableSecondary
                })}>
                <AutoComplete
                  multiple
                  disableCloseOnSelect
                  type="secondaryProblems"
                  value={clinicalPathsConfigurationStore.customPaths[index].secondaryProblems}
                  disabled={!clinicalPathsConfigurationStore.customPaths[index].enableSecondary}
                  input={clinicalPathsConfigurationStore.customPaths[index]}
                  testId={`custom-clinical-path-input-secondary-${index}`}
                  onChange={(value?: ProblemCategories[]) => {
                    props.onChangeCustomClinicalPathSecondaries(index, value)
                  }}
                />
              </div>
            </div>

            <div className={styles.fieldContainer}>
              <div className={styles.labelContainer}>
                <label htmlFor={`Functional-Impairment-${index}`}>Flags</label>
              </div>
              <AutoComplete
                type="flags"
                value={clinicalPathsConfigurationStore.customPaths[index].flags}
                testId={`custom-clinical-path-input-flags-${index}`}
                onChange={(value?: ClinicalFlags | "ALL" | "NONE") => {
                  props.onChangeCustomClinicalPathFlags(index, value)
                }}
              />
            </div>

            <div className={styles.fieldContainer}>
              <div className={styles.labelContainer}>
                <label htmlFor={`Referral-Type-${index}`}>Referral Type</label>
              </div>
              <TextField
                name={`Referral-Type-${index}`}
                value={clinicalPathsConfigurationStore.customPaths[index].referralType}
                placeholder="Add a referral type"
                className={styles.textField}
                onChange={e => props.onChangeCustomClinicalPathReferralType(index, e.target.value)}
              />
            </div>

            <div className={styles.deletePathButtonContainer}>
              <div className={styles.labelContainer} />
              <IconButton
                data-testid={`custom-clinical-path-remove-row-${index}`}
                className={styles.deletePathButton}
                onClick={() => props.onRemoveCustomClinicalPath(index)}>
                <CancelIcon />
              </IconButton>
            </div>
          </div>
          {!clinicalPathsConfigurationStore.customPaths[index].valid && (
            <label className={styles.invalidInputs}>All fields must be completed</label>
          )}
        </React.Fragment>
      ))}

      <div className={styles.addPathButtonContainer}>
        <MuiButton onClick={props.onAddCustomClinicalPath}>
          <AddIcon /> New Path
        </MuiButton>
      </div>
    </>
  )
}

export default observer(CustomPaths)
