import React, { useMemo, useState } from "react"
import { observer } from "mobx-react"
import classes from "clsx"
import { Autocomplete, Button, Chip, FormControl, TextField } from "@mui/material"
import { LanguageCodes, languagesMap } from "@limbic/types"
import TranslationsEditor from "./components/TranslationsEditor"
import Dialog from "../../../../components/Dialog"
import styles from "./TranslationsConfiguration.module.scss"
import { useServiceConfigurationStore, useServiceStore } from "../../../../context/rootStoreContext"
import deepCompare from "../../../../../utils/deepCompare"

const LANGUAGE_OPTIONS = Object.values(LanguageCodes)

interface Props {
  setError: (value: boolean) => void
}

function TranslationsConfiguration(props: Props): JSX.Element {
  const serviceStore = useServiceStore()
  const serviceConfig = useServiceConfigurationStore()
  const [isConfirmDialogueOpen, setIsConfirmDialogueOpen] = useState(false)
  const [defaultLanguage, setDefaultLanguage] = useState(serviceConfig.defaultLanguage)
  const [supportedLanguages, setSupportedLanguages] = useState(serviceConfig.supportedLanguages)

  const translations = serviceConfig.config.translations?.customTranslations
  const serviceDataTranslations = serviceStore.modeConfig?.translations?.customTranslations
  const showTranslationsEditor = !serviceStore.isLoading && !!supportedLanguages.length
  const showNoSupportedLanguages = !serviceStore.isLoading && !supportedLanguages.length

  const isChanged = useMemo(
    () =>
      translations &&
      serviceDataTranslations &&
      !deepCompare(translations, serviceDataTranslations),
    [serviceDataTranslations, translations]
  )

  const onConfirmUpdateLanguages = () => {
    if (isChanged) {
      setIsConfirmDialogueOpen(true)
    } else {
      void onUpdateLanguages()
    }
  }

  const onUpdateLanguages = async () => {
    try {
      await serviceStore.updateTranslationLanguages(defaultLanguage, supportedLanguages)
    } catch (error) {
      props.setError(true)
      console.error(error)
    }
  }

  return (
    <div className={styles.translationsConfigurationContainer}>
      <Dialog
        maxWidth="sm"
        ActionButton={
          <Button variant="contained" color="error" onClick={onUpdateLanguages}>
            Continue
          </Button>
        }
        open={isConfirmDialogueOpen}
        title="Translations have unsaved changes"
        onClose={() => setIsConfirmDialogueOpen(false)}>
        There seem to be unsaved changes in the translations. If you continue before saving them,
        the changes will be lost. Are you sure you want to continue?
      </Dialog>
      <div className={styles.languages}>
        <FormControl className={classes(styles.formControl, styles.defaultLanguage)}>
          <Autocomplete
            className={styles.autocompleteOptions}
            multiple={false}
            id="selected-default-language"
            onChange={(_, v) => setDefaultLanguage(v ?? LanguageCodes.EN)}
            options={LANGUAGE_OPTIONS}
            getOptionLabel={code => `${languagesMap[code]} [${code}]`}
            value={defaultLanguage}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Default Language"
                placeholder="Please select the default language for the bot"
              />
            )}
          />
        </FormControl>
        <FormControl className={styles.formControl}>
          <Autocomplete
            className={styles.autocompleteOptions}
            multiple
            id="supported-languages"
            onChange={(_, v) => setSupportedLanguages(v)}
            options={LANGUAGE_OPTIONS.filter(code => code !== defaultLanguage)}
            getOptionLabel={code => `${languagesMap[code]} [${code}]`}
            renderTags={(value: LanguageCodes[], getTagProps) =>
              value.map((code: LanguageCodes, index: number) => {
                return (
                  <Chip
                    variant="outlined"
                    label={`${languagesMap[code]} [${code}]`}
                    {...getTagProps({ index })}
                    className={styles.optionChip}
                  />
                )
              })
            }
            value={supportedLanguages}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Supported languages"
                placeholder="Add the languages that the bot should support i.e. English, Spanish"
              />
            )}
          />
        </FormControl>
        <Button variant="contained" color="primary" onClick={onConfirmUpdateLanguages}>
          Update
        </Button>
      </div>

      {showTranslationsEditor && <TranslationsEditor setError={props.setError} />}
      {showNoSupportedLanguages && "No supported languages configured"}
    </div>
  )
}

export default observer(TranslationsConfiguration)
