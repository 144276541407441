import React, { useState } from "react"

import styles from "./CCGExplainer.module.scss"
import Dialog from "../../../../../../../../components/Dialog"
import { TextField, Tooltip } from "@mui/material"
import { CCGCodes } from "@limbic/types"

interface Props {
  index: number
  isOpen: boolean
  onClose: () => void
  onCCGClick: (key: string, index: number) => void
}

function CCGExplainer(props: Props): JSX.Element {
  const [ccgSearchCode, setCCGSearchCode] = useState<string>("")

  const handleSearchCodeChange = e => {
    setCCGSearchCode(e.target.value)
  }

  return (
    <Dialog onClose={props.onClose} open={props.isOpen} title="CCG Codes" maxWidth="sm">
      <div className={styles.ccgCodesWrapper}>
        <div className={styles.ccgCodesSearchInputContainer}>
          <TextField placeholder="Search by Code" onChange={e => handleSearchCodeChange(e)} />
        </div>
        <div style={{ margin: "64px 0", minWidth: "100%" }}>
          {Object.keys(CCGCodes)
            .filter(key => {
              if (ccgSearchCode.length < 2) return key
              return (
                ccgSearchCode.length > 1 &&
                String(CCGCodes[key]).toLowerCase().includes(ccgSearchCode.toLowerCase())
              )
            })
            .map((key, index) => (
              <Tooltip
                key={`${key}_${index}`}
                title={`Click to add CCG with code: ${CCGCodes[key]}`}>
                <div
                  onClick={() => props.onCCGClick(key, props.index)}
                  className={styles.ccgCodesContainer}
                  style={{
                    backgroundColor: index % 2 === 0 ? "lightgray" : "white"
                  }}>
                  <div className={styles.ccgKeyLabel}>{key}</div>
                  <div className={styles.ccgCodeLabel}>{CCGCodes[key]}</div>
                </div>
              </Tooltip>
            ))}
        </div>
      </div>
    </Dialog>
  )
}

export default CCGExplainer
