import React, { useMemo, useState } from "react"
import { observer } from "mobx-react"
import { useServiceConfigurationStore, useServiceStore } from "../../../../context/rootStoreContext"
import Button from "../../../../components/Button"
import deepCompare from "../../../../../utils/deepCompare"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import styles from "./EligibilityConfiguration.module.scss"
import { EligibilityType } from "@limbic/types"
import IAPTConfigurator from "./components/IAPTConfigurator"

interface Props {
  setError: (value: boolean) => void
}

function EligibilityConfiguration(props: Props): JSX.Element {
  const serviceStore = useServiceStore()
  const serviceConfigurationStore = useServiceConfigurationStore()
  const serviceData = serviceStore.serviceData?.[serviceStore.mode]

  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const isChanged = useMemo(() => {
    return !deepCompare(serviceData?.eligibility ?? {}, serviceConfigurationStore.eligibility)
  }, [serviceConfigurationStore.eligibility, serviceData?.eligibility])

  const onSubmitChanges = async () => {
    try {
      await serviceStore.updateServiceEligibilityConfiguration(
        serviceConfigurationStore.eligibility
      )
    } catch (e) {
      props.setError(true)
      // TODO: report exception to sentry
      console.log({ e })
    }
  }

  const onDiscardChanges = () => {
    if (isChanged) {
      serviceConfigurationStore.setEligibilityConfig(
        serviceStore.serviceData?.[serviceStore.mode]?.eligibility?.dashboard ?? {}
      )
    } else {
      serviceConfigurationStore.resetEligibility()
    }
  }

  const onInputChange = (value: EligibilityType) => {
    serviceConfigurationStore.setEligibilityType(value)
  }

  return (
    <div className={styles.eligibilityConfigurationContainer}>
      <div className={styles.eligibilityMainConfiguration}>
        <FormControl className={styles.inputContainer}>
          <InputLabel htmlFor="eligibilityType">Type of Eligibility</InputLabel>
          <Select
            name="eligibilityType"
            label="Type of Eligibility"
            value={serviceConfigurationStore.eligibility.type}
            onChange={e => onInputChange(e.target.value as EligibilityType)}>
            <MenuItem key="nhsEligibility" value="nhs" className={styles.eligibilityType}>
              NHS
            </MenuItem>
          </Select>
        </FormControl>
        <IAPTConfigurator saveDisabled={value => setHasErrors(value)} />
      </div>
      <div className={styles.eligibilityConfigurationButtons}>
        <Button onClick={onSubmitChanges} disabled={!isChanged || hasErrors}>
          {serviceStore.isLoading ? "Updating..." : "Save Changes"}
        </Button>
        <Button onClick={onDiscardChanges} disabled={!isChanged}>
          Discard Changes
        </Button>
      </div>
    </div>
  )
}

export default observer(EligibilityConfiguration)
