import React from "react"
import { observer } from "mobx-react"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material"
import classes from "clsx"
import { ChatFlowsEnum } from "@limbic/types"
import styles from "./ServiceSearch.module.scss"

function ServiceSearch(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  const handleNumberChange = (
    event: SelectChangeEvent<number>,
    key: "retryPostcodeTimes" | "retryGPTimes"
  ) => {
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [ChatFlowsEnum.SERVICE_SEARCH]: {
          ...chatFlowSettings[ChatFlowsEnum.SERVICE_SEARCH],
          [key]: event.target.value
        }
      }
    })
  }

  return (
    <>
      <Typography align="right" className={styles.serviceSearchTitle} variant="subtitle1">
        Opening message explaining the procedure for the eligibility check:
      </Typography>
      <MessageList
        messages={messages?.askYourAreaOrGPPostCode ?? []}
        messageType="askYourAreaOrGPPostCode"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askYourAreaOrGPPostCode}
        placeholder="Type a message here..."
        name="askYourAreaOrGPPostCode"
        updateState={updateState}
        messages={messages?.askYourAreaOrGPPostCode ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.serviceSearchTitle} variant="subtitle1">
        Bot message to ask for user's postcode:
      </Typography>
      <MessageList
        messages={messages?.askPostCodeOfUser ?? []}
        messageType="askPostCodeOfUser"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askPostCodeOfUser}
        placeholder="Type a message here..."
        name="askPostCodeOfUser"
        updateState={updateState}
        messages={messages?.askPostCodeOfUser ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="enableReferAnyway"
        label="Continue with referral even if the user is not eligible"
        labelPlacement="start"
        updateState={updateState}
      />
      <Typography
        align="right"
        className={classes(styles.serviceSearchTitle, {
          [styles.serviceSearchTitleDisabled]: !chatFlowSettings.serviceSearch?.enableReferAnyway
        })}
        variant="subtitle1">
        Bot message in case that the GP was not possible to be identified:
      </Typography>
      <MessageList
        disabled={!chatFlowSettings.serviceSearch?.enableReferAnyway}
        messages={messages?.askWantMeToReferYouAnyway ?? []}
        messageType="askWantMeToReferYouAnyway"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={!chatFlowSettings.serviceSearch?.enableReferAnyway}
        value={chatFlowSettings.currentMessage?.askWantMeToReferYouAnyway}
        placeholder="Type a message here..."
        name="askWantMeToReferYouAnyway"
        updateState={updateState}
        messages={messages?.askWantMeToReferYouAnyway ?? []}
      />
      <SolidDivider />
      <div className={styles.retriesNumberSelect}>
        <Typography>Number of attempts to check a failed postcode</Typography>
        <FormControl>
          <InputLabel id="retry-postcode-times-label">Retries</InputLabel>
          <Select
            className={styles.retriesSelector}
            labelId="retry-postcode-times-label"
            id="retry-postcode-times-select"
            value={chatFlowSettings.serviceSearch?.retryPostcodeTimes ?? 3}
            label="Retries"
            onChange={event => handleNumberChange(event, "retryPostcodeTimes")}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
      </div>
      <SolidDivider />
      <div className={styles.retriesNumberSelect}>
        <Typography>Number of attempts to check a failed GP search</Typography>
        <FormControl>
          <InputLabel id="retry-gp-times-label">Retries</InputLabel>
          <Select
            className={styles.retriesSelector}
            labelId="retry-gp-times-label"
            id="retry-gp-times-select"
            value={chatFlowSettings.serviceSearch?.retryGPTimes ?? 3}
            label="Retries"
            onChange={event => handleNumberChange(event, "retryGPTimes")}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
      </div>
    </>
  )
}

export default observer(ServiceSearch)
