import { createTheme } from "@mui/material/styles"

const limbicColor = "#fd81a0"
// eslint-disable-next-line
const limbicColorRGB = "rgb(253, 129, 160)"
const limbicColorRGBHover = "rgb(177, 90, 112)"
const limbicError = "red"
const limbicWhite = "#ffffff"
const limbicBlack = "#000000"

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#303030"
    },
    secondary: {
      main: limbicColor
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: limbicColor,
          borderRadius: 12,
          fontSize: 14,
          fontWeight: 600,
          margin: 4,
          padding: "4px 24px",
          color: limbicWhite,
          "&:hover": {
            backgroundColor: limbicColorRGBHover
          }
        },
        containedSecondary: {
          backgroundColor: limbicWhite,
          borderRadius: 12,
          fontSize: 14,
          fontWeight: 600,
          margin: 4,
          padding: "4px 24px",
          color: limbicBlack,
          "&:hover": {
            color: limbicWhite
          }
        },
        containedError: {
          backgroundColor: limbicError,
          borderRadius: 12,
          fontSize: 14,
          fontWeight: 600,
          margin: 4,
          padding: "4px 24px",
          color: limbicWhite,
          "&:hover": {
            color: limbicWhite
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 16
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: limbicColor
        }
      }
    }
  },
  typography: {
    body1: {
      fontWeight: 100,
      wordBreak: "break-word"
    },
    fontFamily: [
      "Aeroport",
      "Roboto",
      "-apple-system",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  }
})

export default theme
