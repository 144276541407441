import React from "react"
import { observer } from "mobx-react"
import {
  useServiceConfigurationStore,
  useServiceStore
} from "../../../../../../context/rootStoreContext"
import classes from "clsx"
import limbicLogo from "../../../../../../assets/bot-logo.png"
import ClickToAddIcon from "../../../../../../assets/click-to-add.png"

import styles from "./SideBarPreview.module.scss"

function SideBarPreview(): JSX.Element {
  const serviceConfigurationStore = useServiceConfigurationStore()
  const serviceStore = useServiceStore()
  const serviceData = serviceStore.serviceData?.[serviceStore.mode]
  const orgIcon = serviceConfigurationStore?.bigBot.sidebarIAPTIcon

  return (
    <div className={styles.lbSidebarWrapper}>
      <div className={styles.lbSidebarContainer}>
        <article className={styles.lbSidebarCard}>
          <div className={styles.lbHeader}>
            <img
              src={serviceData?.configuration?.logo ?? limbicLogo}
              className={styles.lbHeaderImg}
              alt=""
            />
            <h3 className="subtitle">{serviceConfigurationStore?.bigBot?.sidebarTitle}</h3>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: serviceConfigurationStore?.bigBot?.sidebarText ?? ""
            }}
          />
          {orgIcon && orgIcon !== ClickToAddIcon && (
            <img className={styles.nhsLogo} src={orgIcon} alt="Organisation Icon" />
          )}
        </article>
        <div className={styles.lbSidebarSpacer} />
        <article className={classes(styles.lbSidebarCard, styles.fullHeight)}>
          <p>
            Limbic helps people access psychological support quicker and easier. Your data is
            secured according to the highest NHS standards.
          </p>
          <img
            className={styles.badges}
            src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
            alt={"Badges"}
          />
        </article>
      </div>
    </div>
  )
}

export default observer(SideBarPreview)
