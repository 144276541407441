// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zEvihxH5zsU0hrm6uJTw{width:100%}.YBEyHkLYWHmOw1qrrLwE{margin:1em 0}.k8OQmnxtsseqhWrbcpvG{height:360px}", "",{"version":3,"sources":["webpack://./src/app/pages/SelectService/components/CreateService/CreateService.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,YAAA,CAGF,sBACE,YAAA","sourcesContent":[".formControlCreateServiceInput {\n  width: 100%;\n}\n\n.createServiceInputContainer {\n  margin: 1em 0;\n}\n\n.createServiceWrapper {\n  height: 360px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formControlCreateServiceInput": "zEvihxH5zsU0hrm6uJTw",
	"createServiceInputContainer": "YBEyHkLYWHmOw1qrrLwE",
	"createServiceWrapper": "k8OQmnxtsseqhWrbcpvG"
};
export default ___CSS_LOADER_EXPORT___;
