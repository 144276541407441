import React from "react"
import classes from "clsx"
import styles from "./DummyInput.module.scss"
import { useFlowStore, useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import { EditorType, PromptType } from "../../../../models/NodeEditors"
import { Checkbox } from "@mui/material"
import { CSSProperties } from "@stitches/react"
import CrisisIndicator from "../../../CrisisIndicator"
import { IDashboardNode } from "@limbic/types"

type DummyPromptType = Exclude<
  PromptType,
  | PromptType.Checkbox
  | PromptType.InlinePicker
  | PromptType.InlinePickerMultiSelect
  | PromptType.Message
>

interface Props {
  nodeId?: string
  promptType: PromptType
  options?: string[]
  size?: "small" | "large"
  css?: CSSProperties
  crisisEnabled?: boolean
  hasTextPromptWithInlinePicker?: boolean
}

function DummyInput(props: Props): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const flowStore = useFlowStore()
  const { questionEditorState } = nodeEditorStore
  const { checkboxOptions } = questionEditorState.promptSettings
  const {
    nodeId,
    promptType,
    options,
    size = "large",
    css,
    crisisEnabled,
    hasTextPromptWithInlinePicker
  } = props

  const textMap: Record<DummyPromptType, string> = {
    text: "Free Text Input",
    phoneNumber: "Phone Number Input",
    name: "Name Input",
    email: "Email Input",
    date: "Date Input"
  }

  const text = textMap[promptType]
  const isCheckBox = promptType === "checkbox" && options?.length
  const showCrisisIndicator =
    (crisisEnabled && promptType === "text") || (crisisEnabled && hasTextPromptWithInlinePicker)

  const handleCheckbox = (option: string): void => {
    const checkboxOptions = questionEditorState.promptSettings.checkboxOptions
    const updatedCheckboxOptions = checkboxOptions.map(o => {
      if (o.body === option) return { ...o, initialValue: !o.initialValue }
      return o
    })
    nodeEditorStore.updateState(EditorType.QUESTION, {
      ...questionEditorState,
      promptSettings: {
        ...questionEditorState.promptSettings,
        checkboxOptions: updatedCheckboxOptions
      }
    })
  }

  const updateCrisisNextQuestion = (value: boolean) => {
    const nodes = JSON.parse(JSON.stringify(flowStore.nodes))
    const nodeIndex = nodes.findIndex(n => n.id === props.nodeId)
    const node: IDashboardNode = JSON.parse(JSON.stringify(nodes[nodeIndex]))
    const updatedNode: IDashboardNode = {
      ...node,
      settings: {
        ...node.settings,
        promptSettings: {
          ...node.settings?.promptSettings,
          crisisShouldRepeatQuestion: value
        }
      }
    }

    nodes[nodeIndex] = updatedNode
    flowStore.setNodes(nodes)
  }

  return (
    <div
      className={classes(styles.dummyInputContainer, {
        [styles.hideDummyInput]: !text && !isCheckBox && !hasTextPromptWithInlinePicker
      })}
      style={css}>
      {isCheckBox && (
        <div className={styles.checkboxOptions}>
          {options?.map((option, index) => {
            const isChecked =
              checkboxOptions.length && checkboxOptions.find(o => o.body === option)?.initialValue
            return (
              <div key={option + index} className={styles.checkboxOption}>
                <Checkbox onChange={() => handleCheckbox(option)} checked={!!isChecked} />
                <span>{option}</span>
              </div>
            )
          })}
        </div>
      )}
      <div className={classes({ [styles.withCrisis]: showCrisisIndicator })}>
        <div
          className={classes(styles.dummyInput, {
            [styles.dummyInputSmall]: size === "small",
            [styles.checkboxInput]: isCheckBox,
            [styles.dummyInputWithCrisis]: showCrisisIndicator
          })}>
          {hasTextPromptWithInlinePicker ? textMap["text"] : text}
        </div>
        {showCrisisIndicator && (
          <CrisisIndicator
            crisisShouldRepeatQuestion={
              !!flowStore.nodes.find(n => n.id === props.nodeId)?.settings?.promptSettings
                ?.crisisShouldRepeatQuestion
            }
            onCrisisCheckboxToggle={updateCrisisNextQuestion}
            nodeId={nodeId!}
            value="Crisis"
            index={0}
          />
        )}
      </div>
    </div>
  )
}

export default DummyInput
