import React from "react"
import styles from "./SnippetItem.module.scss"
import InputLabel from "../../views/BasicConfiguration/components/InputLabel"
import CodeMirror from "@uiw/react-codemirror"
import { html } from "@codemirror/lang-html"
import { IconButton } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { Snippet } from "@limbic/types"
import { observer } from "mobx-react"

interface SnippetItemProps {
  snippet: Snippet
  onEdit: () => void
  onDelete: () => void
}

const SnippetItem: React.FC<SnippetItemProps> = ({ snippet, onEdit, onDelete }) => (
  <div className={styles.snippetItem}>
    <div>
      <InputLabel label={`Positioned in ${snippet.position}`} />
      <CodeMirror
        value={snippet.content}
        theme="dark"
        height="150px"
        width="300px"
        extensions={[html()]}
        readOnly
      />
    </div>
    <div className={styles.snippetActions}>
      <IconButton className={styles.snippetActionsButton} onClick={onEdit}>
        <EditIcon />
      </IconButton>
      <IconButton className={styles.snippetActionsButton} onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </div>
  </div>
)

export default observer(SnippetItem)
