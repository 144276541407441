import React, { useMemo } from "react"
import { useServiceIntegrationStore, useServiceStore } from "../../context/rootStoreContext"
import styles from "./Integration.module.scss"
import { observer } from "mobx-react"
import { ServiceIntegrationStore } from "../../stores/ServiceIntegrationStore"
import { BackendIntegration, BackendIntegrationType } from "@limbic/types"
import deepCompare from "../../../utils/deepCompare"
import Button from "../../components/Button"
import { FormControl, Input, InputLabel, MenuItem, Select } from "@mui/material"

interface IServiceIntegrationProps {
  onError: () => void
}
function ServiceIntegration(props: IServiceIntegrationProps) {
  const serviceStore = useServiceStore()
  const serviceIntegrationStore = useServiceIntegrationStore()

  const serviceData = serviceStore.serviceData?.[serviceStore.mode]

  const isChanged = useMemo(() => {
    return !deepCompare(serviceData?.integration ?? {}, serviceIntegrationStore.config)
  }, [serviceIntegrationStore.config, serviceData?.integration])

  const onInputChange = (input: string, value?: BackendIntegration[keyof BackendIntegration]) => {
    const currentInput = input as keyof ServiceIntegrationStore["config"]

    serviceIntegrationStore.setConfig({ [currentInput]: value })

    serviceIntegrationStore.validateConfig()
  }

  const onSaveChanges = async () => {
    try {
      await serviceStore.updateServiceIntegration(serviceIntegrationStore.config)
    } catch (e) {
      props.onError()
      console.log({ e })
    }
  }

  const onDiscardChanges = () => serviceIntegrationStore.sync(serviceData)

  return (
    <>
      <div className={styles.propertiesContainer}>
        <FormControl className={styles.inputContainer}>
          <InputLabel htmlFor="type">This will be the type of service integration</InputLabel>
          <Select
            name="type"
            label="This will be the type of service integration"
            value={serviceIntegrationStore.config.type}
            onChange={e => onInputChange(e.target.name, e.target.value as BackendIntegrationType)}>
            {Object.entries(BackendIntegrationType).map(([key, value]) => (
              <MenuItem key={key} value={value} className={styles.questionItem}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {serviceIntegrationStore.config.type === "PCMIS" && (
          <>
            <FormControl className={styles.inputContainer}>
              <InputLabel htmlFor="type">
                {serviceIntegrationStore.errors?.metaData ??
                  "This will be PCMIS' external service id"}
              </InputLabel>

              <Input
                name="ExternalServiceId"
                value={serviceIntegrationStore.config.metaData?.ExternalServiceId ?? ""}
                onChange={e =>
                  onInputChange("metaData", {
                    ...serviceIntegrationStore.config.metaData,
                    ExternalServiceId: e.target.value
                  })
                }
                placeholder="Enter the external service id"
              />
            </FormControl>
          </>
        )}
      </div>

      <div className={styles.buttonsSpacer} />
      <div className={styles.buttonsContainer}>
        <Button disabled={!isChanged} onClick={onSaveChanges}>
          {serviceStore.isLoading ? "Updating..." : "Save Changes"}
        </Button>
        <Button disabled={!isChanged} onClick={onDiscardChanges}>
          Discard Changes
        </Button>
      </div>
    </>
  )
}

export default observer(ServiceIntegration)
