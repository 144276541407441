import React from "react"
import { observer } from "mobx-react"
import FlowEditor from "../../components/FlowEditor/FlowEditor"
import Editors from "./Editors"
import Loading from "../../components/Loading"
import styles from "./BotQuestions.module.scss"
import { useServiceStore } from "../../context/rootStoreContext"

function BotQuestions(): JSX.Element {
  const serviceStore = useServiceStore()

  return (
    <div className={styles.botQuestionsWrapper}>
      <Editors />
      {serviceStore.isLoading ? <Loading /> : <FlowEditor />}
    </div>
  )
}

export default observer(BotQuestions)
