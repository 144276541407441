import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { TextField, Tooltip, Typography } from "@mui/material"
import Button from "../../../Button"
import Dialog from "../../../Dialog"
import styles from "./PublishDialogue.module.scss"
import formatTimeStamp from "../../../../../utils/formatTimeStamp"
import { useServiceStore } from "../../../../context/rootStoreContext"

interface Props {
  isPublishDialogueOpen: boolean
  setPublishDialogueOpen: (value: boolean) => void
}

function PublishDialogue(props: Props): JSX.Element {
  const serviceStore = useServiceStore()
  const [error, setError] = React.useState<string>("")
  const [publishPassword, setPublishPassword] = React.useState<string>("")
  const [publishedSuccessfully, setPublishedSuccessfully] = React.useState<boolean>(false)

  const [publishTime, publishTimeTitle] = formatTimeStamp(serviceStore.serviceData?.lastPublishDate)
  const [draftTime, draftTimeTitle] = formatTimeStamp(serviceStore.serviceData?.lastDraftDate)

  const reset = () => {
    setError("")
    setPublishPassword("")
    setPublishedSuccessfully(false)
  }

  const handlePublish = async () => {
    try {
      await serviceStore.publish(publishPassword)
      reset()
    } catch (e) {
      // TODO: log exception to sentry
      setError(e.message)
    }
  }

  useEffect(reset, [props.isPublishDialogueOpen])

  return (
    <Dialog
      maxWidth="xs"
      open={props.isPublishDialogueOpen}
      onClose={() => props.setPublishDialogueOpen(false)}
      title="Publish"
      ActionButton={
        <Button disabled={publishPassword.length < 4} onClick={handlePublish}>
          Publish
        </Button>
      }>
      <div className={styles.publishContainer}>
        <TextField
          onKeyDown={event => {
            if (event.key === "Enter") {
              event.preventDefault()
              if (publishPassword.length > 3) void handlePublish()
            }
          }}
          error={!!error}
          helperText={error}
          id="outlined-basic"
          label="Password"
          variant="outlined"
          type="password"
          value={publishPassword}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setPublishPassword(event.target.value)
          }
        />
        <Typography className={styles.publishSuccessful}>
          {publishedSuccessfully ? "Service configuration has been published successfully" : ""}
        </Typography>
        <Tooltip title={publishTimeTitle ?? "N/A"}>
          <Typography className={styles.lastPublishDate}>
            <b>Last Publish:</b> {publishTime.length ? publishTime : "N/A"}
          </Typography>
        </Tooltip>
        <Tooltip title={draftTimeTitle ?? "N/A"}>
          <Typography className={styles.lastPublishDate}>
            <b>Last Draft:</b> {draftTime.length ? draftTime : "N/A"}
          </Typography>
        </Tooltip>
      </div>
    </Dialog>
  )
}

export default observer(PublishDialogue)
