import React, { useState } from "react"
import CustomizedAccordion from "../../../../../Accordion/Accordion"
import { observer } from "mobx-react"
import { ChatFlowsEnum, ISelectableExtended } from "@limbic/types"
import { Autocomplete, Chip, TextField } from "@mui/material"
import Options from "../Options"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"

import styles from "./ChatFlowOptions.module.scss"

interface Props {
  showChatFlowInputForOptions: boolean
  showCanBeSelectedIndividually: boolean
  options: ISelectableExtended[]
  secondaryOptions: ISelectableExtended[] | undefined
}

function moveEnglishFirst(languages) {
  const english = languages.filter(lang => lang.body === "English")
  const others = languages.filter(lang => lang.body !== "English")

  return english.concat(others)
}

function ChatFlowOptions({
  showChatFlowInputForOptions,
  showCanBeSelectedIndividually,
  options,
  secondaryOptions
}: Props): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings, chatFlow } = chatFlowEditorState
  const [selectIndividuallyExpanded, setSelectIndividuallyExpanded] = useState<string | false>(
    "selectIndividuallyPanel"
  )

  const handleSelectIndividuallyToggle =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setSelectIndividuallyExpanded(newExpanded ? panel : false)
    }

  let reorderedOptions = options
  if (chatFlow === ChatFlowsEnum.COLLECT_LANGUAGE) {
    reorderedOptions = moveEnglishFirst(options)
  }

  return (
    <>
      {showChatFlowInputForOptions && (
        <Options
          options={reorderedOptions}
          secondaryOptions={secondaryOptions}
          chatFlow={chatFlow}
          chatFlowSettings={chatFlowSettings}
        />
      )}
      {showCanBeSelectedIndividually && (
        <CustomizedAccordion
          summary="Options that can be selected individually"
          expanded={selectIndividuallyExpanded}
          onHandlePanelChange={handleSelectIndividuallyToggle}
          panel="selectIndividuallyPanel"
          maxHeight="none"
          forceBorderBottom>
          <Autocomplete
            className={styles.autocompleteOptions}
            multiple
            id="answers-individually"
            onChange={(_event, value) =>
              nodeEditorStore.updateChatFlowState({
                chatFlowSettings: {
                  ...chatFlowSettings,
                  optionsToSelectIndividually: value
                }
              })
            }
            options={reorderedOptions.filter(option => option.include).map(option => option.body!)}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  className={styles.optionChip}
                />
              ))
            }
            value={chatFlowSettings.optionsToSelectIndividually}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Can be selected individually"
                placeholder="Add the answers that can be selected individually i.e. None"
              />
            )}
          />
        </CustomizedAccordion>
      )}
    </>
  )
}

export default observer(ChatFlowOptions)
