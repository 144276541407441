import React, { useCallback, useState } from "react"
import classes from "clsx"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import BotPlayground from "../../../../components/BotPlayground"
import styles from "./BotPlaygroundSection.module.scss"
import { observer } from "mobx-react"
import { useServiceConfigurationStore } from "../../../../context/rootStoreContext"

interface Props {
  uploadedImage: any
  uploadedImageMobile: any
}

function BotPlaygroundSection(props: Props): JSX.Element {
  const { uploadedImage, uploadedImageMobile } = props
  const serviceConfigurationStore = useServiceConfigurationStore()
  const [isOpen, setIsOpen] = useState(false)
  const toggleDrawer = useCallback(() => setIsOpen(v => !v), [])

  return (
    <>
      <div className={styles.botPlaygroundContainerDesktop}>
        <BotPlayground
          {...serviceConfigurationStore.config}
          currentInput={serviceConfigurationStore.currentInput}
          uploadedImage={uploadedImage}
          userMessageBackground={serviceConfigurationStore.config.userMessageBackground}
        />
      </div>
      {isOpen && <div className={styles.drawerBackdrop} onClick={toggleDrawer} />}
      <div
        className={classes(styles.botPlaygroundContainerMobile, {
          [styles.slideIn]: isOpen,
          [styles.slideOut]: !isOpen
        })}
        style={{ right: isOpen ? "-24px" : "-370px" }}>
        <button className={styles.botPlaygroundDrawerButton} onClick={toggleDrawer}>
          {isOpen ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
        </button>
        <BotPlayground
          {...serviceConfigurationStore.config}
          currentInput={serviceConfigurationStore.currentInput}
          uploadedImage={uploadedImageMobile}
          userMessageBackground={serviceConfigurationStore.config.userMessageBackground}
        />
      </div>
    </>
  )
}

export default observer(BotPlaygroundSection)
