// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VoqUymnPVGdJdaQkvkDW{margin-top:12px}.qahn3D6FPVp4xNict2y4{padding-bottom:9px;width:100%;text-align:left}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/CollectGender/CollectGender.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,kBAAA,CACA,UAAA,CACA,eAAA","sourcesContent":[".collectGenderContainer {\n  margin-top: 12px;\n}\n\n.genderLabel {\n  padding-bottom: 9px;\n  width: 100%;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collectGenderContainer": "VoqUymnPVGdJdaQkvkDW",
	"genderLabel": "qahn3D6FPVp4xNict2y4"
};
export default ___CSS_LOADER_EXPORT___;
