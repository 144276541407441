import React from "react"

import styles from "./FlowArrow.module.scss"

function FlowArrow() {
  return (
    <div className={styles.flowArrow}>
      <div className={styles.flowArrowBody} />
      <div className={styles.flowArrowHead} />
    </div>
  )
}

export default FlowArrow
