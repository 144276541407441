// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eemDmhGt657Jon5_dnWp{display:flex;flex-flow:column;justify-content:flex-end;align-items:center}.BUJXCz6ByuSC8YQc9iHt{width:100%}.BzdrypaDTlkYJDlFGpOL{margin-bottom:1em;color:rgba(0,0,0,.7)}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/CheckCrisisDetection/CheckCrisisDetection.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,wBAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CAGF,sBACE,iBAAA,CACA,oBAAA","sourcesContent":[".crisisNumbersSharedContainer {\n  display: flex;\n  flex-flow: column;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.crisisNumbersShared {\n  width: 100%;\n}\n\n.checkCrisisDetectionTitle {\n  margin-bottom: 1em;\n  color: rgba(0, 0, 0, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crisisNumbersSharedContainer": "eemDmhGt657Jon5_dnWp",
	"crisisNumbersShared": "BUJXCz6ByuSC8YQc9iHt",
	"checkCrisisDetectionTitle": "BzdrypaDTlkYJDlFGpOL"
};
export default ___CSS_LOADER_EXPORT___;
