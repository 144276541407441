import { IKeyMetaData, IKeyTransform, KeyType } from "@limbic/types"

/**
 * Returns a populated version of the transform's valuesMap based on
 * optional meta-data of the target key. If meta-data is passed, it
 * will use the meta-data to predict possible values for the transform,
 * otherwise it will return an empty (populated with null) valuesMap.
 * @param transform {IKeyTransform} the transform to predict values for
 * @param targetKeyMetaData {IKeyMetaData?} the key meta-data to predict values from
 */
export default function getPredictedValuesMap(
  transform: IKeyTransform,
  targetKeyMetaData?: IKeyMetaData
): Record<string, string | null> | undefined {
  if (!transform.valuesMap) return undefined

  // start from a cleaned-up valuesMap (populated with null)
  const valuesMap = Object.entries(transform.valuesMap ?? {}).reduce(
    (o, [k]) => ({ ...o, [k]: null }),
    {}
  )

  // if no target metadata then nothing to predict, return the cleaned up
  if (!targetKeyMetaData) return valuesMap

  const allowedValues = targetKeyMetaData.allowedValues ?? []
  const isTransformText = transform.type === KeyType.Text
  const isTransformTextList = transform.type === KeyType.TextList
  const isTransformBoolean = transform.type === KeyType.Boolean
  const isTransformBooleanList = transform.type === KeyType.BooleanList
  const isTargetText = targetKeyMetaData.type === KeyType.Text
  const isTargetTextList = targetKeyMetaData.type === KeyType.TextList
  const isTargetBoolean = targetKeyMetaData.type === KeyType.Boolean
  const isTargetBooleanList = targetKeyMetaData.type === KeyType.BooleanList

  // if target is boolean and transform has Yes and No values
  if ((isTransformText || isTransformTextList) && (isTargetBoolean || isTargetBooleanList)) {
    assignMatchingKeyValue(valuesMap, "yes", true)
    assignMatchingKeyValue(valuesMap, "no", false)
  }

  // if transform is boolean and target has Yes and No values
  if ((isTransformBoolean || isTransformBooleanList) && (isTargetText || isTargetTextList)) {
    assignMatchingKeyValue(valuesMap, true, "yes", allowedValues)
    assignMatchingKeyValue(valuesMap, false, "no", allowedValues)
  }

  // if transform and target, both have text values
  if ((isTransformText || isTransformTextList) && (isTargetText || isTargetTextList)) {
    assignMatchingKeyValue(valuesMap, "yes", "yes", allowedValues)
    assignMatchingKeyValue(valuesMap, "no", "no", allowedValues)
    assignMatchingKeyValue(valuesMap, "other", "NOT_LISTED", allowedValues)
    assignMatchingKeyValue(valuesMap, "I don't know", "UNKNOWN", allowedValues)
    assignMatchingKeyValue(valuesMap, "Prefer not to say", "NOT_ANSWERED", allowedValues)
  }

  return valuesMap
}

/**
 * Case-insensitively searches for a matching key in the object, and
 * if it finds one, it case-insensitively searches for a matching value
 * if an allowedValues list is passed, and assigns the matching value
 * (or the value itself if no allowedValues is passed) to the matching
 * key in the object.
 * @param obj {Record<string, string>}
 * @param key {string} a case-insensitive lookup key of obj
 * @param value {string} the value to assign to they key withing the object's type constraints
 * @param allowedValues {string[]?} an optional list of values to search for a matching value
 */
function assignMatchingKeyValue<T extends Record<string, string>>(
  obj: T,
  key: string | number | boolean,
  value: string | number | boolean,
  allowedValues?: IKeyMetaData["allowedValues"]
): void {
  // basically, if an allowedValues is passed, search for a matching value
  // in the list, otherwise consider the value as the matching value
  const matchingValue: T[keyof T] = allowedValues
    ? (allowedValues.find(
        v => String(v).toLowerCase() === String(value).toLowerCase()
      ) as T[keyof T])
    : (value as T[keyof T])
  if (matchingValue == null) return

  // prettier-ignore
  const matchingKey: keyof T = Object.keys(obj).find(k => k.toLowerCase() === String(key).toLowerCase()) as string
  if (matchingKey) obj[matchingKey] = matchingValue
}
