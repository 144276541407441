import { memo } from "react"
import { gray } from "@radix-ui/colors"

interface IProps {
  size: number
  strokeWidth?: number
  strokeColor?: string
  fillColor?: string
  Icon?: JSX.Element
}

function EndShape({
  size = 100,
  strokeWidth = 1,
  fillColor = gray.gray1,
  strokeColor = "black",
  Icon
}: IProps): JSX.Element {
  return (
    <svg width={size} height={size} style={{ display: "block", overflow: "visible" }}>
      <circle
        className="circle"
        cx={size / 2}
        cy={size / 2}
        r={size / 2}
        fill={"transparent"}
        strokeWidth={strokeWidth}
        stroke={strokeColor}
      />
      {Icon ?? (
        <circle
          className="circle"
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - 4}
          fill={fillColor}
          strokeWidth={strokeWidth}
          stroke={strokeColor}
        />
      )}
    </svg>
  )
}

export default memo(EndShape)
