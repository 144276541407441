// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FbNUMCTDduX3HrCrk15s{display:flex;flex-flow:row}.FbNUMCTDduX3HrCrk15s input{width:33.3%}.QAhTuRL32nqKfW2c1Sx7{display:flex;flex-flow:row;width:100%}.NCfISEZgx__OYUsVn9p1{min-width:33.3%}", "",{"version":3,"sources":["webpack://./src/app/views/BasicConfiguration/components/TermsAndConditions/TermsAndConditions.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,4BACE,WAAA,CAIJ,sBACE,YAAA,CACA,aAAA,CACA,UAAA,CAGF,sBACE,eAAA","sourcesContent":[".termsPairContainer {\n  display: flex;\n  flex-flow: row;\n  & input {\n    width: 33.3%;\n  }\n}\n\n.termsLabelContainer {\n  display: flex;\n  flex-flow: row;\n  width: 100%;\n}\n\n.termsLabel {\n  min-width: 33.3%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"termsPairContainer": "FbNUMCTDduX3HrCrk15s",
	"termsLabelContainer": "QAhTuRL32nqKfW2c1Sx7",
	"termsLabel": "NCfISEZgx__OYUsVn9p1"
};
export default ___CSS_LOADER_EXPORT___;
