import React from "react"
import { Typography } from "@mui/material/"
import { observer } from "mobx-react"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageList from "../MessageList"
import MessageForm from "../MessageForm"
import styles from "./CollectName.module.scss"
import SolidDivider from "../../../../../SolidDivider"
import FormControlWithCheckBox from "../FormControlWithCheckBox"

function CollectName(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <Typography align="right" className={styles.collectNameTitle} variant="subtitle1">
        Bot message to ask user's full name:
      </Typography>
      <MessageList
        messages={messages?.askFullName ?? []}
        messageType="askFullName"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askFullName}
        placeholder="Type a message here..."
        name="askFullName"
        updateState={updateState}
        messages={messages?.askFullName ?? []}
      />
      <Typography align="right" className={styles.collectNameTitle} variant="subtitle1">
        Bot message to ask user's full name if something went wrong the first attempt:
      </Typography>
      <MessageList
        messages={messages?.sayPleaseGiveFullName ?? []}
        messageType="sayPleaseGiveFullName"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayPleaseGiveFullName}
        placeholder="Type a message here..."
        name="sayPleaseGiveFullName"
        updateState={updateState}
        messages={messages?.sayPleaseGiveFullName ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldAskPreferredName"
        label="Ask if they have a preferred name"
        labelPlacement="start"
        updateState={updateState}
      />
      <Typography align="right" className={styles.collectNameTitle} variant="subtitle1">
        Bot message ask if user has a preferred name
      </Typography>
      <MessageList
        disabled={!chatFlowSettings.collectName?.shouldAskPreferredName}
        messages={messages?.askIsPreferredName ?? []}
        messageType="askIsPreferredName"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={!chatFlowSettings.collectName?.shouldAskPreferredName}
        value={chatFlowSettings.currentMessage?.askIsPreferredName}
        placeholder="Type a message here..."
        name="askIsPreferredName"
        updateState={updateState}
        messages={messages?.askIsPreferredName ?? []}
      />
      <Typography align="right" className={styles.collectNameTitle} variant="subtitle1">
        Bot message to ask user's preferred name:
      </Typography>
      <MessageList
        disabled={!chatFlowSettings.collectName?.shouldAskPreferredName}
        messages={messages?.sayPleaseGivePreferredName ?? []}
        messageType="sayPleaseGivePreferredName"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={!chatFlowSettings.collectName?.shouldAskPreferredName}
        value={chatFlowSettings.currentMessage?.sayPleaseGivePreferredName}
        placeholder="Type a message here..."
        name="sayPleaseGivePreferredName"
        updateState={updateState}
        messages={messages?.sayPleaseGivePreferredName ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldSayNiceToMeetYou"
        label="Say nice to meet you after name is provided"
        labelPlacement="start"
        updateState={updateState}
      />
      <Typography align="right" className={styles.collectNameTitle} variant="subtitle1">
        Bot message to say nice to meet you:
      </Typography>
      <MessageList
        disabled={!chatFlowSettings.collectName?.shouldSayNiceToMeetYou}
        messages={messages?.sayNiceToMeetYou ?? []}
        messageType="sayNiceToMeetYou"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={!chatFlowSettings.collectName?.shouldSayNiceToMeetYou}
        value={chatFlowSettings.currentMessage?.sayNiceToMeetYou}
        placeholder="Type a message here..."
        name="sayNiceToMeetYou"
        updateState={updateState}
        messages={messages?.sayNiceToMeetYou ?? []}
      />
    </>
  )
}

export default observer(CollectName)
