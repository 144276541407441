// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dUK75XbmEkVeg4W1NxKM{color:gray;text-align:right;position:absolute;top:0;right:8px}.e8v2wSGDTkzvlEz0Fhgc{color:red}", "",{"version":3,"sources":["webpack://./src/app/views/KeepingSafe/components/InputWrapper/InputWrapper.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,gBAAA,CACA,iBAAA,CACA,KAAA,CACA,SAAA,CAGF,sBACE,SAAA","sourcesContent":[".inputInstructions {\n  color: gray;\n  text-align: right;\n  position: absolute;\n  top: 0;\n  right: 8px;\n}\n\n.error {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputInstructions": "dUK75XbmEkVeg4W1NxKM",
	"error": "e8v2wSGDTkzvlEz0Fhgc"
};
export default ___CSS_LOADER_EXPORT___;
