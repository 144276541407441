import self from "./publish"
import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import { ServiceStatus } from "../../models/Service"
import invariant from "../../utils/invariant"
import Logger from "../../utils/Logger"
import formatUnicorn from "../../utils/formatUnicorn"

const TOTAL_RETRIES = 3
const PUBLISH_URL = `${BACKEND_URL}/v1/dashboard/{serviceApiKey}/publish`

const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export default async function publish(
  serviceApiKey: string,
  password: string,
  retry = 0
): Promise<ServiceStatus> {
  invariant(serviceApiKey, "Cannot publish without a serviceApiKey")
  invariant(password, "Cannot publish without a password")

  try {
    const URL = formatUnicorn(PUBLISH_URL, { serviceApiKey })
    const body = JSON.stringify({ password })
    const result = await fetch(URL, { method, headers, body })
    const json = await result.json()

    const { success, data, error, validations } = json

    if (!success && error) {
      const regex = /invalid password/i
      if (regex.test(error)) return ServiceStatus.InvalidPassword
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    if (success && !data) {
      return ServiceStatus.NoServiceFound
    }
    return ServiceStatus.Success
  } catch (e) {
    Logger.getInstance().exception(e, "publish failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`publish key for retry ${retry}`, serviceApiKey)
      Logger.getInstance().message("publish retry")
      await delay(1)
      return await self(serviceApiKey, password, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
