import React from "react"
import { observer } from "mobx-react"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"

function CheckPostCodeFromAddressLookup(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <FormControlWithCheckBox
        name="hideEarlierYouSaid"
        label="Do not display intro message 'So earlier you said your postcode is DN10 4DL'"
        labelPlacement="start"
        updateState={updateState}
      />
      <FormControlWithCheckBox
        name="startWithAskPostcode"
        label="Start by asking the postcode (in case the postcode wasn't asked before)"
        labelPlacement="start"
        updateState={updateState}
      />
      <FormControlWithCheckBox
        name="skipPermissionsQuestion"
        label="Do not ask for permission to send mail to the user's address"
        labelPlacement="start"
        updateState={updateState}
      />
    </>
  )
}

export default observer(CheckPostCodeFromAddressLookup)
