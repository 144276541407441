import React from "react"
import styles from "./Loading.module.scss"

export default function Loading(): JSX.Element {
  return (
    <div className={styles.snippet} data-title=".dot-pulse">
      <div className={styles.container}>
        <div className={styles.dotPulse}></div>
      </div>
    </div>
  )
}
