import React, { PropsWithChildren } from "react"
import { Dialog as MuiDialog } from "@mui/material/"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import Button from "../Button"

interface Props {
  ActionButton?: React.ReactChild | React.ReactChild[]
  onClose: (value: boolean) => void
  closeButtonLabel?: string
  open?: boolean
  title: string
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
}

export default function Dialog(props: PropsWithChildren<Props>): JSX.Element {
  const { ActionButton, children, onClose, open, title, maxWidth = "lg" } = props

  const handleClose = () => {
    onClose(false)
  }

  return (
    <MuiDialog fullWidth maxWidth={maxWidth} open={!!open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof children === "string" ? (
          <DialogContentText>{children}</DialogContentText>
        ) : (
          children
        )}{" "}
      </DialogContent>
      <DialogActions>
        {ActionButton && ActionButton}
        <Button onClick={handleClose}>{props.closeButtonLabel ?? "Close"}</Button>
      </DialogActions>
    </MuiDialog>
  )
}
