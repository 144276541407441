import React from "react"
import { observer } from "mobx-react"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"

function CollectNationality(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <FormControlWithCheckBox
        name="withTextPrompt"
        label="Include a free text input to allow typing of nationality"
        labelPlacement="start"
        updateState={updateState}
      />
    </>
  )
}

export default observer(CollectNationality)
