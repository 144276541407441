import { differenceWith, isEqual } from "lodash"

export function removeAtIndex<T>(arr: T[], index: number): T[] {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

/**
 * Accepts two arrays and returns true if they are equal.
 * They can be equal if they have the same items by deep
 * compare and without taking into account their order
 * @param listA {*[]}
 * @param listB {*[]}
 */
export function getArraysAreEqual(listA: any[], listB: any[]): boolean {
  if (listA?.length !== listB?.length) return false
  const diff1 = differenceWith(listA, listB, isEqual)
  const diff2 = differenceWith(listB, listA, isEqual)
  return !diff1.length && !diff2.length
}

export function uniquelyConcat(...params: any[]): any[] {
  return [...new Set([].concat(...params))]
}
