// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LjHiX97DUpgcMHLVg_V2{display:flex;flex-flow:column;justify-content:center;align-items:center;width:70vw;height:80vh}@media only screen and (max-width: 1200px){.LjHiX97DUpgcMHLVg_V2{width:90vw}}", "",{"version":3,"sources":["webpack://./src/app/views/BotQuestions/BotQuestions.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,2CAPF,sBAQI,UAAA,CAAA","sourcesContent":[".botQuestionsWrapper {\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n  width: 70vw;\n  height: 80vh;\n  @media only screen and (max-width: 1200px) {\n    width: 90vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"botQuestionsWrapper": "LjHiX97DUpgcMHLVg_V2"
};
export default ___CSS_LOADER_EXPORT___;
