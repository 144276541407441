import { action, makeObservable, observable } from "mobx"
import { IChatbotPropertyKey } from "@limbic/types"
import { IBotServiceData } from "@limbic/types"
import getChatbotPropertyKeys from "../../utils/getChatbotPropertyKeys"

export class ChatbotPropertiesStore {
  @observable chatbotProperties: IChatbotPropertyKey[]

  onChatbotPropertiesUpdate?(properties: IChatbotPropertyKey[]): void

  constructor() {
    this.chatbotProperties = []
    makeObservable(this)
  }

  /** Actions */

  @action
  sync(data?: IBotServiceData): void {
    this.chatbotProperties = getChatbotPropertyKeys(data?.flow ?? {})
  }
}
