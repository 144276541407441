import {
  ClinicalConditions,
  StateConditions,
  IGoodbyeRecapMessageConditionChain
} from "@limbic/types"

export const DISALLOWED_PAIRS: Record<
  "allTrue" | "allFalse",
  Partial<Record<ClinicalConditions | StateConditions, ClinicalConditions | StateConditions>>
> = {
  allTrue: {
    [ClinicalConditions.IS_HIGH_RISK]: ClinicalConditions.IS_MODERATE_RISK,
    [ClinicalConditions.IS_MODERATE_RISK]: ClinicalConditions.IS_HIGH_RISK,
    [StateConditions.NEEDS_ASSESSMENT_CALL]: StateConditions.NEEDS_TO_CALL,
    [StateConditions.NEEDS_TO_CALL]: StateConditions.NEEDS_ASSESSMENT_CALL
  },
  allFalse: {}
}

export const DEFAULT_OPTIONS: IGoodbyeRecapMessageConditionChain = [
  {
    name: ClinicalConditions.IS_CRISIS,
    type: "clinicalStore",
    value: "skip"
  },
  {
    name: ClinicalConditions.IS_TRIGGERWORDS_CRISIS,
    type: "clinicalStore",
    value: "skip"
  },
  {
    name: ClinicalConditions.IS_HIGH_RISK,
    type: "clinicalStore",
    value: "skip"
  },
  {
    name: ClinicalConditions.IS_MODERATE_RISK,
    type: "clinicalStore",
    value: "skip"
  },
  {
    name: ClinicalConditions.TREATMENT,
    type: "clinicalStore",
    value: "skip"
  },
  {
    name: StateConditions.REQUIRES_URGENT_SUPPORT,
    type: "state",
    value: "skip"
  },
  {
    name: StateConditions.CAN_KEEP_SELF_SAFE,
    type: "state",
    value: "skip"
  },
  {
    name: StateConditions.REFERRAL_SUBMITTED,
    type: "state",
    value: "skip"
  },
  {
    name: StateConditions.IS_IDLE_SUBMITTED,
    type: "state",
    value: "skip"
  },
  {
    name: StateConditions.NEEDS_TO_CALL,
    type: "state",
    value: "skip"
  },
  {
    name: StateConditions.NEEDS_ASSESSMENT_CALL,
    type: "state",
    value: "skip"
  },
  {
    name: StateConditions.APPOINTMENT,
    type: "state",
    value: "skip"
  }
]
