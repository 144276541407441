import React from "react"
import { Typography } from "@mui/material/"
import { observer } from "mobx-react"
import Choice from "../../../Choice"
import DummyInput from "../../../QuestionEditor/components/DummyInput"
import { PromptType } from "../../../../models/NodeEditors"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import FormControlWithCheckBox from "../../components/FormControlWithCheckBox"
import MessageList from "../../components/MessageList"
import MessageForm from "../../components/MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import styles from "./CollectEmail.module.scss"

function CollectEmail(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const prelQuestionDisabled = !chatFlowSettings.collectEmail?.hasPreliminaryQuestion
  const emailPermissionQuestionDisabled = !chatFlowSettings.collectEmail?.shouldAskEmailPermission

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <FormControlWithCheckBox
        name="hasPreliminaryQuestion"
        label="First ask if user want's to share email"
        labelPlacement="start"
        updateState={updateState}
      />
      <MessageList
        disabled={prelQuestionDisabled}
        messages={messages?.askDoYouWantToShareEmail ?? []}
        messageType="askDoYouWantToShareEmail"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={prelQuestionDisabled}
        value={chatFlowSettings.currentMessage?.askDoYouWantToShareEmail}
        placeholder="Type a message here..."
        name="askDoYouWantToShareEmail"
        updateState={updateState}
        messages={messages?.askDoYouWantToShareEmail ?? []}
      />
      <SolidDivider />
      <Typography className={styles.emailLabel}>Email question message:</Typography>
      <MessageList
        messages={messages?.askEmail ?? []}
        messageType="askEmail"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      {chatFlowSettings.collectEmail?.hasNoEmailOption && (
        <div className={styles.optionContainer}>
          <Choice value="I don't have an email address" nodeId="" index={0} withoutHandle={true} />
        </div>
      )}
      <DummyInput promptType={PromptType.Text} />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askEmail}
        placeholder="Type a message here..."
        name="askEmail"
        updateState={updateState}
        messages={messages?.askEmail ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="hasNoEmailOption"
        label="Include clickable option 'I don't have an email address'"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldAskEmailPermission"
        label="Ask for email permission'"
        labelPlacement="start"
        updateState={updateState}
      />
      <MessageList
        disabled={emailPermissionQuestionDisabled}
        messages={messages?.askEmailPermission ?? []}
        messageType="askEmailPermission"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={emailPermissionQuestionDisabled}
        value={chatFlowSettings.currentMessage?.askEmailPermission}
        placeholder="Type a message here..."
        name="askEmailPermission"
        updateState={updateState}
        messages={messages?.askEmailPermission ?? []}
      />
    </>
  )
}

export default observer(CollectEmail)
