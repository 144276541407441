export function capitaliseFirst(value?: string, perword = false): string | undefined {
  if (!value?.length) return value
  if (perword) {
    const split = value.split(/\s/)
    return split.map(word => capitaliseFirst(word)).join(" ")
  }
  const [first, ...rest] = value.toLowerCase()
  return `${first.toUpperCase()}${rest.join("")}`
}

export function removeQuotes(str: string, quotes = "\"'"): string {
  return str.replace(new RegExp(`^[${quotes}]|[${quotes}]$`, "g"), "")
}

export function unQuote(str: string): string {
  return removeQuotes(str, "\"'`")
}
