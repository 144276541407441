// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kEHVETYQwCY5ClfU3TjU{margin-bottom:1em !important}.OT2tirY0yz6IKF3AVWNh{margin:0 0 1em 0 !important}.OT2tirY0yz6IKF3AVWNh:first-child{margin-bottom:2em !important}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/AdvancedConditionEditor/components/Operator/Operator.module.scss"],"names":[],"mappings":"AAAA,sBACE,4BAAA,CAGF,sBACE,2BAAA,CAGF,kCACE,4BAAA","sourcesContent":[".descriptionSubtitle {\n  margin-bottom: 1em !important;\n}\n\n.formControlAdvancedCondition {\n  margin: 0 0 1em 0 !important;\n}\n\n.formControlAdvancedCondition:first-child {\n  margin-bottom: 2em !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionSubtitle": "kEHVETYQwCY5ClfU3TjU",
	"formControlAdvancedCondition": "OT2tirY0yz6IKF3AVWNh"
};
export default ___CSS_LOADER_EXPORT___;
