import React, { LegacyRef, useEffect } from "react"
import classes from "clsx"
import styles from "./LogoInput.module.scss"
import Logo from "../../assets/logo.png"

interface Props {
  disabled?: boolean
  imageUploader: LegacyRef<HTMLInputElement>
  uploadedImage: LegacyRef<HTMLImageElement>
  src?: string
  onClick: () => void
  onImageError: () => void
  onChange: (_event) => void
  text?: string
}

function LogoInput(props: Props): JSX.Element {
  const { imageUploader, uploadedImage, onChange, onClick, src, text, onImageError } = props
  const [imgSrc, setImgSrc] = React.useState<any>(src)

  useEffect(() => {
    setImgSrc(src)
  }, [src])

  const handleImageError = () => {
    setImgSrc(Logo)
    onImageError()
  }

  return (
    <>
      <input
        disabled={props.disabled}
        type="file"
        ref={imageUploader}
        accept=".png, .jpeg, .jpg"
        className={classes(styles.imageInput, { [styles.imageInputDisabled]: props.disabled })}
        name="logo"
        onChange={onChange}
      />
      <div
        className={
          text
            ? classes(styles.imageUploaderText, { [styles.imageInputDisabled]: props.disabled })
            : classes(styles.imageUploader, { [styles.imageInputDisabled]: props.disabled })
        }
        onClick={onClick}>
        {text ? (
          <span className={styles.uploadText}>{text}</span>
        ) : (
          <img
            onError={handleImageError}
            alt="Upload"
            ref={uploadedImage}
            src={imgSrc}
            className={classes(styles.imageLogo, { [styles.imageInputDisabled]: props.disabled })}
          />
        )}
      </div>
      <input
        disabled={props.disabled}
        id="image-upload-input"
        accept=".png, .jpeg, .jpg"
        className={classes(styles.imageInput, { [styles.imageInputDisabled]: props.disabled })}
        name="logo"
        onChange={onChange}
        ref={imageUploader}
        type="file"
      />
    </>
  )
}

export default LogoInput
