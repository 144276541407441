import { useState, MouseEvent, useRef, useEffect } from "react"
import { styled } from "@stitches/react"
import autosize from "autosize"

interface IProps {
  value: string
  editable?: boolean
  onChange?: (value?: string) => void
  onEditingChange?: (editing: boolean) => void
}

export default function SimpleText({ editable = true, ...props }: IProps) {
  const [editing, setEditing] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    autosize(textAreaRef.current)
  }, [props.value])

  const onSetEditing = (newValue: boolean) => {
    if (newValue !== editing) {
      props.onEditingChange?.(newValue)
      setEditing(newValue)
    }
  }

  const onDoubleClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    if (editable) {
      onSetEditing(true)
      textAreaRef.current?.select()
    }
  }

  return (
    <>
      <StyledText>{props.value}</StyledText>
      {!editing && <DoubleClickOverlay onDoubleClick={onDoubleClick} />}
    </>
  )
}

const StyledText = styled("div", {
  // doing this instead of {all: "unset"}
  // because for whatever reason it also
  // removes the text selection background
  // on safari  ¯\_(ツ)_/¯
  fontSize: "unset",
  fontFamily: "unset",
  backgroundColor: "unset",
  outline: "unset",
  border: "unset",
  textAlign: "unset",
  resize: "unset",
  margin: "0 auto",
  width: "85%",
  variants: {
    editable: {
      true: { pointerEvents: "auto" },
      false: { pointerEvents: "none" }
    }
  }
})

const DoubleClickOverlay = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})
