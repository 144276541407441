import { DefaultEdgeOptions, MarkerType, Position } from "react-flow-renderer"
import { EdgeTypes } from "@limbic/types"

export const snapGrid: [number, number] = [1, 1]
export const connectionLineStyle = { stroke: "#000", strokeDasharray: "4" }

export const defaultEdgeOptions: DefaultEdgeOptions = {
  type: EdgeTypes.Custom,
  markerEnd: { type: MarkerType.ArrowClosed, strokeWidth: 2 }
}

export const positionsMap = {
  [Position.Bottom]: Position.Left,
  [Position.Left]: Position.Top,
  [Position.Top]: Position.Right,
  [Position.Right]: Position.Bottom
}

export const opposingPositionsMap = {
  [Position.Bottom]: Position.Top,
  [Position.Top]: Position.Bottom,
  [Position.Left]: Position.Right,
  [Position.Right]: Position.Left
}
