import React from "react"
import { observer } from "mobx-react"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import styles from "./AddClinicalNote.module.scss"
import LabeledCheckbox from "../../../../../ui/Form/LabeledCheckbox"
import { EditorType } from "../../../../models/NodeEditors"
import ChipInput from "../../../../../ChipInput"

function AddClinicalNote() {
  const nodeEditorStore = useNodeEditorStore()

  const onToggleShouldUpdateReferral = (v: boolean) => {
    nodeEditorStore.updateState(EditorType.ACTION, {
      ...nodeEditorStore.actionEditorState,
      actionAddClinicalNotesShouldUpdateReferral: v
    })
  }

  const onAddClinicalNote = (v: string) => {
    const newList = [...new Set([...nodeEditorStore.actionEditorState.actionAddClinicalNotes, v])]
    nodeEditorStore.updateState(EditorType.ACTION, {
      ...nodeEditorStore.actionEditorState,
      actionAddClinicalNotes: newList
    })
  }

  const onDeleteClinicalNote = (v: string) => {
    const newList = nodeEditorStore.actionEditorState.actionAddClinicalNotes.filter(
      note => note !== v
    )
    nodeEditorStore.updateState(EditorType.ACTION, {
      ...nodeEditorStore.actionEditorState,
      actionAddClinicalNotes: newList
    })
  }

  return (
    <div className={styles.addClinicalNoteContainer}>
      <div className={styles.addClinicalNoteContent}>
        <ChipInput
          label="Clinical Notes"
          placeholder="Type a Clinical Note"
          onAdd={onAddClinicalNote}
          onDelete={onDeleteClinicalNote}
          options={nodeEditorStore.actionEditorState.actionAddClinicalNotes}
        />
      </div>
      <LabeledCheckbox
        name="shouldUpdateReferral"
        label="Should update Referral"
        labelPlacement="end"
        checked={nodeEditorStore.actionEditorState.actionAddClinicalNotesShouldUpdateReferral}
        onChange={onToggleShouldUpdateReferral}
      />
    </div>
  )
}

export default observer(AddClinicalNote)
