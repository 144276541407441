import { internalsSymbol, Position } from "react-flow-renderer"
import Vector2 from "../../../../../../models/Vector2"
import { IDashboardNode, NodeTypes } from "@limbic/types"

export function getPosition(nodeA: IDashboardNode, nodeB: IDashboardNode): Position {
  // if the horizontal difference is bigger than the vertical
  // difference we return the horizontal positions (Left/Right)
  // otherwise we return the vertical ones (Top/Bottom)
  const centerA = getNodeCenter(nodeA)
  const centerB = getNodeCenter(nodeB)
  const hDiff = Math.abs(centerA.x - centerB.x)
  const vDiff = Math.abs(centerA.y - centerB.y)
  if (hDiff > vDiff) {
    return centerA.x > centerB.x //
      ? Position.Left
      : Position.Right
  }
  return centerA.y > centerB.y //
    ? Position.Top
    : Position.Bottom
}

const offsetsMap = {
  [NodeTypes.FlowEnd]: -3
}

export function getHandleCoordsByPosition(
  node: IDashboardNode,
  position: Position,
  type: "source" | "target" = "source"
): [x: number, y: number] {
  const handle = node[internalsSymbol]?.handleBounds?.[type]?.find(h => h.position === position)
  let offsetX = n(handle?.width) / 2
  let offsetY = n(handle?.height) / 2

  const extraOffset = offsetsMap[node.type!] ?? 0

  switch (position) {
    case Position.Left:
      offsetX = -extraOffset
      break
    case Position.Right:
      offsetX = (handle?.width ?? offsetX) + extraOffset
      break
    case Position.Top:
      offsetY = -extraOffset
      break
    case Position.Bottom:
      offsetY = (handle?.height ?? offsetY) + extraOffset
      break
  }

  const x = n(node.positionAbsolute?.x) + n(handle?.x) + offsetX
  const y = n(node.positionAbsolute?.y) + n(handle?.y) + offsetY
  return [x, y]
}

export function getNodeCenter(node: IDashboardNode): Vector2 {
  return {
    x: n(node.positionAbsolute?.x) + n(node.width) / 2,
    y: n(node.positionAbsolute?.y) + n(node.height) / 2
  }
}

export function getFloatingEdgeParams(source: IDashboardNode, target: IDashboardNode) {
  const sourcePos = getPosition(source, target)
  const targetPos = getPosition(target, source)
  const [sx, sy] = getHandleCoordsByPosition(source, sourcePos, "source")
  const [tx, ty] = getHandleCoordsByPosition(target, targetPos, "target")
  return { sx, sy, tx, ty, sourcePos, targetPos }
}

export const n = (n?: number | null | undefined): number => n ?? 0
