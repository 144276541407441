import React from "react"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { IServiceExtendedConfig } from "@limbic/types"
import styles from "./Dropdown.module.scss"

interface Props {
  selected: IServiceExtendedConfig
  options: IServiceExtendedConfig[]
  onSelect: (value: string) => void
}

function Dropdown(props: Props): JSX.Element {
  const { options, selected, onSelect } = props
  const [value, setValue] = React.useState<string>(selected.serviceApiKey)

  const handleChange = event => {
    setValue(event.target.value)
    onSelect(event.target.value)
  }

  return (
    <Select
      id="select-service-input"
      color="primary"
      variant="outlined"
      className={styles.select}
      onChange={handleChange}
      value={value}>
      {options.map(option => (
        <MenuItem key={option.serviceApiKey} value={option.serviceApiKey}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default Dropdown
