// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UeAdB7KD4z4js17ArdLP{margin:8px 0}.Lroy2rZ2gEkWoA3AHYLL{overflow-y:auto}.guULzJ29RboOViwuQfWz{border:1px solid #d3d3d3;margin:4px 0;display:flex;flex-flow:column;justify-content:flex-start;align-items:center}.mw9lL6NHb9WN7QaRaHJ5{border:1px solid #d3d3d3;border-radius:5px;outline:none;width:300px}.yvv21ImevvRwxlhXqVML{display:flex;flex-flow:column;align-items:flex-end;width:100%;padding-right:.5em}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/Options/Options.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBAEE,eAAA,CAGF,sBACE,wBAAA,CACA,YAAA,CACA,YAAA,CACA,gBAAA,CACA,0BAAA,CACA,kBAAA,CAGF,sBACE,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CACA,oBAAA,CACA,UAAA,CACA,kBAAA","sourcesContent":[".defaultChatFlowOptionsContainer {\n  margin: 8px 0;\n}\n\n.optionsContainer {\n  // max-height: 300px;\n  overflow-y: auto;\n}\n\n.optionBlock {\n  border: 1px solid lightgray;\n  margin: 4px 0;\n  display: flex;\n  flex-flow: column;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.labelInput {\n  border: 1px solid lightgray;\n  border-radius: 5px;\n  outline: none;\n  width: 300px;\n}\n\n.checkUncheckAllContainer {\n  display: flex;\n  flex-flow: column;\n  align-items: flex-end;\n  width: 100%;\n  padding-right: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultChatFlowOptionsContainer": "UeAdB7KD4z4js17ArdLP",
	"optionsContainer": "Lroy2rZ2gEkWoA3AHYLL",
	"optionBlock": "guULzJ29RboOViwuQfWz",
	"labelInput": "mw9lL6NHb9WN7QaRaHJ5",
	"checkUncheckAllContainer": "yvv21ImevvRwxlhXqVML"
};
export default ___CSS_LOADER_EXPORT___;
