import React, { useMemo } from "react"
import HeaderControlsImg from "../../assets/header-controls.png"
import BotBubble from "./components/BotBubble"
import styles from "./BotPlayground.module.scss"
import BotOptions from "./components/BotOption"

interface Props {
  children?: React.ReactChild | React.ReactChild[]
  currentInput?: string
  inputs?: React.ReactChild | React.ReactChild[]
  title: string
  serviceName: string
  organisationName: string
  organisationTermsNames: string[]
  organisationTermsLinksText: string[]
  organisationTermsLinks: string[]
  organisationPhoneNumbers: string[]
  crisisPhoneNumbers: string
  logo: string
  userMessageBackground: string
  uploadedImage: any
}

function BotPlayground(props: Props): JSX.Element {
  const {
    currentInput,
    title,
    serviceName,
    organisationName,
    organisationTermsNames,
    organisationTermsLinksText,
    organisationTermsLinks,
    organisationPhoneNumbers,
    crisisPhoneNumbers,
    userMessageBackground,
    logo,
    uploadedImage
  } = props

  const { element, inputs = null } = useMemo(() => {
    switch (currentInput) {
      case "serviceName":
        return {
          element: (
            <>
              <BotBubble isFirstMessage>
                Hey Jake, you have indicated you are in crisis and need urgent help
              </BotBubble>
              <BotBubble>
                {serviceName || organisationName} is not a crisis service. If you need urgent,
                life-threatening medical help please call 999
              </BotBubble>
            </>
          ),
          inputs: (
            <BotOptions
              options={["Okay", "I Understand"]}
              userMessageBackground={userMessageBackground}
            />
          )
        }
      case "title":
      case "organisationName":
      case "logo":
      case "userMessageBackground":
      case "faqLink":
        return {
          element: (
            <>
              <BotBubble isFirstMessage>Hi there!</BotBubble>
              <BotBubble>I’m Limbic</BotBubble>
              <BotBubble>
                I'm a friendly robot assistant to help you access psychological support from{" "}
                {organisationName || serviceName}
              </BotBubble>
              <BotBubble>Can I help?</BotBubble>
            </>
          ),
          inputs: (
            <BotOptions
              options={[
                `Yes, I'd like to refer myself to ${organisationName || serviceName}`,
                "No, I'm just browsing"
              ]}
              userMessageBackground={userMessageBackground}
            />
          )
        }
      case "organisationTerms":
        return {
          element: (
            <>
              <BotBubble isFirstMessage>I know mental health can be a sensitive subject</BotBubble>
              <BotBubble>
                I just want to reassure you that everything you discuss is stored safely and
                confidentially &nbsp; however there may be limits to this, please read the terms and
                conditions before you proceed.
              </BotBubble>
              <BotBubble>
                You can find all the details here:
                <br />
                <br />
                <>
                  <span>
                    Limbic&nbsp;
                    <a href="https://limbic.ai/tos" target="_blank" rel="noreferrer">
                      Terms of Service
                    </a>
                  </span>
                  <br />
                  <span>
                    Limbic&nbsp;
                    <a href="https://limbic.ai/pp" target="_blank" rel="noreferrer">
                      Privacy Policy
                    </a>
                  </span>
                  <br />
                  {organisationTermsNames
                    .filter(item => item !== "")
                    .map((name, index) => {
                      return (
                        <span key={name + index}>
                          {name}&nbsp;
                          <a href={organisationTermsLinks[index]} target="_blank" rel="noreferrer">
                            {organisationTermsLinksText[index]}
                          </a>
                          <br />
                        </span>
                      )
                    })}
                </>
              </BotBubble>
            </>
          )
        }
      case "organisationPhoneNumbers":
        return {
          element: (
            <>
              <BotBubble isFirstMessage>
                So Jack, with the NHS, you can get mental health support from services in the same
                area as your registered GP
              </BotBubble>
              <BotBubble>
                Please give any one of our services a call on the following phone numbers:
                <br />
                <br />
                <>
                  {organisationPhoneNumbers.length && organisationPhoneNumbers[0].length ? (
                    organisationPhoneNumbers.map((number, index) => {
                      return (
                        <span key={`phoneNumber-${index}`}>
                          {number}
                          <br />
                        </span>
                      )
                    })
                  ) : (
                    <span>[ORGANISATION PHONE NUMBERS]</span>
                  )}
                </>
              </BotBubble>
            </>
          )
        }
      case "crisisPhoneNumbers":
        return {
          element: (
            <>
              <BotBubble isFirstMessage>Sorry to hear that Jack</BotBubble>
              <BotBubble>
                It is normal for people to have thoughts of this nature at times
              </BotBubble>
              <BotBubble>However, this is not an emergency response service</BotBubble>
              <BotBubble>
                If you need urgent, life threatening medical help please call 999
              </BotBubble>
              <BotBubble>
                Alternatively, you can call our services if it's not a life-threatening situation on
                the following numbers:
                <br />
                {crisisPhoneNumbers && <br />}
                {crisisPhoneNumbers}
              </BotBubble>
            </>
          ),
          inputs: (
            <BotOptions
              options={["I understand", "Okay"]}
              userMessageBackground={userMessageBackground}
            />
          )
        }
    }
    return {}
  }, [
    currentInput,
    organisationName,
    serviceName,
    userMessageBackground,
    organisationPhoneNumbers,
    crisisPhoneNumbers,
    organisationTermsNames,
    organisationTermsLinksText,
    organisationTermsLinks
  ])

  return (
    <div className={styles.chatWindow}>
      {currentInput === "faqLink" && (
        <div className={styles.settingsMenu}>
          <span key="faq" style={{ fontWeight: 600 }}>
            FAQ
          </span>
          <span key="contrast-mode">Contrast Mode</span>
          <span key="bigger-text">Bigger Text</span>
        </div>
      )}
      <div className={styles.headerContainer}>
        <img ref={uploadedImage} className={styles.logo} src={logo} alt="Logo" />
        <div className={styles.title}>{title}</div>
        <img className={styles.headerControls} src={HeaderControlsImg} alt="Buttons" />
      </div>
      <div className={styles.messagesContainer}>{element}</div>
      <div className={styles.inputContainer}>{inputs}</div>
    </div>
  )
}

export default BotPlayground
