import { IClinicalPath, ICustomClinicalPathsDescriptor, IDashboardDoc } from "@limbic/types"
import * as self from "./clinicalPaths"
import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import invariant from "../../utils/invariant"
import Logger from "../../utils/Logger"
import formatUnicorn from "../../utils/formatUnicorn"

const TOTAL_RETRIES = 3
const CLINICAL_PATHS_URL = `${BACKEND_URL}/v1/dashboard/{serviceApiKey}/clinical-paths`

const method = "POST"
const headers = new Headers()
headers.set("x-api-key", API_KEY)
headers.set("Content-Type", "application/json")

export async function updateClinicalPaths(
  serviceApiKey: string,
  clinicalPaths: IClinicalPath[],
  retry = 0
): Promise<IDashboardDoc | undefined> {
  invariant(serviceApiKey, "Cannot update clinical paths without a serviceApiKey")
  invariant(clinicalPaths?.length, "Cannot update clinical paths without data")
  const URL = formatUnicorn(CLINICAL_PATHS_URL, { serviceApiKey })
  const body = JSON.stringify({ clinicalPaths })
  try {
    const result = await fetch(URL, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "updateClinicalPaths failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`updateClinicalPaths body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("updateClinicalPaths retry")
      await delay(1)
      return await self.updateClinicalPaths(serviceApiKey, clinicalPaths, retry + 1)
    }
    throw e
  }
}

const CUSTOM_CLINICAL_PATHS_URL = `${CLINICAL_PATHS_URL}/custom`

export async function updateCustomClinicalPaths(
  serviceApiKey: string,
  customClinicalPaths: ICustomClinicalPathsDescriptor[],
  retry = 0
): Promise<IDashboardDoc | undefined> {
  invariant(serviceApiKey, "Cannot update clinical paths without a serviceApiKey")

  const URL = formatUnicorn(CUSTOM_CLINICAL_PATHS_URL, { serviceApiKey })
  const body = JSON.stringify({ customClinicalPaths })
  try {
    const result = await fetch(URL, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "updateClinicalPaths failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`updateClinicalPaths body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("updateClinicalPaths retry")
      await delay(1)
      return await self.updateCustomClinicalPaths(serviceApiKey, customClinicalPaths, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
