// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J503WXzTOlQCWUZnkqX_{display:flex}.Sk0z8rnB5WhhOHPczbpX{display:flex;justify-content:flex-end;width:100%}.vzY8kjWo5RkAEWSeFKGv{display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/QuestionEditor/components/PromptSettings/PromptSettings.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,aAAA,CACA,cAAA,CACA,sBAAA","sourcesContent":[".promptSettings {\n  display: flex;\n}\n\n.promptSettingsContainer {\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n}\n\n.checkboxContainer {\n  display: flex;\n  flex-flow: row;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promptSettings": "J503WXzTOlQCWUZnkqX_",
	"promptSettingsContainer": "Sk0z8rnB5WhhOHPczbpX",
	"checkboxContainer": "vzY8kjWo5RkAEWSeFKGv"
};
export default ___CSS_LOADER_EXPORT___;
