import { FormControl, Select, Typography, MenuItem } from "@mui/material"
import React from "react"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import {
  BooleanOperators,
  ListOperators,
  NumberOperators,
  NumberOperatorsLabelMap,
  TextOperators,
  AllOperators
} from "@limbic/types"
import { Operators } from "../../AdvancedConditionEditor"
import styles from "./Operator.module.scss"

interface Props {
  operators:
    | TextOperators[]
    | NumberOperators[]
    | BooleanOperators[]
    | ListOperators[]
    | AllOperators[]
  handleOperatorChange: (value: Operators) => void
}

function Operator(props: Props): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { advancedConditionEditorState } = nodeEditorStore

  return (
    <>
      <Typography className={styles.descriptionSubtitle} variant="subtitle1">
        Operator
      </Typography>
      <FormControl className={styles.formControlAdvancedCondition}>
        <Select
          labelId="operator-label"
          id="operator-select"
          value={advancedConditionEditorState.operator}
          label="Operator"
          onChange={e => props.handleOperatorChange(e.target.value as Operators)}>
          {props.operators.map(op => {
            const opLabel = NumberOperatorsLabelMap[op]
              ? `${op} (${NumberOperatorsLabelMap[op]})`
              : op
            return (
              <MenuItem key={`operator-${op}`} value={op}>
                {opLabel}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  )
}

export default Operator
