import React from "react"
import {
  Cell,
  CellTemplate,
  Compatible,
  getCellProperty,
  Uncertain,
  isNavigationKey,
  isAlphaNumericKey,
  keyCodes,
  UncertainCompatible
} from "@silevis/reactgrid"
import { TrashIcon } from "@radix-ui/react-icons"
import { IconButton } from "@mui/material"
import styles from "./CellWithButton.module.scss"

export interface CellWithButton extends Cell {
  type: "cellWithButton"
  text: string
  onClick?: (cell: Compatible<CellWithButton>) => void
}

export class CellWithButtonTemplate implements CellTemplate<Cell | CellWithButton> {
  getCompatibleCell(uncertainCell: Uncertain<CellWithButton>): Compatible<CellWithButton> {
    const text = getCellProperty(uncertainCell, "text", "string")
    const value = parseFloat(text)
    const onClick = uncertainCell.onClick
    return { ...uncertainCell, text, value, onClick }
  }

  render(
    cell: Compatible<CellWithButton>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<CellWithButton>, commit: boolean) => void
  ): React.ReactElement {
    if (!isInEditMode) {
      return (
        <>
          {cell.text.toLocaleLowerCase() === "unused" ? (
            <span>
              {cell.text}
              <IconButton
                className={styles.deleteButton}
                color="primary"
                onClick={() => cell.onClick?.(cell)}>
                <TrashIcon />
              </IconButton>
            </span>
          ) : (
            <span>{cell.text}</span>
          )}
        </>
      )
    }
    return (
      <input
        ref={input => input?.focus()}
        defaultValue={cell.text}
        onChange={e =>
          onCellChanged(this.getCompatibleCell({ ...cell, text: e.currentTarget.value }), false)
        }
        onCopy={e => e.stopPropagation()}
        onCut={e => e.stopPropagation()}
        onPaste={e => e.stopPropagation()}
        onPointerDown={e => e.stopPropagation()}
        onKeyDown={e => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode)) e.stopPropagation()
        }}
      />
    )
  }

  handleKeyDown(
    cell: Compatible<CellWithButton>,
    keyCode: number,
    ctrl: boolean,
    _shift: boolean,
    alt: boolean
  ): { cell: Compatible<CellWithButton>; enableEditMode: boolean } {
    if (!ctrl && !alt && isAlphaNumericKey(keyCode)) return { cell, enableEditMode: true }
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
    }
  }

  update(
    cell: Compatible<CellWithButton>,
    cellToMerge: UncertainCompatible<CellWithButton>
  ): Compatible<CellWithButton> {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text })
  }
}
