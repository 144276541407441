export interface AstPosition {
  start: { line: number; column: number; offset: number }
  end: { line: number; column: number; offset: number }
}

export enum DiffMethod {
  CHARS = "diffChars",
  WORDS = "diffWords",
  WORDS_WITH_SPACE = "diffWordsWithSpace",
  LINES = "diffLines",
  TRIMMED_LINES = "diffTrimmedLines",
  SENTENCES = "diffSentences",
  CSS = "diffCss"
}
