import React, { useEffect, useState } from "react"
import {
  Autocomplete,
  Dialog as MuiDialog,
  FormControl,
  TextField,
  Typography
} from "@mui/material/"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Button from "../../../Button"
import { useFlowStore, useNodeEditorStore } from "../../../../context/rootStoreContext"
import { EditorType } from "../../models/NodeEditors"
import { observer } from "mobx-react"
import styles from "./ConditionEditor.module.scss"
import { Conditions } from "@limbic/types"

const actionMap: Record<Conditions, string> = {
  exists: "Answer exists",
  isTrue: "Answer is true",
  isValidPhoneNumber: "Is a valid phone number",
  isValidMobilePhone: "Is a valid mobile phone number",
  isEmail: "Is a valid email address",
  isDirectReferral: "Is a direct referral"
}

interface Props {
  ActionButton?: React.ReactChild | React.ReactChild[]
  children?: React.ReactChild | React.ReactChild[]
  onClose?: (value: boolean) => void
  open?: boolean | undefined
  title?: string
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
}

function ConditionEditor(props: Props): JSX.Element {
  const { ActionButton, title, maxWidth = "md" } = props
  const flowStore = useFlowStore()
  const nodeEditorStore = useNodeEditorStore()
  const { conditionEditorState } = nodeEditorStore
  const { action } = conditionEditorState.ifStatement

  const [hasSourceEdge, setHasSourceEdge] = useState<boolean>(false)

  useEffect(() => {
    const nodeId = nodeEditorStore.nodeId
    const currentNode = flowStore.nodes.find(node => node.id === nodeId)

    const nodeHasSourceEdge = flowStore.edges.find(edge => edge.target === currentNode?.id)
    if (!nodeHasSourceEdge) {
      setHasSourceEdge(false)
    } else {
      setHasSourceEdge(true)
    }

    if (currentNode && currentNode.settings && Object.keys(currentNode.settings).length) {
      const { settings } = currentNode
      const { ifStatement, thenBlock, elseBlock } = settings!
      nodeEditorStore.updateState(EditorType.CONDITION, { ifStatement, thenBlock, elseBlock })
    } else {
      nodeEditorStore.resetState(EditorType.CONDITION)
    }
  }, [
    flowStore.conditionEditorNodeID,
    flowStore.nodes,
    flowStore.edges,
    setHasSourceEdge,
    nodeEditorStore
  ])

  const handleClose = (): void => {
    nodeEditorStore.setEditorClosed(EditorType.CONDITION)
  }

  const handleSave = (): void => {
    const nodeId = nodeEditorStore.nodeId
    const label = actionMap[action]
    flowStore.updateConditionNode(nodeId, conditionEditorState, label)
  }

  const handleActionChange = (value: string): void => {
    updateState({
      ...conditionEditorState,
      ifStatement: { ...conditionEditorState.ifStatement, action: value }
    })
  }

  const updateState = data => {
    nodeEditorStore.updateState(EditorType.CONDITION, data)
  }

  return (
    <MuiDialog
      fullWidth
      maxWidth={maxWidth}
      open={nodeEditorStore.conditionEditorOpen}
      onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      {hasSourceEdge ? (
        <DialogContent>
          <div className={styles.nodeEditorContainer}>
            <div className={styles.optionsContainer}>
              <Typography>Options</Typography>
              <div className={styles.optionsContent}>
                <div className={styles.formControl}>
                  <FormControl className={styles.formControl} fullWidth>
                    <Autocomplete
                      className={styles.autocompleteOptions}
                      multiple={false}
                      id="selected-condition"
                      onChange={(_event, value) => handleActionChange(value!)}
                      options={Object.values(Conditions).map(action => action)}
                      getOptionLabel={action => actionMap[action]}
                      value={action}
                      filterSelectedOptions
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Condition"
                          placeholder="Please select a condition to be checked"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="subtitle1">
            Please note, in order to edit this Condition block you need to first connect it to a
            parent node
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        {ActionButton && ActionButton}
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSave} disabled={!hasSourceEdge}>
          Save
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default observer(ConditionEditor)
