import React from "react"
import { Typography } from "@mui/material/"
import { observer } from "mobx-react"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageList from "../MessageList"
import MessageForm from "../MessageForm"
import styles from "./CollectDateOfBirth.module.scss"

function CollectDateOfBirth(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <Typography align="right" className={styles.collectDateOfBirthTitle} variant="subtitle1">
        Bot message to ask user's birthday:
      </Typography>
      <MessageList
        messages={messages?.askBirthday ?? []}
        messageType="askBirthday"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askBirthday}
        placeholder="Type a message here..."
        name="askBirthday"
        updateState={updateState}
        messages={messages?.askBirthday ?? []}
      />
    </>
  )
}

export default observer(CollectDateOfBirth)
