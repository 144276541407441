import { action, makeObservable, observable } from "mobx"
import { ILimbicSettings } from "@limbic/types"
import { SettingsStatus } from "../../models/Settings"
import { getSettings, updateSettings } from "../../backend/api/settings"

export class SettingsStore {
  @observable settings?: ILimbicSettings | undefined
  @observable areSettingsLoading!: boolean

  constructor() {
    this.reset()
    makeObservable(this)
  }

  /** Actions */

  @action
  reset(): void {
    this.settings = undefined
    this.areSettingsLoading = false
  }

  @action
  setIsLoading(value: boolean): void {
    this.areSettingsLoading = value
  }

  @action
  setSettings(value: ILimbicSettings | undefined): void {
    this.settings = value
  }

  @action
  async init(): Promise<SettingsStatus> {
    const [data, status] = await getSettings()

    if (status === SettingsStatus.Success && data?.settings) {
      this.setSettings(data?.settings)
      return SettingsStatus.Success
    } else {
      return SettingsStatus.RequestFailed
    }
  }

  @action
  async update(settings): Promise<SettingsStatus> {
    const [data, status] = await updateSettings(settings)
    if (status === SettingsStatus.Success && data?.settings) {
      this.setSettings(settings)
      return SettingsStatus.Success
    } else {
      return SettingsStatus.RequestFailed
    }
  }
}
