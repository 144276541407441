/** This modifies the console.log to display mobx observables as plain objects */
import "./polyfills.ts"
import * as React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter as Router } from "react-router-dom"
import App from "./app/App"
import Logger from "./utils/Logger"
import Loggable from "./models/Loggable"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import theme from "./app/styles/theme"
import reportWebVitals from "./reportWebVitals"
import packageInfo from "../package.json"
import rootStore from "./app/stores/RootStore"
import { RootStoreProvider } from "./app/context/rootStoreContext"
import { CssBaseline } from "@mui/material"
import "./app/styles/styles.scss"

const dist = process.env.REACT_APP_DIST
const dsn = "https://28700c4c23214fc6af2fee833ca668e6@o138988.ingest.sentry.io/6190410"
const allowUrls = undefined

try {
  Logger.setup(packageInfo.version, dsn, dist, allowUrls)
  const loggerSingleton = Logger.getInstance()
  Loggable.setLogger(loggerSingleton)
} catch (e) {
  console.log(`init: ${e.message}`)
  Logger.getInstance().exception(e, "init -> Trackers setup")
}

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <RootStoreProvider rootStore={rootStore}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <CssBaseline />
            <App />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </RootStoreProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
