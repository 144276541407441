import React from "react"
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material/"
import InlinePicker from "./InlinePicker"
import { IPromptSettings, PromptType } from "../../../../models/NodeEditors"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import { IDashboardNodePromptSettings } from "@limbic/types"
import styles from "./PromptSettings.module.scss"
import Date from "./Date"
import PhoneNumber from "./PhoneNumber/PhoneNumber"

interface Props {
  promptType: string
  updateNode?: (data: IPromptSettings) => void
  onChange?: (data: boolean, type: string) => void
}

const Prompts = {
  [PromptType.Text]: (_props, onChange, promptSettings: IDashboardNodePromptSettings) => (
    <div className={styles.checkboxContainer}>
      <FormGroup className={styles.checkboxFormGroup}>
        <FormControlLabel
          className={styles.formControlLabel}
          value="isUndoable"
          control={
            <Checkbox
              checked={promptSettings.isUndoable}
              onChange={(_event, value) => onChange(value, "isUndoable")}
            />
          }
          label="Undoable"
          labelPlacement="start"
        />
        <FormControlLabel
          className={styles.formControlLabel}
          value="forceValue"
          control={
            <Checkbox
              checked={promptSettings.forceValue}
              onChange={(_event, value) => onChange(value, "forceValue")}
            />
          }
          label="Force value"
          labelPlacement="start"
        />
      </FormGroup>
      <FormGroup className={styles.checkboxFormGroup}>
        <FormControlLabel
          className={styles.formControlLabel}
          value="trackResponse"
          control={
            <Checkbox
              checked={promptSettings.trackResponse}
              onChange={(_event, value) => onChange(value, "trackResponse")}
            />
          }
          label="Track response"
          labelPlacement="start"
        />
        <FormControlLabel
          className={styles.formControlLabel}
          value="crisisDetection"
          control={
            <Checkbox
              checked={promptSettings.crisisDetection}
              onChange={(_event, value) => onChange(value, "crisisDetection")}
            />
          }
          label="Crisis detection"
          labelPlacement="start"
        />
      </FormGroup>
    </div>
  ),
  [PromptType.PhoneNumber]: (_props, _onChange) => <PhoneNumber />,
  [PromptType.Email]: (_props, onChange, promptSettings: IDashboardNodePromptSettings) => {
    return (
      <div className={styles.checkboxContainer}>
        <FormGroup className={styles.checkboxFormGroup}>
          <FormControlLabel
            className={styles.formControlLabel}
            value="isUndoable"
            control={
              <Checkbox
                checked={promptSettings.isUndoable}
                onChange={(_event, value) => onChange(value, "isUndoable")}
              />
            }
            label="Undoable"
            labelPlacement="start"
          />
          <FormControlLabel
            className={styles.formControlLabel}
            value="trackResponse"
            control={
              <Checkbox
                checked={promptSettings.trackResponse}
                onChange={(_event, value) => onChange(value, "trackResponse")}
              />
            }
            label="Track response"
            labelPlacement="start"
          />
          <FormControlLabel
            className={styles.formControlLabel}
            value="forceValue"
            control={
              <Checkbox
                checked={promptSettings.forceValue}
                onChange={(_event, value) => onChange(value, "forceValue")}
              />
            }
            label="Force value"
            labelPlacement="start"
          />
          <FormControlLabel
            className={styles.formControlLabel}
            value="setPeople"
            control={
              <Checkbox
                checked={promptSettings.setPeople ?? false}
                onChange={(_event, value) => onChange(value, "setPeople")}
              />
            }
            label="Set user data in Mixpanel"
            labelPlacement="start"
          />
        </FormGroup>
      </div>
    )
  },
  [PromptType.Name]: (_props, onChange, promptSettings: IDashboardNodePromptSettings) => (
    <div className={styles.checkboxContainer}>
      <FormGroup className={styles.checkboxFormGroup}>
        <FormControlLabel
          className={styles.formControlLabel}
          value="isUndoable"
          control={
            <Checkbox
              checked={promptSettings.isUndoable ?? false}
              onChange={(_event, value) => onChange(value, "isUndoable")}
            />
          }
          label="Undoable"
          labelPlacement="start"
        />
        <FormControlLabel
          className={styles.formControlLabel}
          value="trackResponse"
          control={
            <Checkbox
              checked={promptSettings.trackResponse}
              onChange={(_event, value) => onChange(value, "trackResponse")}
            />
          }
          label="Track response"
          labelPlacement="start"
        />
        <FormControlLabel
          className={styles.formControlLabel}
          value="setPeople"
          control={
            <Checkbox
              checked={promptSettings.setPeople ?? false}
              onChange={(_event, value) => onChange(value, "setPeople")}
            />
          }
          label="Set user data in Mixpanel"
          labelPlacement="start"
        />
      </FormGroup>
    </div>
  ),
  [PromptType.Checkbox]: (props, _onChange) => <InlinePicker {...props} />,
  [PromptType.InlinePicker]: (props, _onChange) => <InlinePicker {...props} />,
  [PromptType.InlinePickerMultiSelect]: (props, onChange) => (
    <InlinePicker isMulti onChange={onChange} {...props} />
  ),
  [PromptType.Date]: (_props, _onChange) => <Date />
}

function PromptSettings(props: Props): JSX.Element {
  const { promptType, onChange, ...other } = props
  const nodeEditorStore = useNodeEditorStore()
  const { questionEditorState } = nodeEditorStore

  return (
    <div className={styles.promptSettingsContainer}>
      {Prompts[promptType](other, onChange, questionEditorState.promptSettings)}
    </div>
  )
}

export default PromptSettings
