import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useMatch, useNavigate } from "react-router-dom"
import styles from "./DashboardNavigation.module.scss"

type TabKeys =
  | "/"
  | "keeping-safe"
  | "clinical-paths"
  | "bot-questions"
  | "goodbye-messages"
  | "other"

function DashboardNavigation(): JSX.Element {
  const navigate = useNavigate()
  const matchRoute = useMatch("/*")
  const [selected, setSelected] = React.useState<TabKeys>(
    () => (matchRoute?.params["*"]?.replace("/", "") || "/") as TabKeys
  )

  const handleChange = (_event, value) => {
    setSelected(value)
    navigate(value)
  }

  return (
    <div className={styles.navigation}>
      <Tabs
        value={selected}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Access Dashboard Navigation">
        <Tab label="Configuration" value="/" />
        <Tab label="Keeping Safe Leaflet" value="keeping-safe" />
        <Tab label="Clinical Paths" value="clinical-paths" />
        <Tab label="Flow & Questions" value="bot-questions" />
        <Tab label="Integration" value="integration" />
      </Tabs>
    </div>
  )
}

export default DashboardNavigation
