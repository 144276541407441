const formatTermsAndConditions = (names: string[], linksText: string[], links: string[]) => {
  let organisationTerms
  names.forEach((name, index) => {
    const formattedLink = `${name} [${linksText[index]}](${links[index]})`
    organisationTerms = organisationTerms ? organisationTerms + formattedLink : formattedLink
    if (index !== names.length - 1) {
      organisationTerms = organisationTerms + "\n"
    }
  })

  return organisationTerms
}

export default formatTermsAndConditions
