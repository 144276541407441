// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dAmrpgKVOq__CZbY9FAK{display:flex;align-items:center;justify-content:center;width:100%;margin:8px 0}.qBDWSHzcL5HHa1MiA_QG{width:40%}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ActionEditor/components/SetIAPT/SetIAPT.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,YAAA,CAGF,sBACE,SAAA","sourcesContent":[".setIAPTIDContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  margin: 8px 0;\n}\n\n.formControlSetIAPTIDContainer {\n  width: 40%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setIAPTIDContainer": "dAmrpgKVOq__CZbY9FAK",
	"formControlSetIAPTIDContainer": "qBDWSHzcL5HHa1MiA_QG"
};
export default ___CSS_LOADER_EXPORT___;
