import React from "react"
import { observer } from "mobx-react"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material"
import { ChatFlowsEnum } from "@limbic/types"
import classes from "clsx"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import styles from "./SpineSearch.module.scss"

function SpineSearch(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  const handleNumberChange = (
    event: SelectChangeEvent<number>,
    key: "retryPostcodeTimes" | "retryGPTimes" | "spineSearchCount"
  ) => {
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [ChatFlowsEnum.SPINE_SEARCH]: {
          ...chatFlowSettings[ChatFlowsEnum.SPINE_SEARCH],
          [key]: event.target.value
        }
      }
    })
  }

  return (
    <>
      <Typography align="right" className={styles.spineSearchTitle} variant="subtitle1">
        Bot message to ask for user's postcode:
      </Typography>
      <MessageList
        messages={messages?.askPostCodeOfUser ?? []}
        messageType="askPostCodeOfUser"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askPostCodeOfUser}
        placeholder="Type a message here..."
        name="askPostCodeOfUser"
        updateState={updateState}
        messages={messages?.askPostCodeOfUser ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.spineSearchTitle} variant="subtitle1">
        Bot message before searching the user in the NHS database:
      </Typography>
      <MessageList
        messages={messages?.sayIntroToSpineSearch ?? []}
        messageType="sayIntroToSpineSearch"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayIntroToSpineSearch}
        placeholder="Type a message here..."
        name="sayIntroToSpineSearch"
        updateState={updateState}
        messages={messages?.sayIntroToSpineSearch ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.spineSearchTitle} variant="subtitle1">
        Bot message when user is successfully found in the NHS database:
      </Typography>
      <MessageList
        messages={messages?.saySearchSuccessful ?? []}
        messageType="saySearchSuccessful"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.saySearchSuccessful}
        placeholder="Type a message here..."
        name="saySearchSuccessful"
        updateState={updateState}
        messages={messages?.saySearchSuccessful ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.spineSearchTitle} variant="subtitle1">
        Bot message when user is found in the NHS but not their GP:
      </Typography>
      <MessageList
        messages={messages?.sayICouldntFindYourGP ?? []}
        messageType="sayICouldntFindYourGP"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayICouldntFindYourGP}
        placeholder="Type a message here..."
        name="sayICouldntFindYourGP"
        updateState={updateState}
        messages={messages?.sayICouldntFindYourGP ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="canReferWithoutGP"
        label="Continue with referral even if the user is not eligible"
        labelPlacement="start"
        updateState={updateState}
      />
      <Typography
        align="right"
        className={classes(styles.spineSearchTitle, {
          [styles.spineSearchTitleDisabled]: !chatFlowSettings.spineSearch?.canReferWithoutGP
        })}
        variant="subtitle1">
        Bot message in case that the GP was not possible to be identified:
      </Typography>
      <MessageList
        disabled={!chatFlowSettings.spineSearch?.canReferWithoutGP}
        messages={messages?.askWantMeToReferYouAnywaySpineSearch ?? []}
        messageType="askWantMeToReferYouAnywaySpineSearch"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={!chatFlowSettings.spineSearch?.canReferWithoutGP}
        value={chatFlowSettings.currentMessage?.askWantMeToReferYouAnywaySpineSearch}
        placeholder="Type a message here..."
        name="askWantMeToReferYouAnywaySpineSearch"
        updateState={updateState}
        messages={messages?.askWantMeToReferYouAnywaySpineSearch ?? []}
      />
      <SolidDivider />
      <div className={styles.retriesNumberSelect}>
        <Typography>Number of attempts to check a failed postcode</Typography>
        <FormControl>
          <InputLabel id="retry-postcode-times-label">Retries</InputLabel>
          <Select
            className={styles.retriesSelector}
            labelId="retry-postcode-times-label"
            id="retry-postcode-times-select"
            value={chatFlowSettings.spineSearch?.retryPostcodeTimes ?? 3}
            label="Retries"
            onChange={event => handleNumberChange(event, "retryPostcodeTimes")}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
      </div>
      <SolidDivider />
      <div className={styles.retriesNumberSelect}>
        <Typography>Number of attempts to check the NHS for user's data</Typography>
        <FormControl>
          <InputLabel id="spine-search-count-label">Retries</InputLabel>
          <Select
            className={styles.retriesSelector}
            labelId="spine-search-count-label"
            id="spine-search-count-select"
            value={chatFlowSettings.spineSearch?.spineSearchCount ?? 3}
            label="Retries"
            onChange={event => handleNumberChange(event, "spineSearchCount")}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={styles.allowReferralCheckboxContainer}>
        <FormControlWithCheckBox
          name="shouldUseServiceSearchFallback"
          label={`If the user is not found on NHS' spine after ${chatFlowSettings.spineSearch?.spineSearchCount} retries, provide the user with further options to continue the referral`}
          labelPlacement="start"
          updateState={updateState}
        />
        <Typography
          variant="subtitle1"
          className={classes(styles.allowReferralToContinueTip, {
            [styles.allowReferralToContinueTipWarning]:
              !chatFlowSettings.spineSearch?.shouldUseServiceSearchFallback
          })}>
          <strong>WARNING:</strong> Please note that by disabling this, after the{" "}
          {chatFlowSettings.spineSearch?.spineSearchCount} retries, the user will be notified with
          the below messages, and then the chat will come to an end
        </Typography>
      </div>
      <Typography align="right" className={styles.spineSearchCustomTitle} variant="subtitle1">
        Bot message if referral cannot continue due <br /> to reaching the failed attempts
      </Typography>
      <MessageList
        disabled={chatFlowSettings.spineSearch?.shouldUseServiceSearchFallback}
        messages={messages?.sayCannotReferYou ?? []}
        messageType="sayCannotReferYou"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={chatFlowSettings.spineSearch?.shouldUseServiceSearchFallback}
        value={chatFlowSettings.currentMessage?.sayCannotReferYou}
        placeholder="Type a message here..."
        name="sayCannotReferYou"
        updateState={updateState}
        messages={messages?.sayCannotReferYou ?? []}
      />
    </>
  )
}

export default observer(SpineSearch)
