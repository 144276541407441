import self from "./languages"
import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import { IDashboardDoc, LanguageCodes } from "@limbic/types"
import invariant from "../../utils/invariant"
import Logger from "../../utils/Logger"
import formatUnicorn from "../../utils/formatUnicorn"

const TOTAL_RETRIES = 3
const CONFIGURATION_URL = `${BACKEND_URL}/v1/dashboard/{serviceApiKey}/translations/languages`

const method = "POST"
const headers = new Headers()
headers.set("x-api-key", API_KEY)
headers.set("Content-Type", "application/json")

export default async function updateLanguages(
  serviceApiKey: string,
  defaultLanguage: LanguageCodes,
  supportedLanguages: LanguageCodes[] | undefined,
  retry = 0
): Promise<IDashboardDoc | undefined> {
  invariant(serviceApiKey, "Cannot update translation languages without a serviceApiKey")

  const payload = {
    defaultLanguage,
    supportedLanguages
  }
  const URL = formatUnicorn(CONFIGURATION_URL, { serviceApiKey })
  const body = JSON.stringify(payload)

  try {
    const result = await fetch(URL, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "updateLanguages failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`updateLanguages body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("updateLanguages retry")
      await delay(1)
      return await self(serviceApiKey, defaultLanguage, supportedLanguages, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
