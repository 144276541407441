// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UAjKEoPITl4d7hyrWfIK{margin-top:12px}.BYwfmNLQXNehTsZ5xIBl{padding-bottom:9px;width:100%;text-align:left}.ih2Dx3pL9Ynl037Eg89e{display:flex;justify-content:center;width:100%}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/CollectEmail/CollectEmail.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,kBAAA,CACA,UAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,UAAA","sourcesContent":[".collectEmailContainer {\n  margin-top: 12px;\n}\n\n.emailLabel {\n  padding-bottom: 9px;\n  width: 100%;\n  text-align: left;\n}\n\n.optionContainer {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collectEmailContainer": "UAjKEoPITl4d7hyrWfIK",
	"emailLabel": "BYwfmNLQXNehTsZ5xIBl",
	"optionContainer": "ih2Dx3pL9Ynl037Eg89e"
};
export default ___CSS_LOADER_EXPORT___;
