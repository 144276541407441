// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ebROPkyTHYW5pi0DRqEf{width:100%;margin-bottom:16px}.j5QeRkoSMJHuqy0LzguC{border:none !important;color:#fff !important;margin:0 8px 8px 0 !important;background-color:#fd81a0 !important}.j5QeRkoSMJHuqy0LzguC:hover{opacity:.7;background-color:#fd81a0}.j5QeRkoSMJHuqy0LzguC:focus{opacity:.9;background-color:#fd81a0}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/ChatFlowOptions/ChatFlowOptions.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,kBAAA,CAGF,sBACE,sBAAA,CACA,qBAAA,CACA,6BAAA,CACA,mCAAA,CACA,4BACE,UAAA,CACA,wBAAA,CAEF,4BACE,UAAA,CACA,wBAAA","sourcesContent":[".autocompleteOptions {\n  width: 100%;\n  margin-bottom: 16px;\n}\n\n.optionChip {\n  border: none !important;\n  color: white !important;\n  margin: 0 8px 8px 0 !important;\n  background-color: #fd81a0 !important;\n  &:hover {\n    opacity: 0.7;\n    background-color: #fd81a0;\n  }\n  &:focus {\n    opacity: 0.9;\n    background-color: #fd81a0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autocompleteOptions": "ebROPkyTHYW5pi0DRqEf",
	"optionChip": "j5QeRkoSMJHuqy0LzguC"
};
export default ___CSS_LOADER_EXPORT___;
