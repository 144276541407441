import React from "react"
import styles from "./BotOptions.module.scss"

interface Props {
  options?: string[]
  userMessageBackground?: string
}

export default function BotOptions(props: Props): JSX.Element {
  const { options, userMessageBackground } = props

  return (
    <div className={styles.botInputsContainer}>
      {options?.map(option => (
        <div
          key={option}
          style={{ backgroundColor: userMessageBackground }}
          className={styles.botButton}>
          {option}
        </div>
      ))}
    </div>
  )
}
