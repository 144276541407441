import React from "react"
import Button from "../../components/Button"
import Dropdown from "../../components/Dropdown"
import { Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Loading from "../../components/Loading"
import Dialog from "../../components/Dialog"
import { ServiceStatus } from "../../../models/Service"
import { observer } from "mobx-react"
import { useServiceStore, useSettingsStore } from "../../context/rootStoreContext"
import invariant from "../../../utils/invariant"
import { IServiceExtendedConfig } from "@limbic/types"
import CreateService from "./components/CreateService"

import styles from "./SelectService.module.scss"

function SelectService(): JSX.Element {
  const navigate = useNavigate()
  const serviceStore = useServiceStore()
  const settingsStore = useSettingsStore()
  const [service, setService] = React.useState<IServiceExtendedConfig>()
  const [servicesLoaded, setServicesLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState(false)
  const [settingsError, setSettingsError] = React.useState<boolean>(false)
  const [createServiceDialogueOpen, setCreateServiceDialogueOpen] = React.useState<boolean>(false)

  React.useEffect(() => {
    async function loadServices() {
      try {
        serviceStore.setIsLoading(true)
        await serviceStore.getServices()
        invariant(serviceStore.services?.length, "No services found")
        setService(serviceStore.services[0])
      } catch (e) {
        setError(true)
      } finally {
        setServicesLoaded(true)
        serviceStore.setIsLoading(false)
      }
    }

    async function getAndSetSettings() {
      try {
        settingsStore.setIsLoading(true)
        await settingsStore.init()
        invariant(
          settingsStore.settings,
          `Something went wrong fetching the dashboard settings - please try again`
        )
      } catch (e) {
        setError(true)
        setSettingsError(true)
        return
      } finally {
        settingsStore.setIsLoading(false)
      }
    }
    void getAndSetSettings()
    void loadServices()
  }, [serviceStore, settingsStore])

  React.useEffect(() => {
    if (servicesLoaded && !serviceStore.services?.length) {
      setError(true)
    }
  }, [serviceStore.services, servicesLoaded])

  const goToDashboard = async (key?: string) => {
    try {
      serviceStore.setIsLoading(true)
      serviceStore.setServiceKey(key ?? service?.serviceApiKey)
      const status = await serviceStore.getServiceData()
      if (status === ServiceStatus.NoServiceFound) {
        return
      }
      invariant(
        serviceStore.serviceData,
        `Something went wrong fetching service data for ${key ?? service?.serviceApiKey}`
      )
      navigate("/", { replace: true })
    } catch (e) {
      setError(true)
    } finally {
      serviceStore.setIsLoading(false)
    }
  }

  const openCreateServiceDialogue = async () => {
    setCreateServiceDialogueOpen(true)
  }

  const getError = (name: string | undefined) => {
    if (settingsError) return <span>An error occurred while trying to fetch the settings</span>
    if (name) {
      return <span>An error occurred while trying to fetch the data of {service?.name}.</span>
    } else {
      return <span>An error occurred while trying to fetch the data.</span>
    }
  }

  return (
    <div className={styles.layout}>
      <Dialog
        onClose={() => window.location.reload()}
        open={error || settingsError}
        title="Something went wrong"
        maxWidth="sm">
        {getError(service?.name)}
        <br />
        <span>Please try again.</span>
      </Dialog>
      <CreateService
        goToDashboard={goToDashboard}
        setCreateServiceDialogueOpen={setCreateServiceDialogueOpen}
        createServiceDialogueOpen={createServiceDialogueOpen}
      />
      {!!serviceStore.services?.length && (
        <>
          <Typography variant="h5">Select a service:</Typography>
          <Dropdown
            onSelect={key => setService(serviceStore.services!.find(s => s.serviceApiKey === key)!)}
            selected={serviceStore.services[0]}
            options={serviceStore.services}
          />
          <Button onClick={() => goToDashboard()}>Start</Button>
          <Button
            css={{ marginTop: "2em" }}
            color="secondary"
            onClick={() => openCreateServiceDialogue()}>
            Create a New Service
          </Button>
        </>
      )}
      {(serviceStore.isLoading || settingsStore.areSettingsLoading) && <Loading />}
    </div>
  )
}

export default observer(SelectService)
