import { action, computed, makeObservable, observable } from "mobx"
import Logo from "../assets/logo.png"
import ClickToAddIcon from "../assets/click-to-add.png"
import {
  BasicConfiguration,
  BigBotConfiguration,
  CCGCodes,
  EligibilityConfigurationDashboard,
  EligibilityType,
  IBotServiceData,
  IIAPTDashboardService,
  LanguageCodes
} from "@limbic/types"
import formatUnicorn from "../../utils/formatUnicorn"

const DEFAULT_IAPT: IIAPTDashboardService = {
  name: "Demo IAPT",
  backendInstanceID: "DEMO_MAIN",
  ccgs: [Object.keys(CCGCodes)[0]],
  ageThreshold: 18
}

const DEFAULT_BIG_BOT_SIDEBAR_TEXT = `
  <p>{organisationName} is a free and confidential NHS service <strong>for people aged 16 and over</strong> who 
  are registered with a GP in [SUPPORT AREAS].&nbsp;</p><p>Our service supports people with their mental health 
  and wellbeing by providing access to evidence-based, short term psychological therapies for mild to moderate 
  depression and anxiety disorders. We do this by recommending suitable therapies best suited to the problems 
  you are experiencing and the goals that you have set for yourself. Our therapy options include online courses 
  or remote therapy that can be attended in the comfort of your own home.</p><p>You can be referred by a health 
  professional, such as your GP, or you can self-refer online or by calling {organisationName} Administration Team 
  on {organisationPhoneNumbers}</p><p>If you prefer to use our standard referral from, click [here]</p>
`

export class ServiceConfigurationStore {
  @observable config!: BasicConfiguration
  @observable eligibility!: EligibilityConfigurationDashboard
  @observable bigBot!: BigBotConfiguration
  @observable sidebarIAPTIcon?: File
  @observable logo?: File
  @observable currentInput!: keyof this["config"]
  @observable errors!: Partial<Record<keyof this["config"], string>>

  constructor() {
    this.resetConfig()
    this.resetEligibility()
    this.resetBigBot()
    makeObservable(this)
  }

  /** Actions */

  @action
  resetConfig(): void {
    this.config = {
      title: "Limbic Self Referral Assistant",
      serviceName: "Demo Service",
      organisationName: "Demo Organisation",
      organisationTerms: "",
      organisationTermsNames: [],
      organisationTermsLinksText: [],
      organisationTermsLinks: [],
      organisationPhoneNumbers: [
        "Demo Clinic London: +44 1234 567890",
        "Demo Clinic Kent: +44 1234 567890",
        "Demo Clinic Calderdale: +44 1234 567890"
      ],
      crisisPhoneNumbers: "",
      logo: Logo,
      userMessageBackground: "#fd81a0",
      faqLink: "https://limbic.ai/faq"
    }
    this.currentInput = "title"
    this.errors = {}
  }

  @action
  resetEligibility(): void {
    this.eligibility = {
      type: "nhs",
      iapts: [JSON.parse(JSON.stringify(DEFAULT_IAPT))]
    }
    this.errors = {}
  }

  @action
  resetBigBot(): void {
    this.bigBot = {
      sidebarTitle: this.getDefaultSideBarTitle(this.config),
      sidebarText: this.getDefaultSideBarText(this.config),
      sidebarIAPTIcon: ClickToAddIcon
    }
    this.sidebarIAPTIcon = undefined
  }

  @action
  sync(data?: IBotServiceData): void {
    /**
     * Need to reset before we sync again because in the
     * respective setters they will not change
     */
    this.resetConfig()
    this.resetEligibility()
    this.resetBigBot()
    this.setConfig(data?.configuration ?? {})
    this.setEligibilityConfig(data?.eligibility?.dashboard ?? {})
    this.setBigBotConfig(data?.bigBot ?? {})
  }

  @action
  setConfig(config: Partial<this["config"]>): void {
    this.config = { ...this.config, ...config }
  }

  @action
  setEligibilityConfig(eligibilityConfig: Partial<this["eligibility"]>): void {
    this.eligibility = { ...this.eligibility, ...eligibilityConfig }
  }

  @action
  setBigBotConfig(bigBot: Partial<this["bigBot"]>): void {
    this.bigBot = { ...this.bigBot, ...bigBot }
  }

  @action
  setBigBotSidebarText(data: string | undefined): void {
    this.bigBot = {
      ...this.bigBot,
      sidebarText: data
    }
  }

  @action
  setBigBotTitle(data: string | undefined): void {
    this.bigBot = {
      ...this.bigBot,
      sidebarTitle: data
    }
  }

  @action
  setIAPTIconURL(logoURL: string): void {
    this.bigBot = {
      ...this.bigBot,
      sidebarIAPTIcon: logoURL
    }
  }
  @action
  setIAPTIcon(logo?: File): void {
    this.sidebarIAPTIcon = logo
  }

  @action
  setEligibilityConfigForIAPT(
    eligibilityConfig: Partial<IIAPTDashboardService>,
    index: number
  ): void {
    const targetIAPT = JSON.parse(JSON.stringify(this.eligibility.iapts![index]))

    const updatedIAPT = {
      ...targetIAPT,
      ...eligibilityConfig
    }

    const updatedIAPTS = JSON.parse(JSON.stringify(this.eligibility.iapts))

    updatedIAPTS[index] = updatedIAPT

    this.eligibility = {
      ...this.eligibility,
      iapts: updatedIAPTS
    }
  }

  @action
  setEligibilityType(type: EligibilityType): void {
    this.eligibility = { ...this.eligibility, type }
  }

  @action
  setAttachmentURL(index: number, attachmentUrl: string): void {
    const targetIAPT = JSON.parse(JSON.stringify(this.eligibility.iapts![index]))

    const updatedIAPT = {
      ...targetIAPT,
      keepingSafeLeaflet: {
        ...targetIAPT.keepingSafeLeaflet,
        attachmentUrl: attachmentUrl ?? ""
      }
    }

    const updatedIAPTS = JSON.parse(JSON.stringify(this.eligibility.iapts))

    updatedIAPTS[index] = updatedIAPT

    this.eligibility = {
      ...this.eligibility,
      iapts: updatedIAPTS
    }
  }

  @action
  setAttachmentFile(index: number, file?: File): void {
    const targetIAPT = JSON.parse(JSON.stringify(this.eligibility.iapts![index]))

    const updatedIAPT = {
      ...targetIAPT,
      keepingSafeLeaflet: {
        ...targetIAPT.keepingSafeLeaflet,
        attachmentFile: file,
        attachmentUrl: ""
      }
    }

    const updatedIAPTS = JSON.parse(JSON.stringify(this.eligibility.iapts))

    updatedIAPTS[index] = updatedIAPT

    this.eligibility = {
      ...this.eligibility,
      iapts: updatedIAPTS
    }
  }

  @action
  setLogo(logo?: File): void {
    this.logo = logo
  }

  @action
  setCurrentInput(input: keyof this["config"]): void {
    this.currentInput = input
  }

  @action
  setErrors(errors: Partial<Record<keyof this["config"], string>>): void {
    this.errors = errors
  }

  @action
  validateConfig(): void {
    const CONFIG_ERRORS = {
      title: "Title is Required",
      serviceName: "A service or organisation name is Required",
      organisationName: "A service or organisation name is Required",
      organisationPhoneNumbers: "At least one phone number is Required",
      logo: "Title is Required",
      userMessageBackground: "Message color is Required"
    } as Partial<Record<keyof this["config"], string>>

    const errors: Partial<Record<keyof this["config"], string>> = {}
    for (const key of Object.keys(CONFIG_ERRORS)) {
      const value = this.config[key]

      if (key === "organisationPhoneNumbers") {
        errors.organisationPhoneNumbers =
          !!value.length && !!value[0].length //
            ? undefined
            : CONFIG_ERRORS[key]
      }

      if (key === "serviceName") {
        errors.serviceName =
          value || this.config.organisationName //
            ? undefined
            : CONFIG_ERRORS[key]
      }

      if (key === "organisationName") {
        errors.organisationName =
          value || this.config.serviceName //
            ? undefined
            : CONFIG_ERRORS[key]
      }

      errors[key] = value ? undefined : CONFIG_ERRORS[key]
    }

    this.setErrors(errors)
  }

  @action
  addIAPT(): void {
    const iapts = JSON.parse(JSON.stringify(this.eligibility.iapts ?? []))
    iapts.push(JSON.parse(JSON.stringify(DEFAULT_IAPT)))
    this.eligibility.iapts = iapts
  }

  @action
  removeIAPT(indexToRemove: number): void {
    const iapts = JSON.parse(JSON.stringify(this.eligibility.iapts ?? []))
    iapts.splice(indexToRemove, 1)
    this.eligibility.iapts = iapts
  }

  getDefaultSideBarText(config: BasicConfiguration): string {
    if (config.organisationName || config.organisationPhoneNumbers) {
      const values = {
        organisationName: config.organisationName,
        organisationPhoneNumbers: config.organisationPhoneNumbers
      }
      return formatUnicorn(DEFAULT_BIG_BOT_SIDEBAR_TEXT, values)
    }
    return DEFAULT_BIG_BOT_SIDEBAR_TEXT
  }

  getDefaultSideBarTitle(config: BasicConfiguration): string {
    if (config.organisationName) {
      return `Refer yourself to ${this.config.organisationName}`
    }
    return `Refer yourself to {organisationName}`
  }

  /** Getters / Setters */

  @computed
  get defaultLanguage(): LanguageCodes {
    return this.config.translations?.defaultLanguage ?? LanguageCodes.EN
  }

  @computed
  get supportedLanguages(): LanguageCodes[] {
    return this.config.translations?.supportedLanguages ?? []
  }
}
