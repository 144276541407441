import { action, makeObservable, observable } from "mobx"
import { BackendIntegration, BackendIntegrationType, IBotServiceData } from "@limbic/types"

/**
 * This store is used to manage external services integrations
 * such as PCMIS to allow the bot to create e.g appointments.
 */
export class ServiceIntegrationStore {
  @observable config!: BackendIntegration
  @observable logo?: File
  @observable errors!: Partial<Record<keyof this["config"], string>>

  constructor() {
    this.reset()
    makeObservable(this)
  }

  /** Actions */

  @action
  reset(): void {
    this.config = { type: BackendIntegrationType.Webhooks }
    this.errors = {}
  }

  @action
  sync(data?: IBotServiceData): void {
    this.setConfig(data?.integration ?? {})
  }

  @action
  setConfig(config: Partial<this["config"]>): void {
    this.config = { ...this.config, ...config }
  }

  @action
  setLogo(logo?: File): void {
    this.logo = logo
  }

  @action
  setErrors(errors: Partial<Record<keyof this["config"], string>>): void {
    this.errors = errors
  }

  @action
  validateConfig(): void {
    const CONFIG_ERRORS = {
      type: "Please select a type",
      metaData: "Please enter a valid JSON"
    } as Partial<Record<keyof this["config"], string>>

    const errors: Partial<Record<keyof this["config"], string>> = {}
    for (const key of Object.keys(CONFIG_ERRORS)) {
      const value = this.config[key]

      errors[key] = value ? undefined : CONFIG_ERRORS[key]
    }

    this.setErrors(errors)
  }
}
