import React, { ForwardedRef, forwardRef, PropsWithChildren, useMemo } from "react"
import { CSSProperties, styled } from "@stitches/react"
import { Connection, Handle, useStore } from "react-flow-renderer"
import { HandleStyleFn, getDynamicHandles } from "../../../../../utils/getDynamicHandles"
import { Row } from "../../components/Layout"
import { useFlowStore } from "../../../../context/rootStoreContext"

const ERROR_COLOUR = "#ff5656"

interface IProps {
  id: string
  dragging?: boolean
  dynamicHandles?: boolean
  sourceHandleStyle?: CSSProperties | HandleStyleFn
  targetHandleStyle?: CSSProperties | HandleStyleFn
  children?: React.ReactChild | React.ReactChild[]
}

const checkConnection = ({ source, target }: Connection): boolean => {
  return source !== target
}

const NodeContainer = forwardRef(
  (props: PropsWithChildren<IProps>, ref: ForwardedRef<HTMLDivElement>) => {
    const { id, dragging, dynamicHandles, sourceHandleStyle, targetHandleStyle } = props
    const store = useStore()
    const flowStore = useFlowStore()
    const node = flowStore.getNode(id)

    const h = useMemo(() => {
      if (!dynamicHandles) return null
      const handles = getDynamicHandles(id, store, sourceHandleStyle, targetHandleStyle)

      return handles.map(handle => {
        const showHandleLabels =
          handle.type === "source" &&
          (node?.type === "condition" || node?.type === "advancedCondition")
        let label, labelStyle
        if (showHandleLabels && handle.position === "bottom") {
          label = "True"
          labelStyle = {
            bottom: "-16px",
            justifyContent: "center",
            alignItems: "flex-end"
          }
        }
        if (showHandleLabels && handle.position === "right") {
          label = "False"
          labelStyle = {
            left: "28px",
            justifyContent: "flex-end",
            alignItems: "center"
          }
        }

        const skipLeftConditionHandle =
          handle.position === "left" &&
          (node?.type === "condition" || node?.type === "advancedCondition")

        const isPositionBottomOrRight = handle.position === "bottom" || handle.position === "right"

        const hasSourceConnection = !!flowStore.edges.find(edge => edge.source === id)
        const hasTargetConnection = !!flowStore.edges.find(edge => edge.target === id)

        let hasTrueConditionConnection = true
        let hasFalseConditionConnection = true

        if (node?.type === "condition" || node?.type === "advancedCondition") {
          // get s-bottom (True)
          if (
            !flowStore.edges.find(
              edge => edge.source === node.id && edge.sourceHandle === "s-bottom"
            )
          ) {
            hasTrueConditionConnection = false
          }
          // get s-right (False)
          if (
            !flowStore.edges.find(
              edge => edge.source === node.id && edge.sourceHandle === "s-right"
            )
          ) {
            hasFalseConditionConnection = false
          }
        }

        handle = {
          ...handle,
          style: {
            ...handle.style,
            backgroundColor: !hasSourceConnection || !hasTargetConnection ? ERROR_COLOUR : "white"
          }
        }

        return (
          <HandleContainer key={`handle-container-${handle.id}`}>
            {showHandleLabels && isPositionBottomOrRight && (
              <LabelContainer style={labelStyle}>
                {handle.position === "right" && (
                  <HandleLabel
                    css={{
                      backgroundColor: hasFalseConditionConnection ? "lightgray" : ERROR_COLOUR
                    }}>
                    {label}
                  </HandleLabel>
                )}
                {handle.position === "bottom" && (
                  <HandleLabel
                    css={{
                      backgroundColor: hasTrueConditionConnection ? "lightgray" : ERROR_COLOUR
                    }}>
                    {label}
                  </HandleLabel>
                )}
              </LabelContainer>
            )}
            {!skipLeftConditionHandle && (
              <Handle
                isValidConnection={connection => checkConnection(connection)}
                key={handle.id}
                {...handle}
              />
            )}
          </HandleContainer>
        )
      })
    }, [id, dynamicHandles, sourceHandleStyle, store, targetHandleStyle, flowStore.edges, node])

    return (
      <ContainerRow ref={ref} dragging={dragging}>
        {h}
        {props.children}
      </ContainerRow>
    )
  }
)

const HandleContainer = styled("div", {
  width: "100%",
  height: "100%"
})

const LabelContainer = styled("div", {
  fontSize: "8px",
  pointerEvents: "none",
  position: "absolute",
  width: "100%",
  height: "100%",
  display: "flex",
  flexFlow: "row"
})

const HandleLabel = styled("span", {
  lineHeight: 1,
  backgroundColor: "lightgray",
  padding: "1px 2px",
  border: "1px dashed gray",
  borderRadius: "5px"
})

const ContainerRow = styled(Row, {
  display: "flex",
  flexFlow: "row",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "240px",
  width: "auto",
  flexWrap: "wrap",
  height: "auto",
  variants: {
    dragging: {
      true: { opacity: 0.5 },
      false: { opacity: 1 }
    }
  }
})

const Label = styled("span", {
  display: "flex",
  flexFlow: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  boxSizing: "content-box",
  height: "auto",
  width: "auto",
  fontSize: 12,
  textAlign: "center",
  variants: {
    color: {
      white: { color: "white" }
    }
  }
})

const MessagesContainer = styled("div", {
  position: "relative",
  boxSizing: "content-box",
  height: "100%",
  width: "auto",
  fontSize: 12,
  textAlign: "center",
  marginBottom: "4px"
})

const BotMessage = styled("div", {
  cursor: "default",
  position: "relative",
  width: "fit-content",
  maxWidth: "260px",
  padding: "6px 14px",
  borderRadius: "0 22px 22px 0",
  borderColor: "rgba(7, 34, 48, 0.1)",
  backgroundColor: "#ffffff",
  whiteSpace: "pre-wrap",
  borderBottomLeftRadius: "22px",
  borderBottom: "3px solid rgba(7, 34, 48, 0.1)",
  textAlign: "left",
  marginLeft: "32px",
  fontSize: 10,
  height: "auto"
})

const LabelEllipsis = styled("span", {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  boxSizing: "content-box",
  height: "auto",
  width: "80%",
  fontSize: 12,
  textAlign: "center",
  variants: {
    isDark: {
      true: { color: "white" }
    }
  }
})

const CloneButton = styled("button", {
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "-10px",
  left: "-10px",
  background: "white",
  border: "1px solid black",
  color: "black",
  borderRadius: "30%",
  width: "20px",
  height: "20px",
  cursor: "pointer",
  "&:hover": {
    background: "lightgray"
  }
})

const NodeId = styled("div", {
  position: "absolute",
  right: 0,
  top: 0,
  fontSize: 7,
  padding: "1px 4px 1px 2px",
  color: "gray",
  fontStyle: "italic",
  variants: {
    actionNode: {
      true: { right: "24px" }
    },
    chatFlowNode: {
      true: { right: 0 }
    },
    conditionNode: {
      true: { right: 0, width: "100%", textAlign: "center" }
    },
    questionNode: {
      true: { right: 0 }
    },
    color: {
      white: { color: "white" }
    },
    isCenter: {
      true: { right: "50%", transform: "translateX(50%)" }
    }
  }
})

export const CustomNode = {
  Container: NodeContainer,
  MessagesContainer: MessagesContainer,
  BotMessage: BotMessage,
  Label: Label,
  LabelEllipsis: LabelEllipsis,
  NodeId: NodeId,
  CloneButton: CloneButton
}
