import React from "react"

import styles from "./OptionsPlaceholder.module.scss"

interface Props {
  name: string
  disabled?: boolean
}

const OptionsPlaceholder = ({ name, disabled = false }: Props) => {
  return (
    <div className={styles.optionsPlaceholderContainer}>
      <span style={{ opacity: disabled ? 0.4 : 1 }}>{name} option(s)</span>
    </div>
  )
}

export default OptionsPlaceholder
