import React, { PropsWithChildren } from "react"
import Header from "../Header"
import styles from "./Layout.module.scss"

export default function Layout(props: PropsWithChildren<any>): JSX.Element {
  return (
    <div className={styles.layout}>
      <Header />
      <div className={styles.appContainer}>{props.children}</div>
    </div>
  )
}
