import self from "./eligibilityConfiguration"
import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import { getPresignedURL } from "./service"
import {
  EligibilityConfigurationDashboard,
  IDashboardDoc,
  IIAPTDashboardService
} from "@limbic/types"
import invariant from "../../utils/invariant"
import Logger from "../../utils/Logger"
import formatUnicorn from "../../utils/formatUnicorn"

const TOTAL_RETRIES = 3
const CONFIGURATION_URL = `${BACKEND_URL}/v1/dashboard/{serviceApiKey}/eligibility`

const method = "POST"
const headers = new Headers()
headers.set("x-api-key", API_KEY)
headers.set("Content-Type", "application/json")

export default async function updateEligiblityConfiguration(
  serviceApiKey: string,
  config: EligibilityConfigurationDashboard,
  retry = 0
): Promise<IDashboardDoc | undefined> {
  invariant(serviceApiKey, "Cannot update eligibility configuration without a serviceApiKey")

  const payload = { ...config }

  if (config.iapts?.find(iapt => iapt.keepingSafeLeaflet?.attachmentFile)) {
    const updatedIapts = await processAttachments(config.iapts, serviceApiKey)
    payload.iapts = updatedIapts
  }

  const URL = formatUnicorn(CONFIGURATION_URL, { serviceApiKey })
  const body = JSON.stringify(payload)

  try {
    const result = await fetch(URL, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "updateEligiblityConfiguration failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`updateEligiblityConfiguration body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("updateEligiblityConfiguration retry")
      await delay(1)
      return await self(serviceApiKey, config, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}

const processAttachments = async (
  iapts: IIAPTDashboardService[],
  serviceApiKey: string
): Promise<IIAPTDashboardService[]> => {
  const updatedIapts: IIAPTDashboardService[] = []

  for (const iapt of iapts) {
    const updatedIapt = JSON.parse(JSON.stringify(iapt))
    if (iapt.keepingSafeLeaflet?.attachmentUrl === "delete") {
      updatedIapt.keepingSafeLeaflet.attachmentUrl = "delete"
    } else if (iapt.keepingSafeLeaflet?.attachmentFile) {
      const fileType = `attachment.${
        iapt.keepingSafeLeaflet?.attachmentFile?.type.split("/")[1]
      }` as "logo-leaflet.jpeg" | "logo-leaflet.jpg" | "logo-leaflet.png" | "attachment.pdf"

      const uploadUrl = await getPresignedURL(
        serviceApiKey,
        fileType,
        iapt.backendInstanceID.toLowerCase()
      )

      const uploadHeaders = new Headers()
      uploadHeaders.set("Content-Type", iapt.keepingSafeLeaflet?.attachmentFile?.type)

      const uploadAttachment = await fetch(uploadUrl, {
        method: "PUT",
        headers: uploadHeaders,
        body: iapt.keepingSafeLeaflet?.attachmentFile
      })

      delete updatedIapt.keepingSafeLeaflet.attachmentFile
      updatedIapt.keepingSafeLeaflet.attachmentUrl = uploadAttachment.url?.split("?")[0]
    }
    updatedIapts.push(updatedIapt)
  }
  return updatedIapts
}
