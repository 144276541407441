import React, { PropsWithChildren, useMemo } from "react"
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material"
import styles from "../Integration.module.scss"
import { observer } from "mobx-react"
import { useBackendMappingStore } from "../../../context/rootStoreContext"
import { capitaliseFirst } from "../../../../utils/string"
import { IKeyMetaData, IKeyTransform, KeyType } from "@limbic/types"
import CancelIcon from "@mui/icons-material/Cancel"
import IconButton from "@mui/material/IconButton"

interface IKeyDescriptor extends IKeyMetaData {
  key: string
  disabled: boolean
  valuesLabel?: string
}

interface IProps {
  transform: IKeyTransform
  usedTargetKeys: string[]
  onChangeTargetKey: (transform: IKeyTransform, targetKey: string) => void
  onRemoveTargetKey: (transform: IKeyTransform) => void
}

function KeyMappingInput(props: PropsWithChildren<IProps>) {
  const mappingStore = useBackendMappingStore()
  const { transform, usedTargetKeys } = props

  const targetKeys = useMemo(() => {
    return Object.entries(mappingStore.targetKeys).reduce(
      (keys: IKeyDescriptor[], [key, targetKeyMetaData]) => {
        const item: IKeyDescriptor = { ...targetKeyMetaData, key, disabled: false }
        const isTransformText = transform.type === KeyType.Text
        const transformHasValues = !!Object.keys(transform.valuesMap ?? {}).length
        const isTransformFreeText = isTransformText && !transformHasValues

        const isTargetText = targetKeyMetaData.type === KeyType.Text
        const targetHasValues = !!targetKeyMetaData.allowedValues?.length

        if (isTransformFreeText && targetHasValues) item.disabled = true
        else if (usedTargetKeys.includes(key)) item.disabled = true

        if (isTargetText && targetHasValues) {
          item.valuesLabel = `(${targetKeyMetaData.allowedValues?.length} values)`
        }
        return [...keys, item]
      },
      []
    )
  }, [transform, mappingStore.targetKeys, usedTargetKeys])

  const keyLabel = `${transform.sourceKey} [${capitaliseFirst(transform.type)}]`

  return (
    <div className={styles.keyMappingContainer}>
      <div className={styles.keyMappingEntry}>
        <FormControl className={styles.keyMappingInput}>
          <Tooltip title={keyLabel}>
            <InputLabel>{keyLabel.replace("__inlineFreeTextValue__", "")}</InputLabel>
          </Tooltip>
          <Select
            value={transform.targetKey ?? ""}
            label={keyLabel}
            onChange={e => props.onChangeTargetKey(transform, e.target.value)}>
            {targetKeys.map(item => (
              <MenuItem
                disabled={item.disabled}
                key={item.key}
                value={item.key}
                className={styles.questionItem}>
                {item.key}{" "}
                <span className={styles.keyTypeSpan}>
                  [{capitaliseFirst(item.type)}] {item.valuesLabel}
                </span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!!transform.targetKey && (
          <IconButton
            data-testid={`backend-mapping-unmap-key-${transform.context}.${transform.sourceKey}`}
            className={styles.unMapKeyButton}
            onClick={() => props.onRemoveTargetKey(transform)}>
            <CancelIcon />
          </IconButton>
        )}
      </div>
      {props.children}
    </div>
  )
}

export default observer(KeyMappingInput)
