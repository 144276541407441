import React, { PropsWithChildren } from "react"
import classes from "clsx"
import styles from "./InputWrapper.module.scss"

interface Props {
  hasError?: boolean
  label?: string
  className?: string
}

export default function InputWrapper(props: PropsWithChildren<Props>): JSX.Element {
  const { children, label, className, hasError } = props
  return (
    <div className={className}>
      <div
        id="keeping-safe-input-wrapper-label"
        className={classes(styles.inputInstructions, { [styles.error]: hasError })}>
        {label}
      </div>
      {children}
    </div>
  )
}
