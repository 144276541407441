import * as Sentry from "@sentry/react"
import { Severity } from "../models/Logger/Severity"
import invariant from "./invariant"
import { backendEnv } from "../config/config"
import type { Primitive } from "@sentry/types"
import type IBreadcrumb from "../models/Logger/IBreadcrumb"
import type ILogger from "../models/Logger/ILogger"

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"

export default class Logger implements ILogger {
  static isReady = false
  static _instance: Logger

  /** Static Methods */

  static getInstance(): Logger {
    invariant(this.isReady, "Can't get Logger instance before setting it up")
    if (!this._instance) {
      this._instance = new Logger()
    }
    return this._instance
  }
  static setup(version: string, dsn: string, dist?: string, allowUrls?: string[]): void {
    Sentry.init({
      release: version,
      dist,
      dsn,
      debug: false,
      allowUrls,
      maxBreadcrumbs: 150,
      normalizeDepth: 5,
      environment: backendEnv
    })
    Sentry.setTag("build", version)
    Sentry.setExtra("build", version)
    this.isReady = true
    this.getInstance()
  }

  /** Methods */

  message(message: string, level: Severity = Severity.Info): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isDev && global.__LB_LOGS__) {
      console.log(message)
    }
    Sentry.captureMessage(message, { level })
  }

  breadcrumb(breadCrumb: IBreadcrumb): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isDev && global.__LB_LOGS__) {
      console.log(breadCrumb.message, breadCrumb.data || "")
    }
    Sentry.addBreadcrumb(breadCrumb)
  }

  exception(e: Error, message?: string): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isDev && global.__LB_LOGS__) {
      console.log("ERROR:", e.message)
    }
    const level = Severity.Error
    if (message) {
      this.breadcrumb({ message: `Error: ${e.message}`, level })
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    e.name = message ?? e.name
    Sentry.captureException(e)
  }

  setTag(key: string, value: Primitive): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isDev && global.__LB_LOGS__) {
      console.log({ [key]: value })
    }
    Sentry.setTag(key, value)
  }
}
