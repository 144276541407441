export const dataPointsOptions = [
  "N/A",
  "adsmIntro",
  "askAbleToCommunicateInEnglish",
  "askAccessibilityConsiderations",
  "askAccommodationStatus",
  "askAlcohol",
  "askAlcoholFrequency",
  "askAlcoholImpactsLife",
  "askAlcoholQuantity",
  "askAreYouInCrisis",
  "askArmedForces",
  "askCanYouKeepYourselfSafe",
  "askCurrentSupport",
  "askCurrentTherapy",
  "askCurrentTherapyDetails",
  "askDisability",
  "askDoesLTCAffectMood",
  "askDoesOtherLTCAffectMood",
  "askEthnicity",
  "askExArmedForces",
  "askGender",
  "askHasADHD",
  "askHasASD",
  "askHaveYouEverCaughtCovid",
  "askHowMuchLTCAffectsMood",
  "askHowWellYouManageYourLTC",
  "askInterpreterLanguage",
  "askIsMainProblemAboutAlcoholOrDrugs",
  "askLearningDisability",
  "askLongTermMedicalCondition",
  "askMHSupport",
  "askMainIssue",
  "askMedication",
  "askMedicationDoseRange",
  "askMedicationInfo",
  "askMedicationNotTaking",
  "askMedicationNotTakingInfo",
  "askMedicationWithinDosage",
  "askNHSStaff",
  "askNationality",
  "askNonPrescribedSubstances",
  "askPerinatal",
  "askPreferredContactMethod",
  "askPreferredPhoneType",
  "askPrescribedMedication",
  "askPrimaryLanguage",
  "askPriorMHTreatment",
  "askReligion",
  "askRequiresAnInterpreter",
  "askSameGenderAsBirth",
  "askSexuality",
  "askSubstances",
  "askSubstancesAreMedication",
  "askSubstancesForMood",
  "askSubstancesImpactLife",
  "askSubstancesOrigin",
  "askWhatIsYourGoal",
  "consentADSM",
  "otherSubstances",
  "otherSubstancesOrigin"
]
