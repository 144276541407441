import React from "react"
import { observer } from "mobx-react"
import styles from "./PhoneNumber.module.scss"
import { useNodeEditorStore } from "../../../../../../../context/rootStoreContext"
import { Autocomplete, Chip, FormGroup, TextField, Typography } from "@mui/material"
import SolidDivider from "../../../../../../SolidDivider/SolidDivider"
import Button from "../../../../../../Button/Button"
import { COUNTRIES_MAP, ICountry } from "@limbic/types"
import { getArraysAreEqual } from "../../../../../../../../utils/array"
import FormControlWithCheckboxControlledInput from "../../../../ChatFlowEditor/components/FormControlWithCheckBox/FormControlWithCheckboxControlledInput"
import { EditorType, IPromptSettings } from "../../../../../models/NodeEditors"

const DEFAULT_COUNTRY_CODE = process.env.REACT_APP_DEFAULT_PHONE_NUMBER_REGION_CODE ?? "GB"
const COUNTRY_CODES = Object.keys(COUNTRIES_MAP)

function PhoneNumber(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { questionEditorState } = nodeEditorStore
  const { promptSettings } = questionEditorState

  const hasAllCountryCodes = React.useMemo(
    () => getArraysAreEqual(promptSettings?.supportedCountries ?? [], COUNTRY_CODES),
    [promptSettings?.supportedCountries]
  )

  const updateState = (newSettings: Partial<IPromptSettings>): void => {
    nodeEditorStore.updateState(EditorType.QUESTION, {
      ...questionEditorState,
      promptSettings: JSON.parse(
        JSON.stringify({ ...questionEditorState.promptSettings, ...newSettings })
      )
    })
  }

  const onForceMobileClick = (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    updateState({
      forceMobile: value || undefined, // simply removing when false
      forceLandline: value ? false : promptSettings?.forceLandline // disabling when true
    })
  }

  const onForceLandlineClick = (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    updateState({
      forceMobile: value ? false : promptSettings?.forceMobile, // disabling when true
      forceLandline: value || undefined // simply removing when false
    })
  }

  const onAllCountryCodesClick = () => {
    updateState({
      supportedCountries: hasAllCountryCodes
        ? undefined
        : [...new Set([DEFAULT_COUNTRY_CODE, ...COUNTRY_CODES])]
    })
  }

  const handlePhonePlaceholderChange = event => {
    updateState({
      phonePromptPlaceholder: event.target.value
    })
  }

  const onAppendCountryCodesClick = () => {
    const supportedCountries = [
      ...new Set([...(promptSettings?.supportedCountries ?? []), ...COUNTRY_CODES])
    ]

    updateState({ supportedCountries })
  }

  const onCountryCodesChange = (_: React.SyntheticEvent, countries: ICountry[]) => {
    const supportedCountries = countries.map(c => c.isoAlpha2Code)
    updateState({ supportedCountries })
  }

  return (
    <div className={styles.phoneNumberInputContainer}>
      <FormGroup>
        <div className={styles.checkboxContainer}>
          <FormControlWithCheckboxControlledInput
            name="isUndoable"
            value={promptSettings?.isUndoable ?? false}
            label="Undoable"
            labelPlacement="start"
            onToggle={(_, isUndoable: boolean) => updateState({ isUndoable })}
          />

          <FormControlWithCheckboxControlledInput
            name="trackResponse"
            value={promptSettings?.trackResponse ?? false}
            label="Track response"
            labelPlacement="start"
            onToggle={(_, trackResponse: boolean) => updateState({ trackResponse })}
          />

          <FormControlWithCheckboxControlledInput
            name="setPeople"
            value={promptSettings?.setPeople ?? false}
            label="Set user data in Mixpanel"
            labelPlacement="start"
            onToggle={(_, setPeople: boolean) => updateState({ setPeople })}
          />

          <FormControlWithCheckboxControlledInput
            name="forceMobile"
            value={promptSettings?.forceMobile ?? false}
            label="Force Input to be a mobile phone"
            labelPlacement="start"
            onToggle={onForceMobileClick}
          />

          <FormControlWithCheckboxControlledInput
            name="forceLandline"
            value={promptSettings?.forceLandline ?? false}
            label="Force Input to be a landline phone"
            labelPlacement="start"
            onToggle={onForceLandlineClick}
          />
        </div>

        <SolidDivider />

        <div>
          <Typography>Supported Country Codes:</Typography>

          <p>{(promptSettings?.supportedCountries ?? []).length} country codes selected</p>

          <Autocomplete
            multiple
            id="country-codes"
            onChange={onCountryCodesChange}
            options={Object.values(COUNTRIES_MAP)}
            limitTags={5}
            getOptionLabel={(country: ICountry) =>
              `[${country.isoAlpha2Code}] ${country.name} (+${country.phonePrefix})`
            }
            renderTags={(value: readonly ICountry[], getTagProps) =>
              value.map((c: ICountry, index: number) => (
                <Chip variant="outlined" label={c.name} {...getTagProps({ index })} />
              ))
            }
            value={(promptSettings?.supportedCountries ?? []).map(code => COUNTRIES_MAP[code])}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Country Codes"
                placeholder="Type the country codes"
              />
            )}
          />

          {!!promptSettings?.supportedCountries?.length && !hasAllCountryCodes ? (
            <Button onClick={onAppendCountryCodesClick}>Append All</Button>
          ) : (
            <Button onClick={onAllCountryCodesClick}>
              {hasAllCountryCodes ? "Remove" : "Add"} All
            </Button>
          )}
        </div>

        <SolidDivider />
        <div>
          <div>
            <Typography className={styles.settingsTitle}>Placeholder:</Typography>
          </div>
          <TextField
            size="small"
            name="phonePromptPlaceholder"
            className={styles.textField}
            placeholder="Please type phone number"
            value={promptSettings.phonePromptPlaceholder || ""}
            onChange={handlePhonePlaceholderChange}
          />
        </div>
      </FormGroup>
    </div>
  )
}

export default observer(PhoneNumber)
