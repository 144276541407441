import { useCallback, useState } from "react"
import { NodeProps, Position, useStore } from "react-flow-renderer"
import classNames from "classnames"
import { tomato } from "@radix-ui/colors"
import { styled } from "@stitches/react"
import { CustomNode } from "../CustomNode"
import EndShape from "../../../components/shapes/EndShape"
import NodeSettings from "../NodeSettings"
import useDeleteNode from "../../../../../hooks/useDeleteNode"
import { positionsMap } from "../../../config"
import CancelIcon from "@mui/icons-material/Cancel"
export const END_CHAT_NODE_SIZE = 32

export default function EndChatNode(props: NodeProps) {
  const { id, selected, dragging } = props
  const onDelete = useDeleteNode(id)
  const [labelPosition, setLabelPosition] = useState(Position.Bottom)
  const store = useStore()
  const handleStyle = store.connectionHandleId ? handleVisible : handleHidden
  const onRotate = useCallback(() => setLabelPosition(p => positionsMap[p]), [])

  const style = {
    [Position.Left]: { top: 0, bottom: 0, left: -52 },
    [Position.Right]: { top: 0, bottom: 0, right: -52 },
    [Position.Top]: { left: -9, right: 25, top: -16, width: 50 },
    [Position.Bottom]: { left: -9, right: 25, bottom: -16, width: 50 }
  }[labelPosition]

  return (
    <CustomNode.Container
      dynamicHandles
      id={id}
      dragging={dragging}
      sourceHandleStyle={handleHidden}
      targetHandleStyle={handleStyle}>
      <Container className={classNames("react-flow__node-shape nopan", { selected })}>
        <EndShape size={END_CHAT_NODE_SIZE} Icon={<CancelIcon />} />
        <Content style={style}>
          <CustomNode.Label>End chat</CustomNode.Label>
        </Content>
      </Container>
      <NodeSettings
        open={props.selected && !props.dragging}
        onRotate={onRotate}
        onDelete={onDelete}
      />
    </CustomNode.Container>
  )
}

const Container = styled("div", {
  alignItems: "center",
  justifyContent: "center"
})

const Content = styled("div", {
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})

const handleVisible = { borderColor: tomato.tomato9 }
const handleHidden = { opacity: 0 }
