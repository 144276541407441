import { NodeTypes } from "@limbic/types"
import React, { PropsWithChildren, ReactNode } from "react"
import { DropdownMenu } from "../../components/DropdownMenu/DropdownMenu"
import { useFlowStore } from "../../../../context/rootStoreContext"

export default function CreateToolDropdownMenu(
  props: PropsWithChildren<{ children?: ReactNode | undefined }>
) {
  const flowStore = useFlowStore()

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{props.children}</DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Label>Generic</DropdownMenu.Label>
        <DropdownMenu.Item
          disabled={flowStore.nodes.some(n => n.type === NodeTypes.FlowStart)}
          label="Flow Start"
          onClick={() => flowStore.addNode(NodeTypes.FlowStart)}
        />
        <DropdownMenu.Item
          label="Bot Question"
          onClick={() =>
            flowStore.addNode(NodeTypes.Question, { label: "Double click to edit this question" })
          }
        />
        <DropdownMenu.Item
          label="Basic Condition"
          onClick={() => flowStore.addNode(NodeTypes.Condition, { label: "Basic Condition" })}
        />
        <DropdownMenu.Item
          label="Advanced Condition"
          onClick={() =>
            flowStore.addNode(NodeTypes.AdvancedCondition, { label: "Adv. Condition" })
          }
        />
        <DropdownMenu.Item
          label="Action"
          onClick={() => flowStore.addNode(NodeTypes.Action, { label: "Action" })}
        />
        <DropdownMenu.Item
          label="Track Ineligible User"
          onClick={() =>
            flowStore.addNode(NodeTypes.IneligibleUser, { label: "Set Ineligible User" })
          }
        />
        <DropdownMenu.Item
          label="Default Chat Flow"
          onClick={() => flowStore.addNode(NodeTypes.ChatFlow, { label: "Default Chat Flow" })}
        />
        <DropdownMenu.Item
          disabled={flowStore.nodes.some(n => n.type === NodeTypes.FlowEnd)}
          label="Flow End"
          onClick={() => flowStore.addNode(NodeTypes.FlowEnd)}
        />
        <DropdownMenu.Item
          disabled={flowStore.nodes.some(n => n.type === NodeTypes.EndChat)}
          label="End Chat"
          onClick={() => flowStore.addNode(NodeTypes.EndChat)}
        />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
