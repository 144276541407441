import {
  LeftHandContextType,
  RightHandContextType,
  TextOperators,
  NumberOperators,
  BooleanOperators,
  ListOperators
} from "@limbic/types"

export const storageNames: Record<
  keyof typeof LeftHandContextType | keyof typeof RightHandContextType,
  string
> = {
  REFERRAL_STORE: "Referral Store",
  STATE: "State",
  CUSTOM_FIELDS: "Custom Fields",
  CONFIG_STORE: "Config Store",
  CLINICAL_STORE: "Clinical Store",
  NUMBER: "Number",
  STRING: "String",
  BOOLEAN: "Boolean"
}

export enum InputTypes {
  LIST = "list",
  TEXT = "text",
  NUMBER = "number",
  BOOLEAN = "boolean"
}

export interface IStorage {
  name: RightHandContextType | RightHandContextType
  inputType: InputTypes
}

export const storageOptions: IStorage[] = [
  {
    name: RightHandContextType.STATE,
    inputType: InputTypes.LIST
  },
  {
    name: RightHandContextType.CUSTOM_FIELDS,
    inputType: InputTypes.TEXT
  },
  {
    name: RightHandContextType.CONFIG_STORE,
    inputType: InputTypes.LIST
  },
  {
    name: RightHandContextType.CLINICAL_STORE,
    inputType: InputTypes.LIST
  },
  {
    name: RightHandContextType.NUMBER,
    inputType: InputTypes.NUMBER
  },
  {
    name: RightHandContextType.STRING,
    inputType: InputTypes.TEXT
  },
  {
    name: RightHandContextType.BOOLEAN,
    inputType: InputTypes.BOOLEAN
  }
]

export enum OperatorTypes {
  TEXT = "text",
  NUMBER = "number",
  BOOLEAN = "boolean",
  LIST = "list"
}

export interface Operators {
  textOperators: {
    type: OperatorTypes.TEXT
    values: TextOperators[]
  }
  numberOperators: {
    type: OperatorTypes.NUMBER
    values: NumberOperators[]
  }
  booleanOperators: {
    type: OperatorTypes.BOOLEAN
    values: BooleanOperators[]
  }
  listOperators: {
    type: OperatorTypes.LIST
    values: ListOperators[]
  }
}

export const operators: Operators = {
  textOperators: {
    type: OperatorTypes.TEXT,
    values: [
      TextOperators.EQUALS,
      TextOperators.NOT_EQUALS,
      TextOperators.CONTAINS,
      TextOperators.STARTS_WITH,
      TextOperators.ENDS_WITH,
      TextOperators.HAS_LENGTH
    ]
  },
  numberOperators: {
    type: OperatorTypes.NUMBER,
    values: [
      NumberOperators.EQUALS,
      NumberOperators.NOT_EQUALS,
      NumberOperators.GREATER_THAN,
      NumberOperators.GREATER_THAN_OR_EQUAL,
      NumberOperators.LESS_THAN,
      NumberOperators.LESS_THAN_OR_EQUAL
    ]
  },
  booleanOperators: {
    type: OperatorTypes.BOOLEAN,
    values: [BooleanOperators.IS_FALSE, BooleanOperators.IS_TRUE]
  },
  listOperators: {
    type: OperatorTypes.LIST,
    values: [ListOperators.INCLUDES, ListOperators.NOT_INCLUDES, ListOperators.HAS_LENGTH]
  }
}
