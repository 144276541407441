export default function deepCompare(a: Record<string, any>, b: Record<string, any>): boolean {
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false
    return !a.find((item, index) => !deepCompare(item, b[index]))
  }

  if (typeof a === "object" && typeof b === "object") {
    const aSorted = sortObject(a)
    const bSorted = sortObject(b)
    return JSON.stringify(aSorted) === JSON.stringify(bSorted)
  }

  return a === b
}

function sortObject(obj: Record<string, any>): Record<string, any> {
  if (Array.isArray(obj)) return obj.map(sortObject)

  const sorted: Record<string, any> = {}
  Object.keys(obj ?? {})
    .sort()
    .forEach(key => {
      const value = obj[key]
      if (typeof value === "object") {
        sorted[key] = sortObject(value)
      } else {
        sorted[key] = value
      }
    })
  return sorted
}
