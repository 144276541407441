// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AEuV1z9mbKcSgRcc4dcs{display:flex;flex-flow:column;flex:1;justify-content:center;align-items:center;align-self:stretch}.uzEH0l4BzXrfYbwnZg2f{flex:1;display:flex;flex-flow:column;align-items:stretch;width:calc(100% - 16px);max-width:960px;min-width:320px;padding:16px 0 16px 0}.yjj5ztOOmkxdUYuyK3O0:not(:first-of-type){margin-top:1rem}.yjj5ztOOmkxdUYuyK3O0:not(:first-of-type) input{padding-inline:1rem}", "",{"version":3,"sources":["webpack://./src/app/views/BasicConfiguration/components/EligibilityConfiguration/EligibilityConfiguration.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,MAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA,CAGF,sBACE,MAAA,CACA,YAAA,CACA,gBAAA,CACA,mBAAA,CACA,uBAAA,CACA,eAAA,CACA,eAAA,CACA,qBAAA,CAGF,0CACE,eAAA,CAEA,gDACE,mBAAA","sourcesContent":[".eligibilityConfigurationContainer {\n  display: flex;\n  flex-flow: column;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  align-self: stretch;\n}\n\n.eligibilityMainConfiguration {\n  flex: 1;\n  display: flex;\n  flex-flow: column;\n  align-items: stretch;\n  width: calc(100% - 16px);\n  max-width: 960px;\n  min-width: 320px;\n  padding: 16px 0 16px 0;\n}\n\n.inputContainer:not(:first-of-type) {\n  margin-top: 1rem;\n\n  & input {\n    padding-inline: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eligibilityConfigurationContainer": "AEuV1z9mbKcSgRcc4dcs",
	"eligibilityMainConfiguration": "uzEH0l4BzXrfYbwnZg2f",
	"inputContainer": "yjj5ztOOmkxdUYuyK3O0"
};
export default ___CSS_LOADER_EXPORT___;
