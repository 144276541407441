import React, { useEffect, useRef } from "react"
import * as jsonToAst from "json-to-ast"
import { observer } from "mobx-react"
import { useSettingsStore } from "../../../../context/rootStoreContext"
import Button from "../../../Button"
import Dialog from "../../../Dialog"
import { JsonEditor as Editor } from "jsoneditor-react"
import ReactDiffViewer from "react-diff-viewer"
import deepCompare from "../../../../../utils/deepCompare"
import { LimbicSettingsSchema } from "@limbic/types"
import { ZodIssue } from "zod"
import Logger from "../../../../../utils/Logger"
import { AstPosition, DiffMethod } from "./constants"
import styles from "./SettingsDialogue.module.scss"
import "jsoneditor-react/es/editor.min.css"

const ERROR_COLOUR_HIGHLIGHT = "#ed8484"
interface Props {
  isSettingsDialogueOpen: boolean
  setSettingsDialogueOpen: (value: boolean) => void
}

function SettingsDialogue(props: Props): JSX.Element {
  const settingsStore = useSettingsStore()

  const jsonEditorRef = useRef<any>(null)

  const [hasError, setHasError] = React.useState<boolean>(false)
  const [hasChanges, setHasChanges] = React.useState<boolean>(false)
  const [isReset, setIsReset] = React.useState<boolean>(false)
  const [oldJSON, setOldJSON] = React.useState<string>(JSON.stringify({}, null, 2))
  const [newJSON, setNewJSON] = React.useState<string>(oldJSON)
  const [errorsJSON, setErrorsJSON] = React.useState<ZodIssue[]>([])
  const [errorLines, setErrorLines] = React.useState<string[]>([])

  const reset = () => {
    setHasError(false)
    setHasChanges(false)
    setErrorsJSON([])
    setErrorLines([])
  }

  useEffect(() => {
    setOldJSON(JSON.stringify(settingsStore.settings ?? {}, null, 2))
  }, [settingsStore.settings])

  useEffect(() => {
    setNewJSON(oldJSON)
  }, [oldJSON])

  useEffect(() => {
    const areSameObjects = deepCompare(JSON.parse(oldJSON), JSON.parse(newJSON))
    setHasChanges(!areSameObjects)
  }, [newJSON, oldJSON])

  useEffect(() => {
    if (jsonEditorRef.current !== null) {
      jsonEditorRef.current.set(JSON.parse(newJSON))
    }
  }, [newJSON])

  useEffect(reset, [props.isSettingsDialogueOpen])

  const setRef = instance => {
    if (instance && isReset) {
      jsonEditorRef.current = instance.jsonEditor
      setIsReset(false)
    } else {
      jsonEditorRef.current = null
    }
  }

  const handleUpdateSettings = async () => {
    try {
      await settingsStore.update(JSON.parse(newJSON))
      setOldJSON(newJSON)
      reset()
    } catch (e) {
      Logger.getInstance().exception(e, "update settings failed")
      setHasError(true)
    }
  }

  const handleResetChanges = async () => {
    setIsReset(true)
    setNewJSON(oldJSON)
    reset()
  }

  const handleLineClick = (lineId: string) => {
    const lineNumber = Number(lineId.slice(2))
    const textArea = document.getElementsByClassName("jsoneditor-tree")[0]
    textArea.scrollTop = lineNumber * 14
  }

  const findLineNumber = (path: (string | number)[]): number | null => {
    const jsonString = newJSON
    const ast = jsonToAst(jsonString)

    function navigateAst(node: jsonToAst.ASTNode, pathIndex = 0): AstPosition | null {
      if (pathIndex >= path.length || !node) return null

      switch (node.type) {
        case "Object":
          for (const property of node.children) {
            if (property.key.value === path[pathIndex]) {
              if (pathIndex === path.length - 1) {
                return property.key.loc
              }
              return navigateAst(property.value, pathIndex + 1)
            }
          }
          break
        case "Array":
          if (typeof path[pathIndex] === "number") {
            const index = path[pathIndex] as number
            if (node.children[index]) {
              return navigateAst(node.children[index], pathIndex + 1)
            }
          }
          break
        default:
          return null
      }

      return null
    }

    const result = navigateAst(ast)
    return result ? result.start.line : null
  }

  /**
   * This is used in order to get +1 and -1 from the line number
   * deduced from findLineNumber, because the result sometimes is
   * exact but sometimes its +/- 1 (seems to be the way that jsonToAst
   * is handling the line numbers), therefore 3 lines are highlighted
   * for pin pointing the error easier
   */
  const getExpandedLines = (lines: number[]): string[] => {
    const expandedLines: string[] = []
    lines.forEach(line => {
      expandedLines.push(`R-${line - 1}`)
      expandedLines.push(`R-${line}`)
      expandedLines.push(`R-${line + 1}`)
    })
    return expandedLines
  }

  const handleEditorChange = data => {
    const result = LimbicSettingsSchema.safeParse(data)
    if (result.success) {
      setErrorsJSON([])
      setErrorLines([])
    } else {
      setErrorsJSON(result.error.issues.map(issue => issue))
      const lines: number[] = []
      result.error.issues.forEach(issue => {
        const lineNumber = findLineNumber(issue.path)
        if (lineNumber) lines.push(lineNumber)
      })
      if (lines.length) {
        setErrorLines(getExpandedLines(lines))
      } else {
        setErrorLines([])
      }
    }

    setNewJSON(JSON.stringify(data, null, 2))
  }

  return (
    <Dialog
      maxWidth="xl"
      open={props.isSettingsDialogueOpen}
      onClose={() => props.setSettingsDialogueOpen(false)}
      title="Settings"
      ActionButton={
        <div>
          <Button disabled={!hasChanges} onClick={handleResetChanges}>
            Reset Changes
          </Button>
          <Button
            disabled={hasError || !!errorsJSON.length || !hasChanges}
            onClick={handleUpdateSettings}>
            Update Settings
          </Button>
        </div>
      }>
      <div>
        <div className={styles.settingsEditorContainer}>
          <div style={{ height: "47vh", width: "70%" }}>
            <Editor
              onChange={handleEditorChange}
              ref={setRef}
              value={JSON.parse(newJSON)}
              mode={Editor.modes.tree}
              htmlElementProps={{
                style: { height: "47vh", width: "100%", fontSize: "20px" }
              }}
            />
          </div>
          <div className={styles.reactDiffViewerContainer}>
            <ReactDiffViewer
              highlightLines={errorLines}
              styles={{
                diffContainer: {
                  height: "47vh"
                },
                line: {
                  wordBreak: "break-all"
                },
                variables: {
                  light: {
                    highlightBackground: ERROR_COLOUR_HIGHLIGHT,
                    highlightGutterBackground: ERROR_COLOUR_HIGHLIGHT
                  }
                }
              }}
              compareMethod={DiffMethod.WORDS}
              oldValue={oldJSON}
              newValue={newJSON}
              splitView={true}
              showDiffOnly={true}
              onLineNumberClick={handleLineClick}
            />
          </div>
        </div>
        <ul className={styles.settingsErrorContainer}>
          {errorsJSON.map(error => (
            <li>{error.message}</li>
          ))}
        </ul>
      </div>
    </Dialog>
  )
}

export default observer(SettingsDialogue)
