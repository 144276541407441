import moment from "moment"

const formatTimeStamp = (timestamp: string | undefined): [string, string | undefined] => {
  if (!timestamp) {
    return ["", undefined]
  } else if (moment().diff(timestamp, "hours") < 24) {
    return [moment(timestamp).fromNow(), moment(timestamp).format("dddd Do [of] MMMM [at] HH:mm")]
  } else {
    return [
      moment(timestamp).startOf("day").fromNow(),
      moment(timestamp).format("dddd Do [of] MMMM [at] HH:mm")
    ]
  }
}

export default formatTimeStamp
