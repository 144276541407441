import React, { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react"
import {
  useKeepingSafeEmailConfigurationStore,
  useServiceStore
} from "../../context/rootStoreContext"
import Loading from "../../components/Loading"
import styles from "./KeepingSafe.module.scss"
import Dialog from "../../components/Dialog"
import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import InputWrapper from "./components/InputWrapper"
import classes from "clsx"
import PDFInput from "./components/PDFInput"
import LogoInput from "../../components/LogoInput"
import Logo from "../../assets/logo.png"
import CustomCKEditor from "../../components/CustomCKEditor"
import Button from "../../components/Button"
import { KeepingSafeEmailUpload } from "@limbic/types"
import CancelIcon from "@mui/icons-material/Cancel"

function KeepingSafe(): JSX.Element {
  const serviceStore = useServiceStore()
  const keepingSafeEmailConfigurationStore = useKeepingSafeEmailConfigurationStore()
  const [emailPreviewHTML, setEmailPreviewHTML] = useState<string | undefined>()
  const [shouldUpdateServiceName, setShouldUpdateServiceName] = useState<boolean>(true)
  const [imageError, setImageError] = useState<boolean>(false)
  const uploadedImage = React.useRef<any>(null)
  const imageUploader = React.useRef<HTMLInputElement>(null)
  const serviceStoreKeepingSafe = serviceStore.serviceData?.[serviceStore.mode]?.keepingSafeEmail

  useEffect(() => {
    const basicConfig = serviceStore.serviceData?.draft?.configuration
    if (
      shouldUpdateServiceName &&
      !serviceStoreKeepingSafe?.content &&
      (basicConfig?.organisationName || basicConfig?.serviceName)
    ) {
      keepingSafeEmailConfigurationStore.reset(
        basicConfig?.organisationName ?? basicConfig?.serviceName
      )
      setShouldUpdateServiceName(false)
    }
  }, [
    serviceStore.serviceData?.draft?.configuration,
    shouldUpdateServiceName,
    serviceStoreKeepingSafe?.content,
    keepingSafeEmailConfigurationStore
  ])

  const error = useMemo(() => {
    const messages = Object.values(keepingSafeEmailConfigurationStore.errors)
      .flat(2)
      .filter(Boolean)
      .map(m => <span>{m}</span>)
    return {
      title: messages.length <= 1 ? "An error occurred" : "Something went wrong",
      message: messages.length ? messages.join("<br />") : undefined
    }
  }, [keepingSafeEmailConfigurationStore.errors])

  const isChanged = useMemo(() => {
    const dbEmail = serviceStore.serviceData?.[serviceStore.mode]?.keepingSafeEmail
    const localEmail = keepingSafeEmailConfigurationStore.keepingSafeEmail

    const isShouldSendKeepingSafeEmailChanged =
      typeof dbEmail?.shouldSendKeepingSafeEmail === "undefined"
        ? true
        : dbEmail?.shouldSendKeepingSafeEmail !== localEmail.shouldSendKeepingSafeEmail

    return (
      !dbEmail ||
      asString(dbEmail?.subject) !== localEmail.subject ||
      asString(dbEmail?.content?.title) !== localEmail.content.title ||
      asString(dbEmail?.content?.text) !== localEmail.content.text ||
      asString(dbEmail?.content?.signature) !== localEmail.content.signature ||
      asString(dbEmail?.logoURL) !== localEmail.logoURL ||
      asString(dbEmail?.attachmentURL) !== localEmail.attachmentURL ||
      isShouldSendKeepingSafeEmailChanged ||
      !!keepingSafeEmailConfigurationStore.logo ||
      !!keepingSafeEmailConfigurationStore.attachmentFile
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    keepingSafeEmailConfigurationStore.attachmentFile,
    keepingSafeEmailConfigurationStore.keepingSafeEmail,
    keepingSafeEmailConfigurationStore.keepingSafeEmail.content.title,
    keepingSafeEmailConfigurationStore.keepingSafeEmail.content.text,
    keepingSafeEmailConfigurationStore.keepingSafeEmail.content.signature,
    keepingSafeEmailConfigurationStore.keepingSafeEmail.subject,
    keepingSafeEmailConfigurationStore.keepingSafeEmail.logoURL,
    keepingSafeEmailConfigurationStore.keepingSafeEmail.attachmentURL,
    keepingSafeEmailConfigurationStore.keepingSafeEmail.shouldSendKeepingSafeEmail,
    keepingSafeEmailConfigurationStore.logo,
    serviceStore.mode,
    serviceStore.serviceData
  ])

  const onInputChange = (
    input: "title" | "subject" | "signature" | "text",
    data?: string
  ): void => {
    if (input === "subject") {
      keepingSafeEmailConfigurationStore.setSubject(data ?? "Keeping Safe Leaflet")
    }

    const newContent = {
      ...keepingSafeEmailConfigurationStore.keepingSafeEmail.content,
      [input]: data
    }
    keepingSafeEmailConfigurationStore.setContent(newContent)
    keepingSafeEmailConfigurationStore.validateKeepingSafeEmail()
  }

  const onLogoChange = e => {
    const logo = e.target.files[0]
    if (logo) {
      keepingSafeEmailConfigurationStore.setLogo(logo)

      const reader = new FileReader()
      if (uploadedImage.current) {
        uploadedImage.current.file = logo
        reader.onload = e => {
          const source = e.target?.result
          if (source) {
            uploadedImage.current.src = source
            keepingSafeEmailConfigurationStore.setLogoURL(String(source))
            keepingSafeEmailConfigurationStore.validateKeepingSafeEmail()
          }
        }
        reader.readAsDataURL(logo)
      }
      setImageError(false)
    }
  }

  const onResetLogo = () => {
    keepingSafeEmailConfigurationStore.setLogo()
    keepingSafeEmailConfigurationStore.setLogoURL(Logo)
    keepingSafeEmailConfigurationStore.validateKeepingSafeEmail()
  }

  const onRemoveLeafletFile = () => {
    keepingSafeEmailConfigurationStore.setAttachmentFile()
    keepingSafeEmailConfigurationStore.setAttachmentURL("delete")
    keepingSafeEmailConfigurationStore.validateKeepingSafeEmail()
  }

  const onSelectLeafletFile = (file?: File) => {
    keepingSafeEmailConfigurationStore.setAttachmentFile(file)
    keepingSafeEmailConfigurationStore.validateKeepingSafeEmail()
  }

  const onSubmitChanges = async () => {
    try {
      const keepingSafeEmail = keepingSafeEmailConfigurationStore.keepingSafeEmail
      const email: KeepingSafeEmailUpload = {
        subject: keepingSafeEmail.subject,
        content: keepingSafeEmail.content,
        logo: keepingSafeEmailConfigurationStore.logo ?? null,
        attachment: keepingSafeEmailConfigurationStore.attachmentFile ?? null
      }
      const shouldRemoveAttachment = keepingSafeEmail.attachmentURL === "delete"

      // TODO: Pass shouldSendKeepingSafeEmail and send to backend
      await serviceStore.updateKeepingSafeEmailConfiguration(
        email,
        keepingSafeEmail.logoURL,
        keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail,
        shouldRemoveAttachment
      )
      await keepingSafeEmailConfigurationStore.setAttachmentFile()
      await keepingSafeEmailConfigurationStore.setLogo()
    } catch (e) {
      keepingSafeEmailConfigurationStore.setError("saveEmail", "Could not save data")
    }
  }

  const onShowEmailPreview = async () => {
    try {
      const logoURL = uploadedImage.current.src
      const preview = await keepingSafeEmailConfigurationStore.getEmailPreviewHTML(logoURL)
      setEmailPreviewHTML(preview)
    } catch (e) {
      keepingSafeEmailConfigurationStore.setError("emailPreview", "Could not generate preview")
    }
  }

  const onDiscardChanges = () => {
    if (isChanged) {
      keepingSafeEmailConfigurationStore.setKeepingSafeEmail(serviceStoreKeepingSafe)
      keepingSafeEmailConfigurationStore.setAttachmentFile()
      keepingSafeEmailConfigurationStore.setLogo()
    } else {
      keepingSafeEmailConfigurationStore.reset()
    }
    keepingSafeEmailConfigurationStore.validateKeepingSafeEmail()
  }

  const handleShouldSendKeepingSafeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    keepingSafeEmailConfigurationStore.setShouldSendKeepingSafe(event.target.checked)
  }

  if (serviceStore.isLoading) {
    return <Loading />
  }

  return (
    <div className={styles.keepingSafeContainer}>
      <div className={styles.editorWrapper}>
        <div className={styles.enableKeepingSafeContainer}>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                checked={
                  !!keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
                }
                onChange={handleShouldSendKeepingSafeChange}
              />
            }
            label="Enable sending keeping safe email to user in case of risk"
          />
        </div>
        <Dialog
          onClose={() => setEmailPreviewHTML(undefined)}
          open={!!emailPreviewHTML}
          title="Email Preview">
          <span className={styles.dialogEmailSubject}>
            <b>Subject: </b>
            {keepingSafeEmailConfigurationStore.keepingSafeEmail.subject}
          </span>
          <span dangerouslySetInnerHTML={{ __html: emailPreviewHTML ?? "" }}></span>
        </Dialog>
        {!serviceStoreKeepingSafe?.content && (
          <Typography
            textAlign="center"
            color={
              keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
                ? "darkred"
                : "lightgray"
            }
            variant="subtitle1">
            This service has no Keeping Safe Email setup. A standard email template has been loaded.
          </Typography>
        )}
        <InputWrapper
          hasError={!!keepingSafeEmailConfigurationStore.errors.subject?.length}
          label={
            keepingSafeEmailConfigurationStore.errors.subject?.length
              ? "Email subject cannot be empty"
              : "* Type in an email subject"
          }
          className={classes(styles.inputWrapperSubject, styles.inputWrapperHeight, {
            [styles.inputWrapperEditorDisabled]:
              !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
          })}>
          <input
            disabled={
              !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
            }
            placeholder="Email Subject"
            value={keepingSafeEmailConfigurationStore.keepingSafeEmail.subject}
            onChange={e => onInputChange("subject", e.target.value)}
            type="text"
            className={classes(styles.textInput, styles.textInputSubject, {
              [styles.inputDisabled]:
                !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
            })}
          />
        </InputWrapper>
        <InputWrapper
          label="Add a PDF leaflet attachment"
          className={classes(styles.inputWrapperLeaflet, styles.inputWrapperHeightLeaflet, {
            [styles.inputWrapperEditorDisabled]:
              !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
          })}>
          <PDFInput
            index={0}
            disabled={
              !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
            }
            file={keepingSafeEmailConfigurationStore.attachmentFile}
            currentFileURL={keepingSafeEmailConfigurationStore.keepingSafeEmail.attachmentURL}
            onChangeLeaflet={onSelectLeafletFile}
            onRemoveLeaflet={onRemoveLeafletFile}>
            View Current Leaflet
          </PDFInput>
        </InputWrapper>
        <div className={styles.emailWrapper}>
          <InputWrapper
            label="Click on the Logo to upload another image"
            className={classes(styles.inputWrapper, styles.inputWrapperLogo, {
              [styles.inputWrapperEditorDisabled]:
                !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
            })}>
            <LogoInput
              disabled={
                !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
              }
              onImageError={() => {
                console.warn("error loading keeping safe logo")
                setImageError(true)
              }}
              src={keepingSafeEmailConfigurationStore.keepingSafeEmail.logoURL || Logo}
              onChange={onLogoChange}
              onClick={() => imageUploader.current?.click()}
              imageUploader={imageUploader}
              uploadedImage={uploadedImage}
            />
            {keepingSafeEmailConfigurationStore.keepingSafeEmail.logoURL !== Logo &&
              !imageError && (
                <span className={styles.resetLogo} onClick={onResetLogo} title="Remove Leaflet">
                  <CancelIcon fontSize="small" /> Reset logo
                </span>
              )}
          </InputWrapper>
          <InputWrapper
            hasError={!!keepingSafeEmailConfigurationStore.errors.title?.length}
            label={
              keepingSafeEmailConfigurationStore.errors.title?.length
                ? "Email heading cannot be empty"
                : "* Type in a main email heading"
            }
            className={classes(styles.inputWrapper, styles.inputWrapperHeight, {
              [styles.inputWrapperEditorDisabled]:
                !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
            })}>
            <input
              disabled={
                !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
              }
              placeholder="Keeping Safe Leaflet"
              value={keepingSafeEmailConfigurationStore.keepingSafeEmail.content.title}
              onChange={e => onInputChange("title", e.target.value)}
              type="text"
              className={classes(styles.textInput, styles.textInputGeneric, {
                [styles.inputDisabled]:
                  !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
              })}
            />
          </InputWrapper>
          <InputWrapper
            hasError={!!keepingSafeEmailConfigurationStore.errors.text?.length}
            label={
              keepingSafeEmailConfigurationStore.errors.text?.length
                ? "Email content cannot be empty"
                : "* Type in the email content"
            }
            className={classes(styles.inputWrapperEditor, {
              [styles.inputWrapperEditorDisabled]:
                !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail,
              [styles.inputWrapperCKEditorDisabled]:
                !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
            })}>
            <CustomCKEditor
              disabled={
                !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
              }
              data={keepingSafeEmailConfigurationStore.keepingSafeEmail.content.text}
              onChange={(data?: string) => onInputChange("text", data)}
            />
          </InputWrapper>
          <InputWrapper
            hasError={!!keepingSafeEmailConfigurationStore.errors.signature?.length}
            label={
              keepingSafeEmailConfigurationStore.errors.signature?.length
                ? "Signature cannot be empty"
                : "* Type in your signature"
            }
            className={classes(styles.inputWrapper, styles.inputWrapperHeight, {
              [styles.inputWrapperEditorDisabled]:
                !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
            })}>
            <input
              placeholder="The Limbic Team"
              value={keepingSafeEmailConfigurationStore.keepingSafeEmail.content.signature}
              onChange={e => onInputChange("signature", e.target.value)}
              type="text"
              className={classes(styles.textInput, styles.textInputSignature, {
                [styles.inputDisabled]:
                  !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
              })}
            />
          </InputWrapper>
        </div>
        <div className={styles.controls}>
          <Button onClick={onSubmitChanges} disabled={!isChanged || !!error.message}>
            {serviceStore.isLoading ? "Updating..." : "Save Changes"}
          </Button>
          <Button
            onClick={onShowEmailPreview}
            disabled={
              !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
            }>
            Preview
          </Button>
          <Button
            onClick={onDiscardChanges}
            disabled={
              !isChanged ||
              !keepingSafeEmailConfigurationStore.keepingSafeEmail?.shouldSendKeepingSafeEmail
            }>
            Discard Changes
          </Button>
        </div>
      </div>
    </div>
  )
}

export default observer(KeepingSafe)

const asString = (value: string | undefined): string => value ?? ""
