import { styled } from "@stitches/react"
import { violet, mauve } from "@radix-ui/colors"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import * as cssAnimations from "../../../../styles/cssAnimations"
import {
  DropdownMenuCheckbox,
  DropdownMenuItem,
  DropdownMenuRadio,
  DropdownMenuSubTrigger
} from "./DropdownMenuItem"

const contentStyles = {
  minWidth: 220,
  backgroundColor: "white",
  borderRadius: 6,
  padding: 5,
  boxShadow:
    "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "600ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: cssAnimations.slideDownAndFade },
      '&[data-side="right"]': { animationName: cssAnimations.slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: cssAnimations.slideUpAndFade },
      '&[data-side="left"]': { animationName: cssAnimations.slideRightAndFade }
    }
  }
}

const StyledContent = styled(DropdownMenuPrimitive.Content, { ...contentStyles })

const StyledArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: "white"
})

function Content({ children, ...props }) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledContent {...props}>
        {children}
        <StyledArrow />
      </StyledContent>
    </DropdownMenuPrimitive.Portal>
  )
}

const StyledSubContent = styled(DropdownMenuPrimitive.SubContent, { ...contentStyles })

function SubContent(props) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledSubContent {...props} />
    </DropdownMenuPrimitive.Portal>
  )
}

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: "25px",
  color: mauve.mauve11
})

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: violet.violet6,
  margin: 5
})

export const DropdownMenu = {
  Root: DropdownMenuPrimitive.Root,
  Trigger: DropdownMenuPrimitive.Trigger,
  Content: Content,
  Item: DropdownMenuItem,
  Checkbox: DropdownMenuCheckbox,
  RadioGroup: DropdownMenuPrimitive.RadioGroup,
  Radio: DropdownMenuRadio,
  Sub: DropdownMenuPrimitive.Sub,
  SubTrigger: DropdownMenuSubTrigger,
  SubContent: SubContent,
  Label: StyledLabel,
  Separator: StyledSeparator
}
