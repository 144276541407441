import React, { ChangeEvent, KeyboardEvent, MouseEvent, SyntheticEvent } from "react"
import AddIcon from "@mui/icons-material/AddCircle"
import { IDefaultChatFlowMessages, StepResultBodyType } from "@limbic/types"
import styles from "./MessageForm.module.scss"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import { TextareaAutosize } from "@mui/base/TextareaAutosize"
import { Typography, IconButton } from "@mui/material"

interface Props {
  messages: StepResultBodyType[]
  value: string | undefined
  placeholder: string
  name: keyof IDefaultChatFlowMessages
  disabledInput?: boolean
  updateState: (data: Record<string, Record<any, any>>) => void
}

const MessageForm = ({
  name,
  value,
  placeholder,
  disabledInput = false,
  messages,
  updateState
}: Props) => {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState

  const addMessage = (
    event: MouseEvent<HTMLButtonElement> | SyntheticEvent
  ): boolean | undefined => {
    event?.preventDefault()

    if (!messages!.length || !chatFlowSettings.currentMessage![name].length) return
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        messages: {
          ...chatFlowSettings.messages,
          [name]: [...messages!, chatFlowSettings.currentMessage![name]]
        },
        currentMessage: {
          ...chatFlowSettings.currentMessage,
          [name]: ""
        }
      }
    })
  }

  const handleOnKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && event.shiftKey) {
      addMessage(event)
      event.preventDefault()
    }
  }

  const handleCurrentMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        currentMessage: {
          ...chatFlowSettings.currentMessage,
          [name]: event.target.value
        }
      }
    })
  }

  return (
    <div className={styles.messageFormContainer}>
      <form className={styles.addMessageForm} onSubmit={addMessage} action="">
        <TextareaAutosize
          disabled={disabledInput ?? false}
          className={styles.messageInput}
          value={value ?? ""}
          placeholder={placeholder}
          onChange={handleCurrentMessageChange}
          onKeyDown={handleOnKeyDown}
        />
        <IconButton
          size="small"
          className={styles.addButton}
          disabled={!value}
          onClick={addMessage}>
          <AddIcon />
        </IconButton>
      </form>
      <Typography variant="caption">
        Press <b>Shift + Enter</b> to add the message
      </Typography>
    </div>
  )
}

export default MessageForm
