import React, { useRef, useEffect, useCallback } from "react"
import { styled } from "@stitches/react"
import autosize from "autosize"
import { Connection, Handle, Position } from "react-flow-renderer"
import { CSSProperties } from "@stitches/react/types/css-util"
import { useFlowStore } from "../../../../../../../context/rootStoreContext"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

interface IProps {
  isConnectable: boolean
  disabled?: boolean
  value: string
  body: string
  editable?: boolean
  withoutHandle?: boolean
  onChange?: (value?: string) => void
  onEditingChange?: (editing: boolean) => void
  enableHandles?: boolean
  nodeId: string
  index: number
}

const targetHandleStyle: CSSProperties = {
  width: "100%",
  height: "100%",
  background: "blue",
  position: "absolute",
  top: 0,
  left: 0,
  borderRadius: 0,
  transform: "none",
  border: "none",
  opacity: 0,
  zIndex: 1000
}

export default function Path({ editable = true, ...props }: IProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const { withoutHandle = false, disabled = false } = props
  const flowStore = useFlowStore()

  const getHandleId = useCallback((): string => {
    return `${props.nodeId}-optionHandle-${props.value}-${props.index}`
  }, [props.index, props.nodeId, props.value])

  useEffect(() => {
    autosize(textAreaRef.current)
  }, [props.value])

  const addLabelToEdge = (params, value) => {
    const handleId = getHandleId()
    flowStore.updateEdgeWithOption(handleId, value, params.source)
  }

  const checkConnection = ({ source, target }: Connection): boolean => {
    return source !== target
  }

  return withoutHandle ? (
    <StyledOption disabled={disabled}>{props.body}</StyledOption>
  ) : (
    <div>
      <StyledOptionHandle>
        <Handle
          isConnectable={props.isConnectable}
          id={getHandleId()}
          style={targetHandleStyle}
          position={Position.Right}
          type="source"
          onConnect={params => addLabelToEdge(params, props.value)}
          isValidConnection={connection => checkConnection(connection)}
        />
        {props.body}
      </StyledOptionHandle>
      <ArrowDropDownIcon style={{ marginTop: "-16px" }} />
    </div>
  )
}

const StyledOption = styled("div", {
  cursor: "default",
  display: "inline-block",
  position: "relative",
  fontSize: "10px",
  fontFamily: "unset",
  backgroundColor: "#FF778E",
  outline: "unset",
  border: "unset",
  borderRadius: "10px",
  padding: "4px 6px",
  margin: "4px",
  textAlign: "center",
  resize: "unset",
  color: "white",
  minWidth: "36px",
  width: "auto",
  variants: {
    disabled: {
      true: { opacity: 0.5 },
      false: { opacity: 1 }
    }
  }
})

const StyledOptionHandle = styled("div", {
  position: "relative",
  fontSize: "10px",
  fontFamily: "unset",
  backgroundColor: "white",
  color: "black",
  outline: "solid",
  border: "unset",
  borderRadius: "5px",
  padding: "4px 6px",
  margin: "4px",
  textAlign: "unset",
  resize: "unset",
  minWidth: "36px",
  width: "auto",
  variants: {
    editable: {
      true: { pointerEvents: "auto" },
      false: { pointerEvents: "none" }
    }
  },
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.01)"
  }
})
