// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eBWQ17OXKgvm4hfWG0An{margin-top:12px}.c2wuR3yT2R0st9a6TnMH{padding-bottom:9px;width:100%;text-align:left}.O3UVR5bCGNKnhoz8SPCR{display:flex;justify-content:center;width:100%}.ChjX9Wva7oiSiUwcWeMA{display:flex;justify-content:center;width:100%}.mYyQoHpHg9qjxrwLOTWc{height:36px;width:75%;border-radius:15px;margin:12px 6px;border:1px solid #d3d3d3;padding:8px 16px}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/CollectLTC/CollectLTC.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,kBAAA,CACA,UAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,UAAA,CAGF,sBACE,WAAA,CACA,SAAA,CACA,kBAAA,CACA,eAAA,CACA,wBAAA,CACA,gBAAA","sourcesContent":[".collectLTCContainer {\n  margin-top: 12px;\n}\n\n.otherQuestionLabel {\n  padding-bottom: 9px;\n  width: 100%;\n  text-align: left;\n}\n\n.optionContainer {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.noneLabelInputContainer {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.noneLabelInput {\n  height: 36px;\n  width: 75%;\n  border-radius: 15px;\n  margin: 12px 6px;\n  border: 1px solid lightgray;\n  padding: 8px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collectLTCContainer": "eBWQ17OXKgvm4hfWG0An",
	"otherQuestionLabel": "c2wuR3yT2R0st9a6TnMH",
	"optionContainer": "O3UVR5bCGNKnhoz8SPCR",
	"noneLabelInputContainer": "ChjX9Wva7oiSiUwcWeMA",
	"noneLabelInput": "mYyQoHpHg9qjxrwLOTWc"
};
export default ___CSS_LOADER_EXPORT___;
