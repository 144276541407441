import React from "react"
import { FormControlLabel, Checkbox } from "@mui/material"
import styles from "./FormControlWithCheckBox.module.scss"

interface Props {
  disabled?: boolean
  name: string
  value: boolean
  label: string
  labelPlacement: "end" | "start" | "top" | "bottom"
  onToggle?: (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => void
}

export default function FormControlWithCheckboxControlledInput({
  disabled = false,
  name,
  value,
  label,
  labelPlacement,
  onToggle
}: Props) {
  return (
    <FormControlLabel
      disabled={disabled}
      className={styles.formControlLabel}
      value={name}
      control={<Checkbox checked={value} onChange={onToggle} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}
