import React from "react"
import { observer } from "mobx-react"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import { Typography } from "@mui/material"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import styles from "./GetPermissions.module.scss"

function GerPermissions(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <Typography align="right" className={styles.getPermissionsTitle} variant="subtitle1">
        Bot message to ask user for permissions
      </Typography>
      <MessageList
        messages={messages?.showTOSLinks ?? []}
        messageType="showTOSLinks"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.showTOSLinks}
        placeholder="Type a message here..."
        name="showTOSLinks"
        updateState={updateState}
        messages={messages?.showTOSLinks ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldAskResearchConsent"
        label="Should ask for research consent"
        labelPlacement="start"
        updateState={updateState}
      />
      <Typography align="right" className={styles.getPermissionsTitle} variant="subtitle1">
        Bot message to ask user for research consent
      </Typography>
      <MessageList
        disabled={!chatFlowSettings.getPermissions?.shouldAskResearchConsent}
        messages={messages?.askResearchConsent ?? []}
        messageType="askResearchConsent"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={!chatFlowSettings.getPermissions?.shouldAskResearchConsent}
        value={chatFlowSettings.currentMessage?.askResearchConsent}
        placeholder="Type a message here..."
        name="askResearchConsent"
        updateState={updateState}
        messages={messages?.askResearchConsent ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldSayReady"
        label="Should say ready to start"
        labelPlacement="start"
        updateState={updateState}
      />
      <Typography align="right" className={styles.getPermissionsTitle} variant="subtitle1">
        Bot message to say ready to start
      </Typography>
      <MessageList
        disabled={!chatFlowSettings.getPermissions?.shouldSayReady}
        messages={messages?.sayReady ?? []}
        messageType="sayReady"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={!chatFlowSettings.getPermissions?.shouldSayReady}
        value={chatFlowSettings.currentMessage?.sayReady}
        placeholder="Type a message here..."
        name="sayReady"
        updateState={updateState}
        messages={messages?.sayReady ?? []}
      />
      <SolidDivider />
    </>
  )
}

export default observer(GerPermissions)
