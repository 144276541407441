import React from "react"
import BotLogo from "../../../../assets/bot-logo.png"
import styles from "./BotBubble.module.scss"

interface Props {
  children?: React.ReactChild | React.ReactChild[]
  isFirstMessage?: boolean
}

function BotBubble(props: Props): JSX.Element {
  const { children, isFirstMessage } = props

  return (
    <div className={styles.botBubbleContainer}>
      {isFirstMessage && <img className={styles.botLogo} src={BotLogo} alt="logo" />}
      <div className={styles.botBubbleMessage}>{children}</div>
    </div>
  )
}

export default BotBubble
