import React from "react"
import { observer } from "mobx-react"
import DummyInput from "../../../QuestionEditor/components/DummyInput"
import { PromptType } from "../../../../models/NodeEditors"
import FormControlWithCheckBox from "../../components/FormControlWithCheckBox"
import MessageList from "../../components/MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../../components/MessageForm/MessageForm"
import OptionsPlaceholder from "../../components/OptionsPlaceholder"

function CollectSexuality(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <MessageList
        messages={messages?.askSexuality ?? []}
        messageType="askSexuality"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <OptionsPlaceholder name="Sexuality" />
      {chatFlowSettings.collectSexuality?.withTextPrompt && (
        <DummyInput promptType={PromptType.Text} />
      )}
      <MessageForm
        value={chatFlowSettings.currentMessage?.askSexuality}
        placeholder="Type a message here..."
        name="askSexuality"
        updateState={updateState}
        messages={messages?.askSexuality ?? []}
      />
      <FormControlWithCheckBox
        name="withTextPrompt"
        label="Include a free text input"
        labelPlacement="start"
        updateState={updateState}
      />
    </>
  )
}

export default observer(CollectSexuality)
