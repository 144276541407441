import React from "react"
import { observer } from "mobx-react"
import MessageList from "../../components/MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../../components/MessageForm/MessageForm"

function CollectNHSNumber(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <MessageList
        messages={messages?.askNHSNumber ?? []}
        messageType="askNHSNumber"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askNHSNumber}
        placeholder="Type a message here..."
        name="askNHSNumber"
        updateState={updateState}
        messages={messages?.askNHSNumber ?? []}
      />
    </>
  )
}

export default observer(CollectNHSNumber)
