import { ChatFlowsEnum, DASHBOARD_MESSAGES_MAP, IDefaultChatFlowMessages } from "@limbic/types"
import hasOwnProperty from "./hasOwnProperty"

/**
 * This helper removes messages that do not belong to the specified chat flow
 *
 * If chatFlow === ChatFlowsEnum.COLLECT_LANGUAGE, it turns the following object:
 *
 * const messages = {
 *   askPrimaryLanguage: ["message", "message"],
 *   askRequiresAnInterpreter: ["message"],
 *   askInterpreterLanguage: ["message"],
 *   askAbleToCommunicateInEnglish: ["message"],
 *   askHasASD: ["message"],
 *   askHasADHD: ["message"]
 * }
 *
 * To:
 * {
 *   askPrimaryLanguage: ["message", "message"],
 *   askRequiresAnInterpreter: ["message"],
 *   askInterpreterLanguage: ["message"],
 *   askAbleToCommunicateInEnglish: ["message"]
 * }
 * @param chatFlow {ChatFlowsEnum}
 * @param messages {IDefaultChatFlowMessages}
 */
export default function formatDashboardMessages(
  chatFlow?: ChatFlowsEnum,
  messages?: IDefaultChatFlowMessages
): Partial<IDefaultChatFlowMessages> | undefined {
  if (!(chatFlow && Object.keys(messages ?? {}).length)) return

  const keysToInclude = DASHBOARD_MESSAGES_MAP[chatFlow] ?? []
  return keysToInclude.reduce((acc: Record<string, any>, key: keyof IDefaultChatFlowMessages) => {
    const currentValue = messages![key]
    if (currentValue != null && hasOwnProperty(messages, key)) {
      acc[key] = currentValue
    }
    return acc
  }, {})
}
