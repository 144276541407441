import { Configuration, ConfigurationInputValidation } from "../models"
import Logo from "../../../assets/logo.png"

export const DEFAULT_CONFIGURATION: Configuration = {
  title: "Limbic Self Referral Assistant",
  serviceName: "Demo Service",
  organisationName: "Demo Organisation",
  organisationTermsNames: [],
  organisationTermsLinksText: [],
  organisationTermsLinks: [],
  organisationPhoneNumbers: [
    "Demo Clinic London: +44 1234 567890",
    "Demo Clinic Kent: +44 1234 567890",
    "Demo Clinic Calderdale: +44 1234 567890"
  ],
  crisisPhoneNumbers: "",
  logo: Logo,
  userMessageBackground: "#fd81a0",
  faqLink: "https://limbic.ai/faq"
}

export const DEFAULT_VALIDATION: ConfigurationInputValidation = {
  title: true,
  serviceAndOrganisationName: true,
  serviceName: true,
  organisationName: true,
  organisationTerms: true,
  organisationPhoneNumbers: true,
  crisisPhoneNumbers: true,
  logo: true,
  userMessageBackground: true,
  faqLink: true
}

export const DEFAULT_PICKER_COLORS: string[] = [
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#fd81a0",
  "#EB144C",
  "#9900EF"
]
