import { isObservable, toJS } from "mobx"

const originalConsoleLog = console.log

console.log = function (...args) {
  const newArgs = args.map(arg => (isObservable(arg) ? toJS(arg) : arg))
  originalConsoleLog.apply(console, newArgs)
}

if (process.env.REACT_APP_USE_LOCAL_BACKEND === "true") {
  const originalFetch = window.fetch
  window.fetch = async function fetch(
    input: RequestInfo | URL,
    init?: RequestInit
  ): Promise<Response> {
    if (typeof input === "string" && input.includes("https://access-v2-staging.limbic.ai")) {
      return originalFetch(
        input.replace("https://access-v2-staging.limbic.ai", "http://localhost:4000"),
        init
      )
    }
    return originalFetch(input, init)
  }
}
