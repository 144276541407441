import {
  ChatFlowsEnum,
  DiscussionSteps,
  IDashboardEdge,
  IDashboardNode,
  NodeTypes
} from "@limbic/types"
import { IFlowValidation, NodeValidation } from "../app/components/FlowEditor/models/NodeEditors"

const validateFlow = (
  nodes: IDashboardNode[],
  edges: IDashboardEdge[],
  currentHighLevelDialogue: DiscussionSteps
): [boolean, IFlowValidation] => {
  let validation: IFlowValidation = {
    nodes: [] as Array<NodeValidation>,
    hasFlowStart: false,
    hasFlowEnd: true,
    needsSubmitReferral: false,
    someNodesInvalid: false
  }
  let isSelfReferral = false
  let hasSubmitReferral = true
  nodes.forEach(node => {
    let isNodeValid
    if (
      node.type === NodeTypes.Question ||
      node.type === NodeTypes.Action ||
      node.type === NodeTypes.ChatFlow ||
      node.type === NodeTypes.IneligibleUser
    ) {
      isNodeValid =
        edges.find(edge => edge.source === node.id) && edges.find(edge => edge.target === node.id)
    }

    if (node.type === NodeTypes.Condition || node.type === NodeTypes.AdvancedCondition) {
      const edgeInstance = [...edges]
      isNodeValid = edgeInstance.find(edge => edge.target === node.id)
      let firstSourceIndex
      const firstSource = edgeInstance.find((edge, index) => {
        if (edge.source === node.id) firstSourceIndex = index
        return edge.source === node.id
      })
      if (firstSource) {
        isNodeValid = true
        edgeInstance.splice(firstSourceIndex, 1)
        isNodeValid = edgeInstance.find(edge => edge.source === node.id)
      }
    }

    if (node.type === NodeTypes.FlowStart) {
      isNodeValid = edges.find(edge => edge.source === node.id)
    }

    if (node.type === NodeTypes.FlowEnd) {
      isNodeValid = edges.find(edge => edge.target === node.id)
    }

    if (node.type === NodeTypes.EndChat) {
      isNodeValid = edges.find(edge => edge.target === node.id)
    }

    if (currentHighLevelDialogue === DiscussionSteps.SelfReferral) {
      isSelfReferral = true
      hasSubmitReferral = !!nodes.find(
        node =>
          node.type === NodeTypes.ChatFlow &&
          node.settings?.chatFlow === ChatFlowsEnum.SUBMIT_REFERRAL
      )
    }

    validation.nodes.push({
      id: node.id,
      isValid: !!isNodeValid,
      type: node.type
    })
  })

  const hasFlowStart = !!nodes.find(node => node.type === NodeTypes.FlowStart)
  const hasFlowEnd = !!nodes.find(node => node.type === NodeTypes.FlowEnd)
  const needsSubmitReferral = isSelfReferral && !hasSubmitReferral
  const someNodesInvalid = validation.nodes.some(node => !node.isValid)

  validation = {
    ...validation,
    hasFlowStart,
    hasFlowEnd,
    needsSubmitReferral,
    someNodesInvalid
  }

  const isFlowValid =
    !someNodesInvalid && validation.hasFlowStart && validation.hasFlowEnd && !needsSubmitReferral

  return [isFlowValid, validation]
}

export default validateFlow
