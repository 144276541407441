import React, { PropsWithChildren, useMemo } from "react"
import { useBackendMappingStore, useServiceStore } from "../../context/rootStoreContext"
import { IKeyTransform, KeyType } from "@limbic/types"
import { computed } from "mobx"
import getPredictedValuesMap from "../../../utils/getPredictedValuesMap"
import styles from "./Integration.module.scss"
import KeyMappingInput from "./components/KeyMappingInput"
import ValueMappingInput from "./components/ValueMappingInput"
import { observer } from "mobx-react"
import Button from "../../components/Button"
import deepCompare from "../../../utils/deepCompare"

interface IBackendMappingProps {
  onError: () => void
}
function BackendMapping(props: IBackendMappingProps) {
  const serviceStore = useServiceStore()
  const backendMappingStore = useBackendMappingStore()

  const serviceData = serviceStore.serviceData?.[serviceStore.mode]

  const isChanged = useMemo(() => {
    return !deepCompare(
      serviceData?.backendMapping?.transformMap ?? {},
      backendMappingStore.transformMap
    )
  }, [backendMappingStore.transformMap, serviceData?.backendMapping?.transformMap])

  const transformGroups = useMemo(() => {
    return Object.values(backendMappingStore.transformMap).reduce(
      (dialogueGroup, transform: IKeyTransform) => {
        const [dialogue, stepName] = transform.context.split("-->")
        dialogueGroup[dialogue] ??= {}
        dialogueGroup[dialogue][stepName] ??= []
        dialogueGroup[dialogue][stepName].push(transform)
        return dialogueGroup
      },
      {}
    )
  }, [backendMappingStore.transformMap])

  const usedTargetKeys: string[] = useMemo(() => {
    return computed(
      () =>
        Object.values(backendMappingStore.transformMap)
          .map(m => m.targetKey)
          .filter(Boolean) as string[]
    )
  }, [backendMappingStore.transformMap]).get()

  const onChangeTargetKey = (transform: IKeyTransform, targetKey: string) => {
    if (transform.targetKey !== targetKey) {
      const newTransform = { ...transform, targetKey }
      const targetKeyMetaData = backendMappingStore.targetKeys[targetKey]
      const valuesMap = getPredictedValuesMap(transform, targetKeyMetaData)
      if (valuesMap) newTransform.valuesMap = valuesMap
      backendMappingStore.setKeyTransform(newTransform)
    }
  }

  const onRemoveTargetKey = (transform: IKeyTransform) => {
    const newTransform = { ...transform, targetKey: undefined }
    const valuesMap = getPredictedValuesMap(transform)
    if (valuesMap) newTransform.valuesMap = valuesMap
    backendMappingStore.setKeyTransform(newTransform)
  }

  const onRemoveTargetValue = (
    transform: IKeyTransform,
    sourceValue: string | number | boolean
  ) => {
    const valuesMap = { ...transform.valuesMap, [String(sourceValue)]: null }
    const newTransform = { ...transform, valuesMap }
    backendMappingStore.setKeyTransform(newTransform)
  }

  const onChangeValueMapping = (
    transform: IKeyTransform,
    sourceValue: string | number | boolean,
    targetValue: string | number | boolean
  ) => {
    let tValue = targetValue
    const targetKey = backendMappingStore.targetKeys[transform.targetKey ?? ""]
    if ([KeyType.Boolean, KeyType.BooleanList].includes(targetKey?.type)) {
      if (targetValue === "true") tValue = true
      if (targetValue === "false") tValue = false
    }
    const valuesMap = { ...transform.valuesMap, [String(sourceValue)]: tValue }
    const newTransform = { ...transform, valuesMap }
    backendMappingStore.setKeyTransform(newTransform)
  }

  const onSaveChanges = async () => {
    try {
      await serviceStore.updateBackendMapping(backendMappingStore.transformMap)
    } catch (e) {
      props.onError()
      console.log({ e })
    }
  }

  const onDiscardChanges = () => backendMappingStore.sync(serviceData)

  return (
    <>
      <div className={styles.propertiesContainer}>
        {Object.entries(transformGroups).map(([dialogue, steps], index) => (
          <DialogueContainer key={index} dialogue={dialogue}>
            {Object.entries(steps ?? {}).map(([stepName, stepMaps], index) => (
              <StepContainer key={index} stepName={stepName}>
                {stepMaps.map((transform, index) => (
                  <KeyMappingInput
                    key={index}
                    transform={transform}
                    usedTargetKeys={usedTargetKeys}
                    onChangeTargetKey={onChangeTargetKey}
                    onRemoveTargetKey={onRemoveTargetKey}>
                    <ValueMappingInput
                      transform={transform}
                      onChangeValueMapping={onChangeValueMapping}
                      onRemoveTargetValue={onRemoveTargetValue}
                    />
                  </KeyMappingInput>
                ))}
              </StepContainer>
            ))}
          </DialogueContainer>
        ))}
      </div>

      <div className={styles.buttonsSpacer} />
      <div className={styles.buttonsContainer}>
        <Button disabled={!isChanged} onClick={onSaveChanges}>
          {serviceStore.isLoading ? "Updating..." : "Save Changes"}
        </Button>
        <Button disabled={!isChanged} onClick={onDiscardChanges}>
          Discard Changes
        </Button>
      </div>
    </>
  )
}

export default observer(BackendMapping)

function DialogueContainer(props: PropsWithChildren<{ dialogue: string }>) {
  return (
    <div className={styles.dialogueContainer}>
      <h2>Dialogue: {props.dialogue}</h2>
      {props.children}
    </div>
  )
}

function StepContainer(props: PropsWithChildren<{ stepName: string }>) {
  return (
    <div className={styles.stepContainer}>
      <h3>Step: {props.stepName}</h3>
      {props.children}
    </div>
  )
}
