import React, { ChangeEvent, useCallback, useEffect } from "react"
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import { useNodeEditorStore, useServiceStore } from "../../../../../../context/rootStoreContext"
import { EditorType } from "../../../../models/NodeEditors"
import styles from "./SetIAPT.module.scss"
import { observer } from "mobx-react"

function SetIAPT(): JSX.Element | null {
  const nodeEditorStore = useNodeEditorStore()
  const serviceStore = useServiceStore()
  const { actionEditorState } = nodeEditorStore
  const { setIAPTName, setIAPTid } = actionEditorState
  const mode = serviceStore.mode
  const customIAPTs = serviceStore.serviceData?.[mode]?.eligibility?.dashboard.iapts

  const updateState = useCallback(
    data => {
      nodeEditorStore.updateState(EditorType.ACTION, data)
    },
    [nodeEditorStore]
  )

  useEffect(() => {
    /**
     * This is to deal default to the first IAPT in the list if the IAPT name is not set
     */
    if (customIAPTs?.length && !setIAPTName?.length) {
      updateState({ setIAPTName: customIAPTs[0].name })
    }
  }, [setIAPTName, customIAPTs, updateState])

  const handleIAPTChange = (event: SelectChangeEvent<string>): void => {
    const value = event.target.value
    updateState({ setIAPTName: value })
  }

  const handleIAPTIdChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value
    updateState({ setIAPTid: value })
  }

  return (
    <div className={styles.setIAPTIDContainer}>
      {customIAPTs?.length ? (
        <Select
          id="select-service-input"
          color="primary"
          variant="outlined"
          className={styles.select}
          onChange={handleIAPTChange}
          value={setIAPTName?.length ? setIAPTName : customIAPTs[0].name}>
          {customIAPTs.map(iapt => (
            <MenuItem key={iapt.name} value={iapt.name}>
              {iapt.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <FormControl className={styles.formControlSetIAPTIDContainer}>
          <TextField
            id="set-iapt-id"
            label="IAPT ID"
            variant="outlined"
            value={setIAPTid ?? ""}
            onChange={event => handleIAPTIdChange(event)}
          />
        </FormControl>
      )}
    </div>
  )
}

export default observer(SetIAPT)
