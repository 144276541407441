import React from "react"
import { observer } from "mobx-react"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import { Typography } from "@mui/material"
import styles from "./CollectFeedback.module.scss"

function CollectFeedback(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <FormControlWithCheckBox
        name="shouldAskAdditionalFeedback"
        label="Ask user additional feedback (i.e. improvement suggestions)"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <Typography align="right" className={styles.collectFeedbackTitle} variant="subtitle1">
        Bot message to ask user if the bot was helpful:
      </Typography>
      <MessageList
        messages={messages?.askFeedback ?? []}
        messageType="askFeedback"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askFeedback}
        placeholder="Type a message here..."
        name="askFeedback"
        updateState={updateState}
        messages={messages?.askFeedback ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.collectFeedbackTitle} variant="subtitle1">
        Bot message to reply to a <b>negative</b> user reply <br />
        (and ask for additional feedback if applicable)
      </Typography>
      <MessageList
        messages={messages?.saySorryToHearThat ?? []}
        messageType="saySorryToHearThat"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.saySorryToHearThat}
        placeholder="Type a message here..."
        name="saySorryToHearThat"
        updateState={updateState}
        messages={messages?.saySorryToHearThat ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.collectFeedbackTitle} variant="subtitle1">
        Bot message to reply to a <b>neutral</b> user reply <br />
        (and ask for additional feedback if applicable)
      </Typography>
      <MessageList
        messages={messages?.sayUnderstood ?? []}
        messageType="sayUnderstood"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayUnderstood}
        placeholder="Type a message here..."
        name="sayUnderstood"
        updateState={updateState}
        messages={messages?.sayUnderstood ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.collectFeedbackTitle} variant="subtitle1">
        Bot message to reply to a <b>positive</b> user reply <br />
        (and ask for additional feedback if applicable)
      </Typography>
      <MessageList
        messages={messages?.sayImGlad ?? []}
        messageType="sayImGlad"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayImGlad}
        placeholder="Type a message here..."
        name="sayImGlad"
        updateState={updateState}
        messages={messages?.sayImGlad ?? []}
      />
    </>
  )
}

export default observer(CollectFeedback)
