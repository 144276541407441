import React, { ChangeEvent, MouseEvent, SyntheticEvent, useEffect, useState } from "react"
import {
  Autocomplete,
  FormControl,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from "@mui/material/"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import AddIcon from "@mui/icons-material/AddCircle"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import Button from "../../../Button"
import { useFlowStore, useNodeEditorStore } from "../../../../context/rootStoreContext"
import BotBubble from "../../../BotPlayground/components/BotBubble"
import PromptSettings from "./components/PromptSettings"
import DummyInput from "./components/DummyInput"
import {
  EditorType,
  IQuestionEditorState,
  IQuestionEditorValidity,
  PromptType
} from "../../models/NodeEditors"
import CancelIcon from "@mui/icons-material/Cancel"
import { observer } from "mobx-react"
import toCamelCase from "../../../../../utils/toCamelCase"
import BotBubbleEditable from "../../../BotPlayground/components/BotBubbleEditable"
import Drawer from "../../../Drawer"
import SolidDivider from "../../../SolidDivider"
import { TextareaAutosize } from "@mui/base/TextareaAutosize"
import { dataPointsOptions } from "./data"
import isValidDateFormat from "../../../../../utils/isValidDateFormat"
import classes from "clsx"

import styles from "./QuestionEditor.module.scss"

type MaxWidth = "xs" | "sm" | "md" | "lg" | "xl" | false

interface Props {
  ActionButton?: React.ReactChild | React.ReactChild[]
  children?: React.ReactChild | React.ReactChild[]
  onClose?: (value: boolean) => void
  open?: boolean | undefined
  title?: string
  maxWidth?: MaxWidth
}

function QuestionEditor(props: Props): JSX.Element {
  const { ActionButton, title } = props
  const [error, setError] = useState<string>("")
  const [questionDuplicate, setQuestionDuplicate] = useState<boolean>(false)
  const flowStore = useFlowStore()
  const nodeEditorStore = useNodeEditorStore()
  const { questionEditorState } = nodeEditorStore

  const PROMPT_TYPES = {
    [PromptType.Checkbox]: "Checkbox",
    [PromptType.Date]: "Date",
    [PromptType.Email]: "Email",
    [PromptType.InlinePicker]: "Inline Picker Single Selection",
    [PromptType.InlinePickerMultiSelect]: "Inline Picker Multiple Selections",
    [PromptType.Message]: "Message(s)",
    [PromptType.Name]: "Name Prompt",
    [PromptType.PhoneNumber]: "Phone Number Input",
    [PromptType.Text]: "Text Input"
  }

  useEffect(() => {
    const nodeId = nodeEditorStore.nodeId
    const currentNode = flowStore.nodes.find(node => node.id === nodeId)
    if (currentNode && currentNode.settings && Object.keys(currentNode.settings).length) {
      const { settings } = currentNode
      const { messages, questionName, promptSettings, promptType } = settings!
      nodeEditorStore.updateState(EditorType.QUESTION, {
        promptType,
        messages,
        questionName,
        promptSettings
      })
    } else {
      nodeEditorStore.resetState(EditorType.QUESTION)
    }
  }, [flowStore.nodes, nodeEditorStore])

  const handleClose = (): void => {
    nodeEditorStore.setEditorClosed(EditorType.QUESTION)
  }

  const checkValidity = (state: IQuestionEditorState): IQuestionEditorValidity => {
    // prettier-ignore
    const isPicker = [PromptType.InlinePicker, PromptType.InlinePickerMultiSelect].includes(state.promptType)
    const isDate = state.promptType === PromptType.Date
    // prettier-ignore
    return {
      hasMessages: !!state.messages.length,
      hasQuestionName: !!state.questionName.length,
      hasOptions: isPicker ? !!state.promptSettings.options.length : true,
      inputDateFormatValid: isDate ? isValidDateFormat(state.promptSettings.inputDateFormat) : true,
      displayDateFormatValid: isDate ? isValidDateFormat(state.promptSettings.displayDateFormat) : true
    }
  }

  const handleSave = (): void => {
    const nodeId = nodeEditorStore.nodeId
    const valid = checkValidity(questionEditorState)
    if (!valid.hasMessages) {
      setError("Please add at least one bot message")
      return
    }
    if (!valid.hasQuestionName) {
      setError("Please add a descriptive question name i.e. 'Phone number'")
      return
    }
    if (!valid.hasOptions) {
      setError("Please include at least one possible answer for the inline picker")
      return
    }
    if (!Object.values(valid).includes(false)) {
      setError("")
      flowStore.updateQuestionNode(nodeId, questionEditorState)
    }
  }

  const addMessage = (
    event: MouseEvent<HTMLButtonElement> | SyntheticEvent
  ): boolean | undefined => {
    event?.preventDefault()
    if (!questionEditorState.currentMessage.length) return
    if (questionEditorState.messages.includes(questionEditorState.currentMessage)) {
      setError("Duplicate message")
      return
    }
    updateState({ messages: [...questionEditorState.messages, questionEditorState.currentMessage] })
    updateState({ currentMessage: "" })
  }

  const deleteMessage = (message: string): void => {
    updateState({ messages: [...questionEditorState.messages].filter(m => m !== message) })
  }

  const handlePromptTypeChange = (event: SelectChangeEvent): void => {
    updateState({ promptType: event.target.value as PromptType })
  }

  const handleDataPointsNameChange = (value: string): void => {
    updateState({
      ...questionEditorState,
      promptSettings: {
        ...questionEditorState.promptSettings,
        dataPointsName: value === "N/A" ? undefined : value
      }
    })
  }

  const handleCheckBoxChange = (value: boolean, key: string): void => {
    updateState({
      ...questionEditorState,
      promptSettings: {
        ...questionEditorState.promptSettings,
        [key]: value
      }
    })
  }

  const handleOnKeyDown = event => {
    if (event.key === "Enter" && event.shiftKey) {
      addMessage(event)
      event.preventDefault()
    }
  }

  const handleQuestionNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const hasSameName = flowStore.nodes
      .filter(node => node.id !== nodeEditorStore.nodeId)
      .find(node => {
        return node.settings && node.settings.questionName
          ? toCamelCase(node.settings?.questionName) === toCamelCase(event.target.value)
          : false
      })
    updateState({ questionName: event.target.value })
    setQuestionDuplicate(!!hasSameName)
  }

  const updateState = data => {
    nodeEditorStore.updateState(EditorType.QUESTION, data)
  }

  const handleOnMessageChange = (value: string, index: number) => {
    const updatedMessages = [...questionEditorState.messages]
    updatedMessages[index] = value
    updateState({ messages: updatedMessages })
  }

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setError("")
    updateState({ currentMessage: event.target.value })
  }

  return (
    <Drawer anchor="left" isDrawerOpen={nodeEditorStore.questionEditorOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={styles.nodeEditorContainer}>
          <div className={styles.messagesContainer}>
            <Typography>Messages</Typography>
            <div className={styles.messagesList}>
              {questionEditorState.messages.length ? (
                questionEditorState.messages.map((message, index) => (
                  <div className={styles.messageRow} key={index}>
                    <BotBubbleEditable
                      isFirstMessage={index === 0}
                      onMessageChange={handleOnMessageChange}
                      value={message}
                      index={index}
                    />
                    <IconButton
                      size="small"
                      className={styles.deleteButton}
                      onClick={() => deleteMessage(message)}>
                      <CancelIcon />
                    </IconButton>
                  </div>
                ))
              ) : (
                <BotBubble isFirstMessage>Add a message using the input below</BotBubble>
              )}
              <DummyInput
                promptType={questionEditorState.promptType}
                options={questionEditorState.promptSettings.options}
              />
            </div>
            <form className={styles.addMessageForm} onSubmit={addMessage} action="">
              <TextareaAutosize
                className={classes(styles.messageInput, {
                  [styles.messageInputWithError]: error === "Duplicate message"
                })}
                value={questionEditorState.currentMessage}
                placeholder="Type a message here..."
                onChange={handleMessageChange}
                onKeyDown={handleOnKeyDown}
              />
              <IconButton
                size="small"
                className={styles.addButton}
                disabled={!questionEditorState.currentMessage}
                onClick={event => addMessage(event)}>
                <AddIcon />
              </IconButton>
            </form>
            <Typography variant="caption">
              Press <b>Shift + Enter</b> to add the message
            </Typography>
          </div>
          <SolidDivider />
          <div className={styles.optionsContainer}>
            <Typography>Options</Typography>
            <div className={styles.optionsContent}>
              <div className={styles.formControlNameOfQuestion}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Name of Question"
                    name="questionName"
                    className={styles.textField}
                    placeholder="Name of question"
                    value={questionEditorState.questionName}
                    onChange={handleQuestionNameChange}
                  />
                </FormControl>
                <div className={styles.questionIdDisplay}>
                  {questionEditorState.questionName.length && !questionDuplicate ? (
                    <div style={{ color: "gray" }}>
                      <span>
                        <b>Question ID: </b>
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            toCamelCase(questionEditorState.questionName)
                          )
                        }}>
                        {toCamelCase(questionEditorState.questionName)}
                      </span>
                      <IconButton
                        className={styles.copyButton}
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            toCamelCase(questionEditorState.questionName)
                          )
                        }}>
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ) : (
                    <div>
                      {questionDuplicate ? (
                        <span className={styles.questionDuplicate}>This name already exists</span>
                      ) : (
                        <span className={styles.pleaseAddQuestionName}>
                          Please add a question name
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.formControlOptions}>
                <FormControl className={styles.formControl} fullWidth>
                  <Select
                    className={styles.select}
                    size="small"
                    label="Type of Question"
                    name="questionType"
                    placeholder="Type of question"
                    value={questionEditorState.promptType}
                    onChange={handlePromptTypeChange}>
                    {Object.keys(PROMPT_TYPES)
                      .sort()
                      .map(key => (
                        <MenuItem key={key} value={key}>
                          {PROMPT_TYPES[key]}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl className={styles.dataPointsName} fullWidth>
                  <Autocomplete
                    disabled={
                      questionEditorState.promptType !== PromptType.InlinePickerMultiSelect &&
                      questionEditorState.promptType !== PromptType.InlinePicker &&
                      questionEditorState.promptType !== PromptType.Text &&
                      questionEditorState.promptType !== PromptType.Date
                    }
                    freeSolo
                    multiple={false}
                    id="dataPointsName"
                    onChange={(_event, value) => handleDataPointsNameChange(value!)}
                    options={dataPointsOptions}
                    value={questionEditorState.promptSettings.dataPointsName ?? "N/A"}
                    filterSelectedOptions
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Data points name"
                        placeholder="Select the biomarkers name"
                      />
                    )}
                  />
                </FormControl>
              </div>
              {questionEditorState.promptType !== PromptType.Message && (
                <PromptSettings
                  promptType={questionEditorState.promptType}
                  {...questionEditorState.promptSettings}
                  onChange={handleCheckBoxChange}
                />
              )}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={styles.dialogueError}>{error}</div>
        {ActionButton && ActionButton}
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSave} disabled={questionDuplicate}>
          Save
        </Button>
      </DialogActions>
    </Drawer>
  )
}

export default observer(QuestionEditor)
