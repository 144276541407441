import { IDashboardNode, NodeTypes, PromptType } from "@limbic/types"

export function isChatFlowNode(node: IDashboardNode): boolean {
  return node.type === NodeTypes.ChatFlow
}

export function isFreeTextNode(node: IDashboardNode): boolean {
  const promptType = node.settings?.promptType
  return [PromptType.Text, PromptType.Email, PromptType.PhoneNumber].includes(promptType!)
}

export function isCheckboxNode(node: IDashboardNode): boolean {
  return node.settings?.promptType === PromptType.Checkbox
}

export function isInlinePickerNode(node: IDashboardNode): boolean {
  return [
    PromptType.InlinePicker, //
    PromptType.InlinePickerMultiSelect
  ].includes(node.settings?.promptType as PromptType)
}

export function isDateNode(node: IDashboardNode): boolean {
  return node.settings?.promptType === PromptType.Date
}

export function isSetStateActionNode(node: IDashboardNode): boolean {
  return node.type === NodeTypes.Action && node.settings?.action === "setState"
}
