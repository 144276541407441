import React, { useMemo } from "react"
import { observer } from "mobx-react"
import styles from "./AdvancedConfiguration.module.scss"
import { useServiceStore, useServiceConfigurationStore } from "../../../../context/rootStoreContext"
import SnippetInjectionBox from "../../../../components/SnippetInjectionBox/SnippetInjectionBox"
import deepCompare from "../../../../../utils/deepCompare"
import Button from "../../../../components/Button"
import { AdvancedSettings } from "@limbic/types"

interface Props {
  setError: (value: boolean) => void
}

function AdvancedConfiguration(props: Props): JSX.Element {
  const serviceStore = useServiceStore()
  const serviceConfigurationStore = useServiceConfigurationStore()
  const serviceDataConfiguration = serviceStore.serviceData?.[serviceStore.mode]?.configuration
  const [advanced, setAdvanced] = React.useState<AdvancedSettings>(
    serviceConfigurationStore.config.advanced || {}
  )

  const isChanged = useMemo(() => {
    return !deepCompare(serviceDataConfiguration?.advanced ?? {}, advanced)
  }, [serviceDataConfiguration?.advanced, advanced])

  const onSubmitChanges = async () => {
    props.setError(false)
    try {
      const config = {
        ...serviceConfigurationStore.config,
        advanced
      }
      await serviceStore.updateServiceConfiguration(config, serviceConfigurationStore.logo)
    } catch (error) {
      props.setError(true)
    }
  }

  return (
    <div className={styles.advancedConfigurationContainer}>
      <div className={styles.snippet}>
        <SnippetInjectionBox
          currentSnippets={advanced.snippet || []}
          onSnippetChange={snippet =>
            setAdvanced(settings => ({
              ...settings,
              snippet
            }))
          }
        />
      </div>

      <div className={styles.advancedConfigurationButton}>
        <Button onClick={onSubmitChanges} disabled={!isChanged}>
          {serviceStore.isLoading ? "Updating..." : "Save Changes"}
        </Button>
      </div>
    </div>
  )
}

export default observer(AdvancedConfiguration)
