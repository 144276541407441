import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react"
import {
  DiscussionSteps,
  EdgeTypes,
  IDashboardEdge,
  IDashboardNode,
  NodeTypes
} from "@limbic/types"
import { toJS } from "mobx"
import ReactFlow, {
  ReactFlowInstance,
  Background,
  BackgroundVariant,
  Edge as RF_EDGE,
  Node as RF_Node
} from "react-flow-renderer"
import { useFlowStore, useNodeEditorStore, useServiceStore } from "../../context/rootStoreContext"
import { useWindowSize } from "../../hooks/useWindowSize"
import { Column } from "./components/Layout"
import MainToolBar from "./views/menus/MainToolBar"
import MainContextMenu from "./views/menus/MainContextMenu"
import { connectionLineStyle, defaultEdgeOptions, snapGrid } from "./config"
import {
  EdgeTypes as RFR_EdgeTypes,
  NodeTypes as RFR_NodeTypes
} from "react-flow-renderer/dist/esm/types/general"
import FlowStartNode from "./views/nodes/generic/FlowStartNode"
import FlowEndNode from "./views/nodes/generic/FlowEndNode"
import QuestionNode from "./views/nodes/generic/QuestionNode"
import IneligibleUserNode from "./views/nodes/generic/IneligibleUserNode"
import BasicConditionNode from "./views/nodes/generic/BasicConditionNode"
import AdvancedConditionNode from "./views/nodes/generic/AdvancedConditionNode"
import CustomEdge from "./views/edges/CustomEdge"
import FloatingEdge from "./views/edges/FloatingEdge/FloatingEdge"
import { ReactFlowInstanceProvider } from "../../hooks/useReactFlowInstance"
import ActionNode from "./views/nodes/generic/ActionNode"
import ChatFlowNode from "./views/nodes/generic/ChatFlowNode"
import validateFlow from "../../../utils/validateFlow"
import defaultFlows, { DefaultDialogues } from "./defaultFlows"
import CustomDrawer from "../Drawer/Drawer"
import HighLevelFlow from "../HighLevelFlow"
import Button from "../Button"
import ValidationDialogue from "./components/ValidationDialogue"
import Dialog from "../Dialog"
import { Typography, DialogTitle } from "@mui/material"
import EndChatNode from "./views/nodes/generic/EndChatNode"

type Position = "static" | "relative" | "absolute" | "fixed" | "sticky"
type FlexDirection = "column" | "row"

const nodeTypes: RFR_NodeTypes = {
  [NodeTypes.FlowStart]: FlowStartNode,
  [NodeTypes.FlowEnd]: FlowEndNode,
  [NodeTypes.Question]: QuestionNode,
  [NodeTypes.AdvancedCondition]: AdvancedConditionNode,
  [NodeTypes.Condition]: BasicConditionNode,
  [NodeTypes.Action]: ActionNode,
  [NodeTypes.IneligibleUser]: IneligibleUserNode,
  [NodeTypes.ChatFlow]: ChatFlowNode,
  [NodeTypes.EndChat]: EndChatNode
}

const edgeTypes: RFR_EdgeTypes = {
  [EdgeTypes.Custom]: CustomEdge,
  [EdgeTypes.Floating]: FloatingEdge
}

function FlowEditorScreen() {
  const serviceStore = useServiceStore()
  const flowStore = useFlowStore()
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance>()
  const [error, setError] = useState(false)
  const [isDiagramValid, setIsDiagramValid] = useState<boolean>(true)
  const [showBackground, setShowBackground] = useState<boolean>(false)
  const nodeEditorStore = useNodeEditorStore()
  const size = useWindowSize()

  useEffect(() => {
    if (flowStore.shouldRecenter && rfInstance && flowStore.nodes?.length > 0) {
      setTimeout(() => {
        rfInstance.fitView()
      }, 200)
      flowStore.setShouldRecenter(false)
    }
  }, [flowStore.nodes, flowStore.edges, rfInstance, flowStore])

  useEffect(() => {
    flowStore.setFlowInstance(rfInstance)
    const dialogue = flowStore.currentHighLevelDialogue.value
    const mode = serviceStore.mode
    const nodes = serviceStore.serviceData?.[mode]?.flow?.[dialogue]?.dashboard?.nodes ?? []
    const edges = serviceStore.serviceData?.[mode]?.flow?.[dialogue]?.dashboard?.edges ?? []

    flowStore.setNodes(nodes)
    flowStore.setEdges(edges)

    if (!nodes.length) {
      flowStore.addNode(NodeTypes.Question, { label: "Double click to edit this question" })
    }
  }, [
    flowStore,
    rfInstance,
    serviceStore.serviceData,
    flowStore.currentHighLevelDialogue,
    serviceStore.mode
  ])

  useEffect(() => {
    const [isFlowValid] = validateFlow(
      flowStore.nodes,
      flowStore.edges,
      flowStore.currentHighLevelDialogue.value
    )
    setIsDiagramValid(isFlowValid)
  }, [flowStore.nodes, flowStore.edges, flowStore, flowStore.currentHighLevelDialogue])

  useEffect(
    () => () => {
      flowStore.setNodes([])
      flowStore.setEdges([])
    },
    [flowStore]
  )

  const onSaveFlowChanges = useCallback(async () => {
    try {
      const [isFlowValid] = validateFlow(
        flowStore.nodes,
        flowStore.edges,
        flowStore.currentHighLevelDialogue.value
      )

      if (isFlowValid) {
        await serviceStore.updateFlowConfiguration(flowStore.currentHighLevelDialogue.value, {
          nodes: flowStore.nodes as IDashboardNode[],
          edges: flowStore.edges as IDashboardEdge[]
        })
      }
    } catch (e) {
      // TODO: report exception to sentry
      setError(true)
    }
  }, [serviceStore, flowStore])

  const loadDefaultFlow = useCallback(
    (type?: string) => {
      const currentDialogue: DefaultDialogues =
        (flowStore.currentHighLevelDialogue.value as DefaultDialogues) ?? "notImplemented"

      const defaultFlowNodes = type
        ? defaultFlows[currentDialogue][type].nodes
        : Object.values(defaultFlows[currentDialogue])[0]?.nodes

      const defaultFlowEdges = type
        ? defaultFlows[currentDialogue][type].edges
        : Object.values(defaultFlows[currentDialogue])[0]?.edges

      /** Fallback to notImplemented as a safety if a dialogue does not exist */
      flowStore.setNodes(defaultFlowNodes ?? Object.values(defaultFlows["notImplemented"])[0].nodes)
      flowStore.setEdges(defaultFlowEdges ?? Object.values(defaultFlows["notImplemented"])[0].edges)
    },
    [flowStore]
  )

  const toggleBackground = useCallback(() => {
    setShowBackground(!showBackground)
  }, [setShowBackground, showBackground])

  const onOpenHighLevelFlowDrawer = useCallback((): void => {
    nodeEditorStore.setHighLevelEditorOpen()
  }, [nodeEditorStore])

  const onCloseHighLevelEditor = (): void => {
    nodeEditorStore.setHighLevelEditorClosed()
  }

  const onSaveHighLevelFlow = async (): Promise<void> => {
    try {
      await serviceStore.updateHighLevelFlowConfiguration(flowStore.highLevelFlow)
    } catch (e) {
      // TODO: report exception to sentry
      setError(true)
    } finally {
      nodeEditorStore.setHighLevelEditorClosed()
    }
  }

  const onClickOtherDialogue = (id: DiscussionSteps) => {
    flowStore.updateCurrentHighLevelDialogue(id, "other")
  }

  const duplicateNode = (id: string) => {
    const nodes = flowStore.nodes
    const node = nodes.find(el => el.id === id)
    if (node) {
      flowStore.duplicateNode(node)
    }
  }

  return (
    <>
      <ReactFlowInstanceProvider instance={rfInstance}>
        <>
          <Dialog
            onClose={() => setError(false)}
            open={error}
            title="Something went wrong"
            maxWidth="sm">
            {error && (
              <>
                <span>Something went wrong while making updates to the configuration</span>
                <br />
                <span>Please try again</span>
              </>
            )}
          </Dialog>
          <Column style={{ display: "flex", ...size, width: "100%", alignItems: "center" }}>
            <Column css={{ margin: 8, width: "100%", display: "flex", flexFlow: "row" }}>
              <MainToolBar
                onBackgroundToggle={toggleBackground}
                onLoadDefaultFlow={loadDefaultFlow}
                onSaveFlowChanges={onSaveFlowChanges}
                onHighLevelFlowDialogueClick={onOpenHighLevelFlowDrawer}
                disableSave={!isDiagramValid}
              />
            </Column>
            <MainContextMenu>
              <ReactFlow
                fitView
                snapToGrid
                panOnScroll
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodes={
                  toJS(flowStore.nodes).map(node => ({
                    ...node,
                    data: {
                      ...node.data,
                      onDuplicate: duplicateNode
                    }
                  })) as RF_Node<IDashboardNode["data"]>[]
                }
                edges={toJS(flowStore.edges) as RF_EDGE<IDashboardEdge["data"]>[]}
                defaultEdgeOptions={defaultEdgeOptions}
                connectionLineStyle={connectionLineStyle}
                snapGrid={snapGrid}
                style={rfStyle}
                onInit={setRfInstance}
                onNodesChange={flowStore.applyNodeChanges}
                onEdgesChange={flowStore.applyEdgeChanges}
                onNodesDelete={flowStore.deleteNodes}
                onEdgesDelete={flowStore.deleteEdges}
                onConnect={flowStore.addEdge}
                deleteKeyCode={["Delete"]}>
                {showBackground && (
                  <Background
                    variant={BackgroundVariant.Lines}
                    color="lightgray"
                    size={0.4}
                    gap={40}
                  />
                )}
              </ReactFlow>
            </MainContextMenu>
          </Column>
        </>
      </ReactFlowInstanceProvider>
      <CustomDrawer
        width="auto"
        anchor="right"
        isDrawerOpen={nodeEditorStore.highLevelFlowEditorOpen}
        onClose={onCloseHighLevelEditor}>
        <DialogTitle style={{ textAlign: "center" }}>High Level Flow</DialogTitle>
        <div style={drawerContainer}>
          <HighLevelFlow />
          <div style={otherDialoguesContainer}>
            <Typography variant="subtitle1" style={{ textAlign: "center", marginBottom: 6 }}>
              Other dialogues
            </Typography>
            <Button onClick={() => onClickOtherDialogue(DiscussionSteps.Crisis)}>Crisis</Button>
          </div>
          <div style={{ minHeight: "120px" }} />
        </div>
        <div style={buttonContainerStyle}>
          <Button onClick={onSaveHighLevelFlow}>Save</Button>
          <Button onClick={onCloseHighLevelEditor}>Close</Button>
        </div>
      </CustomDrawer>
      <ValidationDialogue dialogueName={flowStore.currentHighLevelDialogue.value} />
    </>
  )
}

export default observer(FlowEditorScreen)

const rfStyle = {
  zIndex: 0,
  boxShadow: "rgb(0 0 0 / 14%) 0px 2px 10px",
  borderRadius: "6px",
  width: "calc(100vw - 64px)",
  height: "calc(100vh - 340px)"
}

const drawerContainer = {
  width: "100%",
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "100%",
  overflowY: "auto" as any
}

const buttonContainerStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute" as Position,
  bottom: 0,
  background: "white",
  paddingBottom: 16,
  paddingTop: 12,
  borderTop: "1px solid lightgray"
}

const otherDialoguesContainer = {
  display: "flex",
  flexDirection: "column" as FlexDirection,
  justifyContent: "center",
  width: "75%",
  border: "1px solid lightgray",
  margin: "6px",
  padding: "12px 24px",
  borderRadius: "20px"
}
