import React from "react"
import { observer } from "mobx-react"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import { Typography } from "@mui/material"
import styles from "./BookAppointment.module.scss"

function BookAppointment(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <h3>Appointment Booking</h3>
      <div className={styles.messageDescription}>
        <p>Appointment booking has the following steps:</p>
        <ul>
          <li>
            <b>Submit Referral to Service:</b> Almost always, the appointment booking system will
            require to know which patient to show available slots for. For that reason, we need to
            make sure we submit the referral first (instead of waiting for the queueing system in
            the backend to do it async)
          </li>
          <li>
            <b>Get available slots:</b> The bot will ask the user to select a slot from the
            available slots
          </li>
          <li>Confirm selected slot: The bot will ask the user to confirm the selected slot</li>
          <li>
            <b>Book appointment:</b> The bot will attempt to book the selected slot. If successful,
            the bot will provide a confirmation message. If not, the bot will provide an error
            message
          </li>
        </ul>

        <p className={styles.warningInfo}>
          All data points that are important to the service must be collected before this preset
          flow happens.
        </p>
        <p className={styles.warningExplanation}>
          <em>
            Appointment booking requires the referral to be submitted first. If the backend
            integration for the current service doesn't allow for referral updates (aka the referral
            can only be sent to the service ONCE and cannot be updated afterwards), make sure to use
            this flow at the end of the conversation where the chatbot has already collected
            everything else it needed to collect. If the backend integration allows for updates,
            then you can use this flow anywhere after the chatbot has collected the minimum required
            data points for a referral submission.
          </em>
        </p>
      </div>

      <SolidDivider />

      <Typography align="right" className={styles.title} variant="subtitle1">
        Bot message introduction:
      </Typography>
      <MessageList
        messages={messages?.sayBookAppointmentIntro ?? []}
        messageType="sayBookAppointmentIntro"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayBookAppointmentIntro}
        placeholder="Type a message here..."
        name="sayBookAppointmentIntro"
        updateState={updateState}
        messages={messages?.sayBookAppointmentIntro ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.title} variant="subtitle1">
        Bot message when submitting the referral fails:
      </Typography>
      <MessageList
        messages={messages?.sayBookAppointmentSubmitReferralFailed ?? []}
        messageType="sayBookAppointmentSubmitReferralFailed"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayBookAppointmentSubmitReferralFailed}
        placeholder="Type a message here..."
        name="sayBookAppointmentSubmitReferralFailed"
        updateState={updateState}
        messages={messages?.sayBookAppointmentSubmitReferralFailed ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.title} variant="subtitle1">
        Bot message when fetching available slots fails:
      </Typography>
      <MessageList
        messages={messages?.sayBookAppointmentGetSlotsFailed ?? []}
        messageType="sayBookAppointmentGetSlotsFailed"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayBookAppointmentGetSlotsFailed}
        placeholder="Type a message here..."
        name="sayBookAppointmentGetSlotsFailed"
        updateState={updateState}
        messages={messages?.sayBookAppointmentGetSlotsFailed ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.title} variant="subtitle1">
        Bot message for slot selection:
      </Typography>
      <MessageList
        messages={messages?.askBookAppointmentSelectSlot ?? []}
        messageType="askBookAppointmentSelectSlot"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askBookAppointmentSelectSlot}
        placeholder="Type a message here..."
        name="askBookAppointmentSelectSlot"
        updateState={updateState}
        messages={messages?.askBookAppointmentSelectSlot ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.title} variant="subtitle1">
        Bot message to confirm the selected slot:
      </Typography>
      <MessageList
        messages={messages?.sayBookAppointmentConfirmSlot ?? []}
        messageType="sayBookAppointmentConfirmSlot"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayBookAppointmentConfirmSlot}
        placeholder="Type a message here..."
        name="sayBookAppointmentConfirmSlot"
        updateState={updateState}
        messages={messages?.sayBookAppointmentConfirmSlot ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.title} variant="subtitle1">
        Bot message when the selected appointment slot is unavailable:
      </Typography>
      <MessageList
        messages={messages?.sayBookAppointmentSlotUnavailable ?? []}
        messageType="sayBookAppointmentSlotUnavailable"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayBookAppointmentSlotUnavailable}
        placeholder="Type a message here..."
        name="sayBookAppointmentSlotUnavailable"
        updateState={updateState}
        messages={messages?.sayBookAppointmentSlotUnavailable ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.title} variant="subtitle1">
        Bot message when the appointment slot booking errored out:
      </Typography>
      <MessageList
        messages={messages?.sayBookAppointmentBookingError ?? []}
        messageType="sayBookAppointmentBookingError"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayBookAppointmentBookingError}
        placeholder="Type a message here..."
        name="sayBookAppointmentBookingError"
        updateState={updateState}
        messages={messages?.sayBookAppointmentBookingError ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.title} variant="subtitle1">
        Bot message when the appointment slot booking went successful:
      </Typography>
      <MessageList
        messages={messages?.sayBookAppointmentBookedSuccessfully ?? []}
        messageType="sayBookAppointmentBookedSuccessfully"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayBookAppointmentBookedSuccessfully}
        placeholder="Type a message here..."
        name="sayBookAppointmentBookedSuccessfully"
        updateState={updateState}
        messages={messages?.sayBookAppointmentBookedSuccessfully ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.title} variant="subtitle1">
        Bot message when the user leaves the appointment booking flow:
      </Typography>
      <MessageList
        messages={messages?.sayBookAppointmentGoodbyeLeave ?? []}
        messageType="sayBookAppointmentGoodbyeLeave"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayBookAppointmentGoodbyeLeave}
        placeholder="Type a message here..."
        name="sayBookAppointmentGoodbyeLeave"
        updateState={updateState}
        messages={messages?.sayBookAppointmentGoodbyeLeave ?? []}
      />
    </>
  )
}

export default observer(BookAppointment)
