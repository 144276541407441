import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material/"
import styles from "./Date.module.scss"
import { useNodeEditorStore, useSettingsStore } from "../../../../../../../context/rootStoreContext"
import { EditorType, IPromptSettings } from "../../../../../models/NodeEditors"
import { FormControl } from "@mui/material"
import React, { useEffect } from "react"

function Date(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { questionEditorState } = nodeEditorStore
  const { promptSettings } = questionEditorState
  const { isUndoable, forceValue, trackResponse, inputDateFormat, displayDateFormat } =
    promptSettings

  const { settings } = useSettingsStore()
  const { dateFormat } = settings || {}
  const { inputFormat: defaultInputFormat, displayFormat: defaultDisplayFormat } = dateFormat || {}

  const handleChange = <K extends keyof IPromptSettings>(
    value: IPromptSettings[K],
    key: K
  ): void => {
    nodeEditorStore.updateState(EditorType.QUESTION, {
      ...questionEditorState,
      promptSettings: {
        ...questionEditorState.promptSettings,
        [key]: value
      }
    })
  }

  useEffect(
    () => {
      nodeEditorStore.updateState(EditorType.QUESTION, {
        ...questionEditorState,
        promptSettings: {
          ...questionEditorState.promptSettings,
          inputDateFormat: inputDateFormat || defaultInputFormat,
          displayDateFormat: displayDateFormat || defaultDisplayFormat
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className={styles.datePickerContainer}>
      <FormGroup className={styles.checkboxFormGroup}>
        <FormControlLabel
          className={styles.formControlLabel}
          value="isUndoable"
          control={
            <Checkbox
              checked={isUndoable}
              onChange={(_event, value) => handleChange(value, "isUndoable")}
            />
          }
          label="Undoable"
          labelPlacement="start"
        />
        <FormControlLabel
          className={styles.formControlLabel}
          value="forceValue"
          control={
            <Checkbox
              checked={forceValue}
              onChange={(_event, value) => handleChange(value, "forceValue")}
            />
          }
          label="Force value"
          labelPlacement="start"
        />

        <FormControlLabel
          className={styles.formControlLabel}
          value="trackResponse"
          control={
            <Checkbox
              checked={trackResponse}
              onChange={(_event, value) => handleChange(value, "trackResponse")}
            />
          }
          label="Track response"
          labelPlacement="start"
        />

        <div>
          <FormGroup className={styles.formContainer}>
            <FormControl className={styles.formControl}>
              <TextField
                size="small"
                label="Input Format"
                name="inputFormat"
                className={styles.textField}
                placeholder="Input Format"
                value={inputDateFormat}
                onChange={e => handleChange(e.target.value, "inputDateFormat")}
              />
            </FormControl>
            <FormControl className={styles.formControl}>
              <TextField
                size="small"
                label="Display Format"
                name="displayFormat"
                className={styles.textField}
                placeholder="Display Format"
                value={displayDateFormat}
                onChange={e => handleChange(e.target.value, "displayDateFormat")}
              />
            </FormControl>
          </FormGroup>
        </div>
      </FormGroup>
    </div>
  )
}

export default Date
