export function validateEmailAddresses(input: string): boolean {
  if (input === "") return true
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const emails = input.split(",").map(email => email.trim())

  for (const email of emails) {
    if (!emailRegex.test(email)) {
      return false
    }
    if (email.includes("..")) {
      return false
    }
    const parts = email.split("@")
    const domainPart = parts[1]
    if (domainPart) {
      const lastDotIndex = domainPart.lastIndexOf(".")
      if (lastDotIndex === -1 || lastDotIndex >= domainPart.length - 1) {
        return false
      }
    } else {
      return false
    }
  }

  return true
}

export function validateGPCodes(input: string): boolean {
  if (input === "") return true
  const validStringRegex = /^[A-Za-z0-9]+$/
  const strings = input.split(",").map(str => str.trim())

  for (const str of strings) {
    if (!validStringRegex.test(str)) {
      return false
    }
  }
  return true
}
