import { styled } from "@stitches/react"
import * as ContextMenuPrimitive from "@radix-ui/react-context-menu"
import { mauve, violet } from "@radix-ui/colors"
import {
  ContextMenuCheckboxItemProps,
  ContextMenuItemProps,
  ContextMenuRadioItemProps,
  ContextMenuSubTriggerProps
} from "@radix-ui/react-context-menu"
import { CheckIcon, ChevronRightIcon, DotFilledIcon } from "@radix-ui/react-icons"
import { MenuRow } from "../MenuRow"

interface IItemProps extends ContextMenuItemProps, React.RefAttributes<HTMLDivElement> {
  label: string
  left?: string | JSX.Element
  right?: string | JSX.Element
}

export function ContextMenuItem(props: IItemProps): JSX.Element {
  const { label, left, right, ...restProps } = props
  return (
    <Item {...restProps} ref={null}>
      <MenuRow {...{ label, left, right }} />
    </Item>
  )
}

interface ICheckboxProps extends ContextMenuCheckboxItemProps, React.RefAttributes<HTMLDivElement> {
  label: string
  right?: string | JSX.Element
}

export function ContextMenuCheckbox(props: ICheckboxProps): JSX.Element {
  const { label, right, ...restProps } = props
  return (
    <CheckboxItem {...restProps} ref={null}>
      <Indicator>
        <CheckIcon />
      </Indicator>
      <MenuRow label={label} right={right} />
    </CheckboxItem>
  )
}

interface IRadioProps extends ContextMenuRadioItemProps, React.RefAttributes<HTMLDivElement> {
  label: string
  right?: string | JSX.Element
}

export function ContextMenuRadio(props: IRadioProps): JSX.Element {
  const { label, right, ...restProps } = props
  return (
    <RadioItem {...restProps} ref={null}>
      <Indicator>
        <DotFilledIcon />
      </Indicator>
      <MenuRow label={label} right={right} />
    </RadioItem>
  )
}

interface ISubTriggerProps extends ContextMenuSubTriggerProps, React.RefAttributes<HTMLDivElement> {
  label: string
}

export function ContextMenuSubTrigger(props: ISubTriggerProps): JSX.Element {
  const { label, ...restProps } = props
  return (
    <SubTrigger {...restProps} ref={null}>
      <MenuRow label={label} right={<ChevronRightIcon />} />
    </SubTrigger>
  )
}

const itemStyles = {
  all: "unset",
  fontSize: 12,
  lineHeight: 1,
  color: violet.violet12,
  borderRadius: 3,
  display: "flex",
  alignItems: "center",
  height: 25,
  padding: "0 5px",
  position: "relative",
  paddingLeft: 25,
  userSelect: "none",

  "&[data-disabled]": {
    color: mauve.mauve8,
    pointerEvents: "none"
  },

  "&[data-highlighted]": {
    backgroundColor: violet.violet9,
    color: violet.violet1
  }
}

const Item = styled(ContextMenuPrimitive.Item, { ...itemStyles })
const CheckboxItem = styled(ContextMenuPrimitive.CheckboxItem, { ...itemStyles })
const RadioItem = styled(ContextMenuPrimitive.RadioItem, { ...itemStyles })

const SubTrigger = styled(ContextMenuPrimitive.SubTrigger, {
  ...itemStyles,
  '&[data-state="open"]': {
    backgroundColor: violet.violet4,
    color: violet.violet11
  }
})

const Indicator = styled(ContextMenuPrimitive.ItemIndicator, {
  position: "absolute",
  left: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: mauve.mauve12,
  "[data-highlighted] > &": { color: "white" },
  "[data-disabled] &": { color: mauve.mauve8 }
})
