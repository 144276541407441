import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
/**
 * @hello-pangea/dnd is a fork of "react-beautiful-dnd" which deals with
 * React's strict mode issues
 * */
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd"
import { Tooltip } from "@mui/material"
import DragHandle from "@mui/icons-material/DragHandle"
import Button from "../Button"
import { useFlowStore, useServiceStore } from "../../context/rootStoreContext"
import ActiveCheckBox from "./ActiveCheckBox"
import FlowArrow from "./Arrow"
import styles from "./HighLevelFlow.module.scss"

function HighLevelFlow() {
  const serviceStore = useServiceStore()
  const flowStore = useFlowStore()
  const [isDragging, setIsDragging] = useState(false)
  const [error, setError] = useState<string>()

  const serviceDataStepsOrder = serviceStore.serviceData?.[serviceStore.mode]?.stepsOrder

  useEffect(
    () => flowStore.initHighLevelFlow(serviceDataStepsOrder),
    [flowStore, serviceDataStepsOrder, serviceStore.mode]
  )

  const onDragEnd = (droppedItem: DropResult): void => {
    setError("")
    if (!droppedItem.destination) return
    setIsDragging(false)
    if (droppedItem.destination.index === 0) {
      setError("The Introduction dialogue should always be first")
      return
    }
    if (droppedItem.destination.index === flowStore.highLevelFlow.length - 1) {
      setError("The Goodbye dialogue should always be last")
      return
    }
    flowStore.reorderHighLevelFlow(droppedItem)
  }

  const onDragStart = (): void => {
    setError(undefined)
    setIsDragging(true)
  }

  const onToggleActiveDialogue = (id): void => {
    setError("")
    flowStore.toggleHighLevelFlowDialogue(id)
  }

  return (
    <div className={styles.diagramDraggableContainer}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId="high-level-flow-container">
          {provided => (
            <div
              className={styles.draggableContainer}
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {flowStore.highLevelFlow.map((item, index) => (
                <Draggable
                  key={item.value}
                  draggableId={item.value}
                  index={index}
                  isDragDisabled={item.fixed}>
                  {provided => {
                    return (
                      <>
                        <div
                          className={styles.draggableButtonContainer}
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}>
                          <Tooltip title="Hold and drag to change position">
                            <DragHandle
                              style={{
                                color: item.fixed ? "lightgray" : "black",
                                cursor: item.fixed ? "default" : "grab"
                              }}
                            />
                          </Tooltip>
                          <div className={styles.buttonContainer}>
                            <Button
                              title={`Click to customise ${item.body}`}
                              key={`button-${item.value}`}
                              disabled={!item.configurable}
                              css={{ width: "200px" }}
                              onClick={() => {
                                flowStore.updateCurrentHighLevelDialogue(item.value)
                              }}>
                              {item.body}
                            </Button>
                          </div>
                          <ActiveCheckBox
                            title={
                              item.disabled
                                ? "Check to include this dialogue"
                                : "Uncheck to exclude this dialogue"
                            }
                            disabled={item.required}
                            name="isActive"
                            isChecked={!item.disabled}
                            label=""
                            labelPlacement="start"
                            handleChange={() => onToggleActiveDialogue(item.value)}
                          />
                        </div>
                        {index === flowStore.highLevelFlow.length - 1 || isDragging ? (
                          <div style={{ height: "20px" }} />
                        ) : (
                          <FlowArrow />
                        )}
                      </>
                    )
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={styles.errors}>{error}</div>
    </div>
  )
}

export default observer(HighLevelFlow)
