import React from "react"
import { observer } from "mobx-react"
import FormControlWithCheckBox from "../../components/FormControlWithCheckBox"
import MessageList from "../../components/MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../../components/MessageForm/MessageForm"
import SolidDivider from "../../../../../SolidDivider"

function CollectMainIssue(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <MessageList
        messages={messages?.askMainIssue ?? []}
        messageType="askMainIssue"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askMainIssue}
        placeholder="Type a message here..."
        name="askMainIssue"
        updateState={updateState}
        messages={messages?.askMainIssue ?? []}
      />

      <SolidDivider />
      <FormControlWithCheckBox
        name="hideClosingMessage"
        label="Do not show a message after user share's the main problem"
        labelPlacement="start"
        updateState={updateState}
      />
      <MessageList
        disabled={chatFlowSettings.collectMainIssue?.hideClosingMessage}
        messages={messages?.closingMessage ?? []}
        messageType="closingMessage"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={chatFlowSettings.collectMainIssue?.hideClosingMessage}
        value={chatFlowSettings.currentMessage?.closingMessage}
        placeholder="Type a message here..."
        name="closingMessage"
        updateState={updateState}
        messages={messages?.closingMessage ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="forceMainIssueValue"
        label="Force the user to provide a main problem (i.e. not optional)"
        labelPlacement="start"
        updateState={updateState}
      />
      <FormControlWithCheckBox
        name="cancelIsEmptySubmit"
        label="Show skip button, allowing user to skip the question"
        labelPlacement="start"
        updateState={updateState}
      />
      {/*
        👇 This may not be understood by a normal user... what this means is that
        as soon as the question is answered there is a call to the backend,
        to update the referral data in the database and add the specific response
        (for now, it's only being used by Vitality)
      */}
      <FormControlWithCheckBox
        name="shouldUpdateReferral"
        label="Enable updating the referral data as soon as the question is answered"
        labelPlacement="start"
        updateState={updateState}
      />
    </>
  )
}

export default observer(CollectMainIssue)
