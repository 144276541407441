import React, { ChangeEvent } from "react"
import { Typography } from "@mui/material/"
import { observer } from "mobx-react"
import Choice from "../../../Choice"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import MessageList from "../MessageList"
import MessageForm from "../MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import OptionsPlaceholder from "../OptionsPlaceholder"
import styles from "./CollectLTC.module.scss"

function CollectLTC(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages
  const noneLabel = chatFlowSettings.askLongTermMedicalConditions?.noneLabel

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  const handleChangeNoneOption = (event: ChangeEvent<HTMLInputElement>) => {
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        askLongTermMedicalConditions: {
          ...chatFlowSettings.askLongTermMedicalConditions,
          noneLabel: event.target.value
        }
      }
    })
  }

  return (
    <>
      <FormControlWithCheckBox
        name="includeOther"
        label="Include 'Other' Option in the list of options"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <Typography className={styles.emailLabel}>Custom label for 'None' value:</Typography>
      <div className={styles.noneLabelInputContainer}>
        <input
          className={styles.noneLabelInput}
          value={noneLabel ?? "I don't"}
          placeholder="Type in the label for the 'None' option"
          onChange={handleChangeNoneOption}
        />
      </div>
      <SolidDivider />
      <Typography className={styles.genderLabel}>
        Long term medical condition question message:
      </Typography>
      <MessageList
        messages={messages?.askLongTermMedicalCondition ?? []}
        messageType="askLongTermMedicalCondition"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <div className={styles.optionContainer}>
        <Choice
          value={noneLabel?.length ? noneLabel : "I don't"}
          nodeId=""
          index={0}
          withoutHandle={true}
        />
      </div>
      <OptionsPlaceholder name="Long term medical conditions" />
      {chatFlowSettings.askLongTermMedicalConditions?.includeOther && (
        <div className={styles.optionContainer}>
          <Choice value="Other" nodeId="" index={0} withoutHandle={true} />
        </div>
      )}
      <MessageForm
        value={chatFlowSettings.currentMessage?.askLongTermMedicalCondition}
        placeholder="Type a message here..."
        name="askLongTermMedicalCondition"
        updateState={updateState}
        messages={messages?.askLongTermMedicalCondition ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        disabled={!chatFlowSettings.askLongTermMedicalConditions?.includeOther}
        name="shouldAskOtherDetails"
        label="Should ask details if Other is selected"
        labelPlacement="start"
        updateState={updateState}
      />
      <Typography className={styles.otherQuestionLabel}>Other question message:</Typography>
      <MessageList
        disabled={
          !chatFlowSettings.askLongTermMedicalConditions?.includeOther ||
          !chatFlowSettings.askLongTermMedicalConditions?.shouldAskOtherDetails
        }
        messages={messages?.askLongTermMedicalConditionOther ?? []}
        messageType="askLongTermMedicalConditionOther"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={
          !chatFlowSettings.askLongTermMedicalConditions?.includeOther ||
          !chatFlowSettings.askLongTermMedicalConditions?.shouldAskOtherDetails
        }
        value={chatFlowSettings.currentMessage?.askLongTermMedicalConditionOther}
        placeholder="Type a message here..."
        name="askLongTermMedicalConditionOther"
        updateState={updateState}
        messages={messages?.askLongTermMedicalConditionOther ?? []}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldAskDoesLTCAffectsMood"
        label="Ask 'Does your selected medical condition impact your mood?'"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldAskHowMuchLTCAffectsMood"
        label="Ask 'How much selected medical condition impact your mood?'"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldAskHowWellYouManageYourLTC"
        label="Ask 'How well do you manage your selected medical condition?'"
        labelPlacement="start"
        updateState={updateState}
      />
    </>
  )
}

export default observer(CollectLTC)
