import React from "react"
import { Checkbox, FormControlLabel } from "@mui/material"
import styles from "../../FlowEditor/components/ChatFlowEditor/components/FormControlWithCheckBox/FormControlWithCheckBox.module.scss"
import { FormControlLabelProps } from "@mui/material/FormControlLabel/FormControlLabel"
import { CheckboxProps } from "@mui/material/Checkbox/Checkbox"

interface IProps extends Omit<FormControlLabelProps, "control" | "onChange"> {
  checked?: CheckboxProps["checked"]
  onChange?: (newValue: boolean) => void
}

export default function LabeledCheckbox(props: IProps) {
  const { disabled = false, name, label, labelPlacement, checked, onChange } = props
  const onChangeHandler = onChange
    ? (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => onChange(value)
    : undefined
  return (
    <FormControlLabel
      disabled={disabled}
      className={styles.formControlLabel}
      value={name}
      control={<Checkbox checked={checked} onChange={onChangeHandler} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}
