import React from "react"
import BotLogo from "../../../../assets/bot-logo.png"
import classes from "clsx"
import { TextareaAutosize } from "@mui/base/TextareaAutosize"
import styles from "./BotBubbleEditable.module.scss"

interface Props {
  index: number
  value: string
  isFirstMessage?: boolean
  disabled?: boolean
  onMessageChange: (_event, _index, _key?: string) => void
}

function BotBubbleEditable(props: Props): JSX.Element {
  const { index, value, isFirstMessage, onMessageChange, disabled = false } = props

  const handleMessageChange = event => {
    onMessageChange(event.target.value, index)
  }

  return (
    <div className={styles.botBubbleContainer}>
      {isFirstMessage && (
        <img
          className={classes(styles.botLogo, { [styles.botLogoDisabled]: disabled })}
          src={BotLogo}
          alt="logo"
        />
      )}
      <TextareaAutosize
        disabled={disabled}
        onChange={event => handleMessageChange(event)}
        className={classes(styles.botBubbleMessage, {
          [styles.botBubbleMessageDisabled]: disabled
        })}
        value={value}
      />
    </div>
  )
}

export default BotBubbleEditable
