// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N98lbzENXlPDQjzY4DQ_{display:flex;height:20px;flex-flow:column;justify-content:center;align-items:center}.ruHZGAQEgqYMMm5GAcDq{width:0;height:10px;border-left:2px solid gray}.cyaC1K_8GGSQg5cVP37Q{width:0;height:0;border-left:5px solid rgba(0,0,0,0);border-right:5px solid rgba(0,0,0,0);border-top:10px solid gray}", "",{"version":3,"sources":["webpack://./src/app/components/HighLevelFlow/Arrow/FlowArrow.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,gBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,sBACE,OAAA,CACA,WAAA,CACA,0BAAA,CAGF,sBACE,OAAA,CACA,QAAA,CACA,mCAAA,CACA,oCAAA,CACA,0BAAA","sourcesContent":[".flowArrow {\n  display: flex;\n  height: 20px;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.flowArrowBody {\n  width: 0;\n  height: 10px;\n  border-left: 2px solid gray;\n}\n\n.flowArrowHead {\n  width: 0;\n  height: 0;\n  border-left: 5px solid transparent;\n  border-right: 5px solid transparent;\n  border-top: 10px solid gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flowArrow": "N98lbzENXlPDQjzY4DQ_",
	"flowArrowBody": "ruHZGAQEgqYMMm5GAcDq",
	"flowArrowHead": "cyaC1K_8GGSQg5cVP37Q"
};
export default ___CSS_LOADER_EXPORT___;
