import React, { ChangeEvent } from "react"
import { observer } from "mobx-react"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import { FormControl, TextField, Typography } from "@mui/material"
import { ChatFlowsEnum } from "@limbic/types"
import styles from "./CheckCrisisDetection.module.scss"

function CheckCrisisDetection(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  const handleCrisisNumbersChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [ChatFlowsEnum.CHECK_CRISIS_DETECTION]: {
          ...chatFlowSettings[ChatFlowsEnum.CHECK_CRISIS_DETECTION],
          crisisNumbersShared: event.target.value
        }
      }
    })
  }

  return (
    <>
      <Typography align="right" className={styles.checkCrisisDetectionTitle} variant="subtitle1">
        Bot message to let user know that trigger words were detected
      </Typography>
      <MessageList
        messages={messages?.sayTriggerWordsDetected ?? []}
        messageType="sayTriggerWordsDetected"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayTriggerWordsDetected}
        placeholder="Type a message here..."
        name="sayTriggerWordsDetected"
        updateState={updateState}
        messages={messages?.sayTriggerWordsDetected ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.checkCrisisDetectionTitle} variant="subtitle1">
        Bot message to ask if user is in crisis
      </Typography>
      <MessageList
        messages={messages?.askAreYouInCrisis ?? []}
        messageType="askAreYouInCrisis"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askAreYouInCrisis}
        placeholder="Type a message here..."
        name="askAreYouInCrisis"
        updateState={updateState}
        messages={messages?.askAreYouInCrisis ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.checkCrisisDetectionTitle} variant="subtitle1">
        Bot message in case the crisis detection was wrong
      </Typography>
      <MessageList
        messages={messages?.saySorryForTheMisunderstanding ?? []}
        messageType="saySorryForTheMisunderstanding"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.saySorryForTheMisunderstanding}
        placeholder="Type a message here..."
        name="saySorryForTheMisunderstanding"
        updateState={updateState}
        messages={messages?.saySorryForTheMisunderstanding ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.checkCrisisDetectionTitle} variant="subtitle1">
        Bot message to say sorry that the user is in crisis:
      </Typography>
      <MessageList
        messages={messages?.saySorryToHear ?? []}
        messageType="saySorryToHear"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.saySorryToHear}
        placeholder="Type a message here..."
        name="saySorryToHear"
        updateState={updateState}
        messages={messages?.saySorryToHear ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.checkCrisisDetectionTitle} variant="subtitle1">
        Bot message to show the crisis phone numbers
      </Typography>
      <MessageList
        messages={messages?.sayCrisisNumbers ?? []}
        messageType="sayCrisisNumbers"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayCrisisNumbers}
        placeholder="Type a message here..."
        name="sayCrisisNumbers"
        updateState={updateState}
        messages={messages?.sayCrisisNumbers ?? []}
      />
      <SolidDivider />
      <Typography align="right" className={styles.checkCrisisDetectionTitle} variant="subtitle1">
        Bot message to recap everything
      </Typography>
      <MessageList
        messages={messages?.recapMessage ?? []}
        messageType="recapMessage"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.recapMessage}
        placeholder="Type a message here..."
        name="recapMessage"
        updateState={updateState}
        messages={messages?.recapMessage ?? []}
      />
      <SolidDivider />
      <div className={styles.crisisNumbersSharedContainer}>
        <Typography>Summary of numbers that were shared for tracking purposes</Typography>
        <FormControl style={{ width: "100%", marginTop: 16 }}>
          <TextField
            className={styles.crisisNumbersShared}
            id="retry-postcode-times-select"
            value={chatFlowSettings.checkCrisisDetection?.crisisNumbersShared ?? "NHS111, 999"}
            label="Crisis numbers shared"
            onChange={event => handleCrisisNumbersChange(event)}
          />
        </FormControl>
      </div>
    </>
  )
}

export default observer(CheckCrisisDetection)
