// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CiwO1HATcCnK5Fpuq2NQ{margin-bottom:1em !important}.w9NLEPSDgVv5yJoIli13{margin:0 0 1em 0 !important}.w9NLEPSDgVv5yJoIli13:first-child{margin-bottom:2em !important}.qWZJ3e7m95j5274koc7D input{padding:4px 12px;height:36px}.X5DGHUFDsDIZ60xrqJXQ{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/AdvancedConditionEditor/components/Operand/Operand.module.scss"],"names":[],"mappings":"AAAA,sBACE,4BAAA,CAGF,sBACE,2BAAA,CAGF,kCACE,4BAAA,CAIA,4BACE,gBAAA,CACA,WAAA,CAIJ,sBACE,eAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".descriptionSubtitle {\n  margin-bottom: 1em !important;\n}\n\n.formControlAdvancedCondition {\n  margin: 0 0 1em 0 !important;\n}\n\n.formControlAdvancedCondition:first-child {\n  margin-bottom: 2em !important;\n}\n\n.customFieldInput {\n  & input {\n    padding: 4px 12px;\n    height: 36px;\n  }\n}\n\n.autcompleteOption {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionSubtitle": "CiwO1HATcCnK5Fpuq2NQ",
	"formControlAdvancedCondition": "w9NLEPSDgVv5yJoIli13",
	"customFieldInput": "qWZJ3e7m95j5274koc7D",
	"autcompleteOption": "X5DGHUFDsDIZ60xrqJXQ"
};
export default ___CSS_LOADER_EXPORT___;
