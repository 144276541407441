import React, { memo } from "react"
import CheckboxChoice from "../../../../components/CheckboxChoice"
import { CustomNode } from "../../CustomNode"
import styles from "./CheckboxChoices.module.scss"

interface Props {
  data: Record<any, any>
}

function CheckboxChoices(props: Props): JSX.Element {
  const { data } = props
  return (
    <CustomNode.Container id="container">
      <div className={styles.checkboxesContainer}>
        {data.choices.map((choice, index) => {
          const isChecked = !!(
            data.checkboxOptions &&
            data.checkboxOptions.length &&
            data.checkboxOptions.find(o => o.body === choice.body).initialValue
          )
          return (
            <React.Fragment key={`${data.id}-choice-${index}`}>
              <CheckboxChoice
                checked={isChecked}
                value={choice.body}
                nodeId={data.nodeId}
                index={index}
              />
            </React.Fragment>
          )
        })}
      </div>
    </CustomNode.Container>
  )
}

export default memo(CheckboxChoices)
