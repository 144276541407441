import React, { useState } from "react"
import { observer } from "mobx-react"
import Dialog from "../../components/Dialog"
import { useServiceStore } from "../../context/rootStoreContext"
import Loading from "../../components/Loading"
import { Tab, Tabs } from "@mui/material"
import BasicConfiguration from "./components/BasicConfiguration"
import EligibilityConfiguration from "./components/EligibilityConfiguration"
import BigBotConfiguration from "./components/BigBotConfiguration"

import styles from "./Configuration.module.scss"
import TranslationsConfiguration from "./components/TranslationsConfiguration"
import AdvancedConfiguration from "./components/AdvancedConfiguration"

function Configuration(): JSX.Element {
  const [error, setError] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<
    "basicConfiguration" | "eligibility" | "bigBot" | "translations" | "advanced"
  >("basicConfiguration")
  const serviceStore = useServiceStore()

  return (
    <div className={styles.configurationWrapper}>
      <Dialog
        onClose={() => setError(false)}
        open={error}
        title="Something went wrong"
        maxWidth="sm">
        {error && (
          <>
            <span>Something went wrong while making updates to the configuration</span>
            <br />
            <span>Please try again</span>
          </>
        )}
      </Dialog>
      <div className={styles.headerContainer}>
        <Tabs
          value={currentTab}
          onChange={(_, tab) => setCurrentTab(tab)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Configuration Navigation">
          <Tab label="Basic Configuration" value="basicConfiguration" />
          <Tab label="Eligibility" value="eligibility" />
          <Tab label="Big Bot" value="bigBot" />
          <Tab label="Translations" value="translations" />
          <Tab label="Advanced" value="advanced" />
        </Tabs>
      </div>
      {currentTab === "basicConfiguration" && <BasicConfiguration setError={setError} />}
      {currentTab === "eligibility" && <EligibilityConfiguration setError={setError} />}
      {currentTab === "bigBot" && <BigBotConfiguration setError={setError} />}
      {currentTab === "translations" && <TranslationsConfiguration setError={setError} />}
      {currentTab === "advanced" && <AdvancedConfiguration setError={setError} />}
      {serviceStore.isLoading && <Loading />}
    </div>
  )
}

export default observer(Configuration)
